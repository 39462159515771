<template>
  <div class="chart-wrapper trading-vol">
    <SectionHeader
        :is-deletable="isDeletable"
        :is-filters-open="isFiltersOpen"
        :show-copy-chart-to-pd="showCopyToPd"
        :title="title"
        @copyChartToPD="$emit('copyChartToPD', parameters)"
        @removeItem="removeItem"
        @setStretchPosition="setStretchPosition"
        @toggleFiltersVisibility="isFiltersOpen = $event"
    />

    <transition name="expand-filters">
      <div
          v-show="isFiltersOpen"
          ref="sectionFilters"
          class="chart-filters"
      >
        <div class="chart-filter">
          <Select
              ref="asset"
              :default-option-value="parametersForSelects.assets || defaultAsset"
              :options="assets"
              title="asset"
              @selected="updateParameter($event, 'assets')"
          />
        </div>

        <div class="chart-filter">
          <MultiSelect
              ref="exchanges"
              :default-option-value="parametersForSelects.exchanges || defaultExchanges"
              :options="exchanges"
              title="exchanges"
              @selected="updateParameter($event, 'exchangeIds')"
          />
        </div>

        <div class="chart-filter">
          <Select
              ref="pair"
              :default-option-value="parametersForSelects.pair || defaultPair"
              :options="pairs"
              title="pair"
              @emptySelect="parameters.currencyPair = defaultPair"
              @selected="updateParameter($event, 'currencyPair')"
          />
        </div>

        <div class="chart-filter">
          <Select
              ref="dimension"
              :default-option-value="parametersForSelects.dimension || defaultDimension"
              :options="dimensions"
              title="time period"
              @selected="updateParameter($event, 'dimension')"
          />
        </div>

        <div class="chart-filter reset">
          <button :disabled="resetDisabled || showPreloader" class="reset" @click="resetParameters">
            Reset
          </button>
        </div>
      </div>
    </transition>

    <div class="chart-content">
      <div v-show="showPreloader" class="main-preloader widget-preloader chart-preloader">
        <Loader
            ref="preloader"
            :animationData="require('@/assets/images/Loader.json')"
            :autoPlay="true"
            :loop="true"
            :speed="1"
        />
      </div>
      <highcharts
          ref="chart"
          :options="chartOptions"
          :style="{ width: '100%', height: chartHeight }"
          constructor-type="stockChart"
      />
    </div>
  </div>
</template>

<script>
import volumeByPrice from '@/mixins/chart-settings/volumeByPrice';
import { mapActions } from 'vuex';
import commonFilters from '@/mixins/filters/commonFilters';
import debounce from '@/mixins/debounce';
import { ws } from '@/utils/socket';
import propsParameters from '@/mixins/propsParameters';

export default {
  name: 'VolumeByPrice',

  components: {},

  mixins: [debounce, volumeByPrice, commonFilters, propsParameters],

  props: {
    isResizeble: Boolean,
  },

  data: () => ({
    parameters: {},
    wsParameters: null,
    showPreloader: true,
    resetDisabled: true,
    isFiltersOpen: true,
  }),

  computed: {
    title() {
      const type = this.assets.get(this.parameters.assets || this.parametersForSelects.assets || this.defaultAsset);

      return `Trading vol distribution ${type}`;
    },

    defaultParameters() {
      return {
        exchangeIds: this.defaultExchanges,
        assets: this.defaultAsset,
        currencyPair: this.defaultPair,
        dimension: this.defaultDimension,
      };
    },
  },

  methods: {
    ...mapActions({
      getVolumeByPriceData: 'charts/getVolumeByPrice',
    }),

    updateParameter(parameter, name) {
      this.resetDisabled = false;
      this.parameters = {
        ...this.parameters,
        [name]: parameter
      };

      this.getChartData();
    },

    async resetParameters() {
      this.resetDisabled = true;
      this.parameters = {
        ...this.defaultParameters,
        ...this.parametersForSelects,
      };

      await this.getChartData();

      this.$refs.asset.reset();
      this.$refs.exchanges.reset();
      this.$refs.pair.reset();
      this.$refs.dimension.reset();
    },

    setStretchPosition() {
      this.$refs.chart?.chart.fullscreen.toggle();
    },

    generateColor(percent) {
      const percentToColor = {
        0: '#005952',
        20: '#28726e',
        40: '#33afaa',
        60: '#11d0c8',
        80: '#29c8c0',
      };

      const percentageFound = Object.keys(percentToColor).find(key => percent <= parseInt(key, 10));

      return percentToColor[percentageFound ?? '80'];
    },

    updateZones() {
      const splineData = this.chartOptions.series[0].data;
      const zones = [];
      const prices = splineData.map(e => e[1]);
      const min = Math.min(...prices);
      const max = Math.max(...prices) - min;
      for (let i = 0; i < splineData.length; i++) {
        const percentBid = Math.abs(((splineData[i][1] - min) / max) * 100);

        zones.push({
          value: splineData[i][0],
          color: this.generateColor(percentBid)
        });
      }

      this.chartOptions.yAxis[0].plotLines[0].value = splineData.at(-1)[1];

      if (this.$refs.chart) {
        this.$refs.chart.chart.series[0].update({ zones });
      }
    },

    getChartData() {
      this.showPreloader = true;
      this.debounce(async () => {
        this.unsubscribeVolumeByPrice();

        const { assets, currencyPair } = this.parameters;
        this.wsParameters = JSON.stringify({ assets: [assets], currencyPair: currencyPair.split(',') });

        try {
          const res = await this.getVolumeByPriceData(this.parameters);
          const volume = [];
          this.chartOptions.series[0].data = res.map(item => {
            volume.push([item.time, item.volume]);

            return [item.time, item.price];
          });
          this.chartOptions.series[1].data = volume;

          this.updateZones();
          this.parameters.dimension === this.defaultDimension
              ? this.subscribeVolumeByPrice()
              : this.wsParameters = null;

        } catch (e) {
          console.log(e);
        } finally {
          this.showPreloader = false;
        }
      }, 500);
    },

    subscribeVolumeByPrice() {
      ws.subscribe(
          'VOLUME_BY_PRICES',
          {
            method: 'SUBSCRIBE',
            params: this.wsParameters
          },
          (response) => this.debounce(() => {
            if (response.params === this.wsParameters) {
              let timeInMs = 0;
              const volumeByPrices = Object.values(response.response);
              const avgPrice = volumeByPrices.reduce((acc, vol) => acc + vol[1], 0) / volumeByPrices.length;
              const avgVolume = volumeByPrices.reduce((acc, vol) => {
                timeInMs = vol[0];
                return acc + vol[2];
              }, 0) / volumeByPrices.length;

              this.chartOptions.series[0].data.shift();
              this.chartOptions.series[0].data.push([timeInMs, avgPrice]);
              this.chartOptions.series[1].data.shift();
              this.chartOptions.series[1].data.push([timeInMs, avgVolume]);
              this.updateZones();
            }
          }, 100)
      );
    },

    unsubscribeVolumeByPrice() {
      ws.unsubscribe(
          'VOLUME_BY_PRICES',
          {
            method: 'UNSUBSCRIBE',
            params: this.wsParameters
          },
      );

      this.wsParameters = null;
    },

    removeItem() {
      this.$emit('removeItem', null);
    },
  },

  created() {
    this.getChartData();
  },

  beforeDestroy() {
    this.unsubscribeVolumeByPrice();
  }
};
</script>

<style scoped>
</style>
