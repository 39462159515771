<template>
  <div class="not-found">
    <div class="not-found__container">
      <h1 class="not-found__title">
        Ooops!
        <br>
        Something’s not right.
      </h1>
      <p class="not-found__description">
        Please check the URL again or let us take you bake to the Terminal
      </p>
      <button
          class="not-found__back ascent-btn"
          type="button"
          @click="$router.replace({ name: 'liquidity' })"
      >
        Back to Terminal
      </button>
      <img
          alt="error"
          class="not-found__image"
          src="@/assets/images/page-not-found.svg"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound',
};
</script>

<style lang="scss" scoped>
.not-found {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  &__container {
    margin: 82px auto 0;
    width: 100%;
    max-width: 854px;
    padding: 0 20px;
    text-align: center;
  }

  &__title {
    margin: 0 0 12px;
    font-size: 2rem;
    line-height: 1.5;
  }

  &__description {
    font-size: 0.7rem;
    color: var(--textSecondaryColor);
  }

  &__back {
    margin: 20px 0 12px;
    border-radius: 6px;
    padding: 12px;
  }

  &__image {
    width: 100%;
    height: 100%;
    max-height: 279px;
  }
}
</style>