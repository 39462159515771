<template>
  <div class="chart-wrapper">
    <div class="layout-item-content custom-scroll">
      <div class="exchange-header">
        <LazyImg :id="exchangeId" :is-token="false" :size="64" />
        <div class="exchange-title">
          <h3 class="exchange-name">{{ exchange.displayName }}</h3>
        </div>
      </div>
      <div class="exchange-social">
        <ul class="exchange-social-list">
          <li class="exchange-social-item">
            <a
                :href="exchange.linkExchange"
                class="exchange-social-link"
                target="_blank"
            >
              <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M12.2422 10.3569L11.2993 9.41411L12.2422 8.47131C13.5439 7.16957 13.5439 5.05902 12.2422 3.75727C10.9404 2.45553 8.82988 2.45553 7.52815 3.75727L6.58533 4.70009L5.64253 3.75727L6.58533 2.81447C8.40781 0.99202 11.3625 0.99202 13.185 2.81447C15.0074 4.63691 15.0074 7.59171 13.185 9.41411L12.2422 10.3569ZM10.3565 12.2426L9.41375 13.1854C7.59135 15.0078 4.63655 15.0078 2.8141 13.1854C0.991654 11.3629 0.991654 8.40817 2.8141 6.5857L3.75691 5.64289L4.69971 6.5857L3.75691 7.52851C2.45516 8.83024 2.45516 10.9408 3.75691 12.2426C5.05865 13.5443 7.16921 13.5443 8.47095 12.2426L9.41375 11.2998L10.3565 12.2426ZM9.88515 5.17149L10.8279 6.1143L6.11393 10.8284L5.17112 9.88551L9.88515 5.17149Z"
                    fill="white"
                />
              </svg>
              <span class="exchange-social-text">{{ exchange.linkExchange }}</span>
              <svg fill="none" height="12" viewBox="0 0 12 12" width="12" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M4.66667 0V1.33333H1.33333V10.6667H10.6667V7.33333H12V11.3333C12 11.7015 11.7015 12 11.3333 12H0.666667C0.29848 12 0 11.7015 0 11.3333V0.666667C0 0.29848 0.29848 0 0.666667 0H4.66667ZM9.72387 1.33333H6.66667V0H12V5.33333H10.6667V2.27614L6 6.9428L5.0572 6L9.72387 1.33333Z"
                    fill="#92939C"
                />
              </svg>
            </a>
          </li>
          <li class="exchange-social-item">
            <a
                :href="exchange.linkFees"
                class="exchange-social-link"
                target="_blank"
            >
              <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M1.33398 13.3334H14.6673V14.6667H1.33398V13.3334ZM2.66732 8.00004H4.00065V12.6667H2.66732V8.00004ZM6.00065 8.00004H7.33398V12.6667H6.00065V8.00004ZM8.66732 8.00004H10.0007V12.6667H8.66732V8.00004ZM12.0007 8.00004H13.334V12.6667H12.0007V8.00004ZM1.33398 4.66671L8.00065 1.33337L14.6673 4.66671V7.33337H1.33398V4.66671ZM8.00065 5.33337C8.36885 5.33337 8.66732 5.03489 8.66732 4.66671C8.66732 4.29852 8.36885 4.00004 8.00065 4.00004C7.63245 4.00004 7.33398 4.29852 7.33398 4.66671C7.33398 5.03489 7.63245 5.33337 8.00065 5.33337Z"
                    fill="#E9E9E9"
                />
              </svg>
              <span class="exchange-social-text">Fees</span>
              <svg fill="none" height="12" viewBox="0 0 12 12" width="12" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M4.66667 0V1.33333H1.33333V10.6667H10.6667V7.33333H12V11.3333C12 11.7015 11.7015 12 11.3333 12H0.666667C0.29848 12 0 11.7015 0 11.3333V0.666667C0 0.29848 0.29848 0 0.666667 0H4.66667ZM9.72387 1.33333H6.66667V0H12V5.33333H10.6667V2.27614L6 6.9428L5.0572 6L9.72387 1.33333Z"
                    fill="#92939C"
                />
              </svg>
            </a>
          </li>
          <li class="exchange-social-item">
            <a
                :href="exchange.linkTwitter"
                class="exchange-social-link"
                target="_blank"
            >
              <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M14.8083 3.7707C14.2994 3.99583 13.7597 4.1437 13.2071 4.20941C13.7893 3.86088 14.2249 3.3126 14.4329 2.6667C13.8858 2.99174 13.287 3.22051 12.6625 3.34307C12.1354 2.78071 11.3987 2.46202 10.6279 2.46289C9.0884 2.46289 7.8398 3.71107 7.8398 5.25122C7.8398 5.46972 7.86487 5.68161 7.91174 5.88606C5.59365 5.76936 3.54005 4.66003 2.16452 2.97294C1.91686 3.39845 1.7867 3.88212 1.78736 4.37445C1.78736 5.34173 2.27974 6.19549 3.02766 6.69533C2.58492 6.68133 2.15192 6.56176 1.76479 6.34649C1.76436 6.3582 1.76436 6.36991 1.76436 6.38099C1.76436 7.73227 2.72546 8.85947 4.00133 9.1154C3.76187 9.1802 3.51488 9.21293 3.26682 9.21273C3.08665 9.21273 2.91181 9.19613 2.74186 9.16353C3.09688 10.271 4.12591 11.0773 5.3464 11.0994C4.35876 11.8745 3.13913 12.2951 1.88362 12.2933C1.66142 12.2935 1.4394 12.2803 1.21875 12.2541C2.49336 13.0735 3.97702 13.5083 5.49228 13.5067C10.6211 13.5067 13.4253 9.25833 13.4253 5.57407C13.4253 5.45353 13.4224 5.33257 13.4171 5.21331C13.9629 4.81897 14.434 4.33045 14.8083 3.7707Z"
                    fill="#E9E9E9"
                />
              </svg>
              <span class="exchange-social-text">Twitter</span>
              <svg fill="none" height="12" viewBox="0 0 12 12" width="12" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M4.66667 0V1.33333H1.33333V10.6667H10.6667V7.33333H12V11.3333C12 11.7015 11.7015 12 11.3333 12H0.666667C0.29848 12 0 11.7015 0 11.3333V0.666667C0 0.29848 0.29848 0 0.666667 0H4.66667ZM9.72387 1.33333H6.66667V0H12V5.33333H10.6667V2.27614L6 6.9428L5.0572 6L9.72387 1.33333Z"
                    fill="#92939C"
                />
              </svg>
            </a>
          </li>
        </ul>
      </div>
      <div class="exchange-info">
        <div v-if="exchange.spotTradingVolume" class="exchange-info-item">
          <div class="exchange-info-item-title">
            <span class="exchange-info-item-title-value">Spot Trading Volume (24h)</span>
            <v-tooltip max-width="400" top>
              <template v-slot:activator="{ on, attrs }">
                <svg
                    class="info-icon"
                    fill="none"
                    height="16"
                    v-bind="attrs"
                    viewBox="0 0 16 16"
                    width="16"
                    xmlns="http://www.w3.org/2000/svg" v-on="on"
                >
                  <path
                      clip-rule="evenodd"
                      d="M7.20898 5.19649C7.20898 4.82968 7.55646 4.51114 8.0005 4.51114C8.43486 4.51114 8.78241 4.82968 8.78241 5.19649C8.78241 5.56333 8.43488 5.89152 8.0005 5.89152C7.55649 5.89152 7.20898 5.56333 7.20898 5.19649ZM7.25716 7.23327C7.25716 6.963 7.56605 6.77955 8.00044 6.77955C8.41552 6.77955 8.75334 6.96297 8.75334 7.23322V10.911C8.75334 11.1427 8.41552 11.3743 8.00044 11.3743C7.56605 11.3743 7.25716 11.1427 7.25716 10.911V7.23327Z"
                      fill="#92939C"
                      fill-rule="evenodd"
                  />
                  <circle cx="8" cy="8" r="7" stroke="#828392" stroke-width="1.5" />
                </svg>
              </template>
              <span>{{ 'description' }}</span>
            </v-tooltip>
          </div>
          <div class="exchange-info-item-value">
            <span class="exchange-info-item-volume">
              ${{ prettifyNumber(String(aroundNumber(exchange.spotTradingVolume) || 0)) }}
            </span>
            <div
                v-if="exchange.spotTradingVolumeChangePercent"
                :class="{'up' : exchange.spotTradingVolumeChangePercent > 0, 'down' : exchange.spotTradingVolumeChangePercent <= 0 }"
                class="exchange-percent-wrap"
            >
              <svg fill="none" height="4" viewBox="0 0 8 4" width="8" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M6.81948 0.666748H1.18165C0.724524 0.666748 0.495963 1.17269 0.819758 1.46928L3.28631 3.72857C3.68153 4.09058 4.32436 4.09058 4.71958 3.72857L5.65763 2.86934L7.18613 1.46928C7.50516 1.17269 7.2766 0.666748 6.81948 0.666748Z"
                    fill="white"
                />
              </svg>
              <span>{{ exchange.spotTradingVolumeChangePercent.toFixed(2) }}%</span>
            </div>
          </div>
          <!--          <span class="exchange-statistic-item-amount">350,311 <span>{{'BTC'}}</span></span>-->
        </div>
        <div v-if="exchange.totalAssets" class="exchange-info-item">
          <div class="exchange-info-item-title">
            <span class="exchange-info-item-title-value">Total Assets</span>
            <v-tooltip max-width="400" top>
              <template v-slot:activator="{ on, attrs }">
                <svg
                    class="info-icon"
                    fill="none"
                    height="16"
                    v-bind="attrs"
                    viewBox="0 0 16 16"
                    width="16"
                    xmlns="http://www.w3.org/2000/svg" v-on="on"
                >
                  <path
                      clip-rule="evenodd"
                      d="M7.20898 5.19649C7.20898 4.82968 7.55646 4.51114 8.0005 4.51114C8.43486 4.51114 8.78241 4.82968 8.78241 5.19649C8.78241 5.56333 8.43488 5.89152 8.0005 5.89152C7.55649 5.89152 7.20898 5.56333 7.20898 5.19649ZM7.25716 7.23327C7.25716 6.963 7.56605 6.77955 8.00044 6.77955C8.41552 6.77955 8.75334 6.96297 8.75334 7.23322V10.911C8.75334 11.1427 8.41552 11.3743 8.00044 11.3743C7.56605 11.3743 7.25716 11.1427 7.25716 10.911V7.23327Z"
                      fill="#92939C"
                      fill-rule="evenodd"
                  />
                  <circle cx="8" cy="8" r="7" stroke="#828392" stroke-width="1.5" />
                </svg>
              </template>
              <span>{{ 'description' }}</span>
            </v-tooltip>
          </div>
          <div class="exchange-info-item-value">
            <span class="exchange-info-item-volume">${{ prettifyNumber(String(aroundNumber(exchange.totalAssets)), 2) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import prettifyNumber from "@/mixins/helpers/prettifyNumber";
import aroundNumber from "@/mixins/helpers/aroundNumber";

export default {
  name: "Info",

  data: () => ({
    parameters: {},
  }),

  mixins: [prettifyNumber, aroundNumber],

  computed: {
    ...mapGetters({
      exchangesInfo: 'filters/exchangesInfo',
      exchangeSlugs: 'filters/exchangeSlugs'
    }),

    exchangeId() {
      return this.exchangeSlugs[this.$route.params.exchange];
    },

    exchange() {
      return this.exchangesInfo[this.exchangeId];
    },
  },
};
</script>

<style scoped>

.exchange-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}

.exchange-header object {
  display: inline-block;
  width: 64px;
  height: 64px;
}

.exchange-title {
  margin-left: 10px;
  width: calc(100% - 74px);
}

.exchange-name {
  display: block;
  margin-bottom: 2px;
  font-family: "Play", sans-serif;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  line-height: 30px;
  color: var(--textMainColor);
}

.exchange-rank {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  line-height: 21px;
  color: var(--textSecondaryColor);
}

.exchange-social {
  margin-bottom: 50px;
}

.exchange-social-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 0;
  gap: 5px;
}

.exchange-social-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  padding: 5px 9px;
  background: #383838;
  text-transform: none;
  gap: 6px;
}

.exchange-social-link {

}

.exchange-social-text {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  line-height: 16px;
  color: #e9e9e9;
}

.exchange-social-link svg {

}

.exchange-info {

}

.exchange-info-item:not(:last-child) {
  margin-bottom: 34px;
}

.exchange-info-item-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  line-height: 16px;
  color: var(--textSecondaryColor);
}

.exchange-info-item-title-value {
  display: inline-block;
  margin-right: 5px;
}

.exchange-info-item-value {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.exchange-percent-wrap {
  margin-left: 10px;
}

.exchange-info-item-volume {
  font-family: "Play", sans-serif;
  font-size: 32px;
  font-weight: 700;
  font-style: normal;
  line-height: 48px;
  color: var(--textMainColor);
}

.exchange-info-item-amount {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  line-height: 16px;
  color: #e9e9e9;
}

.exchange-info-item-amount span {

}

</style>