<template>
  <div class="chart-wrapper">
    <SectionHeader
        ref="sectionHeader"
        :is-deletable="isDeletable"
        :is-filters-open="isFiltersOpen"
        :searchable="true"
        :show-copy-chart-to-pd="showCopyToPd"
        :stretchable="false"
        info-text="description"
        title="Top Cryptocurrency Exchanges"
        @copyChartToPD="$emit('copyChartToPD', {})"
        @removeItem="$emit('removeItem', null)"
        @search="search"
        @setStatic="$emit('setStatic', $event)"
        @toggleFiltersVisibility="isFiltersOpen = $event"
    />

    <transition name="expand-filters">
      <div
          v-show="isFiltersOpen"
          ref="sectionFilters"
          class="chart-filters max-screen-width"
      >
        <div class="filter-btns">
          <div class="chart-filter btn-filter add">
            <button class="add" @click="showFiltersPopup">
              <!--              <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">-->
              <!--                <path d="M1 5H9M5 9V1" stroke="#35D9D1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>-->
              <!--              </svg>-->
              <span>Advanced Filters</span>
            </button>
          </div>
          <div class="chart-filter btn-filter reset">
            <button :disabled="resetDisabled || showPreloader" class="reset" @click="resetParameters">
              <!--              <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">-->
              <!--                <path d="M1.17157 1.17157L6.82843 6.82842M1.17157 6.82842L6.82843 1.17157" stroke="#F27870" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>-->
              <!--              </svg>-->
              Clear All
            </button>
          </div>
        </div>
      </div>
    </transition>

    <div ref="chartContent" class="chart-content">
      <div v-show="showPreloader" class="main-preloader widget-preloader chart-preloader">
        <Loader
            ref="preloader"
            :animationData="require('@/assets/images/Loader.json')"
            :autoPlay="true"
            :loop="true"
            :speed="1"
        />
      </div>

      <div class="custom-scroll max-screen-width">
        <table>
          <thead>
            <tr>
              <th class="rank">
                <div class="header-wrapper">#</div>
              </th>
              <th
                  v-for="([value, info], index) in parameters.visibleColumnsFilters"
                  v-show="parameters.visibleColumns.includes(value)"
                  :key="index"
                  :class="value"
              >
                <button
                    :class="{
                    'sorted': sortBy === value,
                  }"
                    class="header-wrapper"
                    @click="sortTable(value, info[1])"
                >
                  <svg
                      class="arrows"
                      fill="none"
                      height="13"
                      viewBox="0 0 12 13"
                      width="12"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                        :class="{active: !reversed}"
                        d="M9 4.49998L6 1.49999L3 4.49998"
                        stroke="#92939C"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                    />
                    <path
                        :class="{active: reversed}"
                        d="M3 8.50002L6 11.5L9 8.50002"
                        stroke="#92939C"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                    />
                  </svg>

                  {{ info[0] }}
                </button>
              </th>
            </tr>
          </thead>

          <NoData
              v-show="!showPreloader && !totalPages"
              text="There doesn't seem to be any data. <br /> Try other filters"
          />

          <tbody>
            <tr
                v-for="(exchange, index) in visibleExchanges"
                :key="index"
                ref="row"
            >
              <td class="rank">{{ index + 1 }}</td>

              <td v-show="parameters.visibleColumns.includes('displayName')" class="pair">
                <div class="pair-wrapper">
                  <object
                      :data="`data:image/png;base64, ${exchange.iconBase64}`"
                      type="image/png"
                  >
                    <img :src="require(`@/assets/images/default-token-icon.svg`)" />
                  </object>

                  <div class="pair-info">
                    <router-link
                        :to="{ path: `/exchange/${exchange.slug}` }"
                        class="pair-info__link"
                    >
                      <span>
                        {{ exchange.displayName }}
                      </span>
                    </router-link>
                  </div>
                </div>
              </td>

              <td v-show="parameters.visibleColumns.includes('spotTradingVolume')">
                <div class="trading-vol">
                  <span :class="setGrowthDirection(exchange.spotTradingVolumeChangePercent)" class="growth">
                    <svg fill="none" height="4" viewBox="0 0 8 4" width="8" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M1.3231 3.33325L6.96093 3.33325C7.41805 3.33325 7.64661 2.82731 7.32282 2.53072L4.85627 0.271426C4.46105 -0.0905843 3.81822 -0.0905844 3.423 0.271426L2.48495 1.13066L0.956451 2.53072C0.637418 2.82731 0.865978 3.33325 1.3231 3.33325Z"
                          fill="#fff"
                      />
                    </svg>
                    {{ exchange.spotTradingVolumeChangePercent.toFixed(2) }}%
                  </span>
                  ${{ prettifyNumber(exchange.spotTradingVolume) }}
                </div>
              </td>

              <td v-show="parameters.visibleColumns.includes('weeklyVisits')">
                {{ prettifyNumber(exchange.weeklyVisits) }}
              </td>

              <td v-show="parameters.visibleColumns.includes('liquidity')">
                {{ exchange.liquidity }}
              </td>

              <td v-show="parameters.visibleColumns.includes('markets')">
                {{ exchange.markets }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <CustomPagination
          v-if="totalPages > 1"
          :page-count="size"
          :page-number="page"
          :pagination-size="totalPages"
          :total-items="totalExchanges"
          @pageChanged="page = $event"
      />

      <Drawer
          ref="drawer"
          @apply="applyFilters"
          @reset="resetFilters"
      >
        <div class="popup-filters-list">
          <div class="popup-filters-category">
            <span class="popup-filters-category-title">Exchange:</span>
            <div class="popup-filters-item">
              <span class="popup-filters-item-title">Exchange Type</span>
              <div class="checkbox-list">
                <v-checkbox
                    v-model="filters.exchangeTypeFilters"
                    label="DEX"
                    value="dex"
                ></v-checkbox>
                <v-checkbox
                    v-model="filters.exchangeTypeFilters"
                    label="CEX"
                    value="cex"
                ></v-checkbox>
              </div>
            </div>
            <div class="popup-filters-item">
              <span class="popup-filters-item-title">Exchange Liquidity</span>
              <Range
                  :default-value="filters.exchangeLiquidityRange"
                  :max="defaultFilters.exchangeLiquidityRange[1]"
                  :min="defaultFilters.exchangeLiquidityRange[0]"
                  title=""
                  @update="filters.exchangeLiquidityRange = $event"
              />
            </div>
          </div>
          <div class="popup-filters-category">
            <span class="popup-filters-category-title">General:</span>
            <div class="popup-filters-item">
              <span class="popup-filters-item-title">Columns</span>
              <div class="checkbox-list">
                <v-checkbox
                    v-for="(column, i) in [...columns.keys()]"
                    :key="i"
                    v-model="filters.visibleColumns"
                    :disabled="filters.visibleColumns[0] === column && filters.visibleColumns.length === 1"
                    :label="filters.visibleColumnsFilters.get(column)[0]"
                    :value="column"
                />
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import prettifyNumber from '@/mixins/helpers/prettifyNumber';
import aroundNumber from '@/mixins/helpers/aroundNumber';
import sortByProperty from '@/mixins/helpers/sortByProperty';
import Drawer from '@/components/ui/popups/Drawer.vue';
import tableFilters from '@/mixins/filters/tableFilters';
import Range from '@/components/ui/inputs/Range.vue';

export default {
  name: 'SpotExchanges',

  props: {
    isResizeble: Boolean,

    showCopyToPd: {
      type: Boolean,
      default: false,
      required: false,
    },

    isDeletable: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  components: {
    Drawer,
    Range,
  },

  mixins: [tableFilters, prettifyNumber, aroundNumber, sortByProperty],

  data: () => ({
    isFiltersOpen: true,
    showPreloader: false,
    size: 10,
    page: 0,
    searchText: '',
    parameters: {},
    reversed: false,
    sortBy: 'displayName',
    sortType: 'string',
    resetDisabled: true,
    exchangeType: ['dex', 'cex'],
    filters: {},
    defaultFilters: {
      exchangeLiquidityRange: [0, 10000],
      tokenLiquidityRange: [0, 10000],
      tokenMarketCapRange: [0, 10000],
      crossExchangeSwitcherValue: true,
      visibleColumnsFilters: new Map([
        ['displayName', ['Exchange', 'string']],
        ['spotTradingVolume', ['Trading Volume(24h)', 'number']],
        ['weeklyVisits', ['Weekly Visits', 'number']],
        ['liquidity', ['Avg. liquidity', 'number']],
        ['markets', ['# Markets', 'number']],
      ]),
      visibleColumns: [],
      exchangeTypeFilters: ['dex', 'cex'],
      dealLengthFilter: 1,
    },
  }),

  watch: {
    isResizeble() {
      this.setSize();
    },
  },

  computed: {
    ...mapGetters({
      exchangesInfo: 'filters/exchangesInfo',
    }),

    // exchanges() {
    //   const exchanges = Object.values(this.exchangesInfo);
    //
    //   return this.sortByProperty(exchanges, this.sortBy, this.sortAscending);
    // },

    exchanges() {
      const exchangesData = Object.values(this.exchangesInfo);

      const {
        sortBy,
        sortType,
        parameters: {
          exchangeLiquidityRange: [liquidityFrom, liquidityTo],
          exchangeTypeFilters: types,
        },
        searchText,
        reversed,
      } = this;

      const arr = [...exchangesData];

      const filterArray = arr.filter(obj => (
          types.includes(obj.type) &&
          obj.liquidity >= liquidityFrom &&
          obj.liquidity <= liquidityTo
      ));

      let sortedArray = this.sorting(filterArray, sortBy, sortType);

      if (searchText && searchText.length) {
        sortedArray = this.filteredSearchResult(sortedArray, searchText);
      }

      return reversed ? sortedArray.reverse() : sortedArray;
    },

    totalExchanges() {
      return this.exchanges.length;
    },

    totalPages() {
      return Math.ceil(this.totalExchanges / this.size);
    },

    visibleExchanges() {
      const from = this.page * this.size;
      const to = (this.page + 1) * this.size;

      return this.exchanges.slice(from, to);
    },

    columns() {
      const exceptionsColumn = ['displayName'];
      return new Map(Array.from(this.filters.visibleColumnsFilters).filter(column => !exceptionsColumn.includes(column[0])));
    },
  },

  methods: {

    setSize() {
      const rowHeight = this.$refs.row[0].clientHeight;

      this.size = Math.max(Math.floor((this.$refs.chartContent.clientHeight - rowHeight / 2) / rowHeight) - 1, 1);
      this.page = 0;
    },

    setGrowthDirection(number) {
      if (!number) {
        return '';
      }

      return number > 0 ? 'upward' : 'downward';
    },

    getMinMaxValue() {
      const exchangesLiquidity = [];
      this.exchanges.forEach(exchange => {
        exchangesLiquidity.push(exchange.liquidity);
      });

      this.defaultFilters.exchangeLiquidityRange[0] = Math.ceil(Math.min(...exchangesLiquidity));
      this.defaultFilters.exchangeLiquidityRange[1] = Math.ceil(Math.max(...exchangesLiquidity));

      this.filters = this.deepClone(this.defaultFilters);
      const filters = this.deepClone(this.filters);
      this.parameters = { ...this.parameters, ...filters };
    },

    search(text) {
      this.searchText = text;
    },

    filteredSearchResult(arr, text) {
      const filterLowered = text.toLowerCase();

      return arr.filter(item => {
        const { displayName } = item;
        const itemLowered = {
          displayName: displayName.toLowerCase(),
        };

        return Object.values(itemLowered).some(value => value.includes(filterLowered));
      });
    },

  },

  mounted() {
    this.$nextTick(() => {
      this.setSize();
      this.getMinMaxValue();
    });
  }
};
</script>

<style lang="scss" scoped src="@/assets/css/secondary-table.scss" />
<style lang="scss" scoped src="@/assets/css/filters.scss" />
<style lang="scss" scoped>
.custom-pagination {
  padding: 0 15px 0 30px;
}

.trading-vol {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

th.displayName button {
  margin-right: auto;
  margin-left: 0;
}

th button {
  margin-left: auto;
}
</style>
