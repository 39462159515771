import { DefaultPaletteProvider, EFillPaletteMode, } from 'scichart/Charting/Model/IPaletteProvider';
import { parseColorToUIntArgb } from 'scichart/utils/parseColor';
import { uintArgbColorMultiplyOpacity } from 'scichart/utils/colorUtil';

export class MountainPaletteProvider extends DefaultPaletteProvider {
  constructor({ fill, fillIntersection, intersection, type }) {
    super();
    this.fillPaletteMode = EFillPaletteMode.SOLID;
    this.fill = parseColorToUIntArgb(fill);
    this.fillIntersection = parseColorToUIntArgb(fillIntersection);
    this.from = intersection.from;
    this.to = intersection.to;
    this.type = type;
  }

  overrideFillArgb(xValue) {
    const range = this.type === 'bids' ? xValue > this.from && xValue <= this.to : xValue >= this.from && xValue < this.to;
    if (range) {
      return uintArgbColorMultiplyOpacity(this.fillIntersection, 1);
    }

    return uintArgbColorMultiplyOpacity(this.fill, 1);
  }
}