<template>
  <WidgetLayout
      :layout-prop="layout"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import WidgetLayout from '@/layouts/WidgetLayout';

export default {
  name: 'DEXs',

  components: {
    WidgetLayout,
  },

  computed: {
    ...mapGetters({
      layout: 'layouts/DEXsLayout',
    })
  },
};
</script>

<style scoped>
</style>