import { crosshair, plotLine } from '@/mixins/chart-settings/_commonSettings';
import moment from 'moment/moment';
import reflowHighchart from '@/mixins/reflowHighchart';
import { makeCompactNumber } from '@/helpers';
import noExponents from "@/mixins/helpers/noExponents";

export default {
  mixins: [reflowHighchart, noExponents],

  data() {
    const _this = this;
    return {
      chartOptions: {
        chart: {
          backgroundColor: 'var(--mainBGColor)',
        },

        xAxis: [
          {
            crosshair: {
              ...crosshair,

              label: {
                ...crosshair.label,

                formatter(event) {
                  return moment(event).format('YYYY/DD/MM hh:mm');
                }
              }
            },
            gridLineWidth: 1,
            gridLineColor: 'var(--gridLineColor)',
            lineWidth: 1,
          }
        ],

        yAxis: [
          {
            crosshair: {
              ...crosshair,
              label: {
                ...crosshair.label,

                formatter(val) {
                  const number = _this.noExponents(val)
                  return +val < 1 ? _this.noExponents(_this.trimNumber(number)) : val.toFixed(2);
                }
              }
            },

            gridLineWidth: 1,
            gridLineColor: 'var(--gridLineColor)',
            lineWidth: 1,
            plotLines: [
              {
                ...plotLine,
                value: 0,
                color: '#464646',

                label: {
                  ...plotLine.label,

                  style: {
                    ...plotLine.label.style,
                    backgroundColor: '#464646',
                  },

                  formatter() {
                    const number = _this.noExponents(this.options.value)
                    return +this.options.value < 1 ? _this.noExponents(_this.trimNumber(number)) : this.options.value.toFixed(2);
                  }
                }
              }
            ],
            labels: {
              align: 'left',
              formatter: (event) => {
                return +_this.noExponents(event.value) < 1 ? _this.noExponents(event.value) : _this.noExponents(event.value.toFixed(2));
              }
            },
            height: '80%',
            resize: {
              enabled: true
            }
          },
          {
            crosshair: {
              ...crosshair,
              label: {
                ...crosshair.label,

                formatter: val => this.makeCompactNumber(val)
              }
            },

            gridLineWidth: 1,
            gridLineColor: 'var(--gridLineColor)',
            lineWidth: 1,
            labels: {
              align: 'left'
            },
            top: '80%',
            height: '20%',
            offset: 0
          }
        ],

        rangeSelector: {
          enabled: false,
        },

        exporting: {
          enabled: false,
        },

        scrollbar: {
          enabled: false
        },

        plotOptions: {
          areaspline: {
            threshold: 150,
            color: '#03c9c0',
            negativeColor: '#f37870',

            negativeFillColor: {
              linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
              stops: [
                [0, 'rgba(242, 120, 112, 0.05)'],
                [1, 'rgba(242, 120, 112, 0.2)']
              ]
            },
            fillColor: {
              linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
              stops: [
                [0, 'rgba(66, 232, 224, 0.2)'],
                [1, 'rgba(66, 232, 224, 0.05)']
              ]
            }
          }
        },

        navigator: {
          // adaptToUpdatedData: true,
          maskFill: 'rgba(57, 57, 57, 0.5)',
          maskInside: true,
          outlineWidth: 0,
          handles: {
            borderWidth: 2,
            backgroundColor: '#91939c',
            borderColor: '#91939c',
          },

          series: {
            type: 'areaspline',
            fillOpacity: 1,
            lineWidth: 1
          },

          xAxis: {
            gridLineWidth: 1,
            gridLineColor: '#464646',
          },
        },

        series: [
          {
            type: 'areaspline',
            name: 'AAPL Stock Price',
            data: [],
          },
          {
            type: 'column',
            data: [],
            color: '#464646',
            yAxis: 1
          }
        ],
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 800
              },
              chartOptions: {
                rangeSelector: {
                  inputEnabled: false
                }
              }
            }
          ]
        }
      }
    }
  },

  methods: {
    makeCompactNumber,

    trimNumber(number) {
      const trimmed = parseFloat(number).toPrecision(3);
      return parseFloat(trimmed);
    }
  },
};