<template>
  <div class="walls-chart chart-wrapper">
    <div>
      <SectionHeader
          :connection-type="connectionType"
          :is-deletable="isDeletable"
          :is-filters-open="isFiltersOpen"
          :show-copy-chart-to-pd="showCopyToPd"
          :stretchable="false"
          :title="title"
          info-text="Consolidated orderbook for popular pairs, with a choice of exchanges, type (spot, perp), orderbook size by price (slippage %) and rounding."
          @copyChartToPD="$emit('copyChartToPD', parameters)"
          @removeItem="removeItem"
          @setStatic="$emit('setStatic', $event)"
          @setStretchPosition="$emit('setStretchPosition', $event)"
          @toggleFiltersVisibility="isFiltersOpen = $event"
      />

      <transition name="expand-filters">
        <div v-if="slippageFilters" v-show="isFiltersOpen" class="chart-filters">
          <div class="chart-filter">
            <MultiSelect
                ref="exchanges"
                :default-option-value="parametersForSelects.exchanges || defaultExchanges"
                :options="exchanges"
                title="Exchanges"
                @selected="updateParameter($event, 'exchangeIds', true)"
            />
          </div>
          <div class="chart-filter">
            <MultiSelect
                ref="assets"
                :default-option-value="parametersForSelects.assets || defaultAssets"
                :options="assets"
                title="Assets"
                @emptySelect="parameters.assets = defaultAssets"
                @selected="updateParameter($event, 'assets', true)"
            />
          </div>
          <div class="chart-filter">
            <Select
                ref="pair"
                :default-option-value="parametersForSelects.pair || defaultPair"
                :options="pairs"
                title="trading pair"
                @emptySelect="parameters.currencyPair = defaultPair"
                @selected="updateParameter($event, 'currencyPair', true)"
            />
          </div>
          <template v-if="slippages.size">
            <div class="chart-filter">
              <Select
                  ref="slippage"
                  :default-option-value="parametersForSelects.slippageIndex || defaultSlippage"
                  :options="slippages"
                  suffix="%"
                  title="slippage"
                  @emptySelect="parameters.slippageIndex = defaultSlippage"
                  @selected="updateParameter($event, 'slippageIndex', false)"
              />
            </div>
            <div class="chart-filter">
              <Select
                  ref="rounding"
                  :default-option-value="parametersForSelects.roundingIndex || defaultRounding"
                  :options="rounding"
                  title="Rounding"
                  @emptySelect="parameters.roundingIndex = defaultRounding"
                  @selected="updateParameter($event, 'roundingIndex', false)"
              />
            </div>
          </template>
          <div class="chart-filter">
            <Select
                ref="view"
                :default-option-value="parametersForSelects.view ?? defaultView"
                :options="views"
                title="View"
                @emptySelect="parameters.view = defaultView"
                @selected="updateView"
            />
          </div>
          <div class="chart-filter reset">
            <button :disabled="resetDisabled || showPreloader" class="reset" @click="resetParameters">
              Reset
            </button>
          </div>
        </div>
      </transition>
    </div>
    <div class="chart-content">
      <div v-show="parameters.view" class="mid-market">
        <div class="mid-market__controls">
          <button id="btn1" class="mid-market__control mid-market-decrease" @click="zoomChart(-1)">
            <svg fill="none" height="18" viewBox="0 0 18 18" width="18" xmlns="http://www.w3.org/2000/svg">
              <path
                  clip-rule="evenodd"
                  d="M16 9C16 12.866 12.866 16 9 16C5.13401 16 2 12.866 2 9C2 5.13401 5.13401 2 9 2C12.866 2 16 5.13401 16 9ZM18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM6 8C5.44772 8 5 8.44771 5 9C5 9.55229 5.44772 10 6 10H9H12C12.5523 10 13 9.55229 13 9C13 8.44771 12.5523 8 12 8H9H6Z"
                  fill="#92939C"
                  fill-rule="evenodd"
              />
            </svg>
          </button>
          <h2 v-if="midMarketPrice !== null" class="mid-market__amount">{{ roundedMidMarketPrice }}</h2>
          <button class="mid-market__control mid-market-increase" @click="zoomChart(1)">
            <svg fill="none" height="18" viewBox="0 0 18 18" width="18" xmlns="http://www.w3.org/2000/svg">
              <path
                  clip-rule="evenodd"
                  d="M16 9C16 12.866 12.866 16 9 16C5.13401 16 2 12.866 2 9C2 5.13401 5.13401 2 9 2C12.866 2 16 5.13401 16 9ZM18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM10 6C10 5.44772 9.55228 5 9 5C8.44772 5 8 5.44772 8 6V8H6C5.44772 8 5 8.44772 5 9C5 9.55228 5.44772 10 6 10H8V12C8 12.5523 8.44772 13 9 13C9.55228 13 10 12.5523 10 12V10H12C12.5523 10 13 9.55228 13 9C13 8.44772 12.5523 8 12 8H10V6Z"
                  fill="#92939C"
                  fill-rule="evenodd"
              />
            </svg>
          </button>
        </div>
        <span class="mid-market__title">Mid-Market Price</span>
      </div>
      <NoData
          v-if="!showPreloader && dataEmpty"
          text="Something went wrong. <br /> Try other filters."
      />
      <div
          v-else
          :id="chartID"
          ref="sciChart"
          :class="{depth: parameters.view}"
          class="chart"
          @wheel.prevent
      />
      <div v-show="showPreloader" class="main-preloader widget-preloader chart-preloader">
        <Loader
            ref="preloader"
            :animationData="require('@/assets/images/Loader.json')"
            :autoPlay="true"
            :loop="true"
            :speed="1"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import wallsModifier from '@/modifiers/walls-chart/WallsModifier';
import { ws } from '@/utils/socket';
import debounce from '@/mixins/debounce';
import wallsFilters from '@/mixins/filters/orderbookWalls';

export default {
  name: 'OrderbookWalls',

  props: {
    isResizeble: Boolean,

    showCopyToPd: {
      type: Boolean,
      default: false,
      required: false,
    },

    isDeletable: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  mixins: [debounce, wallsModifier, wallsFilters],

  data: () => ({
    isFiltersOpen: true,
    connectionType: null,
    ws: null,
    wsParameters: null,
    parameters: {},
    showPreloader: true,
    dataEmpty: false,
  }),

  computed: {
    title() {
      return this.views.get(this.parameters.view) ? `${this.views.get(this.parameters.view)} Chart` : '';
    },

    roundedMidMarketPrice() {
      return Math.round(this.wallsData.indexPrice * 10 ** this.labelPrecision) / 10 ** this.labelPrecision;
    },
  },

  methods: {
    ...mapActions({
      getOrderbookWallsData: 'charts/getOrderbookWalls',
    }),

    async getChartData() {
      this.dataEmpty = false;
      this.showPreloader = true;
      this.unsubscribeWallsData();
      // eslint-disable-next-line no-unused-vars
      const { view, ...parameters } = this.parameters;
      this.wsParameters = JSON.stringify({
        ...parameters,
        currencyPair: parameters.currencyPair.split(',')
      });
      try {
        const res = await this.getOrderbookWallsData(parameters);

        if (!res.asks.length || !res.bids.length) {
          this.dataEmpty = true;
          this.showPreloader = false;
          return;
        }

        this.setData(res);
        await this.initSciChart();
        this.updateSciChart();
        this.subscribeWallsData();
      } catch (e) {
        this.dataEmpty = true;
        console.log(e);
      } finally {
        this.showPreloader = false;
      }
    },

    removeItem() {
      this.$emit('removeItem', null);
    },

    subscribeWallsData() {
      ws.subscribe(
          'ORDER_BOOK_WALLS',
          {
            method: 'SUBSCRIBE',
            params: this.wsParameters
          },
          (response) => this.debounce(() => {
            const res = response.response;

            if (response.params !== this.wsParameters || !res.asks.length || !res.bids.length) {
              return;
            }

            this.setData(res);
            this.updateSciChart();
          }, 100)
      );
    },

    unsubscribeWallsData() {
      ws.unsubscribe(
          'ORDER_BOOK_WALLS',
          {
            method: 'UNSUBSCRIBE',
            params: this.wsParameters
          },
      );

      this.wsParameters = null;
    },

    setConnectionType(downlink) {
      this.connectionType =
          downlink < 1 ? 'error'
              : downlink < 5 ? 'warning'
                  : 'success';
    }
  },

  created() {
    if (navigator.connection) {
      this.setConnectionType(navigator.connection.downlink);

      navigator.connection.addEventListener('change', () => {
        this.setConnectionType(navigator.connection.downlink);
      });
    }
  },

  beforeDestroy() {
    this.unsubscribeWallsData();
  }
};
</script>

<style lang="scss" scoped>

//.chart-content {
//display: flex;
//flex-direction: column;
//flex-wrap: wrap;
//justify-content: space-between;
//}

.mid-market {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 22px 0;
  text-align: center;
}

.mid-market__controls {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mid-market__control {
  cursor: pointer;
  line-height: 0;
  user-select: none;

  &:hover path {
    fill: var(--lightColor) !important;
  }

  path {
    transition: fill 0.3s;
  }
}

.mid-market__amount {
  margin: 0 13px;
  min-width: fit-content;
  width: 4.5em;
  color: var(--lightColor);
}

.mid-market__title {
  font-size: 0.75rem;
  font-weight: 400;
  color: var(--textSecondaryColor);
}

.walls-chart .no-data {
  top: 180px;
}

.chart {
  width: 100%;
  height: 100%;

  &.depth {
    height: calc(100% - 88px);
  }
}
</style>