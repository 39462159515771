import { crosshair, plotLine } from '@/mixins/chart-settings/_commonSettings';
import moment from 'moment/moment';
import { makeCompactNumber } from '@/helpers';
import reflowHighchart from '@/mixins/reflowHighchart';
import prettifyNumber from '@/mixins/helpers/prettifyNumber';

export default {
  mixins: [reflowHighchart, prettifyNumber],

  data() {
    const _this = this;

    return {
      chartOptions: {
        chart: {
          backgroundColor: 'var(--mainBGColor)',
          margin: [0, 57, 25, 0],

          events: {
            load() {
              let chart = this,
                max = chart.series[1].dataMax,
                min = chart.series[1].dataMin;

              chart.yAxis[1].update({
                max: max,
                min: min
              });
            }
          }
        },

        tooltip: {
          enabled: true,

          formatter() {
            // return 'volume: ' + this.points[1].y.toFixed(2);
            return 'volume: ' +  _this.prettifyNumber(this.points[1].y, 2);
          }
        },

        exporting: {
          enabled: false,
        },

        navigator: {
          enabled: false
        },

        legend: {
          enabled: false
        },
        yAxis: [
          {
            height: '100%',
            gridLineWidth: 1,
            gridLineColor: 'var(--gridLineColor)',
            plotLines: [{
              ...plotLine,

              label: {
                ...plotLine.label,

                formatter() {
                  // return _this.formatLabel(this.options.value);
                  return _this.prettifyNumber(this.options.value,  2);
                },
              }
            }],
            crosshair: {
              ...crosshair,
              zIndex: 0,
              label: {
                ...crosshair.label,

                style: {
                  color: 'var(--lightColor)'
                },

                formatter(event) {
                  return `<span style="font-weight: 700">${_this.prettifyNumber(event,2)}</span>`;
                },
              }
            },
            showLastLabel: true,
            showFirstLabel: false,
            endOnTick: false,
            showOnTick: false,

            labels: {
              align: 'left',
              y: 4,
              x: 10,

              style: {
                fontFamily: 'Inter, sans-serif',
                color: '#fff',
              },
            },
          },
          {
            height: 0,
          },
        ],

        xAxis: [
          {
            gridLineWidth: 1,
            gridLineColor: 'var(--gridLineColor)',
            tickPixelInterval: 190,
            crosshair: {
              ...crosshair,

              label: {
                ...crosshair.label,

                style: {
                  color: 'var(--lightColor)'
                },

                formatter(event) {
                  return moment(event).format('YYYY-MM-DD HH:mm');
                },
              }
            },
            labels: {
              align: 'center',
              y: 15,
              x: 0,
              rotation: 0,

              style: {
                fontFamily: 'Inter, sans-serif',
                color: '#fff',
              },

              formatter(event) {
                return moment(event.value).format('YYYY-MM-DD HH:mm');
              },
            },
          }
        ],
        rangeSelector: {
          enabled: false,
        },

        scrollbar: {
          enabled: false
        },

        plotOptions: {
          series: {
            dataGrouping: {
              enabled: false,
            },

            marker: {
              lineColor: 'var(--mainBGColor)'
            },

            states: {
              inactive: {
                enabled: false
              },

              hover: {
                lineWidth: 2,
                halo: {
                  size: 0,
                }
              }
            }
          },
        },
        series: [
          {
            type: 'spline',
            id: 'AAPL',
            name: 'AAPL',
            color: 'var(--accent)',
            zoneAxis: 'x',
            data: [],
          },
          {
            type: 'column',
            id: 'volume',
            name: 'Volume',
            data: [],
            yAxis: 1,
          },
          {
            type: 'vbp',
            data: [],
            linkedTo: 'AAPL',
            opacity: 1,
            params: {
              ranges: 31,
              align: 'right',
            },
            dataLabels: {
              enabled: false
            },
            zoneLines: {
              enabled: true,

              styles: {
                dashStyle: 'solid',
                color: 'var(--mainBGColor)'
              }
            },
            volumeDivision: {
              useHTML: true,
              styles: {
                negativeColor: '#187b76',
                positiveColor: '#e15248',
              }
            }
          },
        ],
      },
    };
  },

  methods: {
    makeCompactNumber,

    formatLabel(event) {
      console.log(1111, event)
      const maxNumberLength = 5;
      const integerNum = Math.abs(Math.round(event)) + '';
      const fractionDigits = maxNumberLength - integerNum.length;

      return this.makeCompactNumber(event, fractionDigits, fractionDigits);

      // return event
    }
  },
};