<template>
  <div class="chart calendar-section">
    <transition name="expand-filters">
      <div v-show="isFiltersOpen" class="chart-filters">
        <div class="chart-filter">
          <MultiSelect
              ref="eventType"
              :default-option-value="defaultEventType"
              :options="eventTypes"
              title="Event Type"
              @selected="updateParameter($event, 'eventTypes')"
          />
        </div>
        <div class="chart-filter">
          <MultiSelect
              v-if="exchanges"
              ref="exchanges"
              :default-option-value="defaultExchanges"
              :options="exchanges"
              title="Exchanges"
              @selected="updateParameter($event, 'exchanges')"
          />
        </div>
        <div class="chart-filter reset">
          <button :disabled="resetDisabled || showPreloader" class="reset" @click="resetParameters">
            Reset
          </button>
        </div>
      </div>
    </transition>

    <div class="chart-content">
      <div v-show="showPreloader" class="main-preloader widget-preloader chart-preloader">
        <Loader
            ref="preloader"
            :animationData="require('@/assets/images/Loader.json')"
            :autoPlay="true"
            :loop="true"
            :speed="1"
        />
      </div>
      <Calendar
          v-model="date"
          :attributes="attributes"
          :columns="2"
          :locale="{ id: 'en', masks: { weekdays: 'WW' } }"
          class="calendar"
          @dayclick="dayClicked"
      />
      <div :class="{active: !!selectedAnnouncementDay}" class="calendar-overflow" @click.self="closeCalendarTable">
        <transition name="expand">
          <div v-if="selectedAnnouncementDay" class="day-events">
            <div class="events-header">
              <h6 class="event-header-date">{{ formattedSelectedAnnouncementDay }}</h6>
              <button @click="closeCalendarTable">
                <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M2.25 2.25L7.99995 7.99995M7.99995 7.99995L13.7499 2.25M7.99995 7.99995L13.75 13.7498M7.99995 7.99995L2.2501 13.7498"
                      stroke="#92939C"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                  />
                </svg>
              </button>
            </div>

            <div
                v-show="!announcementsForDay.length"
                class="no-events"
            >
              <p>There don't seem to be any events on this day</p>
            </div>
            <AnnouncementsTable
                :announcements="announcementsForDay"
                :is-main-table="false"
                :is-resizeble="isResizeble"
                class="secondary-table"
                ref="table"
            />
          </div>
        </transition>
      </div>
    </div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex';
import debounce from '@/mixins/debounce';
import Calendar from 'v-calendar/lib/components/calendar.umd';
import moment from 'moment/moment';
import AnnouncementsTable from '@/components/tables/Announcements';
import announcementFilters from '@/mixins/filters/announcements';

export default {
  name: 'CalendarAnnouncements',

  components: {
    Calendar,
    AnnouncementsTable,
  },

  mixins: [debounce, announcementFilters],

  props: {
    isResizeble: Boolean,

    isFiltersOpen: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    date: new Date(),
    selectedAnnouncementDay: '',
    parameters: {},
    showPreloader: false,
    resetDisabled: true,
  }),

  computed: {
    ...mapGetters({
      announcementsData: 'charts/announcements',
      naming: 'filters/naming',
    }),

    defaultParameters() {
      return {
        eventTypes: this.defaultEventType,
        exchanges: this.defaultExchanges,
      };
    },

    filteredAnnouncements() {
      const { eventTypes, exchanges } = this.parameters;
      return this.announcementsData.filter(({ category, exchangeId }) => (
          eventTypes.includes(category)
          && exchanges.includes(exchangeId)
      ));
    },

    attributes() {
      const attributesByDate = new Map();

      this.filteredAnnouncements.forEach(announcement => {
        const { eventTime, category } = announcement;
        const formattedEventDate = moment(eventTime).format('DD/MM/YYYY');
        const attribute = this.generateAttribute(category, eventTime, this.setAnnouncementColor(category));

        if (attributesByDate.has(formattedEventDate)) {
          const attributes = attributesByDate.get(formattedEventDate);

          if (!attributes.types.includes(category)) {
            attributes.types.push(category);
            attributes.attributes.push(attribute);
          }
        } else {
          attributesByDate.set(formattedEventDate, {
            types: [category],
            attributes: [attribute],
          });
        }
      });

      return Array.from(attributesByDate.values()).flatMap(({ attributes }) => attributes);
    },

    announcementsForDay() {
      const startDay = moment(this.selectedAnnouncementDay).startOf('day').valueOf();
      const endDay = moment(this.selectedAnnouncementDay).endOf('day').valueOf();

      return this.filteredAnnouncements.filter(({ eventTime }) => startDay <= eventTime && eventTime <= endDay);
    },

    formattedSelectedAnnouncementDay() {
      return this.selectedAnnouncementDay.format('MMM DD, YYYY');
    },
  },

  methods: {
    updateParameter(event, name) {
      this.resetDisabled = false;
      this.parameters[name] = event;
    },

    resetParameters() {
      this.resetDisabled = true;
      this.parameters = { ...this.defaultParameters };
      this.$refs.eventType.reset();
      this.$refs.exchanges.reset();
      this.$refs.table.page = 0;
    },

    dayClicked(day) {
      this.selectedAnnouncementDay = moment(day.id);
    },

    closeCalendarTable() {
      this.selectedAnnouncementDay = '';
    },

    generateAttribute(eventType, time, eventColor) {
      return {
        description: eventType,
        dates: new Date(time),
        dot: {
          style: {
            backgroundColor: eventColor,
          }
        },
      };
    },

    setAnnouncementColor(announcementName) {
      const announcementColors = {
        'cryptocurrency_planned_listing': '#02c9bf',
        'cryptocurrency_planned_delisting': '#f27870',
        'listing promo': '#969aee',
        'delisting promo': '#feb444',
      };

      return announcementColors[announcementName.toLowerCase()] || '#fff';
    },
  },

  created() {
    this.parameters = { ...this.defaultParameters };
  }
};
</script>

<style lang="scss" scoped>
.chart-content {
  height: calc(100% - 85px);
  position: relative;

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 1px;
    background-color: var(--mainBorderColor);
  }
}

.calendar {
  border: none;
  border-radius: 0;
  width: 100%;
  height: calc(100% - 67px);
  max-height: 415px;
  background-color: transparent;
  font-family: inherit;
  color: var(--textMainColor);

  &::v-deep {
    .vc-pane-layout {
      height: 100%;
    }

    .vc-pane:first-child {
      position: relative;
    }

    .vc-title {
      color: var(--textMainColor);
    }

    .vc-svg-icon,
    .vc-weekday {
      color: var(--textSecondaryColor);
    }

    .vc-day {
      width: 40px;
      height: 40px;
    }

    .vc-arrow:hover {
      background-color: transparent;
    }

    .vc-header {
      padding-bottom: 10px;
    }

    .vc-weeks {
      height: calc(100% - 48px);
    }

    .vc-day-content {
      width: 100%;
      height: 100%;
    }

    .vc-nav-item:hover,
    .vc-day-content:hover {
      border-radius: 4px;
      background-color: #3f3f3f;
    }

    .vc-nav-item.is-active {
      color: var(--lightColor);
    }

    .vc-nav-item:focus,
    .vc-nav-item.is-current {
      border: none;
    }

    .vc-nav-item.is-active,
    .vc-day-content:focus {
      border: 1px solid var(--accent);
      border-radius: 4px;
      background-color: transparent;
    }

    .vc-day-layer {
      bottom: 3px;
    }

    .vc-popover-content {
      border: 1px solid var(--mainBorderColor);
      background-color: var(--mainBGColor);
    }

    .vc-nav-title:focus,
    .vc-nav-arrow:focus {
      border: none;
    }
  }
}

.calendar-section {
  position: relative;
}

.calendar-overflow {
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 1px;
  left: 0;
  opacity: 0;
  transition: opacity 0.3s;
  background-color: rgba(30, 30, 30, 0.5);

  &.active {
    z-index: 2;
    opacity: 1;
    cursor: pointer;
  }
}

.day-events {
  display: flex;
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 10%;
  flex-direction: column;
  overflow: hidden;
  background-color: var(--mainBGColor);
  cursor: default;
}

.events-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--mainBorderColor);
  padding: 23px 20px 18px;

  .event-header-date {
    line-height: 23px;
    color: var(--textMainColor);
  }
}

.expand-enter-active,
.expand-leave-active {
  transform: translateX(0);
  transition: transform 1s;
}

.expand-enter,
.expand-leave-to {
  transform: translateX(100%);
}

.no-events {
  display: flex;
  position: absolute;
  z-index: 2;
  top: 66px;
  right: 0;
  bottom: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  background: var(--mainBGColor);
}

.secondary-table {
  height: calc(100% - 65px);;
}
</style>