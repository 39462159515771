<template>
  <div>
    <div v-if="type === 'tokenFullName'" class="token">
      <div class="symbol-wrapper">
        <LazyImg :id="arbitrageItem.tokenId" :is-token="true" class="logo" />

        <div class="symbol-info text-left">
          <router-link
              :to="{ path: arbitrageItem.tokenLink }"
              class="symbol-full-name"
          >
            {{ arbitrageItem.tokenFullName }}
          </router-link>
          <span class="symbol-abbr secondary-text">{{ arbitrageItem.tokenName }}</span>
        </div>
      </div>
    </div>

    <div v-if="type === 'exchangeSellName'" class="pair">
      <div class="pair-wrapper">
        <div class="sub-row">
          <LazyImg :id="arbitrageItem.exchangeSellId" :is-token="false" :size="16" class="logo" />

          <div class="pair-info">
            <router-link
                :to="{ path: arbitrageItem.exchangeSellLink }"
                class="pair-info__link"
            >
              <span>
                {{ arbitrageItem.exchangeSellName }}
              </span>
            </router-link>
          </div>
        </div>
        <div v-if="arbitrageItem.exchangeSellName !== arbitrageItem.exchangeBuyName" class="sub-row">
          <LazyImg class="logo" :id="arbitrageItem.exchangeBuyId" :is-token="false" :size="16" />

          <div class="pair-info">
            <router-link
                :to="{ path: arbitrageItem.exchangeBuyLink }"
                class="pair-info__link"
            >
              <span>
                {{ arbitrageItem.exchangeBuyName }}
              </span>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div v-if="type === 'baseToken'" class="portfolio">
      <div class="sub-row">
        <span class="label ask">s</span>
        <a :href="arbitrageItem.sellLink" class="arbitrage-pair-link" target="_blank">
          <span
              class="pair"
          >
            <!--  {{ arbitrageItem.baseToken }}/{{arbitrageItem.qouteToken }}-->
            {{ arbitrageItem.sellDirection }}
          </span>
          <svg
              fill="none"
              height="16"
              viewBox="0 0 17 16"
              width="17"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                d="M7.38053 2V3.33333H4.0472V12.6667H13.3805V9.33333H14.7139V13.3333C14.7139 13.7015 14.4154 14 14.0472 14H3.38053C3.01235 14 2.71387 13.7015 2.71387 13.3333V2.66667C2.71387 2.29848 3.01235 2 3.38053 2H7.38053ZM12.4377 3.33333H9.38053V2H14.7139V7.33333H13.3805V4.27614L8.71387 8.9428L7.77107 8L12.4377 3.33333Z"
                fill="#92939C"
            />
          </svg>
        </a>
      </div>
      <div class="sub-row">
        <span class="label bid">b</span>
        <a :href="arbitrageItem.buyLink" class="arbitrage-pair-link" target="_blank">
          <span
              class="pair"
          >
            <!-- {{ arbitrageItem.baseToken }}/{{arbitrageItem.qouteToken }}-->
            {{ arbitrageItem.buyDirection }}
          </span>
        </a>
        <svg
            fill="none"
            height="16"
            viewBox="0 0 17 16"
            width="17"
            xmlns="http://www.w3.org/2000/svg"
        >
          <path
              d="M7.38053 2V3.33333H4.0472V12.6667H13.3805V9.33333H14.7139V13.3333C14.7139 13.7015 14.4154 14 14.0472 14H3.38053C3.01235 14 2.71387 13.7015 2.71387 13.3333V2.66667C2.71387 2.29848 3.01235 2 3.38053 2H7.38053ZM12.4377 3.33333H9.38053V2H14.7139V7.33333H13.3805V4.27614L8.71387 8.9428L7.77107 8L12.4377 3.33333Z"
              fill="#92939C"
          />
        </svg>
      </div>
    </div>

    <div v-if="type === 'longPrice'" class="price">
      <div class="sub-row">
        <span
            class="price"
            :title="prettifyNumber(arbitrageItem.shortPrice, 10, 6)"
        >${{ arbitrageItem.sellPrice }}</span>
      </div>
      <div class="sub-row">
        <span
            class="price"
            :title="prettifyNumber(arbitrageItem.longPrice, 10, 6)"
        >${{ arbitrageItem.buyPrice }}</span>
      </div>
    </div>

    <div v-if="type === 'marginFee'">
      {{ arbitrageItem.marginFee }}<span v-if="arbitrageItem.marginFee !== '-'">%</span>
    </div>

    <div v-if="type === 'marginFee24h'">
      {{ arbitrageItem.marginFee24h }}<span v-if="arbitrageItem.marginFee24h !== '-'">%</span>
    </div>

    <div v-if="type === 'fundingRate'">
      <template v-if="arbitrageItem.fundingRate !== '-'">
        {{ prettifyNumber(arbitrageItem.fundingRate, 4, 4) }}<span>%</span>
      </template>

      <template v-else>
        {{ arbitrageItem.fundingRate }}
      </template>
    </div>

    <div v-if="type === 'fundingRate24h'">
      <template v-if="arbitrageItem.fundingRate24h !== '-'">
        {{ prettifyNumber(arbitrageItem.fundingRate24h * parameters.dealLengthFilter, 4, 4) }}<span>%</span>
      </template>

      <template v-else>
        {{ arbitrageItem.fundingRate24h }}
      </template>
    </div>

    <div v-if="type === 'spread'" class="spread">
      {{ arbitrageItem.spread }}<span>%</span>
    </div>

    <div v-if="type === 'profit'" class="profit">
      {{ arbitrageItem.profit }}<span>%</span>
    </div>

    <div v-if="type === 'profit24h'" class="profit">
      <template>
        {{ arbitrageItem.profit24h }}<span v-if="arbitrageItem.profit24h !== '-'">%</span>
      </template>
    </div>

    <div v-if="type === 'avgProfitHourly'">
      <template>
        {{ arbitrageItem.avgProfitHourly }}<span v-if="arbitrageItem.avgProfitHourly !== '-'">%</span>
      </template>
    </div>

    <div v-if="type === 'arbitrageLagMs'">
      <template>
        {{ arbitrageItem.arbitrageLagMs }}<span> ms</span>
      </template>
    </div>
  </div>
</template>

<script>
import prettifyNumber from '@/mixins/helpers/prettifyNumber';

export default {
  name: "ArbitrageCalculatorCell",
  props: {
    arbitrageItem: {
      type: Object,
    },
    type: {
      type: String,
    },
    parameters: {
      type: Object,
    }
  },
  data() {
    return {
      defaultImage: require(`@/assets/images/default-token-icon.svg`),
    };
  },
  mixins: [prettifyNumber,]
};
</script>
<style lang="scss" scoped src="@/assets/css/secondary-table.scss" />
<style lang="scss" scoped>

.trading-vol {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.pair-wrapper object, .pair-wrapper img {
  width: 16px;
  height: 16px;
}

.pair-info__link {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.portfolio {

}

.sub-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-height: 28px;
  flex-wrap: nowrap;

  &:not(:last-child) {
    margin-bottom: 5px;
  }

  .label {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    border-radius: 6px;
    padding: 4px;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    line-height: 20px;
    text-transform: capitalize;
    text-align: center;

    &.ask {
      background: rgba(242, 120, 112, 0.1);
      color: #f27870;
    }

    &.bid {
      background: rgba(45, 202, 194, 0.1);
      color: #35d9d1;
    }
  }

  .pair {
    display: inline-block;
    margin-right: 5px;
    width: auto;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    //text-decoration-line: underline;
    font-weight: 500;
    font-style: normal;
    line-height: 16px;
    text-transform: capitalize;
    color: #fff;
  }
}

.portfolio {
  text-align: left;
}

.pair-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.symbol-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.symbol-info {
  flex-grow: 1;
  width: 100px;
}

.symbol-full-name {
  display: block;
  overflow: hidden;
  transition: all .5s;
  transition: all .5s;
  text-decoration: none;
  text-overflow: ellipsis;
  color: var(--textMainColor);

  &:hover {
    color: var(--accent);
  }
}

.secondary-text {
  color: var(--textSecondaryColor);
}

.symbol-wrapper .logo {
  margin-right: 12px;
}

.profit,
.profit24h {
  font-weight: 700;
  color: var(--accent) !important;
}

table tbody tr.arbitrage-row {
  cursor: pointer;
}

table tbody tr.arbitrage-row:after {
  transition: all .5s;
}

table tbody tr.arbitrage-row:hover:after {
  background: #1e1e1e;
}

.arbitrage-pair-link {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.arbitrage-pair-link .pair {
  transition: all .5s;
  cursor: pointer;
}

.arbitrage-pair-link:hover .pair {
  color: var(--accent);
}

.pair {
  width: 140px;
}

.token {
  width: 200px;
}

</style>