import { mapActions } from 'vuex';
import { ws } from '@/utils/socket';
import commonFilters from '@/mixins/filters/commonFilters';

export default {
  mixins: [commonFilters],

  props: {
    propsParameters: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    initialParameters: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },

  data: () => ({
    resetDisabled: true,
    slippageFilters: null,
    slippageWsParameters: null,
    parametersForSelects: {},
    chartID: `walls-${+new Date()}`
  }),

  watch: {
    parameters: {
      deep: true,
      immediate: true,
      handler(parameters) {
        if (!Object.keys(parameters).length) {
          return;
        }

        this.$emit('updateFilter', parameters);
      }
    }
  },

  computed: {
    defaultParameters() {
      return {
        exchangeIds: this.defaultExchanges,
        assets: this.defaultAssets,
        currencyPair: this.defaultPair,
        slippageIndex: this.defaultSlippage,
        roundingIndex: this.defaultRounding,
        view: this.defaultView,
      };
    },

    defaultSlippage() {
      return [...this.slippages][this.slippages.size - 1][0];
    },

    defaultRounding() {
      return this.rounding.keys().next().value;
    },

    defaultView() {
      return this.views.keys().next().value;
    },

    views() {
      return new Map([[true, 'Depth'], [false, 'Walls']]);
    },

    slippages() {
      const slippages = this.slippageFilters[0] || [];

      return new Map(slippages.flatMap((slippage, index) => slippage > 0.05 ? [[index + 1, slippage + '']] : []));
    },

    rounding() {
      const slippageIndex = this.parameters.slippageIndex ?? this.defaultSlippage;
      const roundings = this.slippageFilters[1] || [];

      if (roundings.length) {
        return new Map(roundings[slippageIndex - 1].map((rounding, index) => [index + 1, rounding + '']));
      }

      return new Map();
    },
  },

  methods: {
    ...mapActions({
      getSlippageData: 'filters/getSlippages',
    }),

    updateParameter(event, filterName, getSlippages) {
      this.resetDisabled = false;
      this.parameters = {
        ...this.parameters,
        [filterName]: event,
      };

      getSlippages
        ? this.getSlippages()
        : this.getChartData();
    },

    async updateView(view) {
      this.showPreloader = true;
      this.resetDisabled = false;
      this.parameters = { ...this.parameters, view };

      await this.initSciChart();
      this.updateSciChart();

      setTimeout(() => {
        this.showPreloader = false;
      }, 500);
    },

    async resetParameters() {
      this.resetDisabled = true;
      this.parameters = {
        ...this.defaultParameters,
        ...this.propsParameters,
        ...this.initialParameters,
      };

      await this.getChartData();

      this.$refs.exchanges?.reset();
      this.$refs.assets?.reset();
      this.$refs.pair?.reset();
      this.$refs.slippage?.reset();
      this.$refs.rounding?.reset();
      this.$refs.view?.reset();
    },

    setSlippageFilters(slippageFilters) {
      this.slippageFilters = Object.values(slippageFilters)[0];
    },

    async getSlippages() {
      this.unsubscribeSlippageData();

      const parameters = {
        exchangeIds: this.parameters.exchangeIds,
        assets: this.parameters.assets,
        currencyPair: this.parameters.currencyPair,
      };

      this.setSlippageFilters(await this.getSlippageData(parameters));
      this.subscribeSlippageData();
      this.getChartData();
    },

    subscribeSlippageData() {
      const { exchangeIds, assets, currencyPair } = this.parameters;
      this.slippageWsParameters = JSON.stringify({ exchangeIds, assets, currencyPair: currencyPair.split(',') });

      ws.subscribe(
        'SLIPPAGES',
        {
          method: 'SUBSCRIBE',
          params: this.slippageWsParameters
        },
        (response) => this.debounce(() => {
          if (response.params === this.slippageWsParameters) {
            this.setSlippageFilters(response.response);
          }
        }, 100)
      );
    },

    unsubscribeSlippageData() {
      if (!this.slippageWsParameters) {
        return;
      }

      ws.unsubscribe(
        'SLIPPAGES',
        {
          method: 'UNSUBSCRIBE',
          params: this.slippageWsParameters
        },
      );

      this.slippageWsParameters = null;
    },
  },

  async created() {
    this.setSlippageFilters(await this.getSlippageData({
      exchangeIds: this.defaultExchanges,
      assets: this.defaultAssets,
      currencyPair: this.defaultPair,
    }));

    if (Object.keys(this.propsParameters).length) {
      const parameters = {
        ...this.propsParameters,
        slippageIndex: this.slippageFilters[this.propsParameters.slippageIndex] ? this.propsParameters.slippageIndex : this.defaultSlippage,
      };

      this.parameters = { ...parameters };
      this.parametersForSelects = { ...parameters };
      this.resetDisabled = false;
      this.getChartData();
      this.subscribeSlippageData();

      return;
    }

    if (Object.keys(this.initialParameters).length) {
      this.parameters = {
        ...this.defaultParameters,
        ...this.initialParameters,
        slippageIndex: this.slippageFilters[this.initialParameters.slippageIndex] ? this.initialParameters.slippageIndex : this.defaultSlippage,
      };

      this.parametersForSelects = { ...this.parameters };
      this.resetDisabled = false;
      this.getChartData();
      this.subscribeSlippageData();

      return;
    }

    this.resetParameters();
    this.subscribeSlippageData();
  },

  beforeDestroy() {
    this.unsubscribeSlippageData();
  }
};