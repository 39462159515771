import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      filters: 'filters/orderbook',
      orderbookMap: 'filters/orderbookMap',
      exchangesInfo: 'filters/exchangesInfo',
      allSlippages: 'filters/newSlippages',
      coinsInfo: 'filters/coinsInfo',
      naming: 'filters/naming',
    }),

    defaultExchanges() {
      const exchanges = [...this.exchanges.keys()];

      return exchanges.filter(exchange => this.exchangesInfo[exchange].isApproved);
    },

    defaultAssets() {
      return [this.assets.keys().next().value];
    },

    defaultAsset() {
      return this.assets.keys().next().value;
    },

    allAssets() {
      return Array.from(this.assets.keys());
    },

    defaultPair() {
      return this.pairs.get(this.parametersForSelects.currencyPair)
        ? this.parametersForSelects.currencyPair
        : this.pairs.keys().next().value;
    },

    defaultSlippage() {
      return [...this.slippages][this.slippages.size - 1][0];
    },

    defaultDimension() {
      return this.dimensions.keys().next().value;
    },

    exchanges() {
      let exchangesIds = [...this.orderbookMap.keys()];
      let assets = this.parameters?.assets ?? this.propsParameters?.assets ?? this.initialParameters?.assets ?? 'futures_perpetual';

      if (typeof assets === 'string') {
        assets = assets.split(',');
      }

      exchangesIds = assets.flatMap(asset => (
        exchangesIds.filter(exchangesId => this.orderbookMap.get(exchangesId).assets[asset])
      ));

      return new Map(exchangesIds.map(exchangeId => [exchangeId, this.exchangesInfo[exchangeId].displayName]));
    },

    assets() {
      const exchanges = this.parameters.exchangeIds || this.defaultExchanges;
      const allAssets = [...new Set(exchanges.flatMap(exchange => Object.keys(this.orderbookMap.get(exchange)?.assets || {})))];

      return new Map(allAssets.map(asset => [asset, this.naming[asset] || asset]));
    },

    slippages() {
      const pair = this.parameters.pair ?? this.defaultPair;

      return new Map(this.allSlippages[pair][0].map((slippage, index) => [index + '', slippage + '']));
    },

    pairs() {
      const exchanges = this.parameters.exchangeIds ?? this.defaultExchanges;
      let assets = this.parameters.assets || this.defaultAssets;

      if (!Array.isArray(assets)) {
        assets = [assets];
      }

      const allPairs = exchanges.flatMap(exchange => (
        assets.flatMap(asset => this.orderbookMap.get(exchange).assets[asset] || [])
      ));

      const uniquePairs = [...new Set(allPairs.map(pair => JSON.stringify(pair)))];
      const result = uniquePairs.map(pair => {
        const [baseCoinId, quoteCoinId] = JSON.parse(pair);
        const baseCoin = this.coinsInfo[baseCoinId]?.symbol || baseCoinId;
        const quoteCoin = this.coinsInfo[quoteCoinId]?.symbol || quoteCoinId;

        return [
          [baseCoinId, quoteCoinId].toString(),
          `${baseCoin}/${quoteCoin}`.toUpperCase()
        ];
      });

      return new Map(result);
    },

    dimensions() {
      return new Map([
        ['MINUTE_1', 'Now'],
        ['HOUR_1', 'Hour'],
        ['DAY_1', 'Day'],
        ['WEEK_1', 'Week'],
        ['MONTH_1', 'Month'],
        ['YEAR_1', 'Year'],
      ]);
    },
  }
};