<template>
  <v-dialog v-model="activeDialog" class="popup">
    <div class="popup__wrapper">
      <div class="popup__header">
        <h1 class="popup__title">{{title}}</h1>
        <button class="popup__close" @click="closePopup">
          <img alt="close" src="@/assets/images/cross.svg">
        </button>
      </div>

      <div class="popup__content">
        <slot></slot>
      </div>
    </div>
  </v-dialog>
</template>

<script>

export default {
  name: 'Popup',

  props: {
    title: {
      type: String,
    }
  },

  data: () => ({
    activeDialog: false,
  }),

  methods: {
    openPopup() {
      this.activeDialog = true;
    },

    closePopup() {
      this.$emit('closePopup', null);
      this.activeDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.popup {
  &__wrapper {
    margin: 0 auto;
    min-width: 500px;
    width: auto;
    max-width: 720px;
    padding: 20px;
    min-height: 300px;
    background: #2E2D2D;
    border: 1px solid #1E1E1E;
    border-radius: 12px;
  }

  &__header {
    position: relative;
    margin-bottom: 30px;
  }

  &__title {
    text-align: left;
    color: var(--textMainColor);
  }

  &__close {
    display: flex;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  &__content {
    margin: 0 auto;
    width: auto;
    font-family: "Inter", sans-serif;
    font-weight: 700;
    color: var(--textMainColor);
  }

  &__chart-params {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto 10px;
    gap: 10px;

    button {
      border-radius: 4px;
      width: 24px;
    }
  }
}
</style>
