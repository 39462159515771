<template>
  <div class="table-search" v-if="isShowSearch">
    <div
        class="input-box"
        :class="{open: isOpen}"
    >
      <input
          type="text"
          v-model="searchText"
          :placeholder="title"
          class="select-search-input"
      >
      <span class="search" @click="isOpen = true">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.33334 12.6667C10.2789 12.6667 12.6667 10.2789 12.6667 7.33334C12.6667 4.38782 10.2789 2 7.33334 2C4.38782 2 2 4.38782 2 7.33334C2 10.2789 4.38782 12.6667 7.33334 12.6667Z" stroke="#92939C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M13.9996 14L11.0996 11.1" stroke="#92939C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </span>
      <svg @click="clearSearch" class="close-icon" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.25 1.25L6.99995 6.99995M6.99995 6.99995L12.7499 1.25M6.99995 6.99995L12.75 12.7498M6.99995 6.99995L1.2501 12.7498" stroke="#92939C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
  </div>
</template>

<script>
import debounce from "@/mixins/debounce";
export default {
  name: "Search",
  props: {
    title: {
      type: String,
      default: 'Search',
    },

    isShowSearch: {
      type: Boolean,
      default: false,
    },

  },
  data() {
    return {
      searchText: '',
      results: [],
      isOpen: false,
    };
  },

  mixins: [debounce],

  watch: {
    searchText(text) {
      this.search(text)
    }
  },

  methods: {
    search(text) {
      this.debounce(async () => {
        this.$emit('search', text)
      }, 300);
    },

    clearSearch() {
      this.searchText = "";
      this.isOpen = false
    },
  }
}
</script>

<style lang="scss" scoped>
  .table-search {
    display: flex;
    align-items: center;
    justify-content: center;
    &-input {
      background: transparent;
      border-radius: 6px;
      border: 1px solid transparent;
      transition: all .5s;
      padding: 10px 14px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: var(--textSecondaryColor);
      width: calc(100% - 16px);
      margin: 0 auto;
      padding-left: 35px;
      &:focus {
        border: 1px solid #42E8E0;
        outline: none;
      }
      &-input.open {
        background: #464646;
      }
    }

    //svg {
    //  position: absolute;
    //  left: 14px;
    //  top: 50%;
    //  transform: translateY(-50%);
    //}
  }

  .input-box {
    position: relative;
    width: 100%;
    max-width: 32px;
    height: 40px;
    margin-right: 10px;
    border-radius: 6px;
    transition: all 0.5s ease-in-out;
  }

  .input-box.open {
    max-width: 350px;
    margin-right: 20px;
  }

  .input-box input {
    background: transparent;
    border-radius: 6px;
    border: 1px solid transparent;
    transition: all .5s;
    padding: 10px 14px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    height: 40px;
    line-height: 20px;
    color: var(--textSecondaryColor);
    width: calc(100% - 16px);
    margin: 0 auto;
    padding-left: 35px;
    position: relative;
    &:focus {
      border: 1px solid #42E8E0;
      outline: none;
    }
  }

  .input-box.open input {
    padding: 0 15px 0 35px;
    background: #464646;
  }

  .input-box .search {
    position: absolute;
    top: 1px;
    left: 1px;
    width: 30px;
    height: calc(100% - 2px);
    display: flex;
    background: var(--mainBGColor);
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    cursor: pointer;
    transition: all .5s;
  }

  .input-box .search svg {
    width: 24px;
    height: 24px;
  }

  .input-box.open .search {
    border-radius: 6px 0 0 6px;
    background: #464646;
  }

  .search .search-icon {
    font-size: 30px;
    color: #1c1c1c;
  }

  .input-box .close-icon {
    position: absolute;
    top: 50%;
    right: -10px;
    left: auto;
    font-size: 30px;
    color: #1c1c1c;
    transform: translateY(-50%);
    transition: all 0.5s ease-in-out;
    cursor: pointer;
    pointer-events: none;
    opacity: 0;
    width: 20px;
    height: 20px;
  }

  .input-box.open .close-icon {
    transform: translateY(-50%) rotate(180deg);
    pointer-events: auto;
    opacity: 1;
  }
</style>