<template>
  <div class="chart-wrapper">
    <SectionHeader
        ref="sectionHeader"
        :is-deletable="isDeletable"
        :is-filters-open="isFiltersOpen"
        :searchable="true"
        :show-copy-chart-to-pd="showCopyToPd"
        :stretchable="false"
        info-text="description Arbitrage Calculator"
        title="Arbitrage Calculator"
        @copyChartToPD="$emit('copyChartToPD', {})"
        @removeItem="$emit('removeItem', null)"
        @search="search"
        @setStatic="$emit('setStatic', $event)"
        @toggleFiltersVisibility="isFiltersOpen = $event"
    />

    <transition name="expand-filters">
      <div
          v-show="isFiltersOpen"
          ref="sectionFilters"
          class="chart-filters"
      >
        <div class="chart-filters-list">
          <div class="chart-filter">
            <MultiSelect
                ref="exchange"
                :default-option-value="defaultExchanges"
                :options="exchanges"
                title="Exchanges"
                @emptySelect="parameters.exchanges = defaultExchanges"
                @selected="updateParameters($event, 'exchanges')"
            />
          </div>
          <div class="chart-filter">
            <MultiSelect
                ref="asset"
                :default-option-value="defaultAssets"
                :options="assets"
                title="Tokens"
                @emptySelect="parameters.assets = defaultAssets"
                @selected="updateParameters($event, 'assets')"
            />
          </div>
          <div class="chart-filter">
            <MultiSelect
                ref="asset"
                :default-option-value="defaultDealTypes"
                :options="dealTypes"
                title="Deal Type"
                @emptySelect="parameters.dealTypes = defaultDealTypes"
                @selected="updateParameters($event, 'dealTypes')"
            />
          </div>
        </div>
        <div class="filter-btns">
          <div class="chart-filter btn-filter add">
            <button class="add" @click="showFiltersPopup">
              <!--              <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">-->
              <!--                <path d="M1 5H9M5 9V1" stroke="#35D9D1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>-->
              <!--              </svg>-->
              <span> Advanced Filters</span>
            </button>
          </div>
          <div class="chart-filter btn-filter reset">
            <button :disabled="resetDisabled || showPreloader" class="reset" @click="resetParameters">
              <!--              <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">-->
              <!--                <path d="M1.17157 1.17157L6.82843 6.82842M1.17157 6.82842L6.82843 1.17157" stroke="#F27870" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>-->
              <!--              </svg>-->
              Clear All
            </button>
          </div>
        </div>
      </div>
    </transition>

    <div ref="chartContent" class="chart-content">
      <div v-show="showPreloader" class="main-preloader widget-preloader chart-preloader">
        <Loader
            ref="preloader"
            :animationData="require('@/assets/images/Loader.json')"
            :autoPlay="true"
            :loop="true"
            :speed="1"
        />
      </div>

      <NoData
          v-show="!showPreloader && !totalItems"
          text="There doesn't seem to be any data. <br /> Try other filters"
      />

      <div class="custom-scroll">
        <table>
          <thead>
            <tr>
              <th
                  v-for="([value, info], index) in parameters.visibleColumnsFilters"
                  v-show="parameters.visibleColumns.includes(value)"
                  :key="index"
                  :class="['dragging' ? (isDragging && dragIndex === index) : '', value]"
                  :draggable="true"
                  @dragstart="handleDragStart(index)"
                  @drop="handleDrop(index)"
                  @dragover.prevent="allowDrop"
              >
                <button
                    v-if="value !== 'displayName'"
                    :class="{
                    'sorted': sortBy === value,
                  }"
                    @click="sortTable(value, info[1])"
                >
                  <svg
                      class="arrows"
                      fill="none"
                      height="13"
                      viewBox="0 0 12 13"
                      width="12"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                        :class="{active: !reversed}"
                        d="M9 4.49998L6 1.49999L3 4.49998"
                        stroke="#92939C"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                    />
                    <path
                        :class="{active: reversed}"
                        d="M3 8.50002L6 11.5L9 8.50002"
                        stroke="#92939C"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                    />
                  </svg>

                  {{ info[0] }}
                </button>
                <div v-else class="header-wrapper">
                  {{ info[0] }}
                </div>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
                v-for="(arbitrageItem, index) in sortedArbitrageData.slice(page * size, (page + 1) * size)"
                :key="index"
                ref="row"
                class="arbitrage-row"
                @click.stop="openChart($event, arbitrageItem)"
            >

              <!--          <td v-for="(column, index) in columns" :key="index">-->
              <!--            {{ row[column] }}-->
              <!--          </td>-->

              <td
                  v-for="([column]) in parameters.visibleColumnsFilters"
                  v-show="parameters.visibleColumns.includes(column)"
                  :key="column"
                  :class="column"
              >
                <ArbitrageCalculatorCell :arbitrage-item="arbitrageItem" :parameters="parameters" :type="column" />

              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <CustomPagination
          v-if="totalItems > 1"
          :page-count="size"
          :page-number="page"
          :pagination-size="totalPages"
          :total-items="totalItems"
          @pageChanged="page = $event"
      />
    </div>

    <Drawer
        ref="drawer"
        @apply="applyFilters"
        @reset="resetFilters"
    >
      <div class="popup-filters-list">
        <div class="popup-filters-category">
          <span class="popup-filters-category-title">Exchange:</span>
          <div class="popup-filters-item">
            <span class="popup-filters-item-title">Exchange Type</span>
            <div class="checkbox-list">
              <v-checkbox
                  v-model="filters.exchangeTypeFilters"
                  label="DEX"
                  value="dex"
              ></v-checkbox>
              <v-checkbox
                  v-model="filters.exchangeTypeFilters"
                  label="CEX"
                  value="cex"
              ></v-checkbox>
            </div>
          </div>
          <div class="popup-filters-item">
            <span class="popup-filters-item-title">Exchange Liquidity</span>
            <Range
                :default-value="filters.exchangeLiquidityRange"
                :max="defaultFilters.exchangeLiquidityRange[1]"
                :min="defaultFilters.exchangeLiquidityRange[0]"
                title=""
                @update="filters.exchangeLiquidityRange = $event"
            />
          </div>
          <div class="popup-filters-item switcher">
            <span class="popup-filters-item-title">Cross-Exchange</span>
            <v-switch
                v-model="filters.crossExchangeSwitcherValue"
                inset
            ></v-switch>
          </div>
          <div class="popup-filters-item switcher">
            <span class="popup-filters-item-title">Margin Fee Data Available</span>
            <v-switch
                v-model="filters.marginFeeAvailableSwitcherValue"
                inset
            ></v-switch>
          </div>
          <div class="popup-filters-item switcher">
            <span class="popup-filters-item-title">Funding Rate Data Available</span>
            <v-switch
                v-model="filters.fundingRateAvailableSwitcherValue"
                inset
            ></v-switch>
          </div>
        </div>
        <div class="popup-filters-category">
          <span class="popup-filters-category-title">Token:</span>
          <div class="popup-filters-item">
            <span class="popup-filters-item-title">Token Liquidity</span>
            <Range
                :default-value="filters.tokenLiquidityRange"
                :max="defaultFilters.tokenLiquidityRange[1]"
                :min="defaultFilters.tokenLiquidityRange[0]"
                title=""
                @update="filters.tokenLiquidityRange = $event"
            />
          </div>
          <div class="popup-filters-item">
            <span class="popup-filters-item-title">Token Market Cap</span>
            <Range
                :default-value="filters.tokenMarketCapRange"
                :max="defaultFilters.tokenMarketCapRange[1]"
                :min="defaultFilters.tokenMarketCapRange[0]"
                title=""
                @update="filters.tokenMarketCapRange = $event"
            />
          </div>
        </div>
        <div class="popup-filters-category">
          <span class="popup-filters-category-title">General:</span>
          <div class="popup-filters-item">
            <span class="popup-filters-item-title">Arbitrage Lag Ms</span>
            <Range
                :default-value="filters.arbitrageLagMsRange"
                :max="defaultFilters.arbitrageLagMsRange[1]"
                :min="defaultFilters.arbitrageLagMsRange[0]"
                title=""
                @update="filters.arbitrageLagMsRange = $event"
            />
          </div>
          <!--          <div class="popup-filters-item">-->
          <!--            <span class="popup-filters-item-title">Deal Lenght</span>-->
          <!--            <div class="checkbox-list">-->
          <!--              <v-radio-group v-model="filters.dealLengthFilter">-->
          <!--                <v-radio-->
          <!--                    v-for="[value, label] in dealLength"-->
          <!--                    :key="value"-->
          <!--                    :label="label"-->
          <!--                    :value="value"-->
          <!--                ></v-radio>-->
          <!--              </v-radio-group>-->
          <!--            </div>-->
          <!--          </div>-->
          <div class="popup-filters-item">
            <span class="popup-filters-item-title">Columns</span>
            <div class="checkbox-list">
              <v-checkbox
                  v-for="(column, i) in [...columns.keys()]"
                  :key="i"
                  v-model="filters.visibleColumns"
                  :disabled="filters.visibleColumns[0] === column && filters.visibleColumns.length === 1"
                  :label="filters.visibleColumnsFilters.get(column)[0]"
                  :value="column"
              ></v-checkbox>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
    <div
        :class="{active: showChart}"
        class="funding-rate__history"
        @click.self="closeChart"
    >
      <transition name="expand">
        <ArbitrageCalculatorHistory
            v-if="showChart"
            :currency-arbitrage-item="currencyArbitrageItem"
            class="funding-rate__realized"
            @close="showChart = false"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import aroundNumber from '@/mixins/helpers/aroundNumber';
import debounce from '@/mixins/debounce';
import { ws } from '@/utils/socket';
import Range from '@/components/ui/inputs/Range.vue';
import Drawer from '@/components/ui/popups/Drawer.vue';
import tableFilters from '@/mixins/filters/tableFilters';
import ArbitrageCalculatorHistory from '@/components/chart-sections/ArbitrageCalculatorHistory.vue';
import propsParameters from '@/mixins/propsParameters';
import sectionHeader from '@/components/ui/section/SectionHeader.vue';
import ArbitrageCalculatorCell from '@/components/arbitrage-calculator-cell/ArbitrageCalculatorCell.vue';

export default {
  name: 'ArbitrageCalculator',
  components: { ArbitrageCalculatorCell, Drawer, Range, ArbitrageCalculatorHistory },

  props: {
    isResizeble: Boolean,
  },

  mixins: [tableFilters, debounce, aroundNumber, propsParameters],

  data: () => ({
    size: 10,
    page: 0,
    searchText: '',
    showChart: false,
    currencyArbitrageItem: null,
    parameters: {},
    wsParameters: null,
    isApprovedNeeded: false,
    arbitrageData: [],
    arbitrageHistory: [],
    reversed: true,
    sortBy: 'profit',
    sortType: 'number',
    showPreloader: true,
    resetDisabled: true,
    isFiltersOpen: true,
    defaultDealTypes: [
      'spot-futures_perpetual',
      'futures_perpetual-spot',
      'spot-spot',
      'futures_perpetual-futures_perpetual',
    ],
    exchangeType: ['dex', 'cex'],
    filters: {},
    isDragging: false,
    dragIndex: null,
    defaultFilters: {
      exchangeLiquidityRange: [0, 10000],
      tokenLiquidityRange: [0, 10000],
      tokenMarketCapRange: [0, 10000],
      arbitrageLagMsRange: [0, 10000],
      crossExchangeSwitcherValue: true,
      marginFeeAvailableSwitcherValue: false,
      fundingRateAvailableSwitcherValue: false,
      visibleColumnsFilters: new Map([
        ['tokenFullName', ['Token', 'string']],
        ['exchangeSellName', ['Exchange', 'string']],
        ['baseToken', ['Portfolio', 'string']],
        ['longPrice', ['Price', 'number']],
        ['marginFee', ['Margin Fee', 'number']],
        ['marginFee24h', ['Margin Fee 24h', 'number']],
        ['fundingRate', ['Funding Rate', 'number']],
        ['fundingRate24h', ['Funding Rate 24h', 'number']],
        ['spread', ['Spread', 'number']],
        ['profit', ['Profit', 'number']],
        ['profit24h', ['Profit 24h', 'number']],
        ['avgProfitHourly', ['Average', 'number']],
        ['arbitrageLagMs', ['Lag Ms', 'number']],
      ]),
      visibleColumns: [],
      exchangeTypeFilters: ['dex', 'cex'],
      dealLengthFilter: 1,
    },
  }),

  computed: {
    sectionHeader() {
      return sectionHeader;
    },

    ...mapGetters({
      exchangesInfo: 'filters/exchangesInfo',
      coinsInfo: 'filters/coinsInfo',
    }),

    columns() {
      const exceptionsColumn = ['tokenFullName', 'exchangeSellName', 'baseToken',];
      return new Map(Array.from(this.filters.visibleColumnsFilters).filter(column => !exceptionsColumn.includes(column[0])));
    },

    dealLength() {
      const frequency = 3;

      return new Map([
        [1, 'Realtime'],
        [3, 'Last 24h'],
        [3 * frequency, '3 days cumulative'],
        [365 * frequency, '365 days cumulative']
      ]);
    },

    dealTypes() {
      return new Map([
        ['spot-futures_perpetual', 'Spot-Perpetual'],
        ['futures_perpetual-spot', 'Perpetual-Spot'],
        ['spot-spot', 'Spot-Spot'],
        ['futures_perpetual-futures_perpetual', 'Perpetual-Perpetual'],
      ]);
    },

    defaultParameters() {
      return {
        exchanges: this.defaultExchanges,
        assets: this.defaultAssets,
        dealTypes: this.defaultDealTypes,
      };
    },

    defaultAssets() {
      return [...this.assets.keys()];
    },

    uniqueAssets() {
      return Array.from(new Set(this.arbitrageData.flatMap(obj => obj.tokenId)));
    },

    assets() {
      return new Map(
          [...this.uniqueAssets].map(id => [this.coinsInfo[id].slug, this.coinsInfo[id].symbol.toUpperCase() || this.coinsInfo[id].slug])
      );
    },

    exchanges() {
      const uniqueExchanges = Array.from(new Set(this.arbitrageData.flatMap(obj => [obj.exchangeSellId, obj.exchangeBuyId])));

      return new Map(
          uniqueExchanges.map(id => [id, this.exchangesInfo[id].displayName || this.exchangesInfo[id].slug])
      );
    },

    defaultExchanges() {
      const exchanges = [...this.exchanges.keys()];

      return exchanges.filter(exchange => this.exchangesInfo[exchange].isApproved);
    },

    totalItems() {
      return this.sortedArbitrageData.length;
    },

    totalPages() {
      return Math.ceil(this.totalItems / this.size);
    },

    sortedArbitrageData() {
      const {
        sortBy,
        sortType,
        parameters: {
          exchanges,
          assets,
          exchangeTypeFilters: types,
          dealTypes: dealType,
          crossExchangeSwitcherValue: crossExchangeSwitcher,
          marginFeeAvailableSwitcherValue: marginFeeAvailableSwitcher,
          fundingRateAvailableSwitcherValue: fundingRateAvailableSwitcher,
          tokenMarketCapRange: [mCapFrom, mCapTo],
          tokenLiquidityRange: [volumeFrom, volumeTo],
          exchangeLiquidityRange: [liquidityFrom, liquidityTo],
          arbitrageLagMsRange: [LagMsFrom, LagMsTo]
        },
        arbitrageData,
        searchText,
        reversed
      } = this;

      let filteredArray = arbitrageData.filter(obj => (
          exchanges.includes(obj.exchangeBuyId) &&
          exchanges.includes(obj.exchangeSellId) &&
          assets.includes(obj.tokenSlug) &&
          types.includes(obj.type) &&
          dealType.includes(obj.dealType) &&
          obj.crossExchange === crossExchangeSwitcher &&
          obj.shortExchangeLiquidity >= liquidityFrom &&
          obj.shortExchangeLiquidity <= liquidityTo &&
          obj.longExchangeLiquidity >= liquidityFrom &&
          obj.longExchangeLiquidity <= liquidityTo &&
          obj.arbitrageLagMs >= LagMsFrom &&
          obj.arbitrageLagMs <= LagMsTo &&
          obj.mcap >= mCapFrom &&
          obj.mcap <= mCapTo &&
          obj.volumeDay >= volumeFrom &&
          obj.volumeDay <= volumeTo
      ));

      if (marginFeeAvailableSwitcher || fundingRateAvailableSwitcher) {
        filteredArray = filteredArray.filter(obj => (
            (!marginFeeAvailableSwitcher || (obj.marginFee !== '-' && obj.marginFee24h !== '-')) &&
            (!fundingRateAvailableSwitcher || (obj.fundingRate !== '-' && obj.fundingRate24h !== '-'))
        ));
      }

      let sortedArray = this.sorting(filteredArray, sortBy, sortType);

      if (searchText && searchText.length) {
        sortedArray = this.filteredSearchResult(sortedArray, searchText);
      }

      return reversed ? sortedArray.reverse() : sortedArray;
    },

  },

  watch: {
    isResizeble() {
      this.setSize();
    },
  },

  methods: {
    ...mapActions({
      getArbitrageDataCustom: 'charts/getArbitrageDataCustom',
    }),

    saveState() {
      localStorage.setItem('tableColumnsArbitrageCalculator', JSON.stringify(Array.from(this.parameters.visibleColumnsFilters)));
    },

    restoreState() {
      const savedColumns = localStorage.getItem('tableColumnsArbitrageCalculator');
      if (savedColumns) {
        this.defaultFilters.visibleColumnsFilters = new Map(JSON.parse(savedColumns));
      } else {
        this.defaultFilters.visibleColumns = [...this.defaultFilters.visibleColumnsFilters.keys()];
      }
    },

    openChart(e, arbitrageItem) {
      if (e.target.parentElement._prevClass === 'arbitrage-pair-link') {
        return;
      }
      this.currencyArbitrageItem = arbitrageItem;
      this.showChart = true;
    },

    closeChart() {
      this.showChart = false;
    },

    createLinkToExchange(slug, baseToken, quoteToken, type) {
      if (baseToken === 'miota') {
        baseToken = 'iota';
      }

      let url = '';

      const exchangeUrls = {
        whitebit: {
          spot: `https://whitebit.com/trade/${baseToken}-${quoteToken}?type=spot`,
          other: `https://whitebit.com/trade/${baseToken}-${quoteToken}`
        },
        okx: {
          spot: `https://www.okx.com/ru/trade-spot/${baseToken}-${quoteToken}`,
          other: `https://www.okx.com/trade-swap/${baseToken}-${quoteToken}-swap`
        },
        kucoin: {
          spot: `https://www.kucoin.com/ru/trade/${baseToken.toUpperCase()}-${quoteToken.toUpperCase()}`,
          other: `https://www.kucoin.com/ru/futures/trade/${baseToken.toUpperCase()}${quoteToken.toUpperCase()}M`
        },
        indoex: `https://www.indoex.io/trade/${baseToken.toUpperCase()}_${quoteToken.toUpperCase()}`,
        huobi: {
          spot: `https://www.huobi.com/en-us/trade/${baseToken}_${quoteToken}?type=spot`,
          other: `https://www.huobi.com/en-us/futures/linear_swap/exchange/#contract_code=${baseToken.toUpperCase()}-${quoteToken.toUpperCase()}&contract_type=swap&type=isolated`
        },
        dydx: `https://trade.dydx.exchange/trade/${baseToken}-${quoteToken}`,
        deribit: {
          spot: `https://www.deribit.com/futures/${baseToken}_${quoteToken}-PERPETUAL`,
          other: `https://www.deribit.com/spot/${baseToken}_${quoteToken}`
        },
        'coinbase-exchange': `https://exchange.coinbase.com/trade/${baseToken}-${quoteToken}`,
        bybit: {
          spot: `https://www.bybit.com/en-US/trade/spot/${baseToken}/${quoteToken}`,
          other: `https://www.bybit.com/trade/usdt/${baseToken}${quoteToken}`
        },
        bitmex: {
          spot: `https://www.bitmex.com/app/trade/${baseToken.toUpperCase()}_${quoteToken.toUpperCase()}`,
          other: `https://www.bitmex.com/app/trade/${baseToken.toUpperCase()}${quoteToken.toUpperCase()}`
        },
        bitfinex: {
          spot: `https://trading.bitfinex.com/t/${baseToken.toUpperCase()}:${quoteToken.toUpperCase()}?type=exchange`,
          other: `https://trading.bitfinex.com/t/${baseToken.toUpperCase()}F0:${quoteToken.toUpperCase()}F0`
        },
        binance: {
          spot: `https://www.binance.com/en/trade/${baseToken.toUpperCase()}_${quoteToken.toUpperCase()}?theme=dark&type=spot`,
          other: `https://www.binance.com/en/futures/${baseToken.toUpperCase()}${quoteToken.toUpperCase()}`
        }
      };

      const exchangeUrl = exchangeUrls[slug];

      if (exchangeUrl) {
        url = type === 'spot' ? exchangeUrl.spot : exchangeUrl.other;
      }

      return url;
    },

    setSize() {
      const { row, chartContent } = this.$refs;
      const rowHeight = row[0].clientHeight;

      this.size = Math.floor((chartContent.clientHeight - rowHeight / 2) / rowHeight) - 1;
      this.page = 0;
    },

    closeFiltersPopup() {
      this.$refs.filtersPopup.closePopup();
    },

    search(text) {
      this.searchText = text;
    },

    filteredSearchResult(arr, text) {
      const filterLowered = text.toLowerCase();

      return arr.filter(item => {
        const { tokenFullName, tokenName, exchangeBuyName, exchangeSellName, baseToken, qouteToken } = item;
        const itemLowered = {
          tokenFullName: tokenFullName.toLowerCase(),
          tokenName: tokenName.toLowerCase(),
          exchangeBuyName: exchangeBuyName.toLowerCase(),
          exchangeSellName: exchangeSellName.toLowerCase(),
          baseToken: baseToken.toLowerCase(),
          quoteToken: qouteToken.toLowerCase()
        };

        return Object.values(itemLowered).some(value => value.includes(filterLowered));
      });
    },

    getMinMaxValue() {
      const marketCaps = [];
      const volumes = [];
      const exchangesLiquidity = [];
      const arbitrageLagMs = [];

      const uniqueAssets = new Set(this.arbitrageData.flatMap(obj => obj.tokenId));
      const dataAssets = Object.values(this.coinsInfo).filter(coin => uniqueAssets.has(coin.id));
      const uniqueExchanges = new Set(this.arbitrageData.flatMap(obj => [obj.exchangeSellId, obj.exchangeBuyId]));
      const dataExchanges = Object.keys(this.exchangesInfo).filter(exchangeId => uniqueExchanges.has(+exchangeId));

      for (const coin of dataAssets) {
        marketCaps.push(coin.mcap);
        volumes.push(coin.volumeDay);
      }

      for (const exchangeId of dataExchanges) {
        exchangesLiquidity.push(this.exchangesInfo[exchangeId].liquidity);
      }

      this.arbitrageData.map(item => arbitrageLagMs.push(item.arbitrageLagMs));

      this.defaultFilters.tokenMarketCapRange[0] = Math.ceil(Math.min(...marketCaps));
      this.defaultFilters.tokenMarketCapRange[1] = Math.ceil(Math.max(...marketCaps));
      this.defaultFilters.tokenLiquidityRange[0] = Math.ceil(Math.min(...volumes));
      this.defaultFilters.tokenLiquidityRange[1] = Math.ceil(Math.max(...volumes));
      this.defaultFilters.exchangeLiquidityRange[0] = Math.ceil(Math.min(...exchangesLiquidity));
      this.defaultFilters.exchangeLiquidityRange[1] = Math.ceil(Math.max(...exchangesLiquidity));
      this.defaultFilters.arbitrageLagMsRange[0] = Math.ceil(Math.min(...arbitrageLagMs));
      this.defaultFilters.arbitrageLagMsRange[1] = Math.ceil(Math.max(...arbitrageLagMs));

      this.filters = this.deepClone(this.defaultFilters);
      this.parameters = { ...this.parameters, ...this.deepClone(this.filters) };
    },

    setGrowthDirection(number) {
      if (!number) {
        return '';
      }

      return number > 0 ? 'upward' : 'downward';
    },

    transformData(data) {
      const { coinsInfo, exchangesInfo } = this;

      const formatData = data.flatMap((item) => {
        const {
          baseAssetId,
          shortExchangeId,
          longExchangeId,
          shortType,
          longType,
          longPrice,
          shortPrice,
          quoteAssetId,
        } = item;

        if (
            !coinsInfo[baseAssetId] ||
            !exchangesInfo[shortExchangeId] ||
            !exchangesInfo[longExchangeId]
        ) {
          return [];
        }

        const baseAsset = coinsInfo[baseAssetId];
        const shortExchange = exchangesInfo[shortExchangeId];
        const longExchange = exchangesInfo[longExchangeId];

        return {
          ...item,
          tokenName: baseAsset.symbol.toUpperCase(),
          tokenSlug: baseAsset.slug,
          tokenFullName: baseAsset.displayName,
          tokenLink: `/token/${baseAsset.slug}`,
          tokenId: baseAsset.id,
          crossExchange: shortExchange.displayName !== longExchange.displayName,
          type: shortExchange.type,
          dealType: `${shortType}-${longType}`,
          exchangeSellName: shortExchange.displayName,
          exchangeSellLink: `/exchange/${shortExchange.slug}`,
          exchangeSellSlug: shortExchange.slug,
          exchangeSellId: shortExchangeId,
          exchangeBuyName: longExchange.displayName,
          exchangeBuyLink: `/exchange/${longExchange.slug}`,
          exchangeBuySlug: longExchange.slug,
          exchangeBuyId: longExchangeId,
          baseToken: baseAsset.symbol.toUpperCase(),
          qouteToken: coinsInfo[quoteAssetId].symbol.toUpperCase(),
          sellDirection: shortType === 'futures_perpetual' ? 'Perpetual' : 'Spot',
          buyDirection: longType === 'futures_perpetual' ? 'Perpetual' : 'Spot',
          sellLink: this.createLinkToExchange(
              shortExchange.slug,
              baseAsset.symbol,
              coinsInfo[quoteAssetId].symbol,
              shortType
          ),
          buyLink: this.createLinkToExchange(
              longExchange.slug,
              baseAsset.symbol,
              coinsInfo[quoteAssetId].symbol,
              longType
          ),
          marginFee: item.marginFee?.toFixed(4) || '-',
          marginFee24h: item.marginFeeDaily !== 0 ? item.marginFeeDaily?.toFixed(4) : '-',
          fundingRate: item.fundingRate !== 0 ? item.fundingRate?.toFixed(4) : '-',
          fundingRate24h: item.fundingRateDaily !== 0 ? item.fundingRateDaily?.toFixed(4) : '-',
          avgProfitHourly: item.avgProfitHourly !== 0 ? item.avgProfitHourly?.toFixed(4) : '-',
          spread: item.spread.toFixed(4),
          profit: item.profit.toFixed(4),
          profit24h: item.profitDaily !== 0 ? item.profitDaily?.toFixed(4) : '-',
          mcap: baseAsset.mcap,
          volumeDay: baseAsset.volumeDay,
          shortExchangeLiquidity: shortExchange.liquidity,
          longExchangeLiquidity: longExchange.liquidity,
          buyPrice: longPrice !== 0 ? longPrice.toFixed(4) : '-',
          sellPrice: shortPrice !== 0 ? shortPrice.toFixed(4) : '-',
        };
      });

      return formatData;
    },

    getData() {
      this.showPreloader = true;
      this.debounce(async () => {
        this.subscribeArbitrageData();
        try {
          const data = await this.getArbitrageDataCustom({});
          this.arbitrageData = this.transformData(data);

          if (this.arbitrageData.length) {
            this.getMinMaxValue();
          }

          this.$nextTick(() => {
            this.setSize();
          });
        } catch (e) {
          console.log(e);
        } finally {
          this.showPreloader = false;
        }
      }, 500);
    },

    subscribeArbitrageData() {
      ws.subscribe(
          'ARBITRAGE',
          {
            method: 'SUBSCRIBE',
            params: '{}',
          },
          (res) => {
            this.arbitrageData = this.transformData(res.response);
          }
      );
    },

    unsubscribeArbitrageData() {
      ws.unsubscribe(
          'ARBITRAGE',
          {
            method: 'UNSUBSCRIBE',
            params: this.wsParameters
          },
      );

      this.wsParameters = null;
    },
  },

  created() {
    this.restoreState();
    const parameters = this.deepClone(this.defaultFilters);
    this.parameters = { ...this.defaultParameters, ...parameters };
    this.filters = this.deepClone(this.defaultFilters);
  },

  mounted() {
    this.getData();
  },

  beforeDestroy() {
    this.unsubscribeArbitrageData();
  }
};
</script>

<style lang="scss" scoped src="@/assets/css/secondary-table.scss" />
<style lang="scss" scoped src="@/assets/css/filters.scss" />
<style lang="scss" scoped>
.tokenFullName,
.exchangeSellName,
.baseToken,
.longPrice {
  width: auto;
  text-align: left;
}

.funding-rate {
  z-index: 0;

  &__filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px 15px;
  }

  &__history {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    overflow-x: hidden;
    transition: all 1s;
    background-color: rgba(30, 30, 30, 0.5);
    cursor: pointer;

    &.active {
      z-index: 2;
      opacity: 1;
    }
  }

  &__realized {
    margin-left: auto;
    border: 1px solid var(--mainBorderColor);
    width: 60%;
    background-color: var(--mainBGColor);
  }
}

.custom-pagination {
  margin-left: 30px;
}

table .header-wrapper {
  justify-content: flex-start;
}

th button:hover path {
  stroke: #92939c;
}

.sorted:hover path.active {
  stroke: var(--textMainColor);
}

th.dragging {
  opacity: 0.5;
}

.expand-enter-active,
.expand-leave-active {
  transform: translateX(0);
  transition: transform 1s;
}

.expand-enter,
.expand-leave-to {
  transform: translateX(100%);
}

.baseToken {
  width: 120px;
}

.chart-content {
  position: relative;
}

tbody {
  tr {
    position: relative;
    z-index: 1;
    cursor: pointer;

    &::after {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      right: -20px;
      bottom: 0;
      left: -20px;
      transition: all .5s;
    }

    &:nth-child(even)::after {
      background-color: var(--mainBGColorHover);
    }

    &:hover::after {
      background: #1E1E1E !important;
    }
  }

  td {
    padding: 20px 0 20px 10px;
    font-size: 0.875rem;
    line-height: 18px;
    vertical-align: top;
  }
}

</style>
