import store from '@/store';

export default {
  SET_ORDER_BOOK(state, orderBook) {
    state.orderBook = orderBook;
  },

  SET_ASSET_PRICE(state, assetPrice) {
    state.assetPrice = assetPrice;
  },

  SET_VOLUME_WAP(state, volumeWap) {
    state.volumeWap = volumeWap;
  },

  SET_DOMINANCE(state, dominance) {
    state.dominance = dominance;
  },

  SET_ANNOUNCEMENTS(state, announcements) {
    state.announcements = announcements.flatMap(announcement => announcement.pairs.map((pair, index) => ({
      displayName: pair,
      id: announcement.pairIds[index] || [0, 0],
      ...announcement,
    })));
  },

  SET_VOLUME_BY_PRICE(state, volumeByPrice) {
    state.volumeByPrice = volumeByPrice;
  },

  SET_VOLUME_BY_TIME(state, volumeByTime) {
    state.volumeByTime = volumeByTime;
  },

  SET_FUNDING_RATE(state, fundingRate) {
    const coinsInfo = store.getters['filters/coinsInfo'];
    const unknownCoinsInfo = store.getters['filters/unknownCoinsInfo'];
    const unknownIds = new Set();
    const fundingRatePairs = new Map();
    const fundingRateMap = new Map();
    const stableCoinIds = new Set();

    fundingRate.forEach(rate => {
      const pair = JSON.stringify([rate.baseCoinId, rate.quoteCoinId]);

      if (!coinsInfo[rate.baseCoinId] && !unknownCoinsInfo[rate.baseCoinId]) {
        unknownIds.add(rate.baseCoinId);
      }

      if (!fundingRateMap.has(rate.baseCoinId)) {
        fundingRateMap.set(rate.baseCoinId, new Map());
      }

      const baseCoinMap = fundingRateMap.get(rate.baseCoinId);
      if (!baseCoinMap.has(rate.exchangeId)) {
        baseCoinMap.set(rate.exchangeId, new Map());
      }

      const exchangeValues = baseCoinMap.get(rate.exchangeId);
      if (!exchangeValues.has(rate.type)) {
        exchangeValues.set(rate.type, new Map());
      }

      const type = exchangeValues.get(rate.type);
      type.set(rate.quoteCoinId, rate.rate);
      stableCoinIds.add(rate.quoteCoinId);

      if (!fundingRatePairs.has(pair)) {
        fundingRatePairs.set(pair, new Map());
      }

      const exchangeMap = fundingRatePairs.get(pair).get(rate.exchangeId) || new Map();
      exchangeMap.set(rate.type, rate.rate);
      fundingRatePairs.get(pair).set(rate.exchangeId, exchangeMap);
    });

    state.fundingRate = {
      content: fundingRateMap,
      pairs: fundingRatePairs,
      stableCoinIds: stableCoinIds,
    };
  },

  SET_FUNDING_RATE_REALIZED(state, fundingRateRealized) {
    state.fundingRateRealized = fundingRateRealized;
  },

  SET_SCAM_SCORE(state, scamScore) {
    state.scamScore = scamScore;
  },

  SET_SCAM_SCORE_POINTS(state, scamScorePoints) {
    state.scamScorePoints = scamScorePoints;
  },

  SET_EXCHANGE_PAIRS(state, exchangePairs) {
    const tokenIds = new Set();

    exchangePairs.forEach(pair => {
      tokenIds.add(pair.coinId);
    });

    state.exchangePairs = {
      tokenIds: tokenIds,
      content: exchangePairs
    };
  },

  SET_TOKEN_TIME_PERIOD(state, tokenTimePeriod) {
    state.tokenTimePeriod = tokenTimePeriod;
  },
};
