<template>
  <div class="slider range-slider">
    <h6 class="slider__title">{{ title }}</h6>
    <div class="slider__content">
      <v-range-slider
          v-model="sliderValue"
          :max="max"
          :min="min"
          class="align-center"
      >
        <template v-slot:prepend>
          <v-text-field
              :value="transformValue[0]"
              hide-details
              single-line
              type="text"
              :min="min"
              :disabled="true"
              @change="$set(sliderValue, 0, $event)"
          ><span>From</span></v-text-field>
        </template>
        <template v-slot:append>
          <v-text-field
              :value="transformValue[1]"
              hide-details
              single-line
              type="text"
              variant="outlined"
              :max="max"
              density="compact"
              :disabled="true"
              @change="$set(sliderValue, 1, $event)"
          ></v-text-field>
        </template>
      </v-range-slider>
    </div>
  </div>
</template>
<script>
import debounce from '@/mixins/debounce';
import {makeCompactNumber} from "@/helpers";

export default {
  name: 'Range',

  components: {
    // Slider
  },

  mixins: [debounce],

  data() {
    return {
      sliderValue: null,
      transformValue: null,
    };
  },

  watch: {
    sliderValue(val) {
      this.debounce( () => {
        this.$emit('update', val)
        this.transformValue = [this.makeCompactNumber(val[0]), this.makeCompactNumber(val[1])]
      }, 150)
    },

    defaultValue(val) {
      this.sliderValue = val;
    }
  },

  props: {
    title: {
      type: String,
      required: false,
    },

    defaultValue: {
      type: Array,
      required: true,
      default: () => [],
    },

    min: {
      type: Number,
      required: false,
    },

    max: {
      type: Number,
      required: true,
    }
  },

  methods: {
    makeCompactNumber,
  },

  created() {
    this.sliderValue = [...this.defaultValue];
    this.transformValue = [...this.defaultValue];
  },
};
</script>
<style lang="scss" scoped>
.slider {
  &__title {
    margin-bottom: 4px;
    font-size: 0.875rem;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
  }

  &__tooltip {
    display: inline-block;
    border: 1px solid var(--mainBorderColor);
    border-radius: 8px;
    width: 70px;
    padding: 4px 8px;
    background-color: var(--mainBGBodyColor);
    font-size: 0.725rem;
    text-align: center;
  }

  &::v-deep {
    .slider-horizontal {
      width: 200px;
      height: 4px;
    }

    .slider-handle {
      right: calc(24px / 2 * -1);
      transform: translateY(-18%);
      border: 2px solid #2e2d2d;
      width: 24px;
      height: 24px;
      background-color: var(--accent);

      &:focus {
        box-shadow: none;
      }
    }

    .slider-connect {
      background-color: var(--accent);
    }

    .slider-base,
    .slider-connects {
      height: 4px;
      background-color: #2e2d2d;
    }
  }
}
</style>
