<template>
  <transition name="expand">
    <div v-if="prompt" class="update-dialog">
      <div class="update-dialog__content">
        <h6 class="update-dialog__title">A new update is available!</h6>
        <p class="update-dialog__text">Update to get the latest version.</p>
      </div>
      <div class="update-dialog__actions">
        <button
            class="update-dialog__button update-dialog__button--cancel"
            @click="prompt = false"
        >
          Remind Later
        </button>
        <button
            class="update-dialog__button update-dialog__button--confirm ascent-btn"
            @click="update"
        >
          Update
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'UpdatePopup',

  data: () => ({
    prompt: false,
    refreshing: false,
    registration: null,
    updateExists: false,
  }),

  methods: {
    async update() {
      // this.updateExists = false;
      // this.prompt = false;
      //
      // if (!this.registration || !this.registration.waiting) return;
      // this.registration.waiting.postMessage('skipWaiting');
    },

    showRefreshUI(e) {
      this.registration = e.detail;
      this.updateExists = true;
      this.prompt = true;
    },
  },

  created() {
    // document.addEventListener('swUpdated', this.showRefreshUI, { once: true });
    // navigator.serviceWorker.addEventListener('controllerchange', () => {
    //   if (this.refreshing) return;
    //   this.refreshing = true;
    //   window.location.reload();
    // });
  },
};
</script>

<style lang="scss" scoped>
.update-dialog {
  position: fixed;
  z-index: 1000;
  right: 20px;
  bottom: 20px;
  opacity: 1;
  transition: all 1s;
  border: 1px solid var(--mainBorderColor);
  border-radius: 16px;
  width: calc(100% - 40px);
  max-width: 600px;
  padding: 32px 32px 32px 177px;
  background: url("https://dexilon.io/wp-content/themes/dexilon/assets/img/notification-bg.png") var(--headerBGColor) 50% 50%/cover no-repeat;

  &__title {
    margin-bottom: 15px;
    font-size: 1.5rem !important;
    color: var(--textMainColor);
  }

  &__text {
    margin-bottom: 32px;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: .02em;
    color: var(--textSecondaryColor);
  }

  &__actions {
    display: flex;
    gap: 20px;
  }

  &__button {
    transition: 0.2s;
    border-radius: 4px;
    padding: 12px 24px;
    font-family: "Inter", sans-serif;
    font-weight: 800;
    color: var(--lightColor);

    &--confirm {
      flex: 1;
    }

    &--cancel {
      border: 1px solid var(--accent);
      background-color: var(--mainBGColor);
      color: var(--accent);

      &:hover {
        background-color: var(--lightColor);
      }
    }
  }
}

.expand-enter-active,
.expand-leave-active {
  transform: translateX(0);
  transition: transform 1s;
}

.expand-enter,
.expand-leave-to {
  transform: translateX(100%);
}
</style>