<template>
  <div :key="+new Date()">
    <div
        :class="valueStyle(current, 0)"
        :title="values[0]"
        class="text-center sub-row"
        v-html="roundNumber(current)"
    />
    <div
        v-show="showPredicted"
        :class="valueStyle(predicted, 1)"
        class="sub-row"
        v-html="roundNumber(predicted)"
    />
  </div>
</template>

<script>
import { makeCompactNumber } from '@/helpers';

export default {
  name: 'FundingRateCell',

  props: {
    values: {
      type: Map,
      required: false,
      default: () => new Map(),
    },

    rate: {
      type: Number,
      required: false,
    },

    previusRate: {
      type: Number,
      required: false,
    },

    showPredicted: {
      type: Boolean,
      required: true,
    },

    tokenMode: {
      type: Boolean,
      required: true,
    }
  },

  data: () => ({
    prevValues: [],
  }),

  computed: {
    current() {
      const current = this.values.get('current');
      if (current === undefined || current.size === 0) {
        return null;
      }

      if (this.tokenMode) {
        return [...current.values()].reduce((acc, val) => acc + val, 0) / current.size;
      }

      return current;
    },

    predicted() {
      const predicted = this.values.get('predicted');

      if (predicted === undefined || !predicted.size) {
        return null;
      }

      if (this.tokenMode) {
        return [...predicted.values()].reduce((acc, val) => acc + val, 0) / predicted.size;
      }

      return predicted;
    },
  },

  methods: {
    makeCompactNumber,
    valueStyle(value, index) {
      if (value === null || value * this.rate === 0.01) {
        return '';
      }
      const curValue = value * this.rate;
      const prevValue = this.prevValues[index] * this.previusRate;
      const bgcStyle = curValue === prevValue ? ''
          : curValue > prevValue ? 'bgc-up '
              : 'bgc-down ';

      this.prevValues[index] = value;
      return bgcStyle + (value * this.rate > 0.01 ? 'down' : 'up');
    },

    roundNumber(number) {
      if (number === null) {
        return '&#8211;';
      }
      let maxFractionDigits = 5;
      number *= this.rate;
      if (+number >= 10 ** maxFractionDigits) {
        return this.makeCompactNumber(number, 1, 1);
      }
      const integerPart = Math.abs(Math.round(number)) + '';
      let numFractionDigits = maxFractionDigits - integerPart.length;
      numFractionDigits = numFractionDigits < 0 ? 0 : numFractionDigits;

      return this.makeCompactNumber(number, numFractionDigits, numFractionDigits) + '%';
    },
  },
};
</script>

<style scoped>
.sub-row {
  padding: 11.5px 16px;
}

.bgc-up {
  animation: bgc-up 2s;
}

.bgc-down {
  animation: bgc-down 2s;
}

@keyframes bgc-up {
  0% {
    background-color: rgba(0, 255, 240, 0.15);
  }

  50% {
    background-color: rgba(0, 255, 240, 0.15);
  }

  100% {
    background-color: rgba(0, 255, 240, 0);
  }
}

@keyframes bgc-down {
  0% {
    background-color: rgba(255, 61, 0, 0.12);
  }

  50% {
    background-color: rgba(255, 61, 0, 0.12);
  }

  100% {
    background-color: rgba(255, 61, 0, 0);
  }
}

</style>