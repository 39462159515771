<template>
  <div class="custom-scroll">
    <div class="legends">
      <button
          v-for="(exchange, index) in legends"
          :key="index"
          :class="{active: index === 0}"
          :style="{width: 100 / legends.length + '%'}"
          class="legends__item"
          @click="$emit('itemSelected', index)"
      >
        <span class="exchange-name">
          <span
              :style="{backgroundColor: getColor(exchange.name)}"
              class="exchange-name__color"
          />
          {{ legendParameters[exchange.name].displayName }}
        </span>

        <div>
          <span :title="exchange.currentPrice">
            {{ makeCompactNumber(exchange.currentPrice, legendNumberLength, legendNumberLength) }}
            <span class="secondary-text">{{ measure }}</span>
            &nbsp;
          </span>
          <template v-if="exchange.difference">
            <span :class="setColorGrowth(exchange.difference)" class="value">
              {{ makeCompactNumber(exchange.difference) }}
            </span>
            <span v-if="showPercentage" class="secondary-text"> %</span>
          </template>
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import { makeCompactNumber } from '@/helpers';

export default {
  name: 'Legends',

  props: {
    legends: {
      type: Array,
      required: true,
    },

    legendParameters: {
      type: Object,
      required: true,
    },

    measure: {
      type: String,
      required: false,
      default: '',
    },

    showPercentage: {
      type: Boolean,
      required: false,
      default: false,
    }
  },

  computed: {
    legendNumberLength() {
      return this.legends[0]?.currentPrice > 1 ? 2 : 4;
    }
  },

  methods: {
    makeCompactNumber,

    getColor(exchange) {
      return this.legendParameters[exchange].color;
    },

    setColorGrowth(value) {
      return value > 0 ? 'up' : value < 0 ? 'down' : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-scroll {
  overflow-y: hidden !important;
  border-top: 1px solid var(--mainBorderColor);
  height: 60px;
}

.legends {
  display: flex;

  &__item {
    flex-grow: 1;
    transition: background-color 0.1s;
    min-width: 180px;
    padding: 7px 25px;
    cursor: default;
    font-size: 0.875rem;
    text-align: left;
    white-space: nowrap;
    color: var(--textMainColor);

    &:not(:last-child) {
      border-right: 1px solid var(--mainBorderColor);
    }
  }
}

.exchange-name {
  position: relative;

  &__color {
    position: absolute;
    top: 0;
    left: -6px;
    transform: translate(-100%, 50%);
    border-radius: 50%;
    width: 10px;
    height: 10px;
  }
}
</style>