<template>
  <div class="spot-chart chart-wrapper">
    <SectionHeader
        :is-filters-open="isFiltersOpen"
        :is-deletable="isDeletable"
        :show-copy-chart-to-pd="showCopyToPd"
        :title="title"
        @copyChartToPD="$emit('copyChartToPD', {})"
        @removeItem="removeItem"
        @setStatic="$emit('setStatic', $event)"
        @setStretchPosition="setStretchPosition"
        @toggleFiltersVisibility="isFiltersOpen = $event"
    />

    <transition name="expand-filters">
      <div v-show="isFiltersOpen" ref="sectionFilters" class="chart-filters">
        <div class="chart-filter">
          <Select
              ref="asset"
              :default-option-value="parametersForSelects.asset || defaultAsset"
              :options="assets"
              title="Assets"
              @emptySelect="parameters.asset = defaultAsset"
              @selected="updateParameter($event, 'asset')"
          />
        </div>

        <div class="chart-filter">
          <MultiSelect
              ref="exchange"
              :default-option-value="parametersForSelects.exchanges || defaultExchanges"
              :options="exchanges"
              title="exchanges"
              @emptySelect="parameters.exchangeIds = defaultExchanges"
              @selected="updateParameter($event, 'exchangeIds')"
          />
        </div>

        <div class="chart-filter">
          <Select
              ref="pair"
              :default-option-value="defaultPair"
              :options="pairs"
              title="pair"
              @emptySelect="parameters.currencyPair = defaultPair"
              @selected="updateParameter($event, 'currencyPair')"
          />
        </div>

        <div class="chart-filter">
          <Select
              ref="dimension"
              :default-option-value="parametersForSelects.dimension || defaultDimension"
              :options="dimensions"
              title="time period"
              @selected="updateParameter($event, 'dimension')"
          />
        </div>

        <div class="chart-filter reset">
          <button :disabled="showPreloader || resetDisabled" class="reset" @click="resetParameters">
            Reset
          </button>
        </div>
      </div>
    </transition>

    <div class="chart-content">
      <div v-show="showPreloader" class="main-preloader widget-preloader chart-preloader">
        <Loader
            ref="preloader"
            :animationData="require('@/assets/images/Loader.json')"
            :autoPlay="true"
            :loop="true"
            :speed="1"
        />
      </div>

      <highcharts
          ref="chart"
          :options="chartOptions"
          :style="{ width: '100%', height: chartHeight }"
          constructor-type="stockChart"
      />
    </div>

    <Legends
        :legend-parameters="exchangesInfo"
        :legends="legends"
        :show-percentage="true"
        @itemSelected="changeActiveLegend"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ws } from '@/utils/socket';
import Highcharts from 'highcharts';
import debounce from '@/mixins/debounce';
import assetPriceSettings from '@/mixins/chart-settings/assetPrice';
import propsParameters from '@/mixins/propsParameters';
import commonFilters from '@/mixins/filters/commonFilters';

export default {
  name: 'AssetPrice',

  components: {},

  mixins: [debounce, assetPriceSettings, propsParameters, commonFilters],

  props: {
    isResizeble: Boolean,
  },

  data: () => ({
    Highcharts,
    parameters: {},
    wsParameters: null,
    legends: [],
    showPreloader: true,
    resetDisabled: true,
    isFiltersOpen: true,
  }),

  computed: {
    ...mapGetters({
      filters: 'filters/exchangeAssetPrices',
      exchangesInfo: 'filters/exchangesInfo',
      coinsInfo: 'filters/coinsInfo',
    }),

    title() {
      const type = this.assets.get(this.parameters.asset || this.parametersForSelects.asset || this.defaultAsset);
      return `Asset Price on Various Exchanges ${type}`;
    },

    defaultParameters() {
      return {
        exchangeIds: this.defaultExchanges,
        asset: this.defaultAsset,
        currencyPair: this.defaultPair,
        dimension: this.defaultDimension,
      };
    },
  },

  methods: {
    ...mapActions({
      getAssetPricesData: 'charts/getAssetPrices',
    }),

    roundValue(value) {
      return Math.round(value * 1000) / 1000;
    },

    updateParameter(parameter, name) {
      this.resetDisabled = false;
      this.parameters = {
        ...this.parameters,
        [name]: parameter
      };

      this.getChartData();
    },

    async resetParameters() {
      this.resetDisabled = true;
      this.parameters = {
        ...this.defaultParameters,
        ...this.parametersForSelects,
      };

      await this.getChartData();

      this.$refs.asset.reset();
      this.$refs.exchange.reset();
      this.$refs.pair.reset();
      this.$refs.dimension.reset();
    },

    findDifference(exchangeName) {
      const exchange = this.legends[0];
      const currentExchange = this.legends.find(el => el.name === exchangeName);

      if (!exchange || !currentExchange) {
        return;
      }

      const difference = (currentExchange.currentPrice - exchange.currentPrice) / exchange.currentPrice * 100;

      return this.roundValue(difference);
    },

    setColorGrowth(value) {
      return value > 0 ? 'up' : value < 0 ? 'down' : '';
    },

    getChartData() {
      this.showPreloader = true;
      this.debounce(async () => {
        this.unsubscribeAssetPricesData();

        try {
          const { asset, currencyPair } = this.parameters;
          this.wsParameters = JSON.stringify({
            asset,
            currencyPair: currencyPair.split(',')
          });

          const assetPrices = await this.getAssetPricesData(this.parameters);
          this.setSeries(assetPrices);
          this.parameters.dimension === this.defaultDimension
              ? this.subscribeAssetPricesData()
              : this.wsParameters = null;

        } catch (e) {
          console.log(e);
        } finally {
          this.showPreloader = false;
        }
      }, 500);
    },

    subscribeAssetPricesData() {
      ws.subscribe(
          'ASSET_PRICES',
          {
            method: 'SUBSCRIBE',
            params: this.wsParameters
          },
          (res) => {
            if (res.params !== this.wsParameters) {
              return;
            }

            this.chartOptions.series.forEach(ser => {
              const assetPrice = res.response[ser.name];

              ser.data.shift();
              ser.data.push(assetPrice || [res.eventTime, ser.data.at(-1)[1]]);
            });

            this.legends.forEach(exchange => {
              const assetPrice = res.response[exchange.name];

              if (assetPrice) {
                exchange.currentPrice = assetPrice[1];
              }

              exchange.difference = this.findDifference(exchange.name);
            });

            this.legends = [...this.legends];
          }
      );
    },

    unsubscribeAssetPricesData() {
      ws.unsubscribe(
          'ASSET_PRICES',
          {
            method: 'UNSUBSCRIBE',
            params: this.wsParameters
          },
      );

      this.wsParameters = null;
    },

    getExchangeColor(exchange) {
      return this.exchangesInfo[exchange].color;
    },

    setSeries(assetPrices) {
      this.legends = Object.keys(assetPrices).map(asset => ({
        name: asset,
      }));
      this.legends.sort((a, b) => a.name.localeCompare(b.name));
      const series = [];

      this.legends.forEach(exchange => {
        const exchangeName = exchange.name;
        const assetData = assetPrices[exchangeName];
        exchange.currentPrice = assetData.at(-1)[1];
        exchange.difference = this.findDifference(exchangeName);

        series.push({
          ...this.seriesItem,
          pointInterval: 24 * 3600 * 1000,
          name: exchangeName,
          color: this.getExchangeColor(exchangeName),
          data: assetData,
        });
      });
      this.chartOptions.series = series;

      // const element = this.filtersRef;
      // const elementHeight = element.clientHeight;
      // console.log(elementHeight);
      // const newHeight = Math.abs(elementHeight - 5);


      this.chartHeight = `calc(100% - 10px)`;
      this.resize();
      this.$refs.chart.chart.reflow();
    },

    setStretchPosition() {
      this.$refs.chart.chart.fullscreen.toggle();
    },

    showAllLegend() {
      const legendNodeList = document.getElementsByClassName('highcharts-legend-item');
      const legendArray = Array.from(legendNodeList);

      legendArray.forEach((legend, index) => {
        if (legend.classList.contains('highcharts-legend-item-hidden')) {
          Highcharts.fireEvent(document.getElementsByClassName('highcharts-legend-item')[index], 'click');
        }
      });
    },

    setDifferentValue() {
      this.chartOptions.series.forEach(ser => {
        const index = this.legends.findIndex(el => el.name === ser.name);

        if (this.legends[index]) {
          const lastSer = ser.data.at(-1);

          this.legends = this.legends.map((exchange, i) => {
            if (index === i) {
              exchange.currentPrice = lastSer[1];
              exchange.difference = this.findDifference(ser.name);
            }

            return exchange;
          });
        }
      });
    },

    changeActiveLegend(index) {
      const indexSeries = this.chartOptions.series.findIndex(el => el.name === this.legends[0].name);

      this.legends.push(...this.legends.splice(0, index));
      this.chartOptions.series.push(...this.chartOptions.series.splice(0, indexSeries));

      this.setDifferentValue();
    },

    removeItem() {
      this.$emit('removeItem', null);
    },
  },

  created() {
    this.getChartData();
  },

  beforeDestroy() {
    this.unsubscribeAssetPricesData();
  }
};
</script>

<style lang="scss" scoped>
.spot-chart {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  height: 100%;
}

.chart-wrapper::v-deep {
  .legends__item {
    transition: background-color 0.1s;
    cursor: pointer;

    &:hover {
      background-color: var(--mainBGColorHover);
    }

    &.active {
      border-right: 4px solid var(--accent);
      background: var(--mainBorderColor);
    }
  }
}
</style>
