<template>
  <div class="order-book-chart-wrapper chart-wrapper">
    <SectionHeader
        :is-deletable="isDeletable"
        :is-filters-open="isFiltersOpen"
        :show-copy-chart-to-pd="showCopyToPd"
        info-text="Table and pie chart showing the ratio of volumes of selected tokens and stable pairs on various exchanges for the selected period."
        title="Orderbook Vol by Exchange"
        @copyChartToPD="$emit('copyChartToPD', parameters)"
        @removeItem="removeItem"
        @setStatic="$emit('setStatic', $event)"
        @setStretchPosition="setStretchPosition"
        @toggleFiltersVisibility="isFiltersOpen = $event"
    />

    <transition name="expand-filters">
      <div v-show="isFiltersOpen" class="chart-filters">
        <div class="chart-filter">
          <MultiSelect
              ref="exchanges"
              :default-option-value="parametersForSelects.exchangeIds || defaultExchanges"
              :options="exchanges"
              title="Exchanges"
              @selected="updateParameter($event, 'exchangeIds', false)"
          />
        </div>
        <div class="chart-filter">
          <MultiSelect
              ref="assets"
              :default-option-value="parametersForSelects.assets || allAssets"
              :options="assets"
              title="Assets"
              @emptySelect="parameters.assets = allAssets"
              @selected="updateParameter($event, 'assets', false)"
          />
        </div>
        <div class="chart-filter">
          <Select
              ref="symbol"
              :default-option-value="parametersForSelects.symbol || defaultSymbol"
              :options="symbols"
              title="Token"
              @emptySelect="parameters.tokenId = defaultSymbol"
              @selected="updateParameter($event, 'tokenId')"
          />
        </div>
        <div class="chart-filter">
          <MultiSelect
              ref="pairs"
              :default-option-value="parametersForSelects.currencyPairs || defaultPairs"
              :options="filteredCurrencyPairs"
              title="Trading Pairs"
              @emptySelect="parameters.currencyPairs = defaultPairs"
              @selected="updateParameter($event, 'currencyPairs', false)"
          />
        </div>
        <div class="chart-filter">
          <Select
              ref="slippage"
              :default-option-value="parametersForSelects.slippageIndex || defaultSlippage"
              :options="slippages"
              suffix="%"
              title="Slippage"
              @emptySelect="parameters.slippageIndex = defaultSlippage"
              @selected="updateParameter($event, 'slippageIndex')"
          />
        </div>
        <div class="chart-filter">
          <Select
              ref="dimension"
              :default-option-value="parametersForSelects.dimension || defaultDimension"
              :options="dimensions"
              title="Time Period"
              @selected="updateParameter($event, 'dimension')"
          />
        </div>
        <div class="chart-filter reset">
          <button :disabled="showPreloader || resetDisabled" class="reset" @click="resetParameters">
            Reset
          </button>
        </div>
      </div>
    </transition>

    <div class="order-book-chart chart-content">
      <div v-show="showPreloader" class="main-preloader widget-preloader chart-preloader">
        <Loader
            ref="preloader"
            :animationData="require('@/assets/images/Loader.json')"
            :autoPlay="true"
            :loop="true"
            :speed="1"
        />
      </div>
      <div class="order-book-info">
        <Table
            ref="table"
            :active-exchange-id="activeExchangeId"
            :order-book="orderBook"
        />
        <PieChart
            ref="orderBookChartSection"
            :base="baseSlug"
            :orderBookChartData="orderbookChartData"
            @activeExchangeChanged="activeExchangeId = $event"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Table from '@/components/tables/VolumeByExchange';
import PieChart from '@/components/charts/Pie';
import debounce from '@/mixins/debounce';
import { ws } from '@/utils/socket';
import propsParameters from '@/mixins/propsParameters';
import commonFilters from '@/mixins/filters/commonFilters';

export default {
  name: 'ChartOrderBook',

  components: {
    PieChart,
    Table,
  },

  props: {
    isResizeble: Boolean,
  },

  data: () => ({
    isFiltersOpen: true,
    orderbookInitialData: [],
    parameters: {},
    wsParameters: null,
    activeExchangeId: 0,
    showPreloader: true,
    resetDisabled: true,
    reversed: true,
  }),

  mixins: [debounce, commonFilters, propsParameters],

  computed: {
    ...mapGetters({
      breakpoint: 'layouts/getBreakpoint',
      naming: 'filters/naming',
      exchangesInfo: 'filters/exchangesInfo',
    }),

    defaultParameters() {
      return {
        exchangeIds: this.defaultExchanges,
        assets: this.allAssets,
        tokenId: this.defaultSymbol,
        currencyPairs: this.defaultPairs,
        slippageIndex: this.defaultSlippage,
        dimension: this.defaultDimension,
      };
    },

    baseSlug() {
      return this.coinsInfo[this.parameters.tokenId || this.defaultSymbol].symbol.toUpperCase();
    },

    defaultSymbol() {
      return this.symbols.keys().next().value;
    },

    defaultPairs() {
      return Array.from(this.filteredCurrencyPairs.keys());
    },

    symbols() {
      const exchangeIds = this.parameters.exchanges || this.defaultExchanges;
      const assets = this.parameters.assets || this.allAssets;
      const symbols = exchangeIds.flatMap(exchangeId => (
          assets.flatMap(asset => (
              (this.orderbookMap.get(exchangeId).assets?.[asset] || []).flatMap(pair => pair[0] ?? [])
          ))
      ));
      const uniqueSymbols = [...new Set(symbols)];

      return new Map(uniqueSymbols.map(symbol => ['' + symbol, this.coinsInfo[symbol]?.symbol.toUpperCase() || symbol]));
    },

    slippages() {
      const pairs = this.parameters.currencyPairs ?? this.defaultPairs;
      const slippages = pairs.map(pair => {
        return this.allSlippages[pair]?.[0];
      });
      const uniqueSlippages = [];
      slippages.forEach(slippageArr => {
        slippageArr?.forEach((slippage, index) => {
          uniqueSlippages[index] = Math.min(slippage, uniqueSlippages[index] || slippage);
        });
      });

      return new Map(uniqueSlippages.flatMap((slippage, index) => index ? [[index, slippage + '']] : []));
    },

    dimensions() {
      return new Map([
        ['NOW', 'Now'],
        ['DAY_1', 'Day'],
        ['WEEK_1', 'Week'],
        ['MONTH_1', 'Month'],
        ['YEAR_1', 'Year'],
      ]);
    },

    filteredCurrencyPairs() {
      const pairs = [...this.pairs.entries()];
      const tokenId = this.parameters.tokenId ?? this.defaultSymbol;

      return new Map(pairs.filter(([pair]) => pair.split(',')[0] === tokenId));
    },

    filteredOrderbook() {
      const { exchangeIds, assets, currencyPairs } = this.parameters;

      return this.orderbookInitialData.filter(item => (
          currencyPairs.includes([item.baseId, item.quoteId].toString())
          && exchangeIds.includes(item.exchangeId)
          && assets.includes(item.asset)
      ));
    },

    structuredOrderbook() {
      const totalVolume = this.filteredOrderbook.reduce((total, item) => total + item.volume, 0);
      const grouped = this.filteredOrderbook.reduce((acc, curr) => {
        let key = curr.exchangeId;
        let asset = curr.asset;
        let pair = curr.quoteId;
        const base = this.coinsInfo[curr.baseId]?.symbol.toUpperCase() || curr.baseId;

        if (!acc[key]) {
          acc[key] = {
            base: base,
            exchange: this.exchangesInfo[key].displayName,
            exchangeId: key,
            exchangePercentage: 0,
            perpetualPercentage: {},
            perpetualVolume: {},
            spotPercentage: {},
            spotVolume: {}
          };
        }

        if (asset === 'futures_perpetual') {
          if (!acc[key].perpetualVolume[pair]) {
            acc[key].perpetualVolume[pair] = 0;
          }
          acc[key].perpetualVolume[pair] += curr.volume;
        } else if (asset === 'spot') {
          if (!acc[key].spotVolume[pair]) {
            acc[key].spotVolume[pair] = 0;
          }
          acc[key].spotVolume[pair] += curr.volume;
        }

        return acc;
      }, {});
      const groupedData = Object.values(grouped);

      groupedData.forEach((exchange) => {
        for (let pair in exchange.perpetualVolume) {
          exchange.perpetualPercentage[pair] = exchange.perpetualVolume[pair] / totalVolume;
          exchange.exchangePercentage += exchange.perpetualPercentage[pair];
        }
        for (let pair in exchange.spotVolume) {
          exchange.spotPercentage[pair] = exchange.spotVolume[pair] / totalVolume;
          exchange.exchangePercentage += exchange.spotPercentage[pair];
        }
      });

      return groupedData;
    },

    orderBook() {
      return this.structuredOrderbook.map(orderBook => {
        orderBook.totalPerpetualPercentage = this.hasProperty(orderBook.perpetualPercentage)
            ? orderBook.totalPerpetualPercentage = Object.values(orderBook.perpetualPercentage).reduce((t, n) => t + n)
            : null;

        orderBook.totalPerpetualVolume = this.hasProperty(orderBook.perpetualVolume)
            ? Object.values(orderBook.perpetualVolume).reduce((t, n) => t + n)
            : null;

        orderBook.totalSpotPercentage = this.hasProperty(orderBook.spotPercentage)
            ? Object.values(orderBook.spotPercentage).reduce((t, n) => t + n)
            : null;

        orderBook.totalSpotVolume = this.hasProperty(orderBook.spotVolume)
            ? Object.values(orderBook.spotVolume).reduce((t, n) => t + n)
            : null;

        return orderBook;
      });
    },

    orderbookChartData() {
      const orderbookChartData = [{
        id: '0.0',
        parent: '',
        name: ' ',
        color: 'transparent',
      }];

      this.orderBook.forEach((orderbookItem, i) => {
        const pies = this.generateChartPies(orderbookItem, i);

        orderbookChartData.push(...pies);
      });

      return orderbookChartData;
    },
  },

  methods: {
    ...mapActions({
      getOrderBookVolume: 'charts/getOrderBookVolume',
    }),

    setStretchPosition() {
      this.$refs.orderBookChartSection.openInFullScreen();
    },

    updateParameter(parameter, name, getData = true) {
      this.resetDisabled = false;
      this.parameters = {
        ...this.parameters,
        [name]: parameter
      };

      if (getData) {
        this.getOrderBookData();
      }
    },

    async resetParameters() {
      this.parameters = { ...this.defaultParameters };
      this.resetDisabled = true;

      await this.getOrderBookData();

      this.$refs.exchanges.reset();
      this.$refs.assets.reset();
      this.$refs.symbol.reset();
      this.$refs.pairs.reset();
      this.$refs.slippage.reset();
      this.$refs.dimension.reset();
    },

    hasProperty(property) {
      if (property) {
        return !!Object.keys(property) && !!Object.keys(property).length;
      }
    },

    generateChartPies(orderbookItem, i) {
      const pies = [];
      pies.push({
        id: `1.${i}`,
        parent: '0.0',
        name: 'Total',
        exchange: orderbookItem.exchangeId,
        color: this.exchangesInfo[orderbookItem.exchangeId].color,
      });

      pies.push({
        id: `2.${i}${i}`,
        parent: `1.${i}`,
        exchange: orderbookItem.exchangeId,
        name: 'Perps',
      });

      pies.push({
        id: `2.${i + 1}${i}`,
        parent: `1.${i}`,
        exchange: orderbookItem.exchangeId,
        name: 'Spot',
      });

      if (this.hasProperty(orderbookItem.perpetualPercentage)) {
        Object.entries(orderbookItem.perpetualPercentage).forEach((el, index) => {
          const volume = {
            id: `3.${i}${index}`,
            parent: `2.${i}${i}`,
            exchange: orderbookItem.exchangeId,
            name: el[0],
          };

          volume.value = el[1];
          pies.push(volume);
        });
      }

      if (this.hasProperty(orderbookItem.spotPercentage)) {
        Object.entries(orderbookItem.spotPercentage).forEach((el, index) => {
          const volume = {
            id: `3.${i + 1}${i + index + 1000}`,
            parent: `2.${i + 1}${i}`,
            exchange: orderbookItem.exchangeId,
            name: el[0],
          };

          volume.value = el[1];
          pies.push(volume);
        });
      }

      return pies;
    },

    getOrderBookData() {
      this.showPreloader = true;
      this.debounce(async () => {
        this.unsubscribeOrderBookData();

        const { tokenId, slippageIndex } = this.parameters;

        this.wsParameters = JSON.stringify({ tokenId, slippageIndex });

        try {
          this.orderbookInitialData = await this.getOrderBookVolume(this.parameters);

          this.parameters.dimension === this.defaultDimension
              ? this.subscribeOrderBookData()
              : this.wsParameters = null;
        } catch (e) {
          console.log(e);
        } finally {
          this.showPreloader = false;
        }
      }, 500);
    },

    subscribeOrderBookData() {
      ws.subscribe(
          'ORDER_BOOK_VOL',
          {
            method: 'SUBSCRIBE',
            params: this.wsParameters
          },
          (orderbook) => {
            if (this.wsParameters === orderbook.params) {
              this.orderbookInitialData = orderbook.response;
            }
          }
      );
    },

    unsubscribeOrderBookData() {
      ws.unsubscribe(
          'ORDER_BOOK_VOL',
          {
            method: 'UNSUBSCRIBE',
            params: this.wsParameters
          },
      );

      this.wsParameters = null;
    },

    removeItem() {
      this.$emit('removeItem', null);
    },
  },

  created() {
    this.parameters = { ...this.defaultParameters };
    this.getOrderBookData();
  },

  beforeDestroy() {
    this.unsubscribeOrderBookData();
  }
};
</script>

<style scoped>
.chart-filters {
  min-width: 1000px;
  max-width: calc(100vw - 50px);
}

.order-book-chart-wrapper {
  min-width: max-content;
}

.order-book-info {
  display: flex;
  min-width: max-content;
  width: 100%;
  height: 100%;
}

.order-book-chart {
  position: relative;
  flex-grow: 1;
  height: calc(100% - 109px);
}

.widget-preloader {
  z-index: 3;
}

</style>
