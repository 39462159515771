import SectionHeader from '@/components/ui/section/SectionHeader.vue';
import Select from '@/components/ui/inputs/Select.vue';
import MultiSelect from '@/components/ui/inputs/MultiSelect.vue';
import Update from '@/components/ui/popups/Update.vue';
import NoData from '@/components/ui/no-data/NoData.vue';
import Loader from '@/components/ui/loaders/Loader.vue';
import Legends from '@/components/legends/Legends';
import CustomPagination from '@/components/ui/custom-pagination/CustomPagination.vue';
import LazyImg from '@/components/ui/images/LazyImg';

export default [
    SectionHeader,
    Select,
    MultiSelect,
    Update,
    NoData,
    Loader,
    Legends,
    CustomPagination,
    LazyImg,
];