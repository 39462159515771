import { crosshair } from '@/mixins/chart-settings/_commonSettings';
import { mapGetters } from 'vuex';

export default {
  data: () => ({
    chartOptions: {
      chart: {
        backgroundColor: 'var(--mainBGColor)',
        type: 'scatter',
        height: '450px',
        zoomType: 'xy',
        animation: false,

        resetZoomButton: {
          theme: {
            fill: 'var(--accent)',
            stroke: 'var(--accent)',

            style: {
              fontFamily: 'Inter, sans-serif',
              fontWeight: 700,
              color: '#fff',
            },

            states: {
              hover: {
                fill: '#00fff7',
                stroke: '#00fff7',
              }
            }
          },
        },
      },

      xAxis: [
        {
          type: 'linear',
          gridLineWidth: 1,
          gridLineColor: 'var(--gridLineColor)',

          crosshair: {
            ...crosshair,
            snap: false,

            label: {
              ...crosshair.label,

              formatter(event) {
                return event.toFixed(2);
              }
            }
          },

          labels: {
            style: {
              whiteSpace: 'nowrap',
              fontFamily: 'Inter, sans-serif',
              color: '#fff',
            },
          }
        }
      ],

      yAxis: [
        {
          opposite: false,
          gridLineWidth: 1,
          gridLineColor: 'var(--gridLineColor)',
          crosshair: {
            ...crosshair,
            snap: false,

            label: {
              ...crosshair.label,

              formatter(event) {
                return event.toFixed(2);
              }
            }
          },

          labels: {
            style: {
              whiteSpace: 'nowrap',
              fontFamily: 'Inter, sans-serif',
              color: '#fff',
            },
          }
        }
      ],

      tooltip: {
        enabled: false,
      },

      legend: {
        enabled: false,
      },

      states: {
        hover: {
          marker: {
            enabled: false
          }
        }
      },

      rangeSelector: {
        enabled: false,
      },

      scrollbar: {
        enabled: false,
      },

      navigator: {
        enabled: false,
      },

      exporting: {
        enabled: false,
      },

      series: []
    },
  }),

  watch: {
    resized() {
      this.resize();
    },

    isResizeble() {
      this.resize();
    },
  },

  computed: {
    ...mapGetters({
      resized: 'layouts/resized',
    })
  },

  methods: {
    resize() {
      setTimeout(() => {
        this.chartOptions.chart.width = '100%';
        this.chartOptions.chart.width = null;
        this.$refs.chart.chart.reflow();
      }, 500)
    },
  },
};