<template>
  <div class="terminal-container">
    <AppHeader />
    <grid-layout
        ref="gridlayout"
        :col-num="12"
        :is-bounded="false"
        :is-draggable="true"
        :is-resizable="true"
        :layout.sync="layout"
        :margin="[10, 10]"
        :row-height="20"
        :use-css-transforms="false"
        :vertical-compact="true"
        @layout-updated="$emit('layoutUpdated', $event)"
    >
      <grid-item
          v-for="item in layout"
          :key="item.i"
          :class="{fixed: item.stretched}"
          :h="item.h"
          :i="item.i"
          :min-h="item.minH"
          :min-w="item.minW"
          :minH="item.minH || 14"
          :minW="item.minW || 2"
          :static="item.static"
          :w="item.w"
          :x="item.x"
          :y="item.y"
          class="custom-scroll"
          drag-allow-from=".vue-draggable-handle"
          @move="$emit('startDrag')"
          @resized="resizedEvent(item)"
      >
        <component
            :is="item.componentName"
            :is-deletable="isDeletable"
            :isResizeble="item.update"
            :props-parameters="item.filters"
            :show-copy-to-pd="showCopyToPd"
            v-bind="{initialParameters: item.initialParameters}"
            @copyChartToPD="copyChartToPD($event, item)"
            @removeItem="removeItem(item.i)"
            @setStatic="setStatic($event, item.i)"
            @updateFilter="$emit('updateFilter', { item, event: $event })"
        />
      </grid-item>
    </grid-layout>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import { GridItem, GridLayout } from 'vue-grid-layout';
import { mapActions, mapGetters } from 'vuex';
import { getFromLocalStorage, saveToLocalStorage } from '@/helpers';

const chartsContext = require.context('@/components/chart-sections', true, /^.*\.vue$/);
const exchangeContext = require.context('@/components/exchange', false, /^.*\.vue$/);
const tokenContext = require.context('@/components/token', false, /^.*\.vue$/);

const dynamicWidgets = (components, path) => {
  return Object.fromEntries(
      components.keys().map(componentName => {
        const componentNameWithoutPath = componentName.replace(/^\.\//g, '');

        return [
          componentNameWithoutPath.replace(/\.vue$/g, ''),
          defineAsyncComponent(() => import(`@/components/${path}${componentNameWithoutPath}`)),
        ];
      })
  );
};

const chartsComponents = dynamicWidgets(chartsContext, 'chart-sections/');
const exchangeComponents = dynamicWidgets(exchangeContext, 'exchange/');
const tokenComponents = dynamicWidgets(tokenContext, 'token/');

export default {
  name: 'WidgetLayout',

  components: {
    ...chartsComponents,
    ...exchangeComponents,
    ...tokenComponents,
    GridLayout,
    GridItem,
    AppHeader: defineAsyncComponent(() =>
        import('@/components/app-header/AppHeader.vue')
    ),
  },

  props: {
    layoutProp: {
      type: [Array, Object],
      required: false,
      default: () => [],
    },

    showCopyToPd: {
      type: Boolean,
      required: false,
      default: true,
    },

    isDeletable: {
      type: Boolean,
      required: false,
      default: false,
    }
  },

  watch: {
    layoutProp(layout) {
      this.layout = Array.isArray(layout) ? layout : layout[this.breakpoint];
    },

    // breakpoint() {
    //   this.setLayout();
    // },
  },

  data: () => ({
    layout: [],
  }),

  computed: {
    ...mapGetters({
      breakpoint: 'layouts/getBreakpoint',
    })
  },

  methods: {
    ...mapActions({
      setLayout: 'layouts/setLayout',
    }),

    setStatic(isStatic, index) {
      this.layout.find(item => item.i === index).static = isStatic;
    },

    removeItem(val) {
      const index = this.layout.map(item => item.i).indexOf(val);
      this.layout.splice(index, 1);

      const newLayout = this.layout;

      if (newLayout.length === 0) {
        this.setLayout({ layout: newLayout });
      }
    },

    resizedEvent(item) {
      const searchIndex = this.layout.findIndex((el) => el.i === item.i);
      this.layout.splice(searchIndex, 1, {
        ...this.layout[searchIndex],
        update: !this.layout[searchIndex].update
      });

      // this.$refs.gridlayout.$children[this.layout.length].$refs.item.__vue__.$children[0].resize();
    },

    copyChartToPD(filters, item) {
      const layout = getFromLocalStorage('customLayout') || {};
      const currentLayout = layout[this.breakpoint] || [];
      const lastItem = currentLayout.at(-1) || { i: -1, y: 0, h: 0, w: 0, x: 0 };
      const x = lastItem.x + lastItem.w;

      saveToLocalStorage('customLayout', {
        ...layout,
        [this.breakpoint]: [
          ...currentLayout,
          {
            ...item,
            i: lastItem.i + 1,
            y: lastItem.y + lastItem.h,
            x: x >= 2 || (x > 1 && item.w === 2) ? 0 : x,
            w: item.w,
            filters
          }
        ]
      });
    },
  },

  created() {
    this.layout = Array.isArray(this.layoutProp) ? this.layoutProp : this.layoutProp[this.breakpoint];
  }
};
</script>

<style lang="scss" scoped>
.terminal-container {
  position: relative;
  min-height: 100vh;
}

.vue-grid-layout {
  min-height: calc(100vh - 70.5px);
  /*background: #eee;*/
}

//.droppable-element {
//  width: 150px;
//  text-align: center;
//  background: #fff;
//  border: 1px solid black;
//  margin: 10px 0;
//  padding: 10px;
//}

.vue-grid-item {
  &:not(.vue-grid-placeholder) {
    /*background: #ccc;*/
    border: 1px solid black;
  }

  //.resizing {
  //  opacity: 0.9;
  //}

  //.static {
  //  background: #cce;
  //}

  .text {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    font-size: 24px;
    text-align: center;
  }

  .no-drag {
    width: 100%;
    height: 100%;
  }

  //.minMax {
  //  font-size: 12px;
  //}

  .add {
    cursor: pointer;
  }
}

.vue-draggable-handle {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  padding: 0 8px 8px 0;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>") no-repeat bottom right;
  background-origin: content-box;
  cursor: pointer;
}

//.scroll-btn {
//  position: absolute;
//  z-index: 4;
//  top: 200px;
//  left: 200px;
//  background: red;
//}

</style>