<template>
  <div ref="ratioWrapper" class="ratio-bid-asks chart-wrapper">
    <SectionHeader
        :is-deletable="isDeletable"
        :is-filters-open="isFiltersOpen"
        :show-copy-chart-to-pd="showCopyToPd"
        info-text="A widget that, based on orderbook data, shows the average price of popular tokens with additional information about the bid/ask ratio at the time of that price (displayed below), and the color of the line corresponds to the ratio."
        title="Ratio Bid to Asks"
        @copyChartToPD="$emit('copyChartToPD', {})"
        @removeItem="removeItem"
        @setStatic="$emit('setStatic', $event)"
        @setStretchPosition="setStretchPosition"
        @toggleFiltersVisibility="isFiltersOpen = $event"
    />

    <transition name="expand-filters">
      <div v-show="isFiltersOpen" ref="sectionFilters" class="chart-filters">
        <div class="chart-filter">
          <MultiSelect
              ref="exchanges"
              :default-option-value="parametersForSelects.exchanges || defaultExchanges"
              :options="exchanges"
              title="Exchanges"
              @selected="updateParameter($event, 'exchangeIds')"
          />
        </div>
        <div class="chart-filter">
          <MultiSelect
              ref="assets"
              :default-option-value="parametersForSelects.assets ||defaultAssets"
              :options="assets"
              title="assets"
              @emptySelect="parameters.assets = defaultAssets"
              @selected="updateParameter($event, 'assets')"
          />
        </div>
        <div class="chart-filter">
          <Select
              ref="symbol"
              :default-option-value="parametersForSelects.symbol || defaultSymbol"
              :options="symbols"
              title="token"
              @emptySelect="parameters.symbol = defaultSymbol"
              @selected="updateParameter($event, 'tokenId')"
          />
        </div>
        <div class="chart-filter">
          <Select
              ref="dimension"
              :default-option-value="parametersForSelects.dimension || defaultDimension"
              :options="dimensions"
              title="Time Period"
              @selected="updateParameter($event, 'dimension')"
          />
        </div>
        <div class="chart-filter">
          <Select
              ref="slippage"
              :default-option-value="parametersForSelects.slippageIndex || defaultSlippage"
              :options="slippages"
              suffix="%"
              title="slippage"
              @emptySelect="parameters.slippageIndex = defaultSlippage"
              @selected="updateParameter($event, 'slippageIndex')"
          />
        </div>
        <div class="chart-filter reset">
          <button :disabled="resetDisabled || showPreloader" class="reset" @click="resetParameters">
            Reset
          </button>
        </div>
      </div>
    </transition>

    <div class="chart-content">
      <div v-show="showPreloader" class="main-preloader widget-preloader chart-preloader">
        <Loader
            ref="preloader"
            :animationData="require('@/assets/images/Loader.json')"
            :autoPlay="true"
            :loop="true"
            :speed="1"
        />
      </div>

      <highcharts
          ref="chart"
          :options="chartOptions"
          :style="{ width: '100%', height: chartHeight }"
          constructor-type="stockChart"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import debounce from '@/mixins/debounce';
import ratioSettings from '@/mixins/chart-settings/ratio';
import Highcharts from 'highcharts';
import HighchartsNoData from 'highcharts/modules/no-data-to-display';
import { ws } from '@/utils/socket';
import propsParameters from '@/mixins/propsParameters';
import commonFilters from '@/mixins/filters/commonFilters';

HighchartsNoData(Highcharts);

export default {
  name: 'Ratio',

  components: {},

  props: {

    isResizeble: Boolean,
  },

  mixins: [debounce, ratioSettings, commonFilters, propsParameters],

  data: () => ({
    maxElFromArrayBids: null,
    maxElFromArrayAsks: null,
    labelColor: '#fff',
    parameters: {},
    wsParameters: null,
    showPreloader: true,
    resetDisabled: true,
    isFiltersOpen: true,
  }),

  computed: {
    ...mapGetters({
      naming: 'filters/naming',
    }),

    defaultParameters() {
      return {
        exchangeIds: this.defaultExchanges,
        assets: this.defaultAssets,
        tokenId: this.defaultSymbol,
        dimension: this.defaultDimension,
        slippageIndex: this.defaultSlippage,
      };
    },

    defaultSymbol() {
      return this.symbols.keys().next().value;
    },

    symbols() {
      const exchanges = this.parameters.exchanges || this.defaultExchanges;
      const assets = this.parameters.assets || this.defaultAssets;
      const allSymbols = exchanges.flatMap(exchange => (
          assets.flatMap(asset => (
              (this.orderbookMap.get(exchange).assets?.[asset] || []).map(pair => pair[0])
          ))
      ));
      const uniqueSymbols = [...new Set(allSymbols)];

      return new Map(uniqueSymbols.map(symbol => [symbol, this.coinsInfo[symbol]?.symbol.toUpperCase() || symbol]));
    },

    slippages() {
      const tokenId = this.parameters.tokenId ?? this.defaultSymbol;
      const slippages = Object.entries(this.allSlippages).filter(([pair]) => +pair.split(',')[0] === tokenId)
      const uniqueSlippages = [];
      slippages.forEach(slippageArr => {
        slippageArr[1][0].forEach((slippage, index) => {
          uniqueSlippages[index] = Math.min(slippage, uniqueSlippages[index] || slippage);
        })
      })

      return new Map(uniqueSlippages.flatMap((slippage, index) => index ? [[index, slippage + '']] : []));
    },

    dimensions() {
      return new Map([
        ['HOUR_1', 'Hour'],
        ['DAY_1', 'Day'],
        ['WEEK_1', 'Week'],
        ['MONTH_1', 'Month'],
        ['YEAR_1', 'Year'],
      ]);
    },
  },

  methods: {
    ...mapActions({
      getRatioData: 'charts/getRatio',
    }),

    updateParameter(parameter, name) {
      this.resetDisabled = false;
      this.parameters = {
        ...this.parameters,
        [name]: parameter
      };

      this.getRatio();
    },

    async resetParameters() {
      this.parameters = { ...this.defaultParameters };
      this.resetDisabled = true;
      await this.getRatio();

      this.$refs.exchanges.reset();
      this.$refs.assets.reset();
      this.$refs.symbol.reset();
      this.$refs.dimension.reset();
      this.$refs.slippage.reset();
    },

    setStretchPosition() {
      this.$refs.chart.chart.fullscreen.toggle();
    },

    getPercentDifference(num) {
      const value = Math.abs(num);
      return ((value / 100) * 100).toFixed(1);
    },

    generateColorToPercentDiffAsks(percent) {
      const percentToColor = {
        0: '#9b413b',
        20: '#bd4b44',
        40: '#da584f',
        60: '#c8362d',
        80: '#da251a',
      };
      const percentageFound = Object.keys(percentToColor).find(key => percent <= parseInt(key, 10));

      return percentToColor[percentageFound ?? 80];
    },

    generateColorToPercentDiffBids(percent) {
      const percentToColor = {
        0: '#00746e',
        20: '#318d89',
        40: '#45b2ad',
        60: '#0fc7bf',
        80: '#29c8c0',
      };
      const percentageFound = Object.keys(percentToColor).find(key => percent <= parseInt(key, 10));

      return percentToColor[percentageFound ?? 80];
    },

    updateZones() {
      // setTimeout(() => {
      // let points = this.$refs.chart.chart.series[1].points
      let points = this.chartOptions.series[1].data,
          splineData = this.chartOptions.series[0].data,
          zones = [],
          color,
          borderColor;

      this.maxElFromArrayBids = Math.max(...points.filter(e => e.option === 1).map(o => o.y));
      this.maxElFromArrayAsks = Math.min(...points.filter(e => e.option === 2).map(o => o.y));

      for (let i = 0; i < points.length; i++) {
        const percentAsk = Math.abs((points[i].y / this.maxElFromArrayAsks) * 100);
        const percentBid = Math.abs((points[i].y / this.maxElFromArrayBids) * 100);
        const colorAsk = this.generateColorToPercentDiffAsks(percentAsk);
        const colorBid = this.generateColorToPercentDiffBids(percentBid);

        switch (points[i].option) {
          case 1:
            color = colorBid;
            borderColor = '#187b76';
            break;
          case 2:
            color = colorAsk;
            borderColor = '#f27870';
            break;
        }
        zones.push({
          value: points[i].x + 0.001,
          borderColor: borderColor,
          borderWidth: 2,
          color: color
        });
      }

      this.chartOptions.yAxis[0].plotLines[0].value = splineData.at(-1)?.y;

      if (this.$refs.chart) {
        this.$refs.chart.chart.series[0].update({ zones });
        this.$refs.chart.chart.series[1].update({ zones });
      }
      // }, 500);
    },

    getRatio() {
      this.showPreloader = true;
      this.debounce(async () => {
        this.$refs.chart?.chart.showLoading('Loading data');
        this.unsubscribeRatioData();

        // eslint-disable-next-line no-unused-vars
        const { dimension, ...parameters } = this.parameters;
        this.wsParameters = JSON.stringify(parameters);

        try {
          const ratioData = await this.getRatioData(this.parameters);
          const splineDataArray = [];
          const columnDataArray = [];

          ratioData.forEach(ratio => {
            splineDataArray.push({
              x: ratio.time,
              y: ratio.price,
              option: ratio.ratio > 0 ? 1 : 2,
              color: ratio.ratio > 0 ? 'var(--accent)' : '#f27870'
            });

            columnDataArray.push({
              x: ratio.time,
              y: ratio.ratio,
              option: ratio.ratio > 0 ? 1 : 2
            });
          });

          this.chartOptions.series[0].data = splineDataArray;
          this.chartOptions.series[1].data = columnDataArray;
          this.updateZones();
          this.$refs.chart?.chart.hideLoading();

          this.parameters.dimension === this.defaultDimension
              ? this.subscribeRatioData()
              : this.wsParameters = null;
        } catch (e) {
          console.log(e);
        } finally {
          this.showPreloader = false;
        }
      }, 500);
    },

    subscribeRatioData() {
      ws.subscribe(
          'RATIO',
          {
            method: 'SUBSCRIBE',
            params: this.wsParameters
          },
          (res) => {
            if (res.params !== this.wsParameters) {
              return;
            }

            const ratio = res.response;

            this.chartOptions.series[0].data.shift();
            this.chartOptions.series[1].data.shift();
            this.chartOptions.series[0].data.push({
              x: res.eventTime,
              y: ratio.middle,
              option: ratio.bdratio > 0 ? 1 : 2,
              color: ratio.bdratio > 0 ? 'var(--accent)' : '#f27870'
            });
            this.chartOptions.series[1].data.push({
              x: res.eventTime,
              y: ratio.bdratio,
              option: ratio.bdratio > 0 ? 1 : 2,
            });

            this.updateZones();
          }
      );
    },

    unsubscribeRatioData() {
      ws.unsubscribe(
          'RATIO',
          {
            method: 'UNSUBSCRIBE',
            params: this.wsParameters
          },
      );

      this.wsParameters = null;
    },

    removeItem() {
      this.$emit('removeItem', null);
    },
  },

  created() {
    this.getRatio();
  },

  beforeDestroy() {
    this.unsubscribeRatioData();
  }
};
</script>

<style scoped>
</style>
