<template>
  <WidgetLayout :layout-prop="layout" />
</template>

<script>
import WidgetLayout from '@/layouts/WidgetLayout';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TokenPage',

  components: { WidgetLayout },

  computed: {
    ...mapGetters({
      layout: 'layouts/tokenLayout',
      tokenSlugs: 'filters/coinSlugs'
    }),

    token() {
      return this.$route.params.token;
    },
  },

  methods: {
    ...mapActions({
      getCoinInfo: 'filters/getCoinInfo',
    }),

    async getTokenData() {
      try {
        await this.getCoinInfo({
          ids: this.tokenSlugs[this.token],
          isStaticData: true,
          isZeroMarketCapIncluded: true,
        });
      } catch (e) {
        console.log(e);
      } finally {
        this.showPreloader = false;
      }
    }
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      const checkRoute = () => {
        vm.tokenSlugs[vm.token] ? next() : next('/404');
      };

      Object.keys(vm.tokenSlugs).length ? checkRoute() : vm.$bus.$once('inventory-loaded', checkRoute);
    });
  },


  mounted() {
    this.getTokenData();
  },
};
</script>

<style scoped>

</style>