<template>
  <v-app>
    <router-view v-if="filtersLoad" />
    <div v-else class="main-preloader widget-preloader chart-preloader">
      <Loader
          ref="preloader"
          :animationData="require('@/assets/images/Loader.json')"
          :autoPlay="true"
          :loop="true"
          :speed="1"
      />
    </div>
    <UpdatePopup />
  </v-app>
</template>

<script>
import UpdatePopup from '@/components/ui/popups/Update';
import { ws } from '@/utils/socket';
import debounce from '@/mixins/debounce';
import 'vuetify/dist/vuetify.min.css';
import { mapActions, mapMutations } from 'vuex';
import { SciChartSurface } from 'scichart/Charting/Visuals/SciChartSurface';
import ToasterSuccess from "@/components/toasters/ToasterSuccess.vue";
import moment from "moment/moment";

export default {
  name: 'App',

  components: {
    UpdatePopup
  },

  mixins: [debounce],

  data: () => ({
    filtersLoad: false,
  }),

  methods: {
    ...mapActions({
      getFilters: 'filters/getFilters',
      getExchangeSlugs: 'filters/getExchangeSlugs',
      getCoinSlugs: 'filters/getCoinSlugs',
      getExchangesInfo: 'filters/getExchangesInfo',
      getCoinsInfo: 'filters/getCoinsInfo',
    }),

    ...mapMutations({
      SET_LAYOUT: 'layouts/SET_LAYOUT',
    }),

    resizeEventHandler() {
      this.debounce(this.SET_LAYOUT);
    }
  },

  async created() {
    await ws.initWebSocket();
    ws.subscribe(
        'ANNOUNCEMENTS',
        {
          method: 'SUBSCRIBE',
          params: {}
        },
        (response) => this.debounce(() => {
          const res = JSON.parse(response.response);
          let modifyTitle = null;
          let type = null;
          let time = null;
          let description = null;
          let pair = null;

          if (res.category === 'cryptocurrency_planned_listing' || res.category === 'cryptocurrency_planned_delisting') {
            const title = res.exchangeName;
            modifyTitle = title.toLowerCase()[0].toUpperCase() + title.toLowerCase().slice(1);
            type = res.category === 'cryptocurrency_planned_listing' ? 'listing' : 'delisting';
            time = res.publishedAt;
            description = res.description;
            pair = res.tradePairs.join(', ');
          } else {
            const title = res.exchange;
            time = new Date();
            description = res.message;
            modifyTitle = title.toLowerCase()[0].toUpperCase() + title.toLowerCase().slice(1);
            type = res.category === 'cryptocurrency_fact_listing' ? 'listing' : 'delisting';
            pair = res.currency;
          }

          this.$toast.success({
            component: ToasterSuccess,
            props: {
              type: type,
              title: `${modifyTitle} ${pair}`,
              description: description,
              time: moment(time).format('YYYY/MM/DD HH:mm')
            },
          });
        }, 100)
    );
  },

  mounted() {
    this.SET_LAYOUT();
    window.addEventListener('resize', this.resizeEventHandler);

    Promise.all([
      this.getFilters(),
      this.getCoinSlugs(),
      this.getCoinsInfo(),
      this.getExchangesInfo(),
      this.getExchangeSlugs()
    ]).then(() => {
      this.filtersLoad = true;
      this.$bus.$emit('inventory-loaded');
    }).catch(() => {
      this.filtersLoad = true;
      // this.$router.push({ path: '/maintenance' }).catch(() => {});
    });

    SciChartSurface.setRuntimeLicenseKey('bDYGsnPSj6ENhq33PIHYg1j4h1HC5QYKXr2Ok2oP42VX41XV2NwRDw5WFk34QRr5oqpcYAPuxk4TfK6KrYxF+9gHvfKRcIO21+NfqqcsIo/EkPDxgZuddHBH2hTbusZPO6gYMxNdEg7m+ihCCk2G0ABDlaqt+5oZ3hjhN4aRJaswDT4mhYpmaEqVpTfSx9BuDMqk01yNSyCgfPW+rvI8zo5MwCOv8GQ9B9BUXC5HDjOSm6k+afUk1ZNeOTlb2tEdcpZ225nGWCa8aqg9Wm8grHkUTnhGkyFo61pdfZ1NghhtbtO98daCB38b+1WToLcG1rs8KmNkMFIoKta9sX7Ds3nMCC3qd35PR98J59Z/WLmaLfSHezy1X8x3HLKkb8pXjxmVr6RWWN4T1CbUjJcKYcnjT1jLY9MFp6gF41l5v0/2IDUuU6kaLSeKZyALtTvdt2RER5iwxu9vXY8ovxmKRIHi5ZSuTspab2TGl0pRlgSsgXrusQbOc6IyD48f99TdcHQSDH39iVKXI6x42EHwmf4ntSycqy2Aey1d2dt+HScF6PmGqFVcalve8G+TOjdaZTQAu+ebrqq1GYvKRu1z+OL11QxLcNhZMeTamezdxL5KKwBzEAO7uLADv4LeR2qxzw==');
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.resizeEventHandler);
    ws.unsubscribe(
        'markPrice',
        {
          method: 'UNSUBSCRIBE',
          params: ['markPrice']
        },
    );
    ws.close();
  },
};
</script>

<style>

</style>
