export default {
  getCustomLayout: ({ layout }) => layout,
  getLiquidityLayout: ({ liquidityLayout }) => liquidityLayout,
  coinsLayout: ({ coinsLayout }) => coinsLayout,
  arbitrageLayout: ({ arbitrageLayout }) => arbitrageLayout,
  DEXsLayout: ({ DEXsLayout }) => DEXsLayout,
  announcementsLayout: ({ announcementsLayout }) => announcementsLayout,
  wipLayout: ({ wipLayout }) => wipLayout,
  exchangeLayout: ({ exchangeLayout }) => exchangeLayout,
  exchangeListLayout: ({ exchangeListLayout }) => exchangeListLayout,
  tokensListLayout: ({ tokensListLayout }) => tokensListLayout,
  tokenLayout: ({ tokenLayout }) => tokenLayout,
  getBreakpoint: ({ breakpoint }) => breakpoint,
  resized: ({ resized }) => resized,
};
