<template>
  <div class="container success-toast">
    <div class="toaster-icon toaster-icon-error">
      <svg v-if="type === 'delisting'" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2 12.5C2 18.0228 6.47715 22.5 12 22.5C12.9503 22.5 13.8696 22.3674 14.7405 22.1198C14.2709 21.358 14 20.4606 14 19.5C14 16.7386 16.2386 14.5 19 14.5C19.9606 14.5 20.858 14.7709 21.6198 15.2405C21.8674 14.3696 22 13.4503 22 12.5C22 6.97715 17.5228 2.5 12 2.5C6.47715 2.5 2 6.97715 2 12.5ZM7 11.5H11H13H17V13.5H13H11H7V11.5Z" fill="#F27870"/>
        <path d="M19 23.5C16.7909 23.5 15 21.7092 15 19.5C15 17.2909 16.7909 15.5 19 15.5C21.2091 15.5 23 17.2909 23 19.5C23 21.7092 21.2091 23.5 19 23.5ZM19 17.8029L17.3029 19.5L19 21.1971L20.697 19.5L19 17.8029Z" fill="white"/>
      </svg>
      <svg v-else width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2 12.5C2 18.0228 6.47715 22.5 12 22.5C12.9503 22.5 13.8696 22.3674 14.7405 22.1198C14.2709 21.358 14 20.4606 14 19.5C14 16.7386 16.2386 14.5 19 14.5C19.9606 14.5 20.858 14.7709 21.6198 15.2405C21.8674 14.3696 22 13.4503 22 12.5C22 6.97715 17.5228 2.5 12 2.5C6.47715 2.5 2 6.97715 2 12.5ZM7 11.5H11V7.5H13V11.5H17V13.5H13V17.5H11V13.5H7V11.5Z" fill="#02C9BF"/>
        <path d="M19 23.5C16.7909 23.5 15 21.7092 15 19.5C15 17.2909 16.7909 15.5 19 15.5C21.2091 15.5 23 17.2909 23 19.5C23 21.7092 21.2091 23.5 19 23.5ZM19 17.8029L17.3029 19.5L19 21.1971L20.697 19.5L19 17.8029Z" fill="white"/>
      </svg>
    </div>
    <div class="toast-content">
      <span class="toast-title">{{title}}</span>
      <p class="toast-description">{{description}}</p>
      <span class="toast-time">{{time}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ToasterSuccess",
  props: {
    type: String,
    title: String,
    description: String,
    time: String,
  },
}
</script>

<style scoped>

</style>