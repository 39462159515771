<template>
  <div class="chart-header max-screen-width">
    <div class="chart-header__wrapper">
      <div :class="draggable" class="graph-control vue-draggable-handle">
        <img alt="drag indicator" src="@/assets/images/drag-indicator.svg">
      </div>

      <h3 class="chart-title">
        <span v-if="connectionType" :class="`connection-type connection-type__${connectionType}`" />

        {{ title }}
      </h3>
      <span v-if="type" class="chart-type"> {{ `(${type})` }}</span>
      <v-tooltip v-if="infoText && title" max-width="400" top>
        <template v-slot:activator="{ on, attrs }">
          <svg
              class="info-icon"
              fill="none"
              height="16"
              v-bind="attrs"
              viewBox="0 0 16 16"
              width="16"
              xmlns="http://www.w3.org/2000/svg" v-on="on"
          >
            <path
                clip-rule="evenodd"
                d="M7.20898 5.19649C7.20898 4.82968 7.55646 4.51114 8.0005 4.51114C8.43486 4.51114 8.78241 4.82968 8.78241 5.19649C8.78241 5.56333 8.43488 5.89152 8.0005 5.89152C7.55649 5.89152 7.20898 5.56333 7.20898 5.19649ZM7.25716 7.23327C7.25716 6.963 7.56605 6.77955 8.00044 6.77955C8.41552 6.77955 8.75334 6.96297 8.75334 7.23322V10.911C8.75334 11.1427 8.41552 11.3743 8.00044 11.3743C7.56605 11.3743 7.25716 11.1427 7.25716 10.911V7.23327Z"
                fill="#92939C"
                fill-rule="evenodd"
            />
            <circle cx="8" cy="8" r="7" stroke="#828392" stroke-width="1.5" />
          </svg>
        </template>
        <span>{{ infoText }}</span>
      </v-tooltip>
    </div>

    <div class="graph-controls">

      <TableSearch
          ref="tableSearch"
          :is-show-search="searchable"
          @search="search($event)"
      />

      <button v-if="toggleFiltersVisible" class="graph-control lock" @click="toggleFiltersVisibility">
        <img v-if="isFiltersOpen" alt="lock graph" src="@/assets/images/filters-open.svg">
        <img v-else alt="lock graph" src="@/assets/images/filters-closed.svg">
      </button>

      <!--      <div :class="draggable" class="graph-control vue-draggable-handle">-->
      <!--        <img alt="drag indicator" src="@/assets/images/drag-indicator.svg">-->
      <!--      </div>-->

      <button class="graph-control menu" @click="toggleSubMenu">
        <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M12 18.9825L12 19M12 11.9904L12 12.0079M12 5L12 5.01398"
              stroke="#92939C"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="4"
          />
        </svg>

        <ul v-show="subMenuOpen" v-click-outside="closeSubMenu" class="sub-menu" @mouseleave="closeSubMenu" @click.stop>
          <li class="sub-menu__item">
            <button @click="lockChart">
              <template v-if="isStatic">
                <img alt="lock graph" src="@/assets/images/lock-closed.svg">

                Unlock this Widget
              </template>

              <template v-else>
                <img alt="lock graph" src="@/assets/images/lock-open.svg">

                Lock this Widget
              </template>
            </button>
          </li>

          <li v-if="isDeletable" class="sub-menu__item">
            <button @click="removeItem">
              <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M21 5.98C17.67 5.65 14.32 5.48 10.98 5.48C9 5.48 7.02 5.58 5.04 5.78L3 5.98M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97M18.85 9.14L18.2 19.21C18.09 20.78 18 22 15.21 22H8.79C6 22 5.91 20.78 5.8 19.21L5.15 9.14M10.33 16.5H13.66M9.5 12.5H14.5"
                    stroke="#92939C"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                />
              </svg>

              Remove from the Dashboard
            </button>
          </li>

          <li v-if="showCopyChartToPd" class="sub-menu__item">
            <button @click="copyChartToPD">
              <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M22 8.27V4.23C22 2.64 21.36 2 19.77 2H15.73C14.14 2 13.5 2.64 13.5 4.23V8.27C13.5 9.86 14.14 10.5 15.73 10.5H19.77C21.36 10.5 22 9.86 22 8.27ZM10.5 8.52V3.98C10.5 2.57 9.86 2 8.27 2H4.23C2.64 2 2 2.57 2 3.98V8.51C2 9.93 2.64 10.49 4.23 10.49H8.27C9.86 10.5 10.5 9.93 10.5 8.52ZM10.5 19.77V15.73C10.5 14.14 9.86 13.5 8.27 13.5H4.23C2.64 13.5 2 14.14 2 15.73V19.77C2 21.36 2.64 22 4.23 22H8.27C9.86 22 10.5 21.36 10.5 19.77Z"
                    stroke="#92939C"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                />
                <path d="M14.5 17.5H20.5M17.5 20.5V14.5" stroke="#92939C" stroke-linecap="round" stroke-width="2" />
              </svg>

              Add to my Dashboard
            </button>
          </li>

          <li v-if="stretchable" class="sub-menu__item">
            <button @click="setStretchPosition">
              <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M2 9.98V9C2 4 4 2 9 2H15C20 2 22 4 22 9V15C22 20 20 22 15 22H14"
                    stroke="#92939c"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                />
                <path
                    d="M13 11L18.01 5.97998M18.01 5.97998H14M18.01 5.97998V9.98998M11 16.15V18.85C11 21.1 10.1 22 7.85 22H5.15C2.9 22 2 21.1 2 18.85V16.15C2 13.9 2.9 13 5.15 13H7.85C10.1 13 11 13.9 11 16.15Z"
                    stroke="#92939c"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                />
              </svg>

              Fullscreen view
            </button>
          </li>
        </ul>
      </button>
    </div>
  </div>
</template>

<script>

import TableSearch from "@/components/ui/inputs/TableSearch.vue";

export default {
  name: 'SectionHeader',

  components: {
    TableSearch,
  },

  props: {
    title: {
      type: String,
      required: true,
    },

    type: {
      type: String,
      required: false,
    },

    isFiltersOpen: {
      type: Boolean,
      required: false,
      default: true,
    },

    stretchable: {
      type: Boolean,
      default: true,
      required: false,
    },

    searchable: {
      type: Boolean,
      default: false,
      required: false,
    },


    showCopyChartToPd: {
      type: Boolean,
      required: false,
      default: false,
    },

    isDeletable: {
      type: Boolean,
      default: false,
      required: false,
    },

    infoText: {
      type: String,
      required: false,
    },

    toggleFiltersVisible: {
      type: Boolean,
      required: false,
      default: true,
    },

    connectionType: {
      type: [String, null],
      required: false,
      default: null,
      validator(type) {
        const types = ['error', 'warning', 'success', null];

        return types.includes(type);
      }
    }
  },

  data: () => ({
    isStretchedChart: false,
    isStatic: false,
    subMenuOpen: false,
  }),

  computed: {
    draggable() {
      return this.isStatic ? 'no-drag' : '';
    },
  },

  methods: {
    resetFilters() {
      this.$refs.tableSearch.clearSearch();
    },

    lockChart() {
      this.isStatic = !this.isStatic;
      this.setStatic();
    },

    setStretchPosition() {
      this.isStretchedChart = !this.isStretchedChart;
      this.$emit('setStretchPosition');
    },

    toggleFiltersVisibility() {
      this.$emit('toggleFiltersVisibility', !this.isFiltersOpen);
    },

    setStatic() {
      this.$emit('setStatic', this.isStatic);
    },

    removeItem() {
      this.$emit('removeItem', null);
    },

    copyChartToPD() {
      this.$emit('copyChartToPD');
      this.$router.push({ name: 'customLayout' });
    },

    toggleSubMenu() {
      setTimeout(() => {
        this.subMenuOpen = !this.subMenuOpen;
      }, 0);
    },

    closeSubMenu() {
      this.subMenuOpen = false;
    },

    search(event) {
      this.$emit('search', event)
    }
  },
};
</script>

<style lang="scss" scoped>
.chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;

  &__wrapper {
    display: flex;
    align-items: center;
  }
}

.chart-title {
  position: relative;
  margin-left: 20px;
  color: var(--textMainColor);
}

.connection-type {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: -10px;
  transform: translate(-100%, -50%);
  border-radius: 50%;
  width: 8px;
  height: 8px;

  &__success {
    background-color: var(--accent);
  }

  &__warning {
    background-color: #ff1;
  }

  &__error {
    background-color: #f27870;
  }
}

.info-icon {
  transition: filter 0.3s;
  margin-left: 11px;
  width: 14px;
  height: 14px;
  min-width: 14px;
  cursor: help;

  &:hover {
    path {
      fill: var(--lightColor);
    }

    circle {
      stroke: var(--lightColor);
    }
  }

  path,
  circle {
    transition: 0.3s;
  }
}

.graph-controls {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.graph-control {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background-color: transparent;

  &:hover {
    background-color: #3f3f3f;
  }

  &:not(:last-child) {
    margin-right: 10px;
  }

  img {
    pointer-events: none;
    user-select: none;
  }

  & > svg path {
    fill: #92939c;
  }

  &.menu {
    position: relative;
  }
}

.chart-type {
  display: inline-block;
  margin-left: 3px;
}

.no-drag {
  pointer-events: none;
}

.sub-menu {
  position: absolute;
  z-index: 4;
  right: 0;
  bottom: -10px;
  transform: translateY(100%);
  border: 1px solid var(--mainBorderColor);
  border-radius: 6px;
  width: 300px;
  padding: 10px;
  background-color: #1e1e1e;

  &__item {
    font-size: 0.875rem;
    font-weight: 600;
    color: var(--textMainColor);

    button {
      display: flex;
      align-items: center;
      border-radius: 6px;
      width: 100%;
      padding: 17px 11px;
      gap: 10px;

      &:hover {
        background-color: #464646;
      }
    }
  }
}
</style>
