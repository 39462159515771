export const fetchData = async (commit, endpoint, parameters, mutationType) => {
  try {
    const params = Object.fromEntries(
      Object.entries(parameters).map(([name, parameter]) => [name, parameter.toString()])
    );

    let response = await window.$axios.get(endpoint, { params })

    if (commit) {
      commit(mutationType, response.data);
    }

    return response.data;
  } catch (error) {
    // Handle error
    if (error.response) {
      // The request was made and the server responded with a status code
      console.error(error.response.status);
      console.error(error.response.data);
      return Promise.reject(error);
    } else if (error.request) {
      // The request was made but no response was received
      return Promise.reject(error.request);
    } else {
      // Something happened in setting up the request that triggered an error
      console.error(error.message);
    }
    console.error(error.config);

    console.log(1111, error)
  }
};