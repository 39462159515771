const noExponents = {
    methods: {
        noExponents(number) {
            const data = String(number).split(/[eE]/);
            if (data.length == 1) return data[0];

            let z = '',
                sign = this < 0 ? '-' : '',
                str = data[0].replace('.', ''),
                mag = Number(data[1]) + 1;

            if (mag < 0) {
                z = sign + '0.';
                while (mag++) z += '0';
                // eslint-disable-next-line
                return z + str.replace(/^\-/, '');
            }
            mag -= str.length;
            while (mag--) z += '0';
            return str + z;
        },
    },
};

export default noExponents;
