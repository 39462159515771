<template>
  <div class="chart-dominance chart-wrapper">
    <SectionHeader
        :is-deletable="isDeletable"
        :is-filters-open="isFiltersOpen"
        :show-copy-chart-to-pd="showCopyToPd"
        info-text="The percentage of market capitalization of the token in the total capitalization of the cryptocurrency for the selected period"
        title="Dominance"
        @copyChartToPD="$emit('copyChartToPD', {})"
        @removeItem="removeItem"
        @setStatic="$emit('setStatic', $event)"
        @setStretchPosition="setStretchPosition"
        @toggleFiltersVisibility="isFiltersOpen = $event"
    />

    <transition name="expand-filters">
      <div v-show="isFiltersOpen" ref="sectionFilters" class="chart-filters">
        <div class="chart-filter">
          <Select
              ref="coinId"
              :default-option-value="parametersForSelects.coinId || defaultCoinId"
              :options="coinIds"
              title="tokens"
              @selected="updateParameter($event, 'coinId')"
          />
        </div>

        <div class="chart-filter">
          <Select
              ref="dimension"
              :default-option-value="parametersForSelects.dimension || defaultDimension"
              :options="dimensions"
              title="Time Period"
              @selected="updateParameter($event, 'dimension')"
          />
        </div>

        <div class="chart-filter reset">
          <button :disabled="resetDisabled || showPreloader" class="reset" @click="resetParameters">
            Reset
          </button>
        </div>
      </div>
    </transition>

    <div class="chart-content">
      <div v-show="showPreloader" class="main-preloader widget-preloader chart-preloader">
        <Loader
            ref="preloader"
            :animationData="require('@/assets/images/Loader.json')"
            :autoPlay="true"
            :loop="true"
            :speed="1"
        />
      </div>
      <OneLine
          ref="dominanceChart"
          :is-filters-open="isFiltersOpen"
          :isResizeble="isResizeble"
          :series-data="dominance"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import OneLine from '@/components/charts/OneLine';
import debounce from '@/mixins/debounce';
import propsParameters from '@/mixins/propsParameters';

export default {
  name: 'Dominance',

  components: {
    OneLine,
  },

  props: {

    isResizeble: Boolean,
  },

  data: () => ({
    parameters: {},
    dominance: [],
    showPreloader: true,
    resetDisabled: true,
    isFiltersOpen: true,
  }),

  mixins: [debounce, propsParameters],

  computed: {
    ...mapGetters({
      filters: 'filters/dominance',
      naming: 'filters/naming',
      coinsInfo: 'filters/coinsInfo',
    }),

    defaultParameters() {
      return {
        coinId: this.defaultCoinId,
        dimension: this.defaultDimension,
      };
    },

    defaultDimension() {
      return this.dimensions.keys().next().value;
    },

    dimensions() {
      return new Map([
        ['WEEK_1', 'Week'],
        ['MONTH_1', 'Month'],
        ['YEAR_1', 'Year'],
      ]);
    },

    defaultCoinId() {
      return this.filters.coinIds[0];
    },

    coinIds() {
      return new Map(
          this.filters.coinIds.map(id => [id, this.coinsInfo[id].symbol.toUpperCase()])
      );
    },
  },

  methods: {
    ...mapActions({
      getDominanceData: 'charts/getDominance',
    }),

    updateParameter(event, name) {
      this.resetDisabled = false;
      this.parameters[name] = event;
      this.getDominance();
    },

    async resetParameters() {
      this.resetDisabled = true;
      this.parameters = { ...this.defaultParameters };

      await this.getDominance();

      this.$refs.coinId.reset();
      this.$refs.dimension.reset();
    },

    setStretchPosition() {
      this.$refs.dominanceChart.toggleChartFullscreen();
    },

    getDominance() {
      this.showPreloader = true;
      this.debounce(async () => {
        try {
          const dominance = await this.getDominanceData(this.parameters);
          this.dominance = dominance.map(item => [item.time, item.percentage]);
        } catch (e) {
          console.log(e);
        } finally {
          this.showPreloader = false;
        }
      }, 500);
    },

    removeItem() {
      this.$emit('removeItem', null);
    },
  },

  created() {
    this.getDominance();
  }
};
</script>

<style scoped>
</style>