import moment from 'moment';
import { crosshair, plotLine } from '@/mixins/chart-settings/_commonSettings';
import { makeCompactNumber } from '@/helpers';
import reflowHighchart from '@/mixins/reflowHighchart';

export default {
  mixins: [reflowHighchart],

  data() {
    const _this = this;

    return {
      chartOptions: {
        title: {
          text: null
        },

        noData: {
          rotation: 0,
          style: {
            fontWeight: 'normal',
            fontSize: '1rem',
            color: '#92939c'
          }
        },

        xAxis: [
          {
            gridLineColor: 'var(--gridLineColor)',
            gridLineWidth: 1,
            crosshair: {
              ...crosshair,

              label: {
                ...crosshair.label,

                style: {
                  color: 'var(--lightColor)'
                },

                formatter(event) {
                  return moment(event).format('YYYY-MM-DD HH:mm');
                },
              }
            },
            // events: {
            //   setExtremes: function (event) {
            //     if (!event.min && !event.max) {
            // window.zoomButton.destroy();
            // }
            // }
            // },
            tickPixelInterval: 190,
            offset: 1,
            labels: {
              align: 'center',
              y: 15,
              x: 0,
              rotation: 0,

              style: {
                fontFamily: 'Inter, sans-serif',
                color: '#fff',
              },

              formatter(event) {
                return moment(event.value).format('YYYY-MM-DD HH:mm');
              },
            },
          },
        ],

        yAxis: [
          {
            height: '54%',
            gridLineWidth: 1,
            gridLineColor: 'var(--gridLineColor)',
            lineWidth: 1,
            plotLines: [{
              ...plotLine,

              label: {
                ...plotLine.label,

                formatter() {
                  return _this.formatLabel(this.options.value);
                },
              }
            }],
            crosshair: {
              ...crosshair,
              label: {
                ...crosshair.label,

                style: {
                  color: 'var(--lightColor)'
                },

                formatter(event) {
                  return `<span style="color: ${_this.labelColor}; font-weight: 700">${_this.formatLabel(event)}</span>`;
                },
              }
            },
            showLastLabel: true,
            showFirstLabel: false,
            endOnTick: false,
            showOnTick: false,

            labels: {
              align: 'left',
              y: 4,
              x: 5,

              style: {
                fontFamily: 'Inter, sans-serif',
                color: '#fff',
              },
            },
            resize: {
              enabled: true
            }
          },
          {
            gridLineWidth: 1,
            gridLineColor: 'var(--gridLineColor)',
            lineWidth: 1,
            plotLines: [
              {
                color: 'var(--textMainColor)',
                width: 3,
                value: 0,
                dashStyle: 'solid',
                zIndex: 12,
              }
            ],
            crosshair: {
              ...crosshair,
              label: {
                ...crosshair.label,

                style: {
                  color: 'var(--lightColor)'
                },

                formatter(event) {
                  return `<span style="color: ${_this.labelColor}; font-weight: 700">${Math.round(event * 100) / 100}</span>`;
                },
              }
            },
            // min: this.maxElFromArrayAsks,
            // max: this.maxElFromArrayBids,
            step: 5,
            showLastLabel: true,
            endOnTick: false,
            startOnTick: false,
            labels: {
              align: 'left',
              y: 3,

              style: {
                fontFamily: 'Inter, sans-serif',
                color: '#fff',
              },
            },
            top: '54%',
            height: '46%',
            offset: 0
          }
        ],

        boost: {
          useGPUTranslations: true,
          seriesThreshold: 100000000
        },

        plotOptions: {
          series: {
            dataGrouping: {
              enabled: false,
            },

            marker: {
              lineColor: 'var(--mainBGColor)'
            },

            states: {
              inactive: {
                enabled: false
              },

              hover: {
                lineWidth: 2,
                halo: {
                  size: 0,
                }
              }
            }
          },

          column: {
            grouping: false,
            groupPadding: 0,
            pointPadding: 0,
          },
        },

        series: [
          {
            type: 'spline',
            name: 'AAPL Stock Price',
            data: [],
            zoneAxis: 'x',
            dataGrouping: {
              enabled: false
            },
            pointInterval: 24 * 3600 * 1000,
            turboThreshold: 10000000000,
          },
          {
            type: 'column',
            id: 'aapl-volume',
            name: 'AAPL Volume',
            data: [],
            zoneAxis: 'x',
            yAxis: 1,
            dataGrouping: {
              enabled: false
            },
            pointInterval: 24 * 3600 * 1000,
            turboThreshold: 10000000000,
          }
        ],

        chart: {
          backgroundColor: 'var(--mainBGColor)',
          margin: [0, 57, 25, 0],
          // height: 497,
          zoomType: 'x',
          panning: true,
          panKey: 'shift',

          resetZoomButton: {
            theme: {
              fill: 'var(--accent)',
              stroke: 'var(--accent)',

              style: {
                fontFamily: 'Inter, sans-serif',
                fontWeight: 700,
                color: '#fff',
              },

              states: {
                hover: {
                  fill: '#00fff7',
                  stroke: '#00fff7',
                }
              }
            },
          },

          events: {
            load() {
              let chart = this,
                max = chart.series[1].dataMax,
                min = chart.series[1].dataMin;

              chart.yAxis[1].update({
                max: max,
                min: min
              });
            }
            // selection: function () {
            //   const ch = this;
            //   window.zoomButton = ch.renderer.button('Reset zoom', null, null, function () {
            //     ch.xAxis[0].setExtremes(null, null);
            //   }, {
            //     zIndex: 20
            //   }).attr({
            //     id: 'resetZoom',
            //     align: 'right',
            //     title: 'Reset zoom level 1:1'
            //   }).add().align({
            //     align: 'right',
            //     x: -80,
            //     y: 40
            //   }, false, null);
            // }
          }
        },

        exporting: {
          enabled: false,
        },

        navigator: {
          enabled: false
        },

        legend: {
          enabled: false
        },

        tooltip: {
          split: true,
          useHTML: true,

          style: {
            display: 'none'
          },

          positioner: (width, height, point) => {
            if (point.zone) {
              this.labelColor = point.zone.color;
            }

            return {};
          }
        },

        scrollbar: {
          enabled: false
        },

        rangeSelector: {
          enabled: false,
        },
      },
    };
  },

  methods: {
    makeCompactNumber,

    formatLabel(event) {
      const maxNumberLength = 5;
      const integerNum = Math.abs(Math.round(event)) + '';
      const fractionDigits = maxNumberLength - integerNum.length;

      return this.makeCompactNumber(event, fractionDigits, fractionDigits);
    }
  },
};
