import pako from 'pako';
// import dayJS from 'dayjs';
import { EventEmitter } from 'events';

class TvWebSocket {
  url = `${process.env.VUE_APP_WS_URL}`;
  ws = null;
  success = [];
  failure = [];
  timer = null;
  closeFlag = null;
  pingPongInterval = null;
  evt = new EventEmitter();

  initWebSocket() {
    this.ws = new WebSocket(this.url);
    this.ws.binaryType = 'arraybuffer';
    this.ws.onopen = this.onopen.bind(this);
    this.ws.onclose = this.onclose.bind(this);
    this.ws.onerror = this.onerror.bind(this);
    this.ws.onmessage = this.onmessage.bind(this);
    // console.log(' >> WebSocket init :', this.url);
  }

  onopen() {
    clearInterval(this.pingPongInterval);
    // console.log(' >> WebSocket open...');
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;

      this.ws.send(this.success[1]);
    }

    this.failure.forEach((el, i) => {
      if (i === 1) {
        if (this.success.includes(el)) {
          return;
        }
        this.ws.send(this.failure[el]);
        const index = this.success.indexOf(el)

        this.success.splice(index, 1, this.failure[el])
        // console.log(` >> WebSocket send: ${this.failure[el]}`);
      }
    });
    this.failure = [];

    this.pingPongInterval = setInterval(() => {
      this.heartbeat();
    }, 5000);
  }

  onclose(event) {
    this.ws = null;
    clearInterval(this.pingPongInterval);
    if (event.wasClean) {
      this.close();
    } else {
      if (!this.timer) {
        if (!this.closeFlag) {
          this.onReconnection();
        }
      }
      // console.dir(event.code)
    }
  }

  close() {
    clearInterval(this.pingPongInterval);
    this.closeFlag = true;
    if (this.ws) {
      this.ws.close();
    }
  }

  onerror() {
    if (this.ws) {
      this.ws.close();
    }
  }

  onmessage(event) {
    if (!event.data) {
      return;
    }

    let text = event.data;

    if(process.env.VUE_APP_WS_URL.includes('stream-binary')) {
      text = pako.inflate(event.data, { to: 'string' });
    }

    const data = JSON.parse(text);
    if (data && data.ping) {
      this.ws?.send(
        JSON.stringify({
          pong: Date.now(),
        })
      );
      return;
    }
    this.onBroadcast(data);
  }

  heartbeat() {
    if (!this.ws || this.ws.readyState !== 1) {
      return;
    }

    this.ws?.send(JSON.stringify({ 'method': 'PING', }));
  }

  onBroadcast(msg) {

    if (msg.eventType === 'expiredSessionWarning') {
      this.ws?.send(JSON.stringify({ 'method': 'EXTEND_SESSION', 'params': ['MIN_30'] }));
    }



    // let topic = null
    //
    // if(msg.eventType === 'depthChart') {
    //   topic = `depthChart,pair@BTC/USDT,exchanges,assets`
    // }

    // if(msg.eventType === 'candlestick') {
    //   topic = `${msg.symbol}@${msg.eventType}_${msg.interval.toLowerCase()}`
    // }else if(msg.eventType === 'trade' || msg.eventType === 'orderBook'){
    //   topic = `${msg.symbol}@orderBook,${msg.symbol}@trade`
    // }else if(msg.constructor.name == 'Array'){
    //   if (msg[0].eventType === 'markPrice' || msg[0].eventType === 'ticker') {
    //     topic = `!markPrice_arr_3s,!ticker_arr`
    //   }
    // }

    // console.log(msg)

    try {
      this.evt.emit(msg.eventType, msg);
    } catch (e) {
      console.log(e);
    }
  }

  subscribe(name, params, callback) {
    if (!params.params) {
      return;
    }

    const ee = (this.ws && this.ws.readyState === WebSocket.OPEN)
      ? this.successPush(name, params, callback)
      : this.failurePush(name, params, callback);

    return {
      remove: () => {
        this.unsubscribe(name);
        ee.removeAllListeners(name);
      },
    };
  }

  unsubscribe(name, params) {
    if (!params.params) {
      return;
    }

    if (this.failure.includes(params.params)) {
      const index = this.failure.indexOf(params.params);

      this.failure.splice(index, 1);
    }

    const arrSubscribes = this.success;

    const subscribe = arrSubscribes.filter(function(obj) {
      return JSON.stringify(obj) === JSON.stringify(params.params);
    });

    if(subscribe.length > 1) {
      const index = this.success.indexOf(params.params);
      this.success.splice(index, 1)
      return
    }

    if (!this.success.includes(params.params)) {
      return;
    }

    if (!this.ws) {
      const index = this.success.indexOf(params.params);
      this.success.splice(index, 1)
      return;
    }

    const unsub = { method: 'UNSUBSCRIBE', topic: name, params: params.params };
    this.ws.send(JSON.stringify(unsub));
    this.evt.removeAllListeners(params.params);
    const index = this.success.indexOf(params.params);
    this.success.splice(index, 1)
    // console.log(` >> WebSocket send: ${JSON.stringify(unsub)}`);
  }

  successPush(name, params, callback) {
    const wsSocketParams = {
      ...params,
      topic: name,
      params: params.params
    };

    const arrSubscribes = this.success;

    const subscribe = arrSubscribes.filter(function(obj) {
      return JSON.stringify(obj) === JSON.stringify(params.params);
    });

    if(subscribe.length) {
      this.success.push(params.params);
      return this.evt.on(name, callback);
    }

    this.success.push(params.params);
    this.ws?.send(JSON.stringify(wsSocketParams));



    return this.evt.on(name, callback);
  }

  failurePush(name, params, callback) {
    this.failure.push(params.params);
    // console.log(` >> WebSocket 准备订阅: ${this.failure[name]}`);
    return this.evt.on(name, callback);
  }

  onReconnection() {
    if (!this.url) {
      return;
    }
    this.initWebSocket();
    this.timer = setInterval(() => {
      this.initWebSocket();
      // const now = dayJS().format('YYYY-MM-DD HH:mm:ss');
      // console.log(` >> [${now}] WebSocket Reconnect....`);
    }, 10000);
  }
}

export const ws = new TvWebSocket();