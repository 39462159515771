<template>
  <div class="order-book-pie custom-scroll">
    <highcharts
        ref="chart"
        :options="chartOptions"
    />
  </div>
</template>

<script>
import orderBookPieSettings from '@/mixins/chart-settings/orderbookPie';

export default {
  name: 'Pie',

  mixins: [orderBookPieSettings],
  
  props: {
    orderBookChartData: {
      type: Array,
      required: true
    },

    base: {
      type: String,
      required: true,
    }
  },

  watch: {
    orderBookChartData() {
      this.$refs.chart.chart.series[0].update({
        data: this.orderBookChartData
      });
    }
  },

  methods: {
    openInFullScreen() {
      this.$refs.chart.chart.fullscreen.toggle();
    }
  },
};
</script>

<style scoped>
.order-book-pie {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 420px;
}
</style>
