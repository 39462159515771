<template>
  <LottieAnimation
      :animationData="animationData"
      :autoPlay="autoPlay"
      :loop="loop"
      :speed="speed"
  />
</template>

<script>
import LottieAnimation from 'lottie-web-vue';

export default {
  name: 'Loader',

  components: {
    LottieAnimation,
  },

  props: {
    animationData: {},

    autoPlay: {
      type: Boolean,
      default: true,
    },

    loop: {
      type: Boolean,
      default: true,
    },

    speed: {
      type: Number,
      default: 1,
    },
  }
};
</script>

<style scoped>

</style>