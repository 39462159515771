import { getFromLocalStorage } from "@/helpers";
import componentNames from '@/helpers/componentNames';

const createLayoutItem = (x, y, w, h, i, componentName, isStatic = false, initialParameters = {}, stretched = false) => ({
  x, y, w, h, i, componentName, static: isStatic, initialParameters, stretched,
});

export default {
  customLayout: getFromLocalStorage('customLayout') || {
    lg: [],
    md: [],
    sm: [],
  },

  liquidityLayout: {
    lg: [
      createLayoutItem(0, 0, 6, 23, 0, componentNames.walls, false, { view: true }),
      createLayoutItem(6, 0, 6, 23, 1, componentNames.walls, false, { view: false }),
      createLayoutItem(0, 23, 6, 23, 2, componentNames.tradingVol),
      createLayoutItem(6, 23, 6, 23, 3, componentNames.tradingVol, false, { assets: 'spot' }),
      createLayoutItem(0, 46, 6, 23, 4, componentNames.vwap),
      createLayoutItem(6, 46, 6, 23, 5, componentNames.volByTime),
      createLayoutItem(0, 69, 12, 21, 6, componentNames.orderBook),
    ],
    sm: [
      createLayoutItem(0, 0, 12, 21, 0, componentNames.walls),
      createLayoutItem(0, 21, 12, 21, 1, componentNames.tradingVol),
      createLayoutItem(0, 42, 12, 21, 2, componentNames.vwap),
      createLayoutItem(0, 63, 12, 21, 3, componentNames.volByTime),
      createLayoutItem(0, 84, 12, 21, 4, componentNames.orderBook),
    ]
  },

  coinsLayout: {
    lg: [
      createLayoutItem(0, 0, 6, 21, 0, componentNames.dominance),
      createLayoutItem(6, 0, 6, 21, 1, componentNames.ratio),
      createLayoutItem(0, 21, 6, 20, 2, componentNames.statistics),
      createLayoutItem(6, 21, 6, 20, 3, componentNames.statistics, false, { symbolStatisticType: 'LOSER' }),
    ],
    sm: [
      createLayoutItem(0, 0, 12, 21, 0, componentNames.dominance),
      createLayoutItem(0, 21, 12, 21, 1, componentNames.ratio),
      createLayoutItem(0, 42, 12, 20, 2, componentNames.statistics),
      createLayoutItem(0, 62, 12, 20, 3, componentNames.statistics, false, { symbolStatisticType: 'LOSER' }),
    ]
  },

  arbitrageLayout: {
    lg: [
      createLayoutItem(0, 0, 6, 21, 0, componentNames.asset, false, { asset: 'futures_perpetual' }),
      createLayoutItem(6, 0, 6, 21, 1, componentNames.asset, false, { asset: 'spot' }),
      createLayoutItem(0, 21, 12, 34, 2, componentNames.arbitrageCalculator),
      createLayoutItem(0, 55, 12, 27, 3, componentNames.funding),
      createLayoutItem(0, 82, 6, 21, 4, componentNames.techAnalysis),
      createLayoutItem(6, 82, 6, 21, 5, componentNames.cryptoToStockIndexes),
    ],
    sm: [
      createLayoutItem(0, 0, 12, 21, 0, componentNames.asset, false, { asset: 'futures_perpetual' }),
      createLayoutItem(0, 21, 12, 21, 1, componentNames.asset, false, { asset: 'spot' }),
      createLayoutItem(0, 42, 12, 35, 2, componentNames.arbitrageCalculator),
      createLayoutItem(0, 77, 12, 27, 3, componentNames.funding),
      createLayoutItem(0, 104, 12, 21, 4, componentNames.techAnalysis),
      createLayoutItem(0, 125, 12, 21, 5, componentNames.cryptoToStockIndexes),
    ],
  },

  DEXsLayout: {
    lg: [
      createLayoutItem(0, 0, 6, 21, 0, componentNames.dexVsCex),
      createLayoutItem(6, 0, 6, 21, 1, componentNames.dexVsCex, false, { asset: 'futures_perpetual' }),
    ],
    sm: [
      createLayoutItem(0, 0, 12, 21, 0, componentNames.dexVsCex),
      createLayoutItem(0, 21, 12, 21, 1, componentNames.dexVsCex),
    ],
  },

  announcementsLayout: {
    lg: [
      createLayoutItem(0, 0, 12, 20, 0, componentNames.announcements),
    ],
    sm: [
      createLayoutItem(0, 0, 12, 38, 0, componentNames.announcements),
    ],
  },

  wipLayout: {
    lg: [
      createLayoutItem(0, 0, 100, 35, 6, componentNames.arbitrageCalculator),
    ],
    sm: [
      createLayoutItem(0, 0, 100, 35, 6, componentNames.arbitrageCalculator),
    ],
  },

  exchangeListLayout: {
    lg: [
      createLayoutItem(0, 0, 12, 24, 0, componentNames.spotExchanges),
    ],
    sm: [
      createLayoutItem(0, 0, 12, 24, 0, componentNames.spotExchanges),
    ],
  },

  exchangeLayout: {
    lg: [
      createLayoutItem(0, 0, 3, 14, 0, componentNames.exchangeInfo),
      createLayoutItem(3, 0, 9, 14, 1, componentNames.exchangeAbout),
      createLayoutItem(0, 14, 8, 22, 2, componentNames.exchangeOrderbook),
      createLayoutItem(8, 14, 4, 22, 3, componentNames.exchangeScamScore),
      createLayoutItem(0, 36, 12, 24, 4, componentNames.exchangeMarkets),
    ],
    sm: [
      createLayoutItem(0, 0, 12, 26, 0, componentNames.exchangeInfo),
      createLayoutItem(0, 26, 12, 21, 1, componentNames.exchangeAbout),
      createLayoutItem(0, 26, 12, 23, 2, componentNames.exchangeOrderbook),
      createLayoutItem(0, 26, 12, 23, 3, componentNames.exchangeScamScore),
      createLayoutItem(0, 26, 12, 24, 4, componentNames.exchangeMarkets),
    ],
  },

  tokenLayout: {
    lg: [
      createLayoutItem(0, 0, 3, 16, 0, componentNames.tokenInfo),
      createLayoutItem(3, 0, 9, 16, 1, componentNames.tokenStatistics),
      createLayoutItem(0, 16, 9, 22, 2, componentNames.tokenChart),
      createLayoutItem(9, 16, 3, 22, 3, componentNames.tokenAbout),
      createLayoutItem(0, 38, 12, 24, 4, componentNames.tokenMarkets),
    ],
    sm: [
      createLayoutItem(0, 0, 12, 26, 0, componentNames.exchangeInfo),
      createLayoutItem(0, 26, 12, 21, 1, componentNames.exchangeAbout),
      createLayoutItem(0, 26, 12, 23, 2, componentNames.exchangeOrderbook),
      createLayoutItem(0, 26, 12, 23, 3, componentNames.exchangeScamScore),
      createLayoutItem(0, 26, 12, 24, 4, componentNames.exchangeMarkets),
    ],
  },

  tokensListLayout: {
    lg: [
      createLayoutItem(0, 0, 12, 29, 0, componentNames.powTokens),
    ],
    sm: [
      createLayoutItem(0, 0, 12, 29, 0, componentNames.powTokens),
    ],
  },

  breakpoint: 'lg',
  layout: [],
  resized: false,
};
