<template>
  <div class="chart-wrapper">
    <SectionHeader
        :info-text="description"
        :is-deletable="false"
        :is-filters-open="isFiltersOpen"
        :stretchable="true"
        :title="title"
        @setStatic="$emit('setStatic', $event)"
        @setStretchPosition="toggleFullScreen"
        @toggleFiltersVisibility="isFiltersOpen = $event"
    />

    <transition name="expand-filters">
      <div v-show="isFiltersOpen" class="chart-filters">
        <div class="filters">
          <div class="chart-filter">
            <MultiSelect
                ref="exchanges"
                :default-option-value="parametersForSelects.exchanges || defaultExchanges"
                :options="exchanges"
                title="Exchanges"
                @selected="updateParameter($event, 'exchangeIds')"
            />
          </div>

          <div class="chart-filter">
            <Select
                ref="asset"
                :default-option-value="parametersForSelects.asset || defaultAsset"
                :options="assets"
                title="Asset"
                @selected="updateParameter($event, 'asset')"
            />
          </div>

          <div class="chart-filter">
            <Select
                ref="pair"
                :default-option-value="parametersForSelects.pair || defaultPair"
                :options="pairs"
                title="Pair"
                @selected="updateParameter($event, 'currencyPair')"
            />
          </div>

          <div class="chart-filter reset">
            <button :disabled="resetDisabled || showPreloader" class="reset" @click="resetParameters">
              Reset
            </button>
          </div>
        </div>

        <div class="axis-description">
          <div class="axis">
            <span>X-axis: </span>
            <span>Price</span>
          </div>

          <div class="axis">
            <span>Y-axis: </span>
            <span>Vol</span>
          </div>
        </div>
      </div>
    </transition>
    <div class="chart-wrapper">
      <div v-show="showPreloader" class="main-preloader widget-preloader chart-preloader">
        <Loader
            ref="preloader"
            :animationData="require('@/assets/images/Loader.json')"
            :autoPlay="true"
            :loop="true"
            :speed="1"
        />
      </div>

      <highcharts
          ref="chart"
          :options="chartOptions"
          constructor-type="stockChart"
      />
    </div>

    <div class="description">
      <!--      <h2 class="description__title">-->
      <!--        Description-->
      <!--      </h2>-->

      <!--      <p class="description__text">-->
      <!--        Lorem ipsum dolor sit amet consectetur. Interdum dignissim in nec congue velit fringilla nisi id. Lorem lorem ut venenatis molestie sagittis rhoncus enim aliquet consequat. Dignissim viverra elementum nunc elementum volutpat ornare venenatis suspendisse. Tincidunt quam elementum mattis condimentum vestibulum vel sit amet dictumst. Lorem ipsum dolor sit amet consectetur. Interdum dignissim in nec congue velit fringilla nisi id-->
      <!--      </p>-->

      <div class="description__legends">
        <button
            v-for="(legend, index) in chartOptions.series"
            :key="index"
            :class="{inactive: !legend.data.length}"
            class="description__legend"
            @click="toggleLegend(legend)"
        >
          <span
              :style="{backgroundColor: legend.color}"
              class="description__indicator"
          />
          {{ exchangesInfo[legend.name].displayName }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import scatter from '@/mixins/chart-settings/scatter';
import propsParameters from '@/mixins/propsParameters';
import commonFilters from '@/mixins/filters/commonFilters';

export default {
  name: 'Orderbook',

  components: {},

  data: () => ({
    parameters: {},
    showPreloader: true,
    title: 'Orderbook Volume Distribution',
    description: 'Description Orderbook',
    isFiltersOpen: true,
    resetDisabled: true,
  }),

  mixins: [commonFilters, scatter, propsParameters],

  props: {

    isResizeble: {
      type: Boolean,
      required: false,
    },
  },

  computed: {
    ...mapGetters({
      exchangesInfo: 'filters/exchangesInfo',
      naming: 'filters/naming',
      filters: 'filters/scamScore',
      exchangeSlugs: 'filters/exchangeSlugs',
      scamScoreScatter: 'charts/scamScorePoints',
    }),

    defaultParameters() {
      return {
        exchangeIds: this.defaultExchanges,
        asset: this.defaultAsset,
        currencyPair: this.defaultPair
      };
    },

    defaultExchanges() {
      return [this.exchangeSlugs[this.$route.params.exchange] + ''];
    },

    exchanges() {
      const asset = this.parameters?.asset ?? this.propsParameters?.asset ?? this.initialParameters?.asset ?? 'futures_perpetual';
      const exchangesIds = Object.keys(this.filters).filter(exchangesId => this.filters[exchangesId][asset]);

      return new Map(exchangesIds.map(exchangeId => [exchangeId, this.exchangesInfo[exchangeId].displayName]));
    },

    assets() {
      const exchanges = this.parameters.exchanges || this.defaultExchanges;
      const allAssets = [...new Set(exchanges.flatMap(exchange => Object.keys(this.filters[exchange] || {})))];

      return new Map(allAssets.map(asset => [asset, this.naming[asset] || asset]));
    },

    defaultPair() {
      return this.pairs.keys().next().value;
    },

    pairs() {
      const exchanges = this.parameters.exchanges ?? this.defaultExchanges;
      const asset = this.parameters.asset || this.defaultAsset;
      const allPairs = exchanges.flatMap(exchange => this.filters[exchange]?.[asset] || []);

      return new Map(allPairs.map(pair => [pair.toString(), `${this.coinsInfo[pair[0]]?.symbol.toUpperCase() || pair[0]}/${this.coinsInfo[pair[1]]?.symbol.toUpperCase() || pair[1]}`]));
    },
  },

  watch: {
    resized() {
      this.resize();
    },

    isResizeble() {
      this.resize();
    },
  },

  methods: {
    ...mapActions({
      getScamScorePoints: 'charts/getScamScorePoints',
    }),

    toggleFullScreen() {
      this.$refs.chart.chart.fullscreen.toggle();
    },

    async resetParameters() {
      this.resetDisabled = true;
      this.parameters = { ...this.defaultParameters };

      await this.getChartData();

      this.$refs.exchanges.reset();
      this.$refs.asset.reset();
      this.$refs.pair.reset();
    },

    updateParameter(parameter, name) {
      this.resetDisabled = false;
      this.parameters = {
        ...this.parameters,
        [name]: parameter
      };

      this.getChartData();
    },

    toggleLegend(legend) {
      legend.data = legend.data.length ? [] : this.scamScoreScatter[legend.name];
    },

    async getChartData() {
      try {
        this.showPreloader = true;
        const scamScorePoints = await this.getScamScorePoints(this.parameters);

        this.chartOptions.series = Object.entries(scamScorePoints).map(([exchangeName, data]) => ({
          name: exchangeName,
          findNearestPointBy: 'xy',
          color: this.exchangesInfo[exchangeName].color,
          data,

          marker: {
            symbol: 'circle',
          }
        }));
      } catch (e) {
        console.log(e);
      } finally {
        this.showPreloader = false;
      }
    }
  },

  created() {
    this.getChartData();
  },

  mounted() {
    setTimeout(() => {
      this.$refs.chart.chart.reflow();
    }, 0);
  }
};
</script>

<style lang="scss" scoped>
.chart-filters {
  justify-content: space-between;
  align-items: center;
}

.filters {
  display: flex;
  align-items: center;
  gap: 5px;
}

.axis-description {
  display: flex;
  gap: 20px;
}

.axis {
  font-size: 14px;
  font-weight: 600;

  span:first-child {
    color: var(--textSecondaryColor);
  }
}

.description {
  padding: 20px;

  &__title {
    font-size: 1.25rem;
  }

  &__text {
    margin: 10px 0 26px;
    font-size: 12px;
    color: var(--textSecondaryColor);
  }

  &__legends {
    display: flex;
    padding-left: 15px;
    font-size: 0.875rem;
    font-weight: 600;
    gap: 35px;
  }

  &__legend {
    position: relative;

    &.inactive {
      color: var(--textSecondaryColor);
    }
  }

  &__indicator {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: -5px;
    transform: translate(-100%, -50%);
    border-radius: 50%;
    width: 10px;
    height: 10px;
  }
}
</style>