<template>
  <div class="chart-wrapper">
    <SectionHeader
        ref="sectionHeader"
        :is-deletable="isDeletable"
        :is-filters-open="isFiltersOpen"
        :searchable="true"
        :show-copy-chart-to-pd="showCopyToPd"
        :stretchable="false"
        info-text="description"
        title="Top PoW Tokens by Market Capitalization"
        @copyChartToPD="$emit('copyChartToPD', {})"
        @removeItem="$emit('removeItem', null)"
        @search="search"
        @setStatic="$emit('setStatic', $event)"
        @toggleFiltersVisibility="isFiltersOpen = $event"
    />

    <transition-group name="expand-filters">
      <div
          v-show="isFiltersOpen"
          key="filters"
          ref="sectionFilters"
          class="chart-filters max-screen-width"
      >
        <div class="filter-btns">
          <div class="chart-filter btn-filter add">
            <button class="add" @click="showFiltersPopup">
              <!--              <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">-->
              <!--                <path d="M1 5H9M5 9V1" stroke="#35D9D1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>-->
              <!--              </svg>-->
              <span> Advanced Filters</span>
            </button>
          </div>
          <div class="chart-filter btn-filter reset">
            <button :disabled="resetDisabled || showPreloader" class="reset" @click="resetParameters">
              <!--              <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">-->
              <!--                <path d="M1.17157 1.17157L6.82843 6.82842M1.17157 6.82842L6.82843 1.17157" stroke="#F27870" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>-->
              <!--              </svg>-->
              Clear All
            </button>
          </div>
        </div>
      </div>
    </transition-group>

    <div ref="chartContent" class="chart-content">
      <div v-show="showPreloader" class="main-preloader widget-preloader chart-preloader">
        <Loader
            ref="preloader"
            :animationData="require('@/assets/images/Loader.json')"
            :autoPlay="true"
            :loop="true"
            :speed="1"
        />
      </div>

      <NoData
          v-show="!showPreloader && !totalTokens"
          text="There doesn't seem to be any data. <br /> Try other filters"
      />

      <div class="custom-scroll max-screen-width">
        <table>
          <thead>
            <tr>
              <th>
                <button class="header-wrapper justify-start" @click="sortTable('rank')">
                  #
                </button>
              </th>
              <th>
                <button
                    :class="{'sorted': sortBy === 'displayName'}"
                    class="header-wrapper justify-start"
                    @click="sortTable('displayName')"
                >
                  <svg
                      class="arrows"
                      fill="none"
                      height="13"
                      viewBox="0 0 12 13"
                      width="12"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                        :class="{active: !reversed}"
                        d="M9 4.49998L6 1.49999L3 4.49998"
                        stroke="#92939C"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                    />
                    <path
                        :class="{active: reversed}"
                        d="M3 8.50002L6 11.5L9 8.50002"
                        stroke="#92939C"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                    />
                  </svg>

                  Token
                </button>
              </th>
              <th
                  v-for="([value, info], index) in parameters.visibleColumnsFilters"
                  v-show="parameters.visibleColumns.includes(value)"
                  :key="index"
                  :class="['dragging' ? (isDragging && dragIndex === index) : '', value]"
                  :draggable="true"
                  @dragstart="handleDragStart(index)"
                  @drop="handleDrop(index)"
                  @dragover.prevent="allowDrop"
              >
                <button
                    v-if="value !== 'displayName'"
                    :class="{
                    'sorted': sortBy === value,
                  }"
                    @click="sortTable(value)"
                >
                  <svg
                      class="arrows"
                      fill="none"
                      height="13"
                      viewBox="0 0 12 13"
                      width="12"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                        :class="{active: !reversed}"
                        d="M9 4.49998L6 1.49999L3 4.49998"
                        stroke="#92939C"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                    />
                    <path
                        :class="{active: reversed}"
                        d="M3 8.50002L6 11.5L9 8.50002"
                        stroke="#92939C"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                    />
                  </svg>

                  {{ info[0] }}
                </button>
                <div v-else class="header-wrapper">
                  {{ info[0] }}
                </div>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="token in sortedTokensInfo.slice(page * size, (page + 1) * size)" :key="token.id" ref="row">
              <td class="rank">{{ token.rank }}</td>

              <td class="pair">
                <div class="pair-wrapper">
                  <LazyImg :id="+token.id" :is-token="true" class="logo" />

                  <div class="pair-info">
                    <router-link
                        :to="{ path: `/token/${token.slug}` }"
                        class="pair-info__link"
                    >
                      <span>
                        {{ token.displayName }}
                      </span>
                    </router-link>
                  </div>
                </div>
              </td>

              <td v-show="parameters.visibleColumns.includes('lastPrice')">
                ${{ prettifyNumber(token.lastPrice.toFixed(2)) }}
              </td>

              <td v-show="parameters.visibleColumns.includes('pricePercentChangeHour')">
                <div :class="setGrowthDirection(token.pricePercentChangeHour)" class="growth">
                  <svg fill="none" height="4" viewBox="0 0 8 4" width="8" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M1.3231 3.33325L6.96093 3.33325C7.41805 3.33325 7.64661 2.82731 7.32282 2.53072L4.85627 0.271426C4.46105 -0.0905843 3.81822 -0.0905844 3.423 0.271426L2.48495 1.13066L0.956451 2.53072C0.637418 2.82731 0.865978 3.33325 1.3231 3.33325Z"
                        fill="#fff"
                    />
                  </svg>

                  {{ prettifyNumber(token.pricePercentChangeHour.toFixed(2)) }}%
                </div>
              </td>

              <td v-show="parameters.visibleColumns.includes('pricePercentChangeDay')">
                <div :class="setGrowthDirection(token.pricePercentChangeDay)" class="growth">
                  <svg fill="none" height="4" viewBox="0 0 8 4" width="8" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M1.3231 3.33325L6.96093 3.33325C7.41805 3.33325 7.64661 2.82731 7.32282 2.53072L4.85627 0.271426C4.46105 -0.0905843 3.81822 -0.0905844 3.423 0.271426L2.48495 1.13066L0.956451 2.53072C0.637418 2.82731 0.865978 3.33325 1.3231 3.33325Z"
                        fill="#fff"
                    />
                  </svg>

                  {{ prettifyNumber(token.pricePercentChangeDay.toFixed(2)) }}%
                </div>
              </td>

              <td v-show="parameters.visibleColumns.includes('pricePercentChangeWeek')">
                <div :class="setGrowthDirection(token.pricePercentChangeWeek)" class="growth">
                  <svg fill="none" height="4" viewBox="0 0 8 4" width="8" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M1.3231 3.33325L6.96093 3.33325C7.41805 3.33325 7.64661 2.82731 7.32282 2.53072L4.85627 0.271426C4.46105 -0.0905843 3.81822 -0.0905844 3.423 0.271426L2.48495 1.13066L0.956451 2.53072C0.637418 2.82731 0.865978 3.33325 1.3231 3.33325Z"
                        fill="#fff"
                    />
                  </svg>

                  {{ prettifyNumber(token.pricePercentChangeWeek.toFixed(2)) }}%
                </div>
              </td>

              <td v-show="parameters.visibleColumns.includes('pricePercentChangeMonths')">
                <div :class="setGrowthDirection(token.pricePercentChangeMonths)" class="growth">
                  <svg fill="none" height="4" viewBox="0 0 8 4" width="8" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M1.3231 3.33325L6.96093 3.33325C7.41805 3.33325 7.64661 2.82731 7.32282 2.53072L4.85627 0.271426C4.46105 -0.0905843 3.81822 -0.0905844 3.423 0.271426L2.48495 1.13066L0.956451 2.53072C0.637418 2.82731 0.865978 3.33325 1.3231 3.33325Z"
                        fill="#fff"
                    />
                  </svg>

                  {{ prettifyNumber(token.pricePercentChangeMonths.toFixed(2)) }}%
                </div>
              </td>

              <td v-show="parameters.visibleColumns.includes('pricePercentChange3Months')">
                <div :class="setGrowthDirection(token.pricePercentChange3Months)" class="growth">
                  <svg fill="none" height="4" viewBox="0 0 8 4" width="8" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M1.3231 3.33325L6.96093 3.33325C7.41805 3.33325 7.64661 2.82731 7.32282 2.53072L4.85627 0.271426C4.46105 -0.0905843 3.81822 -0.0905844 3.423 0.271426L2.48495 1.13066L0.956451 2.53072C0.637418 2.82731 0.865978 3.33325 1.3231 3.33325Z"
                        fill="#fff"
                    />
                  </svg>

                  {{ prettifyNumber(token.pricePercentChange3Months.toFixed(2)) }}%
                </div>
              </td>

              <td v-show="parameters.visibleColumns.includes('pricePercentChangeYear')">
                <div :class="setGrowthDirection(token.pricePercentChangeYear)" class="growth">
                  <svg fill="none" height="4" viewBox="0 0 8 4" width="8" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M1.3231 3.33325L6.96093 3.33325C7.41805 3.33325 7.64661 2.82731 7.32282 2.53072L4.85627 0.271426C4.46105 -0.0905843 3.81822 -0.0905844 3.423 0.271426L2.48495 1.13066L0.956451 2.53072C0.637418 2.82731 0.865978 3.33325 1.3231 3.33325Z"
                        fill="#fff"
                    />
                  </svg>

                  {{ prettifyNumber(token.pricePercentChangeYear.toFixed(2)) }}%
                </div>
              </td>

              <td v-show="parameters.visibleColumns.includes('mcap')">
                ${{ prettifyNumber(token.mcap.toFixed(2)) }}
              </td>

              <td v-show="parameters.visibleColumns.includes('volumeDay')" class="volume-percent">
                ${{ prettifyNumber(token.volumeDay.toFixed(2)) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <CustomPagination
          v-if="totalTokens > 1"
          :page-count="size"
          :page-number="page"
          :pagination-size="totalPages"
          :total-items="totalTokens"
          @pageChanged="page = $event"
      />
    </div>
    <Drawer
        ref="drawer"
        @apply="applyFilters"
        @reset="resetFilters"
    >
      <div class="popup-filters-list">
        <div class="popup-filters-category">
          <span class="popup-filters-category-title">Token:</span>
          <div class="popup-filters-item">
            <span class="popup-filters-item-title">Token Liquidity</span>
            <Range
                :default-value="filters.tokenLiquidityRange"
                :max="defaultFilters.tokenLiquidityRange[1]"
                :min="defaultFilters.tokenLiquidityRange[0]"
                title=""
                @update="filters.tokenLiquidityRange = $event"
            />
          </div>
          <div class="popup-filters-item">
            <span class="popup-filters-item-title">Token Market Cap</span>
            <Range
                :default-value="filters.tokenMarketCapRange"
                :max="defaultFilters.tokenMarketCapRange[1]"
                :min="defaultFilters.tokenMarketCapRange[0]"
                title=""
                @update="filters.tokenMarketCapRange = $event"
            />
          </div>
        </div>
        <div class="popup-filters-category">
          <span class="popup-filters-category-title">General:</span>
          <div class="popup-filters-item">
            <span class="popup-filters-item-title">Columns</span>
            <div class="checkbox-list">
              <v-checkbox
                  v-for="(column, i) in [...columns.keys()]"
                  :key="i"
                  v-model="filters.visibleColumns"
                  :disabled="filters.visibleColumns[0] === column && filters.visibleColumns.length === 1"
                  :label="filters.visibleColumnsFilters.get(column)[0]"
                  :value="column"
              ></v-checkbox>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import prettifyNumber from '@/mixins/helpers/prettifyNumber';
import aroundNumber from '@/mixins/helpers/aroundNumber';
import Range from '@/components/ui/inputs/Range';
import sortByProperty from '@/mixins/helpers/sortByProperty';
import tableFilters from '@/mixins/filters/tableFilters';
import Drawer from "@/components/ui/popups/Drawer.vue";

export default {
  name: 'PoWTokens',

  components: { Range, Drawer },

  props: {

    isResizeble: Boolean,

    showCopyToPd: {
      type: Boolean,
      default: false,
      required: false,
    },

    isDeletable: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  mixins: [prettifyNumber, aroundNumber, sortByProperty, tableFilters],

  data: () => ({
    isFiltersOpen: true,
    showPreloader: false,
    resetDisabled: true,
    size: 10,
    page: 0,
    searchText: '',
    parameters: {},
    reversed: false,
    sortBy: 'rank',
    sortType: 'number',
    filters: {},
    isDragging: false,
    dragIndex: null,
    defaultFilters: {
      tokenLiquidityRange: [0, 10000],
      tokenMarketCapRange: [0, 10000],
      visibleColumnsFilters: new Map([
        ['lastPrice', ['Price', 'number']],
        ['pricePercentChangeHour', ['1h%', 'number']],
        ['pricePercentChangeDay', ['24h%', 'number']],
        ['pricePercentChangeWeek', ['7d%', 'number']],
        ['pricePercentChangeMonths', ['1m%', 'number']],
        ['pricePercentChange3Months', ['3m%', 'number']],
        ['pricePercentChangeYear', ['1y%', 'number']],
        ['mcap', ['Market Cap', 'number']],
        ['volumeDay', ['Volume (24h)', 'number']]
      ]),
      visibleColumns: [],
    },
  }),

  watch: {
    isResizeble() {
      this.setSize();
    },

    mCapRange() {
      this.page = 0;
    },

    volumeRange() {
      this.page = 0;
    },
  },

  computed: {
    ...mapGetters({
      coinsInfo: 'filters/coinsInfo',
      mCapRangeMax: 'filters/maxCoinsMcap',
      volumeRangeMax: 'filters/maxVolumeByDay',
    }),

    sortedTokensInfo() {
      const {
        sortBy,
        sortType,
        parameters: {
          tokenMarketCapRange: [mCapFrom, mCapTo],
          tokenLiquidityRange: [volumeFrom, volumeTo],
        },
        searchText,
        reversed,
      } = this;

      const tokens = Object.values(this.coinsInfo)
          .filter(coin => (
              coin.mcap >= mCapFrom &&
              coin.mcap <= mCapTo &&
              coin.volumeDay >= volumeFrom &&
              coin.volumeDay <= volumeTo
          ));

      let sortedArray = this.sorting(tokens, sortBy, sortType);

      if (searchText && searchText.length) {
        sortedArray = this.filteredSearchResult(sortedArray, searchText);
      }

      return reversed ? sortedArray.reverse() : sortedArray;
    },

    totalTokens() {
      return this.sortedTokensInfo.length;
    },

    totalPages() {
      return Math.ceil(this.totalTokens / this.size);
    },

    columns() {
      return this.filters.visibleColumnsFilters;
    },
  },

  methods: {
    setSize() {
      const rowHeight = this.$refs.row[0].clientHeight;

      this.size = Math.max(Math.floor((this.$refs.chartContent.clientHeight - rowHeight / 2) / rowHeight) - 1, 1);
      this.page = 0;
    },

    sortTable(sortBy) {
      this.reversed = this.sortBy !== sortBy ? false : !this.reversed;
      this.sortBy = sortBy;
    },

    setGrowthDirection(number) {
      if (!number) {
        return '';
      }

      return number > 0 ? 'upward' : 'downward';
    },

    saveState() {
      localStorage.setItem('tableColumnsTokensList', JSON.stringify(Array.from(this.parameters.visibleColumnsFilters)));
    },

    restoreState() {
      const savedColumns = localStorage.getItem('tableColumnsTokensList');
      if (savedColumns) {
        this.defaultFilters.visibleColumnsFilters = new Map(JSON.parse(savedColumns));
      } else {
        this.defaultFilters.visibleColumns = [...this.defaultFilters.visibleColumnsFilters.keys()];
      }
    },

    search(text) {
      this.searchText = text;
    },

    filteredSearchResult(arr, text) {
      const filterLowered = text.toLowerCase();

      return arr.filter(item => {
        const { displayName } = item;
        const itemLowered = {
          displayName: displayName.toLowerCase(),
        };

        return Object.values(itemLowered).some(value => value.includes(filterLowered));
      });
    },

    getMinMaxValue() {
      const marketCaps = [];
      const volumes = [];

      const assets = Object.values(this.coinsInfo);

      for (const coin of assets) {
        marketCaps.push(coin.mcap);
        volumes.push(coin.volumeDay);
      }

      this.defaultFilters.tokenMarketCapRange[0] = Math.ceil(Math.min(...marketCaps));
      this.defaultFilters.tokenMarketCapRange[1] = Math.ceil(Math.max(...marketCaps));
      this.defaultFilters.tokenLiquidityRange[0] = Math.ceil(Math.min(...volumes));
      this.defaultFilters.tokenLiquidityRange[1] = Math.ceil(Math.max(...volumes));

      this.filters = this.deepClone(this.defaultFilters);
      this.parameters = { ...this.parameters, ...this.deepClone(this.filters) };
    },
  },

  created() {
    this.getMinMaxValue();
    this.restoreState();
    const parameters = this.deepClone(this.defaultFilters);
    this.parameters = { ...this.defaultParameters, ...parameters };
    this.filters = this.deepClone(this.defaultFilters);
  },

  mounted() {
    this.$nextTick(() => {
      this.setSize();
    });
  }
};
</script>

<style lang="scss" scoped src="@/assets/css/secondary-table.scss" />
<style lang="scss" scoped src="@/assets/css/filters.scss" />
<style lang="scss" scoped>
.custom-pagination {
  padding: 0 15px 0 30px;
}

.ranges {
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px 15px;
  gap: 5px;
}
</style>