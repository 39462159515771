<template>
  <div class="chart-wrapper">
    <div class="chart__header">
      <h2>Arbitrage Calculator History</h2>
      <button class="chart__close-btn" @click="$emit('close')">
        <svg
            fill="none"
            height="14"
            viewBox="0 0 14 14"
            width="14"
            xmlns="http://www.w3.org/2000/svg"
        >
          <path
              d="M1.25 1.25L6.99995 6.99995M6.99995 6.99995L12.7499 1.25M6.99995 6.99995L12.75 12.7498M6.99995 6.99995L1.2501 12.7498"
              stroke="#92939C"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
          />
        </svg>
      </button>
    </div>
    <div class="chart-filters">
      <div class="selected">
        <div class="exchange">
          <LazyImg :id="currencyArbitrageItem.tokenId" :is-token="true" class="logo" />
          <div>
            <span class="exchange__name">{{ currencyArbitrageItem.tokenFullName }}</span>
          </div>
        </div>
        <div class="exchange">
          <LazyImg :id="currencyArbitrageItem.exchangeSellId" :is-token="false" class="logo" />
          <div>
            <span class="exchange__name">{{ currencyArbitrageItem.exchangeSellName }}</span>
          </div>
        </div>
        <div v-if="currencyArbitrageItem.exchangeSellName !== currencyArbitrageItem.exchangeBuyName" class="exchange">
          <LazyImg :id="currencyArbitrageItem.exchangeBuyId" :is-token="false" class="logo" />
          <div>
            <span class="exchange__name">{{ currencyArbitrageItem.exchangeBuyName }}</span>
          </div>
        </div>
      </div>
      <div class="chart-filter">
        <Select
            ref="type"
            :default-option-value="defaultType"
            :options="type"
            title="Type"
            @emptySelect="parameters.type = defaultType"
            @selected="updateParameter($event, 'type')"
        />
      </div>
      <div class="dimensions">
        <button
            v-for="([value, text], index) in dimensions"
            :key="index"
            :class="{active: parameters.dimension === value}"
            class="dimensions__button"
            @click="updateParameter(value, 'dimension')"
        >
          {{ text }}
        </button>
      </div>
    </div>

    <div class="chart-content">
      <div v-show="showPreloader" class="main-preloader widget-preloader chart-preloader">
        <Loader
            ref="preloader"
            :animationData="require('@/assets/images/Loader.json')"
            :autoPlay="true"
            :loop="true"
            :speed="1"
        />
      </div>

      <NoData
          v-show="!showPreloader && emptyData"
          text="There seems to be no data. <br /> Try other filters."
      />

      <highcharts
          ref="chart"
          :options="chartOptions"
          :style="{ width: '100%', height: '100%' }"
          constructor-type="stockChart"
      />
    </div>
  </div>
</template>

<script>
import oneLine from '@/mixins/chart-settings/oneLine';
import debounce from "@/mixins/debounce";
import { mapActions } from 'vuex';

export default {
  name: 'FundingRateHistory',

  mixins: [debounce, oneLine],

  props: {
    currencyArbitrageItem: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    showPreloader: true,
    parameters: {},
    emptyData: false,
  }),

  computed: {
    defaultParameters() {
      return {
        dimension: this.defaultDimension,
        type: this.defaultType,
      };
    },

    defaultDimension() {
      return this.dimensions.keys().next().value;
    },

    dimensions() {
      return new Map([
        ['HOUR_1', 'Hour'],
        ['DAY_1', 'Day'],
        ['WEEK_1', 'Week'],
      ]);
    },

    defaultType() {
      return this.type.keys().next().value;
    },

    type() {
      return new Map([
        ['profit', 'Profit'],
        ['spread', 'Spread'],
      ]);
    }
  },

  methods: {
    ...mapActions({
      getArbitrageDataHistory: 'charts/getArbitrageDataHistory',
    }),

    updateParameter(parameter, name) {
      this.parameters[name] = parameter;
      this.getArbitrageHistory();
    },

    async getArbitrageHistory() {

      const {
        shortType,
        longType,
        shortExchangeId,
        longExchangeId,
        baseAssetId,
        quoteAssetId
      } = this.currencyArbitrageItem;

      const params = {
        shortType,
        longType,
        shortExchangeId,
        longExchangeId,
        baseAssetId,
        quoteAssetId
      };

      this.debounce(async () => {
        this.showPreloader = true;
        this.emptyData = false;
        try {
          const res = await this.getArbitrageDataHistory({...params, ...this.parameters});

          const data = res.map(historyItem => {
            return [historyItem.time, historyItem[this.parameters.type]];
          });

          this.chartOptions.series[0].data = data;
          const plotLine = this.chartOptions.yAxis[1].plotLines[0];
          plotLine.value = res.at(-1)[1];

          this.emptyData = false;
        } catch (e) {
          console.log(e);
          this.emptyData = true;
        } finally {
          this.showPreloader = false;
        }
      }, 500);
    },
  },

  created() {
    this.parameters = { ...this.defaultParameters };
    this.getArbitrageHistory();
    this.chartOptions.chart.height = null;
    this.chartOptions.series[0].type = 'line';
    this.chartOptions.plotOptions.series.marker.radius = 3;
    this.chartOptions.plotOptions.series.marker.lineColor = 'var(--accent)';
    this.chartOptions.plotOptions.series.states.hover.halo.size = 9;
  }
};
</script>

<style lang="scss" scoped>
.chart-wrapper {
  cursor: default;
}

.chart {
  &__header {
    position: relative;
    padding: 20px;
  }

  &__close-btn {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);

    path {
      transition: stroke 0.3s;
    }

    &:hover {
      path {
        stroke: var(--lightColor);
      }
    }
  }
}

.chart-filters {
  justify-content: space-between;

  .selected {
    display: flex;
    gap: 15px;
  }
}

.exchange {
  display: flex;
  align-items: center;

  .logo {
    margin-right: 10px;
    border-radius: 50%;
    width: 32px;
    height: 32px;
  }
}

.dimensions {
  border-radius: 8px;
  padding: 4px;
  background-color: #393939;

  &__button {
    transition: background-color 0.2s;
    border-radius: 6px;
    padding: 4px 8px;

    font-size: 0.75rem;

    &:not(:last-child) {
      margin-right: 4px;
    }

    &.active {
      background-color: #2e2d2d;
    }
  }
}

.chart-filter {
  margin-left: auto;
}
</style>