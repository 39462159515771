<template>
  <div class="top-statistic">
    <SectionHeader
        :is-filters-open="isFiltersOpen"
        :is-deletable="isDeletable"
        :show-copy-chart-to-pd="showCopyToPd"
        :stretchable="false"
        :title="title"
        :type="type"
        info-text="List of tokens sorted by percentage of price decrease/increase"
        @copyChartToPD="$emit('copyChartToPD', {})"
        @removeItem="removeItem"
        @setStatic="$emit('setStatic', $event)"
        @setStretchPosition="setStretchPosition"
        @toggleFiltersVisibility="isFiltersOpenChange($event)"
    />

    <transition name="expand-filters">
      <div v-show="isFiltersOpen" class="chart-filters max-screen-width">
        <div class="chart-filter">
          <Select
              ref="marketCap"
              :default-option-value="parametersForSelects.marketCapRange || defaultParameters.marketCapRange"
              :options="marketCapRanges"
              title="Market Cap"
              @selected="updateParameter($event, 'marketCapRange')"
          />
        </div>

        <div class="chart-filter">
          <Select
              ref="sort"
              :default-option-value="parametersForSelects.symbolStatisticType || defaultSort"
              :options="sort"
              title="Type"
              @selected="updateParameter($event,'symbolStatisticType')"
          />
        </div>

        <div class="chart-filter reset">
          <button :disabled="resetDisabled" class="reset" @click="resetParameters">
            Reset
          </button>
        </div>
      </div>
    </transition>

    <div ref="chartContent" class="chart-content">
      <TopTable
          href="table"
          v-if="visibleData.length"
          ref="table"
          :page="page"
          :size="size"
          :sortedBy="sortedBy"
          :top-data="visibleData"
          :totalElements="totalElements"
          :totalPages="totalPages"
          @pageChanged="page = $event"
          @sortTable="sortedBy = $event"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import debounce from '@/mixins/debounce';
import TopTable from '@/components/tables/TopStats';
import propsParameters from '@/mixins/propsParameters';

export default {
  name: 'SymbolStatistics',

  mixins: [debounce, propsParameters],

  props: {

    isResizeble: Boolean,
  },

  components: {
    TopTable,
  },

  data: () => ({
    page: 0,
    size: 4,
    sortedBy: 'pricePercentChangeDay',
    symbolStatistics: {},
    parameters: {},
    wsParameters: null,
    resetDisabled: true,
    isFiltersOpen: true,
    type: "",
  }),

  watch: {
    isResizeble() {
      this.setSize();
    },
  },

  computed: {
    ...mapGetters({
      coinsInfo: 'filters/coinsInfo',
    }),

    title() {
      const type = this.sort.get(this.parameters.symbolStatisticType || this.parametersForSelects.symbolStatisticType || this.defaultSort);
      return `Top ${type}`;
    },

    defaultParameters() {
      return {
        page: 0,
        size: 4,
        symbolStatisticType: this.defaultSort,
        marketCapRange: this.defaultMarketCapRange,
        sort: 'DAY_PERCENTAGE'
      };
    },

    defaultMarketCapRange() {
      return this.marketCapRanges.keys().next().value;
    },

    marketCapRanges() {
      return new Map([
        ['0-Infinity', 'All'],
        ['1-1e6', '1-100m'],
        ['500e6-1e9', '500m-1b'],
        ['1e9-10e9', '1b-10b'],
        ['10e9-Infinity', '10b+'],
      ]);
    },

    defaultSort() {
      return this.sort.keys().next().value;
    },

    sort() {
      return new Map([['PERFORMER', 'Performer'], ['LOSER', 'Loser']]);
    },

    sortedAndFilteredCoins() {
      const ranges = this.parameters.marketCapRange.split('-');
      const from = +ranges[0];
      const to = +ranges[1];
      const filtered = Object.values(this.coinsInfo)
          .filter(coin => coin.mcap >= from && coin.mcap <= to)
          .sort((a, b) => b[this.sortedBy] - a[this.sortedBy]);

      return this.parameters.symbolStatisticType === 'LOSER' ? filtered.reverse() : filtered;
    },

    visibleData() {
      const { page, size } = this;

      return this.sortedAndFilteredCoins.slice(page * size, (page + 1) * size);
    },

    totalElements() {
      return this.sortedAndFilteredCoins.length || 0;
    },

    totalPages() {
      return Math.ceil(this.totalElements / this.size);
    },
  },

  methods: {
    setSize() {
      const rows = this.$refs.table.$refs.row;
      if (!rows) return;
      const rowHeight = rows[0].clientHeight;

      this.size = Math.floor((this.$refs.chartContent.clientHeight - rowHeight / 2) / rowHeight) - 1;
      this.page = 0;
    },

    isFiltersOpenChange(event) {
      this.isFiltersOpen = event;
      // const type = this.propsParameters.symbolStatisticType.toLowerCase()[0].toUpperCase() + this.propsParameters.symbolStatisticType.toLowerCase().slice(1)
      // this.type = !event ? type : ""
    },

    convertCompactedNumber(compactedNumber) {
      let value = parseInt(compactedNumber);
      const suffix = compactedNumber.slice(-1);

      switch (suffix) {
        case 'b':
          value *= 1e9;
          break;
        case 'm':
          value *= 1e6;
          break;
        case 'k':
          value *= 1e3;
          break;
        default:
          break;
      }
      return value;
    },

    updateParameter(event, name) {
      this.resetDisabled = false;
      this.page = 0;
      this.parameters[name] = event;
    },

    async resetParameters() {
      this.parameters = {
        ...this.defaultParameters,
        ...this.parametersForSelects,
      };

      this.resetDisabled = true;
      this.page = 0;
      this.sortedBy = 'pricePercentChangeDay';
      this.$refs.marketCap.reset();
      this.$refs.sort.reset();
    },

    setStretchPosition(isStretched) {
      this.$emit('setStretchPosition', isStretched);
    },

    getClassName(price, prevPrice) {
      if (price > prevPrice) {
        return 'color-up';
      } else if (price < prevPrice) {
        return 'color-down';
      }

      return '';
    },

    removeItem() {
      this.$emit('removeItem', null);
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.setSize();
    })
  }
};
</script>

<style scoped>
.top-statistic {
  display: flex;
  flex-direction: column;
  min-width: min-content;
  height: 100%;
}

/* .chart-content { */
/*   height: calc(100% - 188px); */
/* } */
</style>
