/* eslint-disable no-unreachable */
import { SciChartJSDarkv2Theme } from 'scichart/Charting/Themes/SciChartJSDarkv2Theme';
import { NumberRange } from 'scichart/Core/NumberRange';
import { FastMountainRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/FastMountainRenderableSeries';
import { FastColumnRenderableSeries } from 'scichart/Charting/Visuals/RenderableSeries/FastColumnRenderableSeries';
import { XyDataSeries } from 'scichart/Charting/Model/XyDataSeries';
import { NumericAxis } from 'scichart/Charting/Visuals/Axis/NumericAxis';
import { ENumericFormat } from 'scichart/types/NumericFormat';
import { EAxisAlignment } from 'scichart/types/AxisAlignment';
import { SciChartSurface } from 'scichart/Charting/Visuals/SciChartSurface';
import { SciChartDefaults } from 'scichart/Charting/Visuals/SciChartDefaults';
import { CursorModifier } from '@/modifiers/walls-chart/CursorModifier';
import { MountainPaletteProvider } from '@/modifiers/walls-chart/DepthPaletteProvider';
import { ColumnPaletteProvider } from '@/modifiers/walls-chart/PaletteProvider';
import { TickProvider } from '@/modifiers/walls-chart/TickProvider';
import { AxisMarkerAnnotation } from "scichart/Charting/Visuals/Annotations/AxisMarkerAnnotation";
import { ECoordinateMode } from 'scichart/Charting/Visuals/Annotations/AnnotationBase';
import { LineAnnotation } from 'scichart/Charting/Visuals/Annotations/LineAnnotation';
import { IDs } from '@/modifiers/walls-chart/IDs';
import { EResamplingMode } from 'scichart/Charting/Numerics/Resamplers/ResamplingMode';
import { makeCompactNumber } from '@/helpers';

const wallsModifier = {
  data: () => ({
    midMarketPrice: null,
    sciChartParams: {},
    wallsMinAsk: 0,
    wallsMaxBid: 0,
    uniqueID: +new Date(),
    wallsData: {},
    orderbookWallsData: {},
    zoomPosition: 0,
    initialRange: {
      xAxis: { min: 0, max: 0 },
      yAxis: { min: 0, max: 0 }
    },
  }),

  computed: {
    theme: () => ({
      ...new SciChartJSDarkv2Theme(),
      tickTextBrush: '#fff',
      sciChartBackground: 'var(--mainBGColor)',
      loadingAnimationBackground: 'transparent',
      loadingAnimationForeground: 'transparent',
    }),

    commonAxisSettings: () => ({
      drawMajorGridLines: false,
      drawMinorGridLines: false,
      drawMinorTickLines: false,
      drawMajorBands: false,
      labelFormat: ENumericFormat.Decimal,
      cursorLabelFormat: ENumericFormat.Decimal,

      axisBorder: {
        color: '#1e1e1e'
      }
    }),

    xAxis() {
      return this.sciChartParams.sciChartSurface.xAxes.get(0);
    },

    yAxisLeft() {
      return this.sciChartParams.sciChartSurface.yAxes.get(1);
    },

    yAxisRight() {
      return this.sciChartParams.sciChartSurface.yAxes.get(0);
    },

    labelPrecision() {
      if (!this.rounding.size) {
        return 0;
      }

      const labelPrecision = this.rounding.get(this.parameters.roundingIndex).toString().split('.')[1];

      return labelPrecision ? labelPrecision.length : 0;
    },
  },

  methods: {
    makeCompactNumber,

    setData(data) {
      this.connectionType = 'success';
      const orderbookWalls = JSON.parse(JSON.stringify(data));

      orderbookWalls.bids.reverse();

      if (orderbookWalls.asks.length > 50 && orderbookWalls.bids.length > 50) {
        const shorterLength = Math.min(orderbookWalls.asks.length, orderbookWalls.bids.length);
        orderbookWalls.asks.length = shorterLength;
        orderbookWalls.bids.length = shorterLength;
      }

      const cumulate = (acc, value) => {
        const [price, amount] = value;
        const prevValue = (acc.at(-1) || [0, 0])[1];

        return [...acc, [price, Math.round((prevValue + amount) * 100) / 100]];
      };
      const bids = orderbookWalls.bids.reduce((acc, bid) => cumulate(acc, bid), []);
      const asks = orderbookWalls.asks.reduce((acc, ask) => cumulate(acc, ask), []);

      this.wallsData = {
        ...orderbookWalls,
        bids: bids,
        asks: asks,

        ticks: {
          ...orderbookWalls.ticks,

          bid: orderbookWalls.ticks.bid.flatMap(tick => {
            const index = orderbookWalls.bids.findIndex(bid => bid[1].toFixed(2) === tick.toFixed(2));

            return index !== -1 ? bids[index][1] : [];
          }).sort((a, b) => a - b),

          ask: orderbookWalls.ticks.ask.flatMap(tick => {
            const index = orderbookWalls.asks.findIndex(ask => ask[1].toFixed(2) === tick.toFixed(2));

            return index !== -1 ? asks[index][1] : [];
          }).sort((a, b) => a - b),
        }
      };
      this.orderbookWallsData = {
        ...orderbookWalls,
        asks: orderbookWalls.asks.map(ask => [ask[0], -ask[1]]),

        ticks: {
          ...orderbookWalls.ticks,
          ask: orderbookWalls.ticks.ask.map(ask => -ask),
        }
      };

      this.debounce(() => {
        this.connectionType = 'warning';

        this.debounce(() => {
          this.connectionType = 'error';
        }, 10000);
      }, 5000);
    },

    updateSciChart() {
      const { sciChartSurface } = this.sciChartParams;

      if (sciChartSurface) {
        const suspendUpdates = sciChartSurface.suspendUpdates();
        const cursorModifier = sciChartSurface.chartModifiers.getById(IDs.cursorModifier);

        this.setMidMarketPrice();
        this.zoomChart(0);
        this.setTicks();
        this.formatLabels();

        if (this.parameters.view) {
          this.removeAnnotations();
          this.addAnnotations();
        }

        cursorModifier.midMarketPrice = this.midMarketPrice;
        sciChartSurface.modifierAnnotations.getById(IDs.midPrice).x1 = this.midMarketPrice;
        cursorModifier.update();
        suspendUpdates.resume();
      }
    },

    createAxes() {
      const { sciChartSurface, wasmContext } = this.sciChartParams;
      const xAxis = new NumericAxis(wasmContext, {
        ...this.commonAxisSettings,
        labelPrecision: this.labelPrecision,
        cursorLabelPrecision: this.labelPrecision,
        axisBorder: {
          ...this.commonAxisSettings.axisBorder,
          borderTop: 1,
        },
      });
      const yAxisRight = new NumericAxis(wasmContext, {
        ...this.commonAxisSettings,
        labelPrecision: 2,
        cursorLabelPrecision: 2,
        axisBorder: {
          ...this.commonAxisSettings.axisBorder,
          borderLeft: 1,
        },
      });

      sciChartSurface.xAxes.add(xAxis);
      sciChartSurface.yAxes.add(yAxisRight);

      if (!this.parameters.view) {
        yAxisRight.drawMajorGridLines = true;
        yAxisRight.majorGridLineStyle = { color: '#343436' };
      } else {
        const yAxisLeft = new NumericAxis(wasmContext, {
          ...this.commonAxisSettings,
          labelPrecision: 2,
          cursorLabelPrecision: 2,
          id: IDs.left,
          axisAlignment: EAxisAlignment.Left,

          axisBorder: {
            ...this.commonAxisSettings.axisBorder,
            borderRight: 1,
          },
        });

        sciChartSurface.yAxes.add(yAxisLeft);
      }

      this.formatLabels();
    },

    createSeries(wasmContext) {
      const { yValuesBids, xValuesBids, yValuesAsks, xValuesAsks } = this.formatSeries();
      this.setVisibleRanges(xValuesBids, xValuesAsks, yValuesBids, yValuesAsks);

      const bidsSeries = new FastMountainRenderableSeries(wasmContext, {
        dataSeries: new XyDataSeries(wasmContext, {
          xValues: xValuesBids,
          yValues: yValuesBids
        }),
        dataIsSortedInX: true,
        resamplingMode: EResamplingMode.Auto,
        stroke: '#02c9bf',
        strokeThickness: 2,
        id: IDs.bidsSeries,
        isDigitalLine: true,
        paletteProvider: new MountainPaletteProvider({
          fill: 'rgba(0, 116, 110, 0.5)',
          fillIntersection: 'rgba(2, 201, 191, 0.5)',
          type: 'bids',
          intersection: {
            from: this.initialRange.xAxis.min,
            to: this.initialRange.xAxis.max,
          }
        }),
      });
      const asksSeries = new FastMountainRenderableSeries(wasmContext, {
        dataSeries: new XyDataSeries(wasmContext, {
          xValues: xValuesAsks,
          yValues: yValuesAsks
        }),
        dataIsSortedInX: true,
        resamplingMode: EResamplingMode.Auto,
        stroke: '#f27870',
        strokeThickness: 2,
        id: IDs.asksSeries,
        isDigitalLine: true,
        paletteProvider: new MountainPaletteProvider({
          fill: 'rgba(218, 88, 79, 0.5)',
          fillIntersection: 'rgba(242, 120, 112, 0.5)',
          type: 'asks',
          intersection: {
            from: this.initialRange.xAxis.min,
            to: this.initialRange.xAxis.max,
          }
        }),
      });

      return {
        asksSeries,
        bidsSeries,
      };
    },

    createWallsSeries(wasmContext) {
      const { yValuesBids, xValuesBids, yValuesAsks, xValuesAsks } = this.formatSeries();
      const { ask, bid } = this.orderbookWallsData.ticks;
      this.setVisibleRanges(xValuesBids, xValuesAsks, yValuesBids, yValuesAsks);

      const bidsSeries = new FastColumnRenderableSeries(wasmContext, {
        strokeThickness: 0,
        id: IDs.bidsSeries,
        dataSeries: new XyDataSeries(wasmContext, {
          xValues: xValuesBids,
          yValues: yValuesBids
        }),
        stroke: 'rgba(0, 116, 110, 0.5)',
        paletteProvider: new ColumnPaletteProvider({
          fill: 'rgba(0, 116, 110, 0.5)',
          fillHover: 'rgba(2, 201, 191, 0.5)',
          yValues: bid,
        })
      });
      const asksSeries = new FastColumnRenderableSeries(wasmContext, {
        strokeThickness: 0,
        id: IDs.asksSeries,
        dataSeries: new XyDataSeries(wasmContext, {
          xValues: xValuesAsks,
          yValues: yValuesAsks
        }),
        stroke: 'rgba(218, 88, 79, 0.5)',
        paletteProvider: new ColumnPaletteProvider({
          fill: 'rgba(218, 88, 79, 0.5)',
          fillHover: 'rgba(218, 88, 79, 0.5)',
          yValues: ask,
        })
      });

      return {
        asksSeries,
        bidsSeries,
      };
    },

    setVisibleRanges(xBids, xAsks, yBids, yAsks) {
      const min = this.midMarketPrice - xBids.at(-1);
      const max = xAsks.at(-1) - this.midMarketPrice;
      const minRange = max > min ? min : max;
      const maxBid = Math.max(...yBids);
      const maxAsk = this.parameters.view ? Math.max(...yAsks) : Math.abs(Math.min(...yAsks));
      let maxY = (maxBid > maxAsk ? maxBid : maxAsk) * 1.1;

      this.initialRange = {
        xAxis: {
          min: this.midMarketPrice - minRange,
          max: this.midMarketPrice + minRange,
        },
        yAxis: {
          min: this.parameters.view ? 0 : -maxY,
          max: maxY
        }
      };
      const yVisibleRange = new NumberRange(this.initialRange.yAxis.min, maxY);
      this.xAxis.visibleRange = new NumberRange(this.initialRange.xAxis.min, this.initialRange.xAxis.max);
      this.yAxisRight.visibleRange = yVisibleRange;

      if (this.parameters.view) {
        this.yAxisLeft.visibleRange = yVisibleRange;
      }
    },

    zoomChart(position) {
      if (position === 1 && this.zoomPosition >= 5) {
        return;
      } else if (position === -1 && this.zoomPosition <= 0) {
        this.sciChartParams.sciChartSurface.zoomExtents();

        this.yAxisLeft.visibleRange = new NumberRange(this.initialRange.yAxis.min, this.initialRange.yAxis.max);
        this.yAxisRight.visibleRange = new NumberRange(this.initialRange.yAxis.min, this.initialRange.yAxis.max);
        this.xAxis.visibleRange = new NumberRange(this.initialRange.xAxis.min, this.initialRange.xAxis.max);

        return;
      }

      this.zoomPosition += position;

      const { sciChartSurface, wasmContext } = this.sciChartParams;
      const { yValuesBids, xValuesBids, yValuesAsks, xValuesAsks, asksData, bidsData } = this.formatSeries();
      const cursorModifier = sciChartSurface.chartModifiers.getById(IDs.cursorModifier);
      const bidsSeries = sciChartSurface.renderableSeries.getById(IDs.bidsSeries);
      const asksSeries = sciChartSurface.renderableSeries.getById(IDs.asksSeries);

      bidsSeries.dataSeries = new XyDataSeries(wasmContext, {
        xValues: xValuesBids,
        yValues: yValuesBids,
      });

      asksSeries.dataSeries = new XyDataSeries(wasmContext, {
        xValues: xValuesAsks,
        yValues: yValuesAsks,
      });

      if (this.parameters.view) {
        bidsSeries.paletteProvider.from = 0;
        bidsSeries.paletteProvider.to = this.initialRange.xAxis.max;
        asksSeries.paletteProvider.from = 0;
        asksSeries.paletteProvider.to = this.initialRange.xAxis.max;
      } else {
        const { ask, bid } = this.orderbookWallsData.ticks;

        asksSeries.paletteProvider.from = -1;
        asksSeries.paletteProvider.to = -1;
        asksSeries.paletteProvider.yValues = ask;
        bidsSeries.paletteProvider.yValues = bid;
      }

      this.setVisibleRanges(xValuesBids, xValuesAsks, yValuesBids, yValuesAsks);
      cursorModifier.asksData = asksData;
      cursorModifier.bidsData = bidsData;
      cursorModifier.intersection = {
        from: 0,
        to: this.initialRange.xAxis.max,
      };
    },

    setTicks() {
      const { wasmContext } = this.sciChartParams;
      const { price, ask, bid } = this.wallsData.ticks;

      this.xAxis.tickProvider = new TickProvider(wasmContext, price);

      if (this.parameters.view) {
        this.yAxisLeft.tickProvider = new TickProvider(wasmContext, bid);
        this.yAxisRight.tickProvider = new TickProvider(wasmContext, ask);
      }
    },

    formatLabel(label) {
      const fractionDigitsAmount = label >= 1000 ? 2 : 0;

      return this.makeCompactNumber(label, fractionDigitsAmount, fractionDigitsAmount);
    },

    formatLabels() {
      this.yAxisRight.labelProvider.formatLabel = (label) => this.formatLabel(label);
      this.yAxisRight.labelProvider.formatCursorLabel = (label) => this.formatLabel(label);

      if (this.parameters.view) {
        this.yAxisLeft.labelProvider.formatLabel = (label) => this.formatLabel(label);
        this.yAxisLeft.labelProvider.formatCursorLabel = (label) => this.formatLabel(label);
      }
    },

    formatSeries() {
      const {
        asks,
        bids
      } = JSON.parse(JSON.stringify(this.parameters.view ? this.wallsData : this.orderbookWallsData));
      const shorterLength = asks.length > bids.length ? bids.length : asks.length;
      const length = Math.round(shorterLength * (1 - this.zoomPosition / 10));
      asks.length = length;
      bids.length = length;

      let bidPriceRange = this.midMarketPrice - bids.at(-1)[0];
      let askPriceRange = asks.at(-1)[0] - this.midMarketPrice;
      const minRange = bidPriceRange > askPriceRange ? askPriceRange : bidPriceRange;
      bidPriceRange = this.midMarketPrice - minRange;
      askPriceRange = this.midMarketPrice + minRange;

      const yValuesBids = [];
      const xValuesBids = [];
      const yValuesAsks = [];
      const xValuesAsks = [];
      const bidsData = [];
      const asksData = [];

      bids.forEach((bid) => {
        if (bid[0] < bidPriceRange) return;

        yValuesBids.push(bid[1]);
        xValuesBids.push(bid[0]);
        bidsData.push(bid);
      });

      asks.forEach((ask) => {
        if (ask[0] > askPriceRange) return;

        yValuesAsks.push(ask[1]);
        xValuesAsks.push(ask[0]);
        asksData.push(ask);
      }, 0);

      return {
        yValuesBids,
        xValuesBids,
        yValuesAsks,
        xValuesAsks,
        bidsData,
        asksData,
      };
    },

    setMidMarketPrice() {
      this.midMarketPrice = this.wallsData.indexPrice;
    },

    addAnnotations() {
      const { asks, bids, ticks } = this.wallsData;
      const { price, ask, bid } = ticks;
      const lastAskPrice = asks.at(-1)[0];
      const lastBidPrice = bids.at(-1)[0];

      price.forEach(tick => {
        this.sciChartParams.sciChartSurface.annotations.add(
          new LineAnnotation({
            stroke: '#242424',
            strokeThickness: 1,
            xCoordinateMode: ECoordinateMode.DataValue,
            x1: tick,
            x2: tick,
            id: IDs.tickAnnotation,
            yCoordinateMode: ECoordinateMode.Pixel,
            y1: 0,
            y2: 100000,
            opacity: 0.7
          })
        );
      });

      ask.forEach(tick => {
        this.sciChartParams.sciChartSurface.annotations.add(
          new LineAnnotation({
            stroke: "#242424",
            strokeThickness: 1,
            xCoordinateMode: ECoordinateMode.DataValue,
            x1: this.midMarketPrice,
            x2: lastAskPrice,
            id: IDs.tickAnnotation,
            yCoordinateMode: ECoordinateMode.DataValue,
            y1: tick,
            y2: tick,
            opacity: 0.7
          })
        );
      });

      bid.forEach(tick => {
        this.sciChartParams.sciChartSurface.annotations.add(
          new LineAnnotation({
            stroke: "#242424",
            strokeThickness: 1,
            xCoordinateMode: ECoordinateMode.DataValue,
            x1: lastBidPrice,
            x2: this.midMarketPrice,
            id: IDs.tickAnnotation,
            yCoordinateMode: ECoordinateMode.DataValue,
            y1: tick,
            y2: tick,
            opacity: 0.7
          })
        );
      });
    },

    removeAnnotations() {
      this.sciChartParams.sciChartSurface.annotations.items = this.sciChartParams.sciChartSurface.annotations.items.filter(annotation => (
        annotation.id !== IDs.tickAnnotation
      ));
    },

    async initSciChart() {
      if (!this.$refs.sciChart) {
        return;
      }

      const { sciChartSurface, wasmContext } = await SciChartSurface.create(this.chartID, { theme: this.theme });
      this.sciChartParams = { sciChartSurface, wasmContext };
      this.initialRange = {
        xAxis: {
          min: 0,
          max: 0,
        },
        yAxis: {
          min: 0,
          max: 0,
        },
      };

      this.zoomPosition = 0;
      this.setMidMarketPrice();
      this.createAxes();

      const { bidsSeries, asksSeries } = this.parameters.view
        ? this.createSeries(wasmContext)
        : this.createWallsSeries(wasmContext);
      const wallsModifier = new CursorModifier({
        asksData: this.wallsData.asks,
        bidsData: this.wallsData.bids,
        uniqueID: this.uniqueID,
        bidsSeries,
        asksSeries,
        id: IDs.cursorModifier,
        intersection: {
          from: this.initialRange.xAxis.min,
          to: this.initialRange.xAxis.max,
        },
        showVerticalLineAnnotation: !this.parameters.view,
        midLine: this.midMarketPrice,
        crosshairStrokeDashArray: [3, 1],
        crosshairStrokeThickness: 2,
      });

      sciChartSurface.renderableSeries.add(bidsSeries, asksSeries);
      sciChartSurface.chartModifiers.add(wallsModifier);

      if (this.parameters.view) {
        sciChartSurface.annotations.add(
          new AxisMarkerAnnotation({
            yAxisId: IDs.left,
            id: IDs.bidsAnnotation,
            y1: 0,
            fontSize: 13,
            backgroundColor: '#02c9bf',
            isEditable: true,
            opacity: 0,
          }),
          new AxisMarkerAnnotation({
            y1: 0,
            id: IDs.asksAnnotation,
            isEditable: true,
            fontSize: 13,
            backgroundColor: '#f27870',
            opacity: 0,
          }),
        );

        this.addAnnotations();
      } else {
        sciChartSurface.annotations.add(
          new LineAnnotation({
            stroke: '#1e1e1e',
            strokeThickness: 2,
            xCoordinateMode: ECoordinateMode.Relative,
            x1: 0,
            x2: 1,
            id: IDs.tickAnnotation,
            yCoordinateMode: ECoordinateMode.DataValue,
            y1: 0,
            y2: 0,
            opacity: 1
          })
        );
      }

      this.setTicks();
    },
  },

  created() {
    SciChartDefaults.performanceWarnings = false;

    SciChartSurface.prototype.doDrawingLoop = function () {
      const context = this.renderSurface.getRenderContext();

      try {
        // console.log("Drawing ", this.domChartRoot.id);
        this.sciChartRenderer.render(context);
      } catch {
        // console.error("Error from chart in div ".concat(this.domChartRoot.id), err);
      }
    };

    SciChartSurface.prototype.updateWatermark = function () {
      this.watermarkPropertyPosition.x = -1000;
      this.watermarkPropertyPosition.y = -1000;
      this.watermarkProperties.SetPosition(this.watermarkPropertyPosition);
      this.webAssemblyContext2D.SCRTSetWaterMarkProperties(this.watermarkProperties);
    };
  }
};

export default wallsModifier;
