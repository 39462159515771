import { mapGetters } from 'vuex';

export default {
  data() {
    const _this = this;

    return {
      chartOptions: {
        credits: {
          enabled: true
        },
        // colors: ['transparent', '#5d5bda', '#fff', '#f3ba2f', '#11a9bc', '#005cf4',],
        textShadow: 'unset',
        series: [
          {
            type: 'sunburst',
            borderColor: 'var(--mainBorderColor)',
            tooltip: {
              pointFormatter() {
                _this.$emit('activeExchangeChanged', this.options.exchange || 0);
                const pieTooltip = document.querySelector('.pie-tooltip');

                if (pieTooltip) {
                  const exchange = _this.exchangesInfo[this.options.exchange];
                  const exchangeImageURL = exchange && 'data:image/png;base64,' + exchange.iconBase64;
                  const span = document.createElement('span');
                  pieTooltip.innerHTML = null;

                  if (exchangeImageURL) {
                    const img = new Image(20, 20);
                    img.src = exchangeImageURL;
                    span.innerText = '\xa0: ';

                    pieTooltip.appendChild(img);
                  }

                  const base = isNaN(this.name)
                    ? this.name
                    : `${_this.base}/${_this.coinsInfo[this.name]?.symbol.toUpperCase() || this.name}`;

                  span.innerText += base + ' ' + Math.round(this.value * 100) + ' %';
                  pieTooltip.appendChild(span);
                }
                return `<div class="pie-tooltip"></div>`;
              }
            },
            dataLabels: {
              useHTML: true,
              style: {
                fontFamily: 'Inter, sans-serif',
                textOutline: 0,
                fontSize: 11,
                fontWeight: 600,
                hover: true
              }
            },
            levels: [
              {
                level: 1,
                offset: 0,
                dataLabels: {
                  filter: {
                    property: 'outerArcLength',
                    operator: '>',
                    value: 64
                  }
                }
              },
              {
                level: 2,
                colorByPoint: true,
              },
              {
                level: 3,
                colorVariation: {
                  key: 'brightness',
                  to: -0.5
                }
              },
              {
                level: 4,
                colorVariation: {
                  key: 'brightness',
                  to: 0.5
                }
              }
            ],
            data: [],
            allowTraversingTree: true,
            cursor: "pointer",
          }
        ],
        yAxis: [
          {
            visible: false,
          }
        ],
        xAxis: [
          {
            visible: false,
          }
        ],

        tooltip: {
          useHTML: true,
          style: {
            fontFamily: 'Inter, sans-serif',
            fontSize: '0.75rem',
            color: 'var(--lightColor)',
          }
        },
        plotOptions: {
          series: {
            events: {
              // click: () => {
              //   setTimeout(this.setImages, 500);
              // },
              mouseOut() {
                _this.$emit('activeExchangeChanged', 0);
              },
              // load() {
              //   console.log(545445);
              //   const innerSize = this.userOptions.plotOptions.pie.innerSize
              //
              //   this.myImage = this.renderer.image('/src/assets/images/okex.svg',
              //       this.chartWidth / 2 - innerSize / 2,
              //       this.plotTop + this.plotSizeY / 2 - innerSize / 2,
              //       innerSize,
              //       innerSize
              //   ).add();
              // },

              // redraw() {
              //   console.log(455454);
              //   let chart = this,
              //       innerSize = this.userOptions.plotOptions.pie.innerSize;
              //
              //   if (chart.myImage) {
              //     chart.myImage.attr({
              //       x: chart.chartWidth / 2 - innerSize / 2,
              //       y: chart.plotTop + chart.plotSizeY / 2 - innerSize / 2
              //     });
              //   }
              // }
            }
          },
          innerSize: 100,
          dataLabels: {
            enabled: false
          }
        },
        chart: {
          height: '100%',
          width: 345,
          backgroundColor: 'var(--mainBGColor)',
        },
        exporting: {
          enabled: false,
        },
        title: {
          text: null
        }
      }
    };
  },

  computed: {
    ...mapGetters({
      exchangesInfo: 'filters/exchangesInfo',
      coinsInfo: 'filters/coinsInfo',
    }),
  },
};
