<template>
  <div class="dex-vs-cex chart-wrapper">
    <div class="chart-header-wrap">
      <SectionHeader
          :info-text="infoText"
          :is-filters-open="isFiltersOpen"
          :is-deletable="isDeletable"
          :show-copy-chart-to-pd="showCopyToPd"
          :title="title"
          @copyChartToPD="$emit('copyChartToPD', {})"
          @removeItem="removeItem"
          @setStatic="$emit('setStatic', $event)"
          @setStretchPosition="setStretchPosition"
          @toggleFiltersVisibility="isFiltersOpen = $event"
      />

      <transition name="expand-filters">
        <div v-show="isFiltersOpen" class="chart-filters" ref="sectionFilters">
          <div class="chart-filter">
            <Select
                ref="asset"
                :default-option-value="parametersForSelects.asset || defaultAsset"
                :options="assets"
                title="Asset"
                @selected="updateParameter($event, 'asset')"
            />
          </div>

          <div class="chart-filter">
            <Select
                ref="dimension"
                :default-option-value="parametersForSelects.dimension || defaultDimension"
                :options="dimensions"
                title="Time Period"
                @selected="updateParameter($event, 'dimension')"
            />
          </div>

          <div class="chart-filter reset">
            <button :disabled="resetDisabled || showPreloader" class="reset" @click="resetParameters">
              Reset
            </button>
          </div>
        </div>
      </transition>
    </div>
    <div class="dex-vs-cex-chart chart-content">
      <div v-show="showPreloader" class="main-preloader widget-preloader chart-preloader">
        <Loader
            ref="preloader"
            :animationData="require('@/assets/images/Loader.json')"
            :autoPlay="true"
            :loop="true"
            :speed="1"
        />
      </div>
      <OneLine
          ref="dexVsCexChart"
          :is-filters-open="isFiltersOpen"
          :series-data="marketShare"
          :isResizeble="isResizeble"
      />
    </div>
  </div>
</template>

<script>
import debounce from '@/mixins/debounce';
import OneLine from '@/components/charts/OneLine';
import { mapActions } from 'vuex';
import propsParameters from '@/mixins/propsParameters';

export default {
  name: 'DexVsCex',

  components: {
    OneLine,
  },

  mixins: [debounce, propsParameters],

  props: {

    filtersProps: {
      type: Object,
    },

    isResizeble: Boolean,
  },

  data: () => ({
    parameters: {},
    marketShare: [],
    showPreloader: true,
    resetDisabled: true,
    isFiltersOpen: true,
  }),

  computed: {
    title() {
      const type = this.assets.get(this.parameters.asset || this.parametersForSelects.asset || this.defaultAsset)
      return `DEX vs CEX Marketshare ${type}`
    },

    infoText() {
      const type = this.assets.get(this.parameters.symbolStatisticType || this.parametersForSelects.asset || this.defaultAsset)
      return `The ratio of trading volumes of decentralized and centralized exchanges by ${type}.`
    },

    defaultParameters() {
      return {
        asset: this.defaultAsset,
        dimension: this.defaultDimension,
      };
    },

    defaultAsset() {
      return this.assets.keys().next().value;
    },

    assets() {
      return new Map([['spot', 'Spot'], ['futures_perpetual', 'Perpetual']]);
    },

    defaultDimension() {
      return [...this.dimensions.keys()].at(-1);
    },

    dimensions() {
      return new Map([
          ['WEEK_1', 'Week'],
          ['MONTH_1', 'Month'],
          ['YEAR_1', 'Year'],
      ])
    }
  },

  methods: {
    ...mapActions({
      getMarketShareData: 'charts/getMarketShare',
    }),

    updateParameter(event, name) {
      this.resetDisabled = false;
      this.parameters[name] = event;

      this.getMarketShare();
    },

    async resetParameters() {
      this.resetDisabled = true;
      this.parameters = {
        ...this.defaultParameters,
        ...this.parametersForSelects,
      };

      await this.getMarketShare();

      this.$refs.dimension.reset();
      this.$refs.asset.reset();
    },

    setStretchPosition() {
      this.$refs.dexVsCexChart.toggleChartFullscreen();
    },

    getMarketShare() {
      this.showPreloader = true;
      this.debounce(async () => {
        try {
          const marketShare = await this.getMarketShareData(this.parameters);
          this.marketShare = marketShare.map(item => [item.time, item.ratio]);
        } catch (e) {
          console.log(e);
        } finally {
          this.showPreloader = false;
        }
      }, 500);
    },

    removeItem() {
      this.$emit('removeItem', null);
    },
  },

  created() {
    this.getMarketShare();
  }
};
</script>

<style scoped>

</style>