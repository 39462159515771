<template>
  <div class="chart-wrapper">
    <SectionHeader
        :stretchable="false"
        :is-deletable="false"
        :info-text="'Community description'"
        :title="'Community'"
        @setStatic="$emit('setStatic', $event)"
    />
    <div class="layout-item-content">
    </div>
  </div>
</template>

<script>
import SectionHeader from "@/components/ui/section/SectionHeader.vue";
export default {
  name: "Community",

  components: {
    SectionHeader,
  },
}
</script>

<style scoped>

</style>