import { crosshair, plotLine } from '@/mixins/chart-settings/_commonSettings';
import moment from 'moment';

export default {
  data: () => ({
    chartOptions: {
      title: {
        text: null
      },

      xAxis: [
        {
          type: 'datetime',
          gridLineColor: 'var(--gridLineColor)',
          gridLineWidth: 1,
          tickPixelInterval: 160,

          crosshair: {
            ...crosshair,

            label: {
              ...crosshair.label,

              formatter(event) {
                return moment(event).format('YYYY/DD/MM hh:mm');
              }
            }
          },

          labels: {
            style: {
              fontFamily: 'Inter, sans-serif',
              color: '#fff',
            },

            formatter(event) {
              return moment(event.value).format('YYYY/DD/MM');
            },
          }
        },
      ],

      yAxis: [
        {
          title: null,
          opposite: true,
          crosshair,
        },
        {
          tickLength: 10,
          tickWidth: 1,
          gridLineWidth: 1,
          title: null,
          opposite: true,
          plotLines: [{
            ...plotLine,

            label: {
              ...plotLine.label,

              formatter() {
                return `<span>${this.options.value.toFixed(2)}%</span>`;
              },
            }
          }],
          crosshair: {
            ...crosshair,
            label: {
              ...crosshair.label,

              formatter(val) {
                return val.toFixed(2) + '%';
              }
            }
          },
          showLastLabel: true,
          endOnTick: false,
          startOnTick: false,
          gridLineColor: 'var(--gridLineColor)',
          labels: {
            align: 'center',
            x: 8,
            y: 4,
            //
            style: {
              fontFamily: 'Inter, sans-serif',
              color: '#fff',
            },

            formatter(event) {
              return event.value + '%';
            }
          },
        }
      ],

      series: [
        {
          type: 'spline',
          data: [],
          yAxis: 1,
          color: 'var(--accent)',
        }
      ],

      plotOptions: {
        series: {
          marker: {
            enabled: true,
            fillColor: 'var(--accent)',
            radius: 1,
            lineWidth: 1,
            lineColor: 'var(--mainBGColor)'
          },

          states: {
            hover: {
              lineWidth: 2,
              halo: {
                size: 0,
              }
            }
          }
        },
        spline: {
          marker: {
            enabled: false,
          },
        }
      },

      tooltip: {
        split: true,
        backgroundColor: 'var(--mainBorderColor)',

        style: {
          fontSize: 10,
          fontFamily: 'inherit',
          stroke: 'var(--textMainColor)',
        }
      },

      chart: {
        backgroundColor: 'var(--mainBGColor)',
        margin: [0, 57, 25, 0],
        backgroundPositionY: 5,
        zoomType: 'x',
        animation: true,
        panKey: 'shift',

        panning: {
          enabled: true,
          type: 'x'
        },

        resetZoomButton: {
          theme: {
            fill: 'var(--accent)',
            stroke: 'var(--accent)',

            style: {
              fontFamily: 'Inter, sans-serif',
              fontWeight: 700,
              color: '#fff',
            },

            states: {
              hover: {
                fill: '#00fff7',
                stroke: '#00fff7',
              }
            }
          },
        },
      },

      exporting: {
        enabled: false,
      },

      navigator: {
        enabled: false
      },

      scrollbar: {
        enabled: false
      },

      rangeSelector: {
        enabled: false,
      },

      legend: {
        enabled: false
      },

      noData: {
        style: {
          fontWeight: 'normal',
          fontSize: '0.75rem',
          color: '#92939c'
        }
      }
    },
  }),
};
