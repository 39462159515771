export default {
  props: {
    propsParameters: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    initialParameters: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    showCopyToPd: {
      type: Boolean,
      required: false,
      default: true,
    },

    isDeletable: {
      type: Boolean,
      required: false,
      default: false,
    }
  },

  data: () => ({
    parametersForSelects: {},
  }),

  watch: {
    parameters: {
      deep: true,
      immediate: true,
      handler(parameters) {
        this.$emit('updateFilter', parameters);
      }
    }
  },

  created() {
    if (Object.keys(this.propsParameters).length) {
      this.parameters = { ...this.propsParameters };
      this.parametersForSelects = { ...this.propsParameters };
      this.resetDisabled = false;

      return;
    }

    if (Object.keys(this.initialParameters).length) {
      const parameters = {
        ...this.defaultParameters,
        ...this.initialParameters,
      };

      this.parametersForSelects = { ...parameters };
      this.parameters = { ...parameters };
      this.resetDisabled = false;

      return;
    }

    this.parameters = { ...this.defaultParameters };
  }
};