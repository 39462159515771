<template>
  <div class="chart-wrapper">
    <div class="chart__header">
      <h2>Funding Rate History</h2>
      <button class="chart__close-btn" @click="$emit('close')">
        <svg
            fill="none"
            height="14"
            viewBox="0 0 14 14"
            width="14"
            xmlns="http://www.w3.org/2000/svg"
        >
          <path
              d="M1.25 1.25L6.99995 6.99995M6.99995 6.99995L12.7499 1.25M6.99995 6.99995L12.75 12.7498M6.99995 6.99995L1.2501 12.7498"
              stroke="#92939C"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
          />
        </svg>
      </button>
    </div>

    <div class="chart-filters">
      <div class="selected">
        <div class="exchange">
          <object
              :data="`https://s2.coinmarketcap.com/static/img/coins/64x64/${currencyPair[0]}.png`"
              type="image/png"
          >
            <img :src="require(`@/assets/images/default-token-icon.svg`)" alt="default" />
          </object>
          <div>
            <span class="exchange__name">{{ pairName }}</span>
          </div>
        </div>
        <div class="exchange">
          <object
              :data="'data:image/png;base64,' + exchange.iconBase64"
              type="image/png"
          >
            <img :src="require(`@/assets/images/default-token-icon.svg`)" />
          </object>
          <div>
            <span class="exchange__name">{{ exchange.displayName }}</span>
          </div>
        </div>
      </div>

      <div class="dimensions">
        <button
            v-for="([value, text], index) in dimensions"
            :key="index"
            :class="{active: parameters.dimension === value}"
            class="dimensions__button"
            @click="updateParameter(value, 'dimension')"
        >
          {{ text }}
        </button>
      </div>
    </div>

    <div class="chart-content">
      <div v-show="showPreloader" class="main-preloader widget-preloader chart-preloader">
        <Loader
            ref="preloader"
            :animationData="require('@/assets/images/Loader.json')"
            :autoPlay="true"
            :loop="true"
            :speed="1"
        />
      </div>

      <NoData
          v-show="!showPreloader && emptyData"
          text="There seems to be no data. <br /> Try other filters."
      />

      <highcharts
          ref="chart"
          :options="chartOptions"
          :style="{ width: '100%', height: '100%' }"
          constructor-type="stockChart"
      />
    </div>
  </div>
</template>

<script>
import oneLine from '@/mixins/chart-settings/oneLine';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'FundingRateHistory',

  mixins: [oneLine],

  props: {
    currencyPair: {
      type: Array,
      required: true,
    },

    exchangeId: {
      type: Number,
      required: true,
    },
  },

  data: () => ({
    showPreloader: false,
    parameters: {},
    emptyData: false
  }),

  computed: {
    ...mapGetters({
      exchangesInfo: 'filters/exchangesInfo',
      coinsInfo: 'filters/coinsInfo',
    }),

    defaultParameters() {
      return {
        dimension: this.defaultDimension,
        exchangeIds: this.exchangeId,
        currencyPair: this.currencyPair,
      };
    },

    exchange() {
      return this.exchangesInfo[this.exchangeId];
    },

    pairName() {
      const [baseCoinId, quoteCoinId] = this.currencyPair;

      return `${this.coinsInfo[baseCoinId]?.symbol}/${this.coinsInfo[quoteCoinId].symbol}`.toUpperCase();
    },

    defaultDimension() {
      return this.dimensions.keys().next().value;
    },

    dimensions() {
      return new Map([
        ['DAYS_7', 'Last 7 Days'],
        ['DAYS_14', 'Last 14 Days'],
        ['DAYS_30', 'Last 30 Days'],
      ]);
    }
  },

  methods: {
    ...mapActions({
      getFundingRateRealizedData: 'charts/getFundingRateRealized',
    }),

    updateParameter(parameter, name) {
      this.parameters[name] = parameter;
      this.getFundingRateRealized();
    },

    async getFundingRateRealized() {
      try {
        this.showPreloader = true;
        this.emptyData = false;
        const res = await this.getFundingRateRealizedData(this.parameters);

        if (res.length) {
          this.chartOptions.series[0].data = res;
          const plotLine = this.chartOptions.yAxis[1].plotLines[0];
          plotLine.value = res.at(-1)[1];
          this.emptyData = false;
        } else {
          this.emptyData = true;
        }
      } catch (e) {
        this.emptyData = true;
      } finally {
        this.showPreloader = false;
      }
    },
  },

  created() {
    this.parameters = { ...this.defaultParameters };
    this.getFundingRateRealized();
    this.chartOptions.chart.height = null;
    this.chartOptions.series[0].type = 'line';
    this.chartOptions.plotOptions.series.marker.radius = 3;
    this.chartOptions.plotOptions.series.marker.lineColor = 'var(--accent)';
    this.chartOptions.plotOptions.series.states.hover.halo.size = 9;
  }
};
</script>

<style lang="scss" scoped>
.chart-wrapper {
  cursor: default;
}

.chart {
  &__header {
    position: relative;
    padding: 20px;
  }

  &__close-btn {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);

    path {
      transition: stroke 0.3s;
    }

    &:hover {
      path {
        stroke: var(--lightColor);
      }
    }
  }
}

.chart-filters {
  justify-content: space-between;

  .selected {
    display: flex;
    gap: 15px;
  }
}

.exchange {
  display: flex;
  align-items: center;

  object {
    border-radius: 50%;
    margin-right: 10px;
    width: 32px;
    height: 32px;
  }
}

.dimensions {
  border-radius: 8px;
  padding: 4px;
  background-color: #393939;

  &__button {
    transition: background-color 0.2s;
    border-radius: 6px;
    padding: 4px 8px;

    font-size: 0.75rem;

    &:not(:last-child) {
      margin-right: 4px;
    }

    &.active {
      background-color: #2e2d2d;
    }
  }
}
</style>