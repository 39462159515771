import { mapGetters } from 'vuex';
import commonFilters from '@/mixins/filters/commonFilters';
import propsParameters from '@/mixins/propsParameters';
import debounce from '@/mixins/debounce';

export default {
  mixins: [commonFilters, propsParameters, debounce],

  data: () => ({
    parameters: {},
    legends: [],
    showPreloader: true,
    isFiltersOpen: true,
  }),

  computed: {
    ...mapGetters({
      exchangesInfo: 'filters/exchangesInfo',
      filters: 'filters/technicalAnalysisFilter',
      naming: 'filters/naming',
    }),

    exchanges() {
      return new Map(
        Object.keys(this.filters).map(exchangeId => [exchangeId, this.exchangesInfo[exchangeId].displayName])
      );
    },

    defaultPair() {
      return this.pairs.keys().next().value;
    },

    defaultPairs() {
      return [this.pairs.keys().next().value];
    },

    defaultResource() {
      return this.resources.keys().next().value;
    },

    defaultResources() {
      return [...this.resources.keys()];
    },

    pairs() {
      const exchangeIds = this.parameters.exchanges ?? this.defaultExchanges;
      const pairs = [...new Set(exchangeIds.flatMap(exchangeId => this.filters[exchangeId]))];

      return new Map(pairs.map(pair => [pair, pair]));
    },

    resources() {
      return new Map([
        ['SPX_500', 'S&P 500'],
        ['HANG_SENG', 'Hang Seng'],
        ['FTSE_100', 'FTSE 100'],
        ['NASDAQ_100', 'NASDAQ-100'],
      ]);
    },

    dimensions() {
      return new Map([
        ['WEEK_1', 'Week'],
        ['MONTH_1', 'Month'],
        ['YEAR_1', 'Year'],
      ]);
    }
  },

  methods: {
    setStretchPosition() {
      this.$refs.chart.chart.fullscreen.toggle();
    },

    updateParameter(parameter, name) {
      this.parameters = {
        ...this.parameters,
        [name]: parameter
      };

      this.getChartData();
    },
  },
}