<template>
  <div class="layout-item-content custom-scroll">
    <div class="statistic-wrap">
      <Performance>
        <Subtitle
            :info-text="'Performance description'"
            :title="'Performance'"
        />
      </Performance>
      <Technicals>
        <Subtitle
            :info-text="'Technicals description'"
            :title="'Technicals'"
        />
        <TechnicalsGauge
            :isResizeble="isResizeble"
            :technicals-data="tokenInfo.taIndex"
            backgroundColor="rgba(51, 50, 50, 1)"
        />
      </Technicals>
      <Technicals>
        <Subtitle
            :info-text="'Fear and Greed description'"
            :title="'Fear and Greed'"
        />
        <FearGreed
            :backgroundColor="'rgba(51, 50, 50, 1)'"
            :isResizeble="isResizeble"
            :plot-bands-settings="[
              { color: '#bf453d', text: 'Extreme<br />Fear' },
              { color: '#ffd166', text: 'Neutral' },
              { color: '#1bb8b0', text: 'Extreme<br />Greed' },
             ]"
        />
      </Technicals>
      <InfoItem>
        <Subtitle
            :info-text="'Market Cap description'"
            :title="'Market Cap'"
        />
        <div class="statistic-info-item">
          <span class="statistic-info-item-value">${{ marketCap }}</span>
          <span
              :class="{'up' : +marketCapPercent > 0, 'down' : +marketCapPercent <= 0 }"
              class="statistic-info-item-percent"
          >
            <svg fill="none" height="4" viewBox="0 0 8 4" width="8" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M6.8185 0.666748H1.18067C0.723548 0.666748 0.494987 1.17269 0.818781 1.46928L3.28533 3.72857C3.68055 4.09058 4.32338 4.09058 4.7186 3.72857L5.65665 2.86934L7.18515 1.46928C7.50418 1.17269 7.27562 0.666748 6.8185 0.666748Z"
                  fill="#F27870"
              />
            </svg>
            {{ marketCapPercent }}%
          </span>
          <div class="market-cap-info">
            <span class="market-cap-info-title">24h Vol/ Market Cap</span>
            <span class="market-cap-info-value">{{VolMarketCap24h}} %</span>
          </div>
        </div>
      </InfoItem>
      <InfoItem>
        <Subtitle
            :info-text="'Fully Diluted Market Cap description'"
            :title="'Fully Diluted Market Cap'"
        />
        <div class="statistic-info-item">
          <span class="statistic-info-item-value">${{ fullyDilutedMarketCap }}</span>
          <span
              :class="{'up' : +fullyDilutedMarketCapPercent > 0, 'down' : +fullyDilutedMarketCapPercent <= 0 }"
              class="statistic-info-item-percent"
          >
            <svg fill="none" height="4" viewBox="0 0 8 4" width="8" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M6.8185 0.666748H1.18067C0.723548 0.666748 0.494987 1.17269 0.818781 1.46928L3.28533 3.72857C3.68055 4.09058 4.32338 4.09058 4.7186 3.72857L5.65665 2.86934L7.18515 1.46928C7.50418 1.17269 7.27562 0.666748 6.8185 0.666748Z"
                  fill="#F27870"
              />
            </svg>
            {{ fullyDilutedMarketCapPercent }}%
          </span>
        </div>
      </InfoItem>
      <InfoItem>
        <Subtitle
            :info-text="'Volume description'"
            :title="'Volume'"
        />
        <div class="statistic-info-item">
          <span class="statistic-info-item-value">${{ volume24h }}</span>
          <span
              :class="{'up' : +volume24hPercent > 0, 'down' : +volume24hPercent <= 0 }"
              class="statistic-info-item-percent"
          >
            <svg fill="none" height="4" viewBox="0 0 8 4" width="8" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M6.8185 0.666748H1.18067C0.723548 0.666748 0.494987 1.17269 0.818781 1.46928L3.28533 3.72857C3.68055 4.09058 4.32338 4.09058 4.7186 3.72857L5.65665 2.86934L7.18515 1.46928C7.50418 1.17269 7.27562 0.666748 6.8185 0.666748Z"
                  fill="#F27870"
              />
            </svg>
            {{ volume24hPercent }}%
          </span>
        </div>
      </InfoItem>
      <InfoItem>
        <Subtitle
            :info-text="'Circulating Supply description'"
            :title="'Circulating Supply'"
        />
        <div class="slider-wrapper">
          <span class="slider-value">{{ circulatingSupply }} {{ symbol }}</span>
          <span class="slider-percent">{{ supplyPercent }}%</span>
          <div class="price-change-slider">
            <span class="price-change-slider-bg">
              <span :style="{width: supplyPercent + '%'}" class="price-change-slider-fill"></span>
            </span>
          </div>
        </div>
        <div class="statistic-sub-info">
          <div class="statistic-sub-info-item">
            <Subtitle
                :info-text="'Max Supply'"
                :title="'Max Supply'"
            />
            <span class="statistic-sub-info-value">{{ maxSupply }}</span>
          </div>
          <div class="statistic-sub-info-item">
            <Subtitle
                :info-text="'Total Supply'"
                :title="'Total Supply'"
            />
            <span class="statistic-sub-info-value">{{ totalSupply }}</span>
          </div>
        </div>
      </InfoItem>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import Subtitle from "@/components/ui/Subtitle.vue";
import { mapGetters } from "vuex";
import prettifyNumber from "@/mixins/helpers/prettifyNumber";
import aroundNumber from "@/mixins/helpers/aroundNumber";

export default {
  name: "Statistics",
  components: {
    Subtitle,
    FearGreed: defineAsyncComponent(() =>
        import('@/components/chart-sections/FearGreed.vue')
    ),
    TechnicalsGauge: defineAsyncComponent(() =>
        import('@/components/chart-sections/Technicals.vue')
    ),
    Technicals: defineAsyncComponent(() =>
        import('@/components/token/statistic/Technicals.vue')
    ),
    Performance: defineAsyncComponent(() =>
        import('@/components/token/statistic/Performance.vue')
    ),
    InfoItem: defineAsyncComponent(() =>
        import('@/components/token/statistic/InfoItem.vue')
    ),
  },

  props: {
    isResizeble: Boolean,
  },

  mixins: [prettifyNumber, aroundNumber],

  computed: {
    ...mapGetters({
      tokenInfo: 'filters/coinInfo',
      timePeriod: 'charts/tokenTimePeriod',
    }),

    marketCap() {
      return this.prettifyNumber(String(this.aroundNumber(this.tokenInfo.mcap) || 0), 2);
    },
    marketCapPercent() {
      return this.tokenInfo.mcapChangePercent ? this.tokenInfo.mcapChangePercent.toFixed(2) : 0;
    },
    VolMarketCap24h() {
      return (+this.tokenInfo.volumeDay / +this.tokenInfo.mcap * 100).toFixed(5);
    },
    fullyDilutedMarketCap() {
      return this.prettifyNumber(String(this.aroundNumber(this.tokenInfo.fullyMCap) || 0), 2);
    },
    fullyDilutedMarketCapPercent() {
      return this.tokenInfo.fullyDilutedMCapChangePercent ? this.tokenInfo.fullyDilutedMCapChangePercent.toFixed(2) : 0;
    },
    volume24h() {
      return this.prettifyNumber(String(this.aroundNumber(this.tokenInfo.volumeDay) || 0), 2);
    },
    volume24hPercent() {
      return this.tokenInfo.volumeDayChangePercent ? this.tokenInfo.volumeDayChangePercent.toFixed(2) : 0;
    },
    supplyPercent() {
      const percent = this.tokenInfo.circulatingSupply / this.tokenInfo.maxSupply * 100;
      const supplyPercent = percent > 100 ? 100 : percent;

      return supplyPercent ? supplyPercent.toFixed(2) : 0;
    },
    circulatingSupply() {
      return this.prettifyNumber(String(this.aroundNumber(this.tokenInfo.circulatingSupply) || 0), 2);
    },
    maxSupply() {
      return this.prettifyNumber(String(this.aroundNumber(this.tokenInfo.maxSupply) || 0), 2);
    },
    totalSupply() {
      return this.prettifyNumber(String(this.aroundNumber(this.tokenInfo.totalSupply) || 0), 2);
    },
    symbol() {
      return (this.tokenInfo.symbol || '').toUpperCase();
    },
    lastPricePercent() {
      const period = this.timePeriod;
      let lastPricePercent = 0;

      switch (period) {
        case "DAY_1":
          lastPricePercent = this.tokenInfo.pricePercentChange1d;
          break;
        case "WEEK_1":
          lastPricePercent = this.tokenInfo.pricePercentChange1w;
          break;
        case "MONTH_1":
          lastPricePercent = this.tokenInfo.pricePercentChange1m;
          break;
        case "YEAR_1":
          lastPricePercent = this.tokenInfo.pricePercentChange1y;
          break;

        default:
          lastPricePercent = this.tokenInfo.pricePercentChange1d;
      }

      return lastPricePercent ? lastPricePercent.toFixed(2) : 0;
    },
  },
};
</script>

<style scoped>

.statistic-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: initial;
  gap: 10px 0px;
}

.grid-item {
  border-radius: 12px;
  border-radius: 12px;
  width: calc(33.33% - 6px);
  min-height: 185px;
  padding: 20px;
  background: rgba(51, 50, 50, 1);
}

.grid-item:nth-child(4),
.grid-item:nth-child(5),
.grid-item:nth-child(6) {
  width: calc(22% - 5px);
}

.statistic-info-item {
  margin-top: 10px;
}

.statistic-info-item-value {
  display: block;
  margin-bottom: 5px;
  width: 100%;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: 20px;
  color: var(--textMainColor);
}

.statistic-info-item-percent {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: 20px;
}

.statistic-info-item-percent.up {
  color: #2dcac2;
}

.statistic-info-item-percent.down {
  color: #f27870;
}

.statistic-info-item-percent svg {
  display: inline-block;
  margin-right: 5px;
}

.statistic-info-item-percent.up svg {
  transform: rotate(180deg);
}

.statistic-info-item-percent.up svg path {
  fill: #2dcac2
}

.statistic-info-item-percent.down svg path {
  fill: #f27870;
}

.statistic-sub-info {
  margin-top: 25px;
}

.statistic-sub-info-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.statistic-sub-info-item:last-child {
  margin-bottom: 0;
}

.statistic-sub-info-value {

}

.slider-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.slider-value {
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  line-height: 16px;
  color: var(--textMainColor);
}

.slider-percent {
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  line-height: 16px;
  color: var(--textMainColor);
}

.price-change-slider {
  margin: 0 3px;
  margin-top: 10px;
  width: 100%;
  width: 100%;
}

.price-change-slider-bg {
  display: inline-block;
  display: block;
  position: relative;
  border-radius: 4px;
  width: 100%;
  height: 8px;
  background: #383838;
  line-height: 5;
}

.price-change-slider-fill {
  display: inherit;
  position: absolute;
  top: 0px;
  left: 0px;
  transition: width 3s ease-in-out 0s;
  border-top-left-radius: inherit;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: inherit;
  height: 100%;
  background-color: #92939c;
}

.market-cap-info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 24px;
}

.market-cap-info-title {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #92939C;
  color: var(--textSecondaryColor);
}

.market-cap-info-value {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: var(--textMainColor);
  display: inline-block;
  margin-left: 3px;
}

</style>