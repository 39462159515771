export default {
  SET_FILTERS(state, filters) {
    state.filters = filters;
    state.orderbookMap = new Map(filters.orderBook.map(filter => [filter.exchangeId, filter]));
  },

  SET_EXCHANGE_SLUGS(state, exchangeSlugs) {
    state.exchangeSlugs = exchangeSlugs;
  },

  SET_COIN_SLUGS(state, coinSlugs) {
    state.coinSlugs = coinSlugs;
  },

  SET_EXCHANGES_INFO(state, exchangesInfo) {
    state.exchangesInfo = exchangesInfo;
  },

  SET_COINS_INFO(state, coinsInfo) {
    const marketCaps = [];
    const volumes = [];

    state.coinsInfo = Object.fromEntries(Object.entries(coinsInfo).map(([id, coin]) => {
      marketCaps.push(coin.mcap);
      volumes.push(coin.volumeDay);

      return [id, { ...coin, id }];
    }));

    state.maxCoinsMcap = Math.ceil(Math.max(...marketCaps));
    state.maxVolumeByDay = Math.ceil(Math.max(...volumes));
  },

  SET_COIN_INFO(state, coinInfo) {
    const entries = Object.entries(coinInfo);

    state.coinInfo = {
      id: entries[0][0],
      ...entries[0][1],
    };
  },

  SET_UNKNOWN_COINS_INFO(state, coinsInfo) {
    state.unknownCoinsInfo = {
      ...state.unknownCoinsInfo,
      ...coinsInfo,
    };
  },
};
