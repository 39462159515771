<template>
  <div class="chart-wrapper tech-analysis-chart">
    <SectionHeader
        :is-deletable="isDeletable"
        :is-filters-open="isFiltersOpen"
        :show-copy-chart-to-pd="showCopyToPd"
        title="Technical Analysis"
        @copyChartToPD="$emit('copyChartToPD', {})"
        @removeItem="removeItem"
        @setStretchPosition="setStretchPosition"
        @toggleFiltersVisibility="isFiltersOpen = $event"
    />

    <transition name="expand-filters">
      <div v-show="isFiltersOpen" ref="sectionFilters" class="chart-filters">
        <div class="chart-filter">
          <MultiSelect
              ref="exchange"
              :default-option-value="parametersForSelects.exchanges || defaultExchanges"
              :options="exchanges"
              title="exchanges"
              @selected="updateParameter($event, 'exchangeIds')"
          />
        </div>

        <div class="chart-filter">
          <Select
              ref="pair"
              :default-option-value="parametersForSelects.pair || defaultPair"
              :options="pairs"
              title="pair"
              @emptySelect="parameters.pair = defaultPair"
              @selected="updateParameter($event, 'pair')"
          />
        </div>

        <div class="chart-filter">
          <Select
              ref="dimension"
              :default-option-value="parametersForSelects.dimension || defaultDimension"
              :options="dimensions"
              title="time period"
              @selected="updateParameter($event, 'dimension')"
          />
        </div>

        <div class="chart-filter">
          <Select
              ref="resource"
              :default-option-value="parametersForSelects.resource || defaultResource"
              :options="resources"
              title="Resource"
              @selected="updateParameter($event, 'resource')"
          />
        </div>

        <div class="chart-filter reset">
          <button class="reset" @click="resetParameters">
            Reset
          </button>
        </div>
      </div>
    </transition>

    <div class="chart-content">
      <highcharts
          ref="chart"
          :options="chartOptions"
          :style="{ width: '100%', height: '100%' }"
          constructor-type="stockChart"
      />

      <div v-show="showPreloader" class="main-preloader widget-preloader chart-preloader">
        <Loader
            ref="preloader"
            :animationData="require('@/assets/images/Loader.json')"
            :autoPlay="true"
            :loop="true"
            :speed="1"
        />
      </div>
    </div>

    <Legends
        :legend-parameters="seriesParameters"
        :legends="legends"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import techAnalysis from '@/mixins/chart-settings/techAnalysis';
import TechAnalysisFilters from '@/mixins/filters/techAnalysis';

export default {
  name: 'TechAnalysis',

  components: {},

  props: {
    isResizeble: Boolean,
  },

  mixins: [techAnalysis, TechAnalysisFilters],

  computed: {
    defaultParameters() {
      return {
        exchangeIds: this.defaultExchanges,
        pair: this.defaultPair,
        dimension: this.defaultDimension,
        resource: this.defaultResource,
      };
    },

    seriesParameters() {
      return {
        pairPrice: {
          color: '#0f0',
          displayName: 'Pair Price'
        },

        stockPrice: {
          color: '#6868ff',
          displayName: 'Stock Price'
        },
      };
    },
  },

  methods: {
    ...mapActions({
      getTechnicalAnalysis: 'charts/getTechnicalAnalysis',
    }),

    async resetParameters() {
      this.parameters = { ...this.defaultParameters };

      await this.getChartData();

      this.$refs.exchange.reset();
      this.$refs.dimension.reset();
      this.$refs.pair.reset();
      this.$refs.resource.reset();
    },

    getChartData() {
      this.debounce(async () => {
        try {
          this.showPreloader = true;
          const response = await this.getTechnicalAnalysis(this.parameters);

          this.chartOptions.series[0].data = response.pairPrice;
          this.chartOptions.series[1].data = response.stockPrice;

          this.legends = this.chartOptions.series.map(series => ({
            name: series.name,
            currentPrice: series.data.at(-1)?.[1] || 0
          }));
        } catch (e) {
          console.log(e);
        } finally {
          this.showPreloader = false;
        }
      }, 500);
    },

    removeItem() {
      this.$emit('removeItem', null);
    },
  },

  created() {
    this.getChartData();
  }
};
</script>

<style lang="scss" scoped>
</style>