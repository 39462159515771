import Vue from 'vue';
import App from './App.vue';
import store from './store';
import components from '@/components/ui';
import customAxios from '@/api/$axios';
import Vuetify from 'vuetify';
import VueRouter from 'vue-router';
import Toast from 'vue-toastification';
import CloseButton from '@/components/toasters/CloseButton.vue';
import router from '@/router';
import Highcharts from 'highcharts';
import HighchartsVue from 'highcharts-vue';
import ExportingInit from 'highcharts/modules/exporting';
import DragPanels from 'highcharts/modules/drag-panes';
import LoadSunburst from 'highcharts/modules/sunburst.js';
import HighchartsMore from 'highcharts/highcharts-more';
import Stock from 'highcharts/modules/stock';
import Indicators from 'highcharts/indicators/indicators';
import VBP from 'highcharts/indicators/volume-by-price';
import './registerServiceWorker';
import './gtm.js';
import 'vue-toastification/dist/index.css';
import '@/main.css';

components.map((component) => Vue.component(component.name, component));

DragPanels(Highcharts);
Stock(Highcharts);
Indicators(Highcharts);
VBP(Highcharts);
HighchartsMore(Highcharts);
ExportingInit(Highcharts);
LoadSunburst(Highcharts);

window.$axios = customAxios;

Vue.use(VueRouter);
Vue.use(Vuetify);
Vue.use(HighchartsVue);
Vue.config.productionTip = false;

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.getRegistrations().then(registrations => {
      registrations.forEach(registration => {
        registration.unregister();
      });
    });
  });
}

const options = {
  maxToasts: 3,
  newestOnTop: true,
  position: 'bottom-center',
  closeButton: CloseButton,
  timeout: 10000,
  closeOnClick: true,
  pauseOnFocusLoss: false,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  transition: 'Vue-Toastification__fade',
  rtl: false,
  icon: false
};

Vue.use(Toast, options);

Vue.prototype.$bus = new Vue({
  render: h => h(App),
  store,
  router,
  vuetify: new Vuetify({
    icons: {
      iconfont: 'mdiSvg',
    },
  }),
}).$mount('#app');
