import { mapGetters } from 'vuex';

export default {
  data: () => ({
    chartHeight: '100%',
  }),

  computed: {
    ...mapGetters({
      resized: 'layouts/resized',
    }),

    filtersRef() {
      return this.$refs.sectionFilters || this.$refs.chart.$parent.$parent.$refs.sectionFilters;
    }
  },

  watch: {
    resized() {
      this.resize();
    },

    isResizeble() {
      this.resize();
    },

    isFiltersOpen() {
      const element = this.filtersRef;
      const elementHeight = element.clientHeight;
      const newHeight = Math.abs(elementHeight - 5);


      this.chartHeight = `calc(100% - ${Math.abs(newHeight)}px)`;
      this.resize();
      this.$refs.chart?.chart.reflow();
    }
  },

  methods: {
    resize() {
      setTimeout(() => {
        this.chartOptions.chart.width = '100%';
        this.chartOptions.chart.width = null;
        this.$refs.chart?.chart.reflow();
      }, 500);
    },
  },
};