<template>
  <div class="info-item-subtitle">
    <span class="info-item-subtitle-value">{{title}}</span>
    <v-tooltip v-if="infoText" max-width="400" top>
      <template v-slot:activator="{ on, attrs }">
        <svg
            class="info-icon"
            fill="none"
            height="16"
            v-bind="attrs"
            viewBox="0 0 16 16"
            width="16"
            xmlns="http://www.w3.org/2000/svg" v-on="on"
        >
          <path
              clip-rule="evenodd"
              d="M7.20898 5.19649C7.20898 4.82968 7.55646 4.51114 8.0005 4.51114C8.43486 4.51114 8.78241 4.82968 8.78241 5.19649C8.78241 5.56333 8.43488 5.89152 8.0005 5.89152C7.55649 5.89152 7.20898 5.56333 7.20898 5.19649ZM7.25716 7.23327C7.25716 6.963 7.56605 6.77955 8.00044 6.77955C8.41552 6.77955 8.75334 6.96297 8.75334 7.23322V10.911C8.75334 11.1427 8.41552 11.3743 8.00044 11.3743C7.56605 11.3743 7.25716 11.1427 7.25716 10.911V7.23327Z"
              fill="#92939C"
              fill-rule="evenodd"
          />
          <circle cx="8" cy="8" r="7" stroke="#828392" stroke-width="1.5" />
        </svg>
      </template>
      <span>{{ infoText }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: "Subtitle",
  props: {
    title: {
      type: String,
      required: true,
    },
    infoText: {
      type: String,
      required: false,
    }
  }
}
</script>

<style scoped>
 .info-item-subtitle {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: var(--textSecondaryColor);
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

 .info-item-subtitle-value  {
   display: inline-block;
   margin-right: 5px;
 }
</style>