const debounce = {
  data: () => ({
    timerId: null,
  }),

  methods: {
    debounce(fn, timeout = 1000, ...params) {
      if (this.timerId) {
        clearTimeout(this.timerId);
      }

      this.timerId = setTimeout(() => fn(...params), timeout);
    },
  },
};

export default debounce;
