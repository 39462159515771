import { fetchData } from '@/utils/fetchData';

export default {
  getFilters({ commit }) {
    return fetchData(commit, '/filters', {}, 'SET_FILTERS');
  },

  getSlippages(_, params) {
    return fetchData(null, '/filters/slippages', params, null);
  },

  getExchangeSlugs({ commit }) {
    return fetchData(commit, '/inventory/slug-exchange-name-mapping', {}, 'SET_EXCHANGE_SLUGS');
  },

  getCoinSlugs({ commit }) {
    return fetchData(commit, '/inventory/slug-coin-name-mapping', {}, 'SET_COIN_SLUGS');
  },

  getExchangesInfo({ commit }) {
    return fetchData(commit, '/inventory/exchanges', {}, 'SET_EXCHANGES_INFO');
  },

  getCoinsInfo({ commit }) {
    return fetchData(commit, '/inventory/coins', {}, 'SET_COINS_INFO');
  },

  getCoinInfo({ commit }, params) {
    return fetchData(commit, '/inventory/coins', params, 'SET_COIN_INFO');
  },

  getUnknownCoinsInfo({ commit }, ids) {
    const params = { ids, isZeroMarketCapIncluded: true };

    return fetchData(commit, '/inventory/coins', params, 'SET_UNKNOWN_COINS_INFO');
  },
};
