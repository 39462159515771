<template>
  <div class="chart-wrapper">
    <SectionHeader
        :info-text="description"
        :is-deletable="false"
        :is-filters-open="isFiltersOpen"
        :stretchable="false"
        :title="title"
        @setStatic="$emit('setStatic', $event)"
        @toggleFiltersVisibility="isFiltersOpen = $event"
    />
    <div class="chart-content">
      <div class="chart table-section">
        <transition name="expand-filters">
          <div v-show="isFiltersOpen" class="chart-filters">
            <div class="chart-filter">
              <Select
                  ref="exchange"
                  :default-option-value="defaultExchange"
                  :options="exchanges"
                  title="Exchange"
                  @emptySelect="parameters.exchange = defaultExchange"
                  @selected="updateParameters($event, 'exchange')"
              />
            </div>
            <div class="chart-filter">
              <Select
                  ref="asset"
                  :default-option-value="defaultAsset"
                  :options="assets"
                  title="Assets"
                  @emptySelect="parameters.asset = defaultAsset"
                  @selected="updateParameters($event, 'asset')"
              />
            </div>
            <div class="chart-filter">
              <MultiSelect
                  ref="stableCoins"
                  :default-option-value="defaultStableCoins"
                  :options="stableCoins"
                  title="Stable coins"
                  @emptySelect="parameters.stableCoins = defaultStableCoins"
                  @selected="updateParameters($event, 'stableCoins')"
              />
            </div>
            <div class="chart-filter reset">
              <button :disabled="resetDisabled || showPreloader" class="reset" @click="resetParameters">
                Reset
              </button>
            </div>
          </div>
        </transition>

        <div class="layout-item-content custom-scroll">
          <div v-show="showPreloader" class="main-preloader widget-preloader chart-preloader">
            <Loader
                ref="preloader"
                :animationData="require('@/assets/images/Loader.json')"
                :autoPlay="true"
                :loop="true"
                :speed="1"
            />
          </div>

          <MarketsTable
              :markets-data="filteredPairs"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import markets from '@/mixins/filters/markets';

export default {
  name: 'Markets',

  mixins: [markets],

  data: () => ({
    title: 'Markets',
    description: 'Description Markets',
  }),

  computed: {
    defaultParameters() {
      return {
        exchange: this.defaultExchange,
        asset: this.defaultAsset,
        stableCoins: this.defaultStableCoins,
      };
    },

    assets() {
      const exchange = this.parameters.exchange || this.defaultExchange;
      const uniqueAssets = Array.from(new Set(this.exchangePairs.filter(pair => exchange === pair.exchangeId).map(obj => obj.asset)));

      return new Map(uniqueAssets.map(asset => [asset, this.naming[asset] || asset]));
    },

    defaultExchange() {
      return this.exchangeSlugs[this.$route.params.exchange] || this.exchanges.keys().next().value;
    },

    filteredPairs() {
      const { exchange, asset, stableCoins } = this.parameters;

      return this.exchangePairs.filter(pair => (
          exchange === pair.exchangeId
          && asset === pair.asset
          && stableCoins.includes(pair.quote)
      ));
    },

    stableCoins() {
      const exchange = this.parameters.exchange || this.defaultExchanges;
      const asset = this.parameters.asset || this.defaultAsset;
      const filteredPairs = this.exchangePairs.filter(pair => (
          asset === pair.asset
          && exchange === pair.exchangeId
      ));

      const coins = new Set(filteredPairs.map(pair => pair.quote));
      const stableCoins = new Map();

      coins.forEach(stableCoin => stableCoins.set(stableCoin, stableCoin));
      return stableCoins;
    }
  },

  methods: {
    resetParameters() {
      this.resetDisabled = true;
      this.parameters = { ...this.defaultParameters };

      this.$refs.exchange.reset();
      this.$refs.asset.reset();
      this.$refs.stableCoins.reset();
    },
  },
};
</script>

<style scoped>
.chart-content,
.table-section {
  height: 100%;
}
</style>