import { getFromLocalStorage, saveToLocalStorage, getWindowSize } from '@/helpers';

export default {
    getLayout({ commit }) {
        const breakpoint = getWindowSize();
        const customLayout = getFromLocalStorage('customLayout');
        commit('SET_CUSTOM_LAYOUT', { customLayout, breakpoint });
    },

    setLayout({ state }, { layout }) {
        const breakpoint = getWindowSize();
        const customLayout = getFromLocalStorage('customLayout') || state.customLayout;
        if(layout) {
            saveToLocalStorage('customLayout', { ...customLayout, [breakpoint]: layout });
        }
    },

    updateLayout({ commit }, {layout}) {
        const breakpoint = getWindowSize();
        const customLayout = layout;
        commit('SET_CUSTOM_LAYOUT', { customLayout, breakpoint });
    },

    setDefaultLayout({ commit }) {
        const breakpoint = getWindowSize();
        commit('SET_CUSTOM_LAYOUT', { breakpoint })
    },

    changeStatic({ commit }, { isStatic }) {
        commit('CHANGE_STATIC', isStatic);
    },
};
