import { mapActions, mapGetters } from 'vuex';
import commonFilters from '@/mixins/filters/commonFilters';
import MarketsTable from '@/components/tables/Markets';
import debounce from '@/mixins/debounce';
import propsParameters from '@/mixins/propsParameters';

export default {
  mixins: [commonFilters, debounce, propsParameters],

  components: {
    MarketsTable
  },

  data: () => ({
    resetDisabled: true,
    showPreloader: false,
    parameters: {},
    isFiltersOpen: true,
  }),

  computed: {
    ...mapGetters({
      exchangePairs: 'charts/exchangePairs',
      exchangeSlugs: 'filters/exchangeSlugs',
    }),

    defaultStableCoins() {
      return [...this.stableCoins.keys()];
    },
  },

  methods: {
    ...mapActions({
      getExchangePairs: 'charts/getExchangePairs',
    }),

    updateParameters(event, filterName) {
      this.resetDisabled = false;
      this.parameters[filterName] = event;
    },

    getChartData() {
      this.showPreloader = true;

      this.debounce(async () => {
        try {
          await this.getExchangePairs();
        } catch (e) {
          console.log(e);
        } finally {
          this.showPreloader = false;
        }
      }, 500);
    },
  },

  created() {
    this.parameters = { ...this.defaultParameters };

    if (!this.exchangePairs.length) {
      this.getChartData();
    }
  },
};