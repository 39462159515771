<template>
  <div class="chart-wrapper">
    <SectionHeader
        :info-text="description"
        :is-deletable="false"
        :is-filters-open="isFiltersOpen"
        :stretchable="false"
        :title="title"
        @setStatic="$emit('setStatic', $event)"
        @toggleFiltersVisibility="isFiltersOpen = $event"
    />
    <div class="chart-content custom-scroll">
      <div class="chart table-section">
        <transition name="expand-filters">
          <div v-show="isFiltersOpen" class="chart-filters">
            <div class="chart-filter">
              <MultiSelect
                  ref="exchanges"
                  :default-option-value="defaultExchanges"
                  :options="exchanges"
                  title="Exchange"
                  @emptySelect="parameters.exchanges = defaultExchanges"
                  @selected="updateParameters($event, 'exchanges')"
              />
            </div>

            <div class="chart-filter">
              <MultiSelect
                  ref="asset"
                  :default-option-value="parametersForSelects.assets || defaultAssets"
                  :options="assets"
                  title="assets"
                  @emptySelect="parameters.assets = defaultAssets"
                  @selected="updateParameters($event, 'assets')"
              />
            </div>

            <div class="chart-filter">
              <Select
                  ref="tokenId"
                  :default-option-value="defaultTokenId"
                  :options="tokens"
                  title="Tokens"
                  @emptySelect="parameters.tokenId = defaultTokenId"
                  @selected="updateParameters($event, 'tokenId')"
              />
            </div>

            <div class="chart-filter">
              <MultiSelect
                  ref="stableCoins"
                  :default-option-value="defaultStableCoins"
                  :options="stableCoins"
                  title="Stable coins"
                  @emptySelect="parameters.stableCoins = defaultStableCoins"
                  @selected="updateParameters($event, 'stableCoins')"
              />
            </div>

            <div class="chart-filter reset">
              <button :disabled="resetDisabled || showPreloader" class="reset" @click="resetParameters">
                Reset
              </button>
            </div>
          </div>
        </transition>

        <div class="layout-item-content">
          <div v-show="showPreloader" class="main-preloader widget-preloader chart-preloader">
            <Loader
                ref="preloader"
                :animationData="require('@/assets/images/Loader.json')"
                :autoPlay="true"
                :loop="true"
                :speed="1"
            />
          </div>
          <MarketsTable :marketsData="filteredPairs" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import marketFilters from '@/mixins/filters/markets';

export default {
  name: 'Markets',

  data: () => ({
    title: 'Markets',
    description: 'Description Markets',
  }),

  mixins: [marketFilters],

  computed: {
    ...mapGetters({
      tokenIds: 'charts/exchangePairsTokenIds',
      coinsSlugs: 'filters/coinSlugs',
    }),

    defaultParameters() {
      return {
        exchanges: this.defaultExchanges,
        stableCoins: this.defaultStableCoins,
        assets: this.defaultAssets,
        tokenId: this.defaultTokenId,
      };
    },

    defaultTokenId() {
      const currentTokenId = this.coinsSlugs[this.$route.params.token];

      return this.tokens.get(currentTokenId) ? currentTokenId : this.tokens.keys().next().value;
    },

    tokens() {
      const tokenIds = new Map();
      this.tokenIds.forEach(tokenId => tokenIds.set(tokenId, this.coinsInfo[tokenId]?.symbol.toUpperCase() || tokenId));

      return tokenIds;
    },

    exchanges() {
      const { assets } = this.parameters;

      let exchangesIds = [...new Set(this.exchangePairs.map(pair => pair.exchangeId))];

      if (assets?.length) {
        exchangesIds = exchangesIds.filter(exchangeId =>
            this.exchangePairs.some(pair => pair.exchangeId === exchangeId && assets.includes(pair.asset))
        );
      }

      return new Map(exchangesIds.map(exchangeId => [exchangeId, this.exchangesInfo[exchangeId].displayName]));
    },


    defaultAssets() {
      return [...this.assets.keys()];
    },

    assets() {
      const exchanges = this.parameters.exchanges || this.defaultExchanges;
      const uniqueAssets = Array.from(new Set(this.exchangePairs.filter(pair => exchanges.includes(pair.exchangeId)).map(obj => obj.asset)));

      return new Map(uniqueAssets.map(asset => [asset, this.naming[asset] || asset]));
    },

    filteredPairs() {
      const { exchanges, stableCoins, tokenId, assets } = this.parameters;

      return this.exchangePairs.filter(pair => (
          exchanges?.includes(pair.exchangeId)
          && stableCoins?.includes(pair.quote)
          && assets?.includes(pair.asset)
          && tokenId === pair.coinId
      ));
    },

    stableCoins() {
      const exchanges = this.parameters.exchanges || this.defaultExchanges;
      const assets = this.parameters.assets || this.defaultAssets;
      const tokenId = this.parameters.tokenId ?? this.defaultTokenId;
      const filteredPairs = this.exchangePairs.filter(pair => (
          tokenId === pair.coinId
          && assets.includes(pair.asset)
          && exchanges.includes(pair.exchangeId)
      ));

      const coins = new Set(filteredPairs.map(pair => pair.quote));
      const stableCoins = new Map();

      coins.forEach(stableCoin => stableCoins.set(stableCoin, stableCoin));
      return stableCoins;
    }
  },

  methods: {
    resetParameters() {
      this.resetDisabled = true;
      this.parameters = { ...this.defaultParameters };

      this.$refs.exchanges.reset();
      this.$refs.tokenId.reset();
      this.$refs.stableCoins.reset();
      this.$refs.asset.reset();
    },
  },
};
</script>

<style scoped>

</style>