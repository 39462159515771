<template>
  <div class="chart-wrapper">
    <Gauge
        :backgroundColor="backgroundColor"
        :data="fearAndGreedIndex"
        :plot-bands-settings="plotBandsSettings"
        :isResizeble="isResizeble"
    />
  </div>
</template>

<script>
import Gauge from '@/components/charts/Gauge';
import { mapActions } from 'vuex';

export default {
  name: 'FearGreed',

  components: { Gauge },

  props: {
    backgroundColor: {
      type: String,
      required: false,
    },

    plotBandsSettings: {
      type: Array,
      required: true,
      validator(settings) {
        return settings.every(setting => setting.color && setting.text);
      }
    },

    isResizeble: Boolean,
  },

  data: () => ({
    fearAndGreedIndex: '',
  }),

  methods: {
    ...mapActions({
      getFearAndGreed: 'charts/getFearAndGreed',
    }),

    async getChartData() {
      try {
        this.fearAndGreedIndex = (await this.getFearAndGreed()).fearAndGreedIndex;
      } catch (e) {
        console.log(e);
      }
    }
  },

  created() {
    this.getChartData();
  }
};
</script>

<style scoped>

</style>