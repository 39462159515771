<template>
  <WidgetLayout
      :layout-prop="coinsLayout"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import WidgetLayout from '@/layouts/WidgetLayout';

export default {
  name: 'CoinsData',

  components: { WidgetLayout },

  computed: {
    ...mapGetters({
      coinsLayout: 'layouts/coinsLayout',
    }),
  },
};
</script>

<style scoped>

</style>