<template>
  <WidgetLayout v-if="loaded" :layout-prop="layout" />
</template>

<script>
import WidgetLayout from '@/layouts/WidgetLayout';
import { mapGetters } from 'vuex';

export default {
  name: 'ExchangePage',

  components: { WidgetLayout },

  data: () => ({
    loaded: false,
  }),

  computed: {
    ...mapGetters({
      layout: 'layouts/exchangeLayout',
      exchangeSlugs: 'filters/exchangeSlugs'
    }),

    exchange() {
      return this.$route.params.exchange;
    },
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      const checkRoute = () => {
        // TODO: REMOVE HARDCODE
        const deprecatedExchanges = new Set(['deribit', 'dydx', 'coinbase-exchange']);

        if (vm.exchangeSlugs[vm.exchange] && !deprecatedExchanges.has(vm.exchange)) {
          vm.loaded = true;
          next();
        } else {
          vm.$router.replace({ path: '/404' });
        }
      };

      Object.keys(vm.exchangeSlugs).length ? checkRoute() : vm.$bus.$once('inventory-loaded', checkRoute);
    });
  },
};
</script>

<style scoped>

</style>