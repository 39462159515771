import moment from 'moment';
import { marker, setCurrentValue, states } from '@/mixins/chart-settings/_commonSettings';
import reflowHighchart from '@/mixins/reflowHighchart';

export default {
  mixins: [reflowHighchart],

  data() {
    return {
      seriesItem: {
        lineWidth: 1,
        type: 'spline',
        data: [],
        marker,
        states,
      },
      chartOptions: {
        chart: {
          margin: [0, 57, 25, 0],
          // height: 414,
          backgroundColor: 'var(--mainBGColor)',
          zoomType: 'x',
          animation: true,
          panKey: 'shift',

          panning: {
            enabled: true,
            type: 'x'
          },

          resetZoomButton: {
            theme: {
              fill: 'var(--accent)',
              stroke: 'var(--accent)',

              style: {
                fontFamily: 'Inter, sans-serif',
                fontWeight: 700,
                color: '#fff',
              },

              states: {
                hover: {
                  fill: '#00fff7',
                  stroke: '#00fff7',
                }
              }
            },
          },
        },
        exporting: {
          enabled: false,
        },
        title: {
          text: null
        },

        yAxis: [
          {
            visible: false,
            startOnTick: false,

            title: {
              text: null
            },
          },
          {
            linkedTo: 0,
            gridLineColor: 'var(--gridLineColor)',
            gridLineWidth: 1,
            opposite: true,
            tickLength: 10,
            tickWidth: 1,
            title: null,

            labels: {
              align: 'left',
              x: 5,
              y: 5,

              style: {
                fontFamily: 'Inter, sans-serif',
                fontSize: 10,
                color: '#fff',
              },

              formatter: (event) => {
                return new Intl.NumberFormat('en-US', { notation: 'compact' }).format(event.value) + this.suffix;
              }
            },
          }
        ],

        xAxis: [
          {
            gridLineColor: 'var(--gridLineColor)',
            gridLineWidth: 1,
            tickPixelInterval: 190,

            labels: {
              style: {
                fontFamily: 'Inter, sans-serif',
                color: '#fff',
              },

              formatter: (a) => moment(a.value).format('YYYY/DD/MM HH:mm'),
            },
          },
        ],

        tooltip: {
          split: true,
          padding: 5,
          backgroundColor: 'var(--mainBorderColor)',

          style: {
            fontSize: 10,
            fontFamily: 'inherit',
            stroke: 'var(--textMainColor)',
          },

          crosshairs: {
            enabled: true,
            dashStyle: 'dash',
            interpolate: true,
            label: {
              enabled: true,
              shape: 'unset',
              padding: 7,
              formatter: (ev) => moment(ev).format('YYYY/DD/MM HH:mm:ss'),
            },
          },
          positioner: (width, height, point) => setCurrentValue(point, this.legends),
        },

        plotOptions: {
          area: {
            opacity: 0.8,
            stacking: 'percent',
            marker: {
              enabled: false,
            },
          },
          series: {
            marker: {
              enabled: false,
            },

            point: {
              events: {
                mouseOut: () => {
                  this.chartOptions.series.forEach(ser => {
                    const index = this.legends.findIndex(el => el.name === ser.name);

                    if (this.legends[index]) {
                      const lastSer = ser.data.at(-1);

                      this.legends = this.legends.map((exchange, i) => {
                        if (index === i) {
                          exchange.currentPrice = lastSer[1];
                        }

                        return exchange;
                      });
                    }
                  });
                }
              }
            }
          }
        },

        series: [],

        navigator: {
          enabled: false,
        },

        scrollbar: {
          enabled: false,
        },

        rangeSelector: {
          enabled: false,
        },

        legend: {
          verticalAlign: 'top',
          x: -300,
          y: -100,
          enabled: true,
        }
      },
    };
  },
};
