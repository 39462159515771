<template>
  <div class="listing-delisting chart-wrapper">
    <SectionHeader
        :is-deletable="isDeletable"
        :is-filters-open="isFiltersOpen"
        :show-copy-chart-to-pd="showCopyToPd"
        :stretchable="false"
        info-text="List of listing/delisting announcements from various exchanges. Calendar with dates of the events themselves"
        title="Listings & Delistings"
        @copyChartToPD="$emit('copyChartToPD', {})"
        @removeItem="removeItem"
        @setStatic="$emit('setStatic', $event)"
        @toggleFiltersVisibility="isFiltersOpen = $event"
    />
    <div class="chart-content">
      <div class="chart table-section">
        <transition name="expand-filters">
          <div v-show="isFiltersOpen" class="chart-filters">
            <div class="chart-filter">
              <MultiSelect
                  ref="eventType"
                  :default-option-value="parametersForSelects.eventTypes || defaultEventType"
                  :options="eventTypes"
                  title="event type"
                  @selected="updateParameter($event, 'eventTypes')"
              />
            </div>
            <div class="chart-filter">
              <MultiSelect
                  ref="exchanges"
                  :default-option-value="parametersForSelects.exchanges || defaultExchanges"
                  :options="exchanges"
                  title="Exchanges"
                  @selected="updateParameter($event, 'exchanges')"
              />
            </div>
            <div class="chart-filter">
              <MultiSelect
                  ref="assets"
                  :default-option-value="parametersForSelects.assets || allAssets"
                  :options="assets"
                  title="Assets"
                  @emptySelect="parameters.assets = allAssets"
                  @selected="updateParameter($event, 'assets')"
              />
            </div>
            <div class="chart-filter reset">
              <button :disabled="resetDisabled || showPreloader" class="reset" @click="resetParameters">
                Reset
              </button>
            </div>
          </div>
        </transition>

        <div ref="chartContent" class="chart-content">
          <div v-show="showPreloader" class="main-preloader widget-preloader chart-preloader">
            <Loader
                ref="preloader"
                :animationData="require('@/assets/images/Loader.json')"
                :autoPlay="true"
                :loop="true"
                :speed="1"
            />
          </div>
          <div class="table-wrapper custom-scroll">
            <AnnouncementsTable
                ref="table"
                :announcements="filteredAnnouncements"
                :is-resizeble="isResizeble"
                @pageChanged="updateParameter($event, 'page')"
            />
          </div>
        </div>
      </div>

      <CalendarAnnouncements
          :is-filters-open="isFiltersOpen"
          :is-resizeble="isResizeble"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AnnouncementsTable from '@/components/tables/Announcements';
import debounce from '@/mixins/debounce';
import CalendarAnnouncements from '@/components/chart-sections/CalendarAnnouncements';
import announcementFilters from '@/mixins/filters/announcements';
import propsParameters from '@/mixins/propsParameters';

export default {
  name: 'Announcements',

  components: {
    CalendarAnnouncements,
    AnnouncementsTable,
  },

  mixins: [debounce, announcementFilters, propsParameters],

  props: {
    isResizeble: Boolean,
  },

  data() {
    return {
      parameters: {},
      showPreloader: false,
      resetDisabled: true,
      isFiltersOpen: true,
    };
  },

  computed: {
    ...mapGetters({
      announcementsData: 'charts/announcements',
      naming: 'filters/naming',
    }),

    filteredAnnouncements() {
      const { eventTypes, exchanges, assets } = this.parameters;

      return this.announcementsData.filter(({ category, exchangeId, asset }) => (
          eventTypes.includes(category)
          && exchanges.includes(exchangeId)
          && assets.includes(asset)
      ));
    },

    defaultParameters() {
      return {
        eventTypes: this.defaultEventType,
        exchanges: this.defaultExchanges,
        assets: this.allAssets,
        page: 0,
      };
    },
  },

  methods: {
    ...mapActions({
      getAnnouncementsData: 'charts/getAnnouncements',
    }),

    updateParameter(event, name) {
      this.resetDisabled = false;
      this.parameters[name] = event;
      this.$refs.table.page = 0;
    },

    async resetParameters() {
      this.resetDisabled = true;
      this.parameters = { ...this.defaultParameters };

      this.$refs.eventType.reset();
      this.$refs.exchanges.reset();
      this.$refs.assets.reset();
    },

    async getAnnouncements() {
      this.showPreloader = true;
      this.debounce(async () => {
        try {
          await this.getAnnouncementsData();
        } catch (e) {
          console.log(e);
        } finally {
          this.showPreloader = false;
        }
      }, 500);
    },

    removeItem() {
      this.$emit('removeItem', null);
    },
  },

  mounted() {
    this.getAnnouncements();
  }
};
</script>
<style lang="scss" scoped>
.listing-delisting {
  overflow: hidden;
  min-width: min-content;
  width: 100%;
}

.table-section {
  display: flex;
  flex-direction: column;
  width: max-content;
  flex: 1 1 auto;
}

.table-wrapper {
  width: 100%;
}

@media (min-width: 1024px) {
  .chart-content {
    display: flex;
  }

  .table-section {
    border-right: 1px solid var(--mainBorderColor);
  }
}
</style>

