<template>
  <div class="chart-wrapper">
    <SectionHeader
        :info-text="'description'"
        :is-deletable="false"
        :stretchable="false"
        :toggle-filters-visible="false"
        :title="`What is ${name}?`"
        @setStatic="$emit('setStatic', $event)"
    />
    <div class="layout-item-content custom-scroll">
      <div class="token-about">
        <h3>What Is {{ name }} ({{ symbol.toUpperCase() }})?</h3>
        <p>{{ tokenInfo.priceDescription }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import SectionHeader from "@/components/ui/section/SectionHeader.vue";
import { mapGetters } from "vuex";

export default {
  name: "About",

  components: {
    SectionHeader,
  },

  props: {
  },

  computed: {
    ...mapGetters({
      tokenInfo: 'filters/coinInfo',
    }),

    name() {
      return this.tokenInfo.displayName;
    },

    symbol() {
      return this.tokenInfo.symbol || '';
    },
  },
};
</script>

<style scoped>
.token-about h3 {
  margin: 10px 0;
  font-size: 20px;
  line-height: 30px;
}

.token-about p {
  margin-bottom: 20px;
  line-height: 26px;
  color: var(--textSecondaryColor);
}
</style>