export default {
  methods: {
    sortByProperty(array, sortBy, isSortAscending) {
      const sortedArray = [...array];
      const isNumberic = typeof (sortedArray[0]?.[sortBy]) === 'number';

      isNumberic
        ? sortedArray.sort((a, b) => a[sortBy] - b[sortBy])
        : sortedArray.sort((a, b) => a[sortBy].localeCompare(b[sortBy]));

      return isSortAscending ? sortedArray.reverse() : sortedArray;
    },
  },
};
