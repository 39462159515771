<template>
  <v-img
      :src="src"
      aspect-ratio="1/1"
      class="rounded-circle"
      contain
      :height="size"
      :max-width="size"
      :width="size"
  >
    <img slot="placeholder" :src="fallbackImage" alt="logo" />
  </v-img>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'LazyImg',

  props: {
    id: {
      type: [Number, String],
      required: false,
    },

    isToken: {
      type: Boolean,
      required: true,
    },

    size: {
      type: [String, Number],
      required: false,
      default: 32,
    }
  },

  computed: {
    ...mapGetters({
      exchangesInfo: 'filters/exchangesInfo',
    }),

    fallbackImage() {
      return require(`@/assets/images/default-token-icon.svg`);
    },

    src() {
      if (!this.id) {
        return this.fallbackImage;
      }

      if (this.isToken) {
        return `https://s2.coinmarketcap.com/static/img/coins/64x64/${this.id}.png`;
      }

      return 'data:image/png;base64,' + this.exchangesInfo[this.id].iconBase64;
    }
  },
};
</script>

<style scoped>
img {
  border-radius: 50%;
  width: 32px;
  height: 32px;
}
</style>