<template>
  <div class="custom-pagination max-screen-width">
    <div class="current-position">
      {{ startPaginationPosition }} - {{ endPaginationPosition }} of {{ totalItems }}
    </div>
    <v-pagination
        v-model="page"
        :length="paginationSize"
        :total-visible="7"
        class="table-pagination"
    />
  </div>
</template>

<script>
export default {
  name: 'CustomPagination',

  props: {
    paginationSize: {
      type: Number,
      default: 1,
    },

    totalItems: {
      type: Number,
      default: 0,
    },

    pageCount: {
      type: Number,
      default: 4,
    },

    pageNumber: {
      type: Number,
      required: false,
    }
  },

  data: () => ({
    page: 1
  }),

  watch: {
    page(page) {
      this.$emit('pageChanged', page - 1);
    },

    pageNumber(pageNumber) {
      this.page = pageNumber + 1;
    }
  },

  computed: {
    startPaginationPosition() {
      return (this.page - 1) * this.pageCount + 1;
    },

    endPaginationPosition() {
      const endAmount = this.page * this.pageCount;

      return endAmount < this.totalItems ? endAmount : this.totalItems;
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-pagination {
  margin: 18px 0;

  &::v-deep {
    .v-pagination {
      justify-content: flex-end;
    }

    button {
      box-shadow: none;
      background-color: transparent !important;
      font-size: 0.875rem;
      font-weight: 600;
      color: var(--textMainColor) !important;

      &.v-pagination__item--active {
        background-color: #42e8e0 !important;
        color: #111222 !important;
      }
    }

    .v-pagination__navigation {
      color: var(--textSecondaryColor) !important;

      &--disabled path {
        color: #464646 !important;
      }

      path {
        color: var(--textSecondaryColor);
      }
    }
  }
}
</style>

