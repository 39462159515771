<template>
  <WidgetLayout
      :isDraggable="true"
      :isResizable="true"
      :layout-prop="layout"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import WidgetLayout from '@/layouts/WidgetLayout';

export default {
  name: 'Tokens',

  components: { WidgetLayout },

  computed: {
    ...mapGetters({
      layout: 'layouts/tokensListLayout',
    }),
  },

  created() {

  },
};
</script>

<style scoped>

</style>