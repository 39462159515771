<template>
  <div class="select-search" v-if="isShowSearch">
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.33334 12.6667C10.2789 12.6667 12.6667 10.2789 12.6667 7.33334C12.6667 4.38782 10.2789 2 7.33334 2C4.38782 2 2 4.38782 2 7.33334C2 10.2789 4.38782 12.6667 7.33334 12.6667Z" stroke="#92939C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M13.9996 14L11.0996 11.1" stroke="#92939C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <input
        type="text"
        v-model="searchText"
        :placeholder="title"
        class="select-search-input"
    >
  </div>
</template>

<script>
import debounce from "@/mixins/debounce";
export default {
  name: "Search",
  props: {
    title: {
      type: String,
      default: 'Search',
    },

    isShowSearch: {
      type: Boolean,
      default: false,
    },

  },
  data() {
    return {
      searchText: '',
      results: []
    };
  },

  mixins: [debounce],

  watch: {
    searchText(text) {
      this.search(text)
    }
  },

  methods: {
    search(text) {
      this.debounce(async () => {
        this.$emit('search', text)
      }, 300);
    }
  }
}
</script>

<style lang="scss" scoped>
  .select-search {
    width: calc(100% + 5px);
    position: relative;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    &-input {
      background: #464646;
      border-radius: 6px;
      border: 1px solid transparent;
      transition: all .5s;
      padding: 10px 14px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: var(--textSecondaryColor);
      width: calc(100% - 16px);
      margin: 0 auto;
      padding-left: 35px;
      &:focus {
        border: 1px solid #42E8E0;
        outline: none;
      }
    }

    svg {
      position: absolute;
      left: 14px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
</style>