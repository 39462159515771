<template>
  <div
      class="drawer"
      :class="{active: activeDrawer}"
      v-if="activeDrawer"
      v-click-outside="closeDrawer"
  >
    <div class="drawer-content">
      <div class="drawer-header">
        <span class="drawer-title">Filters</span>
        <button @click="closeDrawer">
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.25 1.25L6.99995 6.99995M6.99995 6.99995L12.7499 1.25M6.99995 6.99995L12.75 12.7498M6.99995 6.99995L1.2501 12.7498" stroke="#92939C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>
      </div>
      <div class="drawer-body custom-scroll">
        <slot></slot>
      </div>
      <div class="drawer-footer">
        <div class="popup-filter-btns">
          <button
              class="drawer-filter-btn reset"
              @click="reset"
          >
            Reset all filters
          </button>
<!--          <button-->
<!--              class="popup-filter-btn ascent-btn"-->
<!--              @click="apply"-->
<!--          >-->
<!--            Show Results-->
<!--          </button>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Drawer",
  data: () => ({
    activeDrawer: false,
  }),

  methods: {
    openDrawer() {
      this.activeDrawer = true;
    },

    closeDrawer() {
      this.$emit('closeDrawer', null);
      this.activeDrawer = false;
    },

    apply() {
      this.$emit('apply', null)
    },

    reset() {
      this.closeDrawer();
      this.$emit('reset', null)
    },
  },
}
</script>

<style scoped>
  .drawer {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
    height: 100%;
    transition: all .5s linear;
  }

  .drawer-content {
    display: flex;
    flex-wrap: wrap;
    background: #1E1E1E;
    width: 560px;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: -560px;
    padding: 20px;
    transition: all .5s linear;
  }

  .drawer.active .drawer-content {
    right: 0;
  }

  .drawer-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #333232;
  }

  .drawer-title {

  }

  .popup-filter-btns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-right: 5px;
  }

  .drawer-footer {
    display: flex;
    align-items: center;
    width: 100%;
    border-top: 1px solid #333232;
    padding-top: 20px;
    margin-top: 20px;
  }

  .drawer-body {
    height: calc(100% - 170px);
    width: calc(100% + 20px);
    margin: 0 -10px 0 0;
    padding-right: 10px;
  }

  .drawer-filter-btn {
    background: var(--mainBGColorHover);
    border-radius: 6px;
    padding: 5px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    transition: all .5s;
  }
</style>