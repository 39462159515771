<template>
  <div class="table-wrapper">
    <table>
      <thead>
        <tr>
          <th>
            <div class="header-wrapper">Pair</div>
          </th>
          <th>
            <div class="header-wrapper">Asset</div>
          </th>
          <th>
            <div class="header-wrapper">Exchange</div>
          </th>
          <th
              v-for="header in headers"
              :key="header.id"
              :class="{'sorted active-column': header.id === sortedColumnID}"
              class="sortable"
              @click="sortTable(header.name, header.id)"
          >
            <div class="header-wrapper">
              <svg
                  class="arrows"
                  fill="none"
                  height="13"
                  viewBox="0 0 12 13"
                  width="12" xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    :class="{active: !isSortAscending}"
                    d="M9 4.49998L6 1.49999L3 4.49998"
                    stroke="#92939C"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                />
                <path
                    :class="{active: isSortAscending}"
                    d="M3 8.50002L6 11.5L9 8.50002"
                    stroke="#92939C"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                />
              </svg>
              {{ header.text }}
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(announcement, i) in visibleAnnouncements" :key="i" ref="row">
          <td class="pair">
            <div class="pair-wrapper">
              <LazyImg
                  :id="announcement.id[0]"
                  :is-token="true"
                  class="img"
              />

              <router-link
                  class="pair-info exchange-wrapper align-start"
                  v-if="coinsInfo[announcement.id[0]]?.slug"
                  :to="{ path: `/token/${coinsInfo[announcement.id[0]]?.slug}`}"
              >
                <span>{{ announcement.displayName }}</span>
                <span class="secondary-text text-capitalize text-left">{{ coinsInfo[announcement.id[0]]?.displayName }}</span>
              </router-link>
              <div v-else class="pair-info">
                <span>{{ announcement.displayName }}</span>
                <span class="secondary-text text-capitalize">{{ coinsInfo[announcement.id[0]]?.displayName }}</span>
              </div>
            </div>
          </td>
          <td class="asset">
            {{ announcement.asset.split('_').join(' ') }}
          </td>
          <td>
            <router-link :to="{ path: `/exchange/${exchangesInfo[announcement.exchangeId].slug}` }" class="exchange-wrapper">
              <LazyImg
                  class="exchange-img"
                  :is-token="false"
                  :id="announcement.exchangeId"
                  :size="16"
              />
              {{ exchangesInfo[announcement.exchangeId].displayName }}
            </router-link>
          </td>
          <td
              v-if="isMainTable"
              :class="{'active-column': 3 === sortedColumnID}"
              v-html="convertInHowMuchTimePassed(announcement.publishedAt)"
          />
          <td :class="{'active-column': 4 === sortedColumnID}" class="event-type">
            <span :style="{ color: categoriesInfo.get(announcement.category)?.color }">
              {{ categoriesInfo.get(announcement.category)?.text || announcement.category }}
            </span>
          </td>
          <td :class="{'active-column last': 5 === sortedColumnID}" class="text-right event-time">
            {{ convertTime(announcement.eventTime) }}
          </td>
        </tr>
      </tbody>
    </table>
    <CustomPagination
        v-if="totalPages > 1"
        :page-count="size"
        :page-number="page"
        :pagination-size="totalPages"
        :total-items="totalAnnouncements"
        @pageChanged="page = $event"
    />
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import sortByProperty from '@/mixins/helpers/sortByProperty';

export default {
  name: 'Announcements',

  mixins: [sortByProperty],

  props: {
    announcements: {
      type: Array,
      required: true,
    },

    isMainTable: {
      type: Boolean,
      default: true,
    },

    isResizeble: Boolean,
  },

  data: () => ({
    sortedBy: '',
    sortedColumnID: 5,
    isSortAscending: true,
    headers: [
      {
        id: 4,
        name: 'category',
        text: 'Event Type',
      },
      {
        id: 5,
        name: 'eventTime',
        text: 'Event Time',
      },
    ],
    size: 4,
    page: 0,
  }),

  watch: {
    isResizeble() {
      this.setSize();
    },

    announcements: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.setSize();
        })
      }
    }
  },

  computed: {
    ...mapGetters({
      exchangesInfo: 'filters/exchangesInfo',
      coinsInfo: 'filters/coinsInfo',
    }),

    categoriesInfo() {
      return new Map([
        [
          'cryptocurrency_planned_listing',
          {
            color: '#02c9bf',
            text: 'Listing'
          }
        ],
        [
          'cryptocurrency_planned_delisting',
          {
            color: '#f27870',
            text: 'Delisting'
          },
        ]
      ]);
    },

    visibleAnnouncements() {
      const sortedAnnouncements = this.sortByProperty(this.announcements, this.sortedBy, this.isSortAscending);

      return sortedAnnouncements.slice(this.page * this.size, (this.page + 1) * this.size);
    },

    totalAnnouncements() {
      return this.announcements.length;
    },

    totalPages() {
      return Math.ceil(this.totalAnnouncements / this.size);
    }
  },

  methods: {
    setSize() {
      if (!this.$refs.row) return;
      const row = this.$refs.row[0];
      const chartContent = row.parentNode.parentNode.parentNode.parentNode;

      this.size = Math.floor((chartContent.clientHeight - row.clientHeight / 2) / row.clientHeight) - 1;
      this.page = 0;
    },

    sortTable(columnName, columnID) {
      this.sortedColumnID = columnID;
      this.isSortAscending = this.sortedBy === columnName ? !this.isSortAscending : false;
      this.sortedBy = columnName;
      this.page = 0;
    },

    convertInHowMuchTimePassed(time) {
      const timeAgo = moment(time).fromNow().split(' ');
      timeAgo.splice(-1);

      return timeAgo.join(' ') + '<span class="secondary-text"> ago</span>';
    },

    convertTime(time) {
      return moment(time).format('DD/MM/YYYY hh:mmA');
    },
  },

  created() {
    if (this.isMainTable) {
      this.sortedBy = 'publishedAt';
      this.sortedColumnID = 3;
      this.headers.unshift({
        id: 3,
        name: 'publishedAt',
        text: 'Announced',
      });
    } else {
      this.sortedBy = 'eventTime';
      this.sortedColumnID = 5;
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.setSize();
    });
  }
};
</script>

<style lang="scss" scoped src="@/assets/css/table.scss" />
<style lang="scss" scoped>
.table-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
}

.event-type {
  width: 14%;
  text-transform: capitalize;
}

.event-time {
  width: 20%;
}

.pair-wrapper {
  display: flex;
  align-items: center;

  .img {
    display: block;
    margin-right: 12px;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    object-fit: contain;
  }
}

.pair-info {
  display: flex;
  flex-direction: column;
}

td {
  vertical-align: middle;
}

.exchange-wrapper {
  display: flex;
  align-items: center;
  transition: all .5s;
  font-family: "Inter", sans-serif;
  font-size: .875rem;
  font-weight: 500;
  font-style: normal;
  line-height: 18px;
  color: var(--textMainColor);

  &:hover {
    color: var(--accent);
  }

  .exchange-img {
    margin-right: 4px;
  }
}

.justify-end {
  justify-content: flex-end;
}

.asset {
  text-transform: capitalize;
}
</style>
