import { NumericTickProvider } from 'scichart/Charting/Numerics/TickProviders/NumericTickProvider';

export class TickProvider extends NumericTickProvider {
  constructor(wasmContext, ticks) {
    super(wasmContext);
    this.ticks = ticks;
  }

  getMinorTicks() {
    return this.ticks;
  }

  getMajorTicks() {
    return this.ticks;
  }
}
