import { DefaultPaletteProvider, EFillPaletteMode } from 'scichart/Charting/Model/IPaletteProvider';
import { parseColorToUIntArgb } from 'scichart/utils/parseColor';
import { uintArgbColorMultiplyOpacity } from 'scichart/utils/colorUtil';

export class ColumnPaletteProvider extends DefaultPaletteProvider {
  constructor({ fill, fillHover, yValues }) {
    super();
    this.fillPaletteMode = EFillPaletteMode.SOLID;
    this.fill = parseColorToUIntArgb(fill);
    this.fillHover = parseColorToUIntArgb(fillHover);
    this.yValues = yValues;
  }

  overrideFillArgb(xValue, yValue) {
    if (this.yValues.includes(yValue)) {
      return uintArgbColorMultiplyOpacity(this.fillHover, 1);
    }

    if (xValue === this.from || xValue === this.to) {
      return uintArgbColorMultiplyOpacity(this.fillHover, 0.5);
    }

    return uintArgbColorMultiplyOpacity(this.fill, 0.5);
  }
}