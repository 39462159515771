export const IDs = {
  tickAnnotation: 'TICK_ANNOTATION',
  cursorModifier:  'CURSOR_MODIFIER',
  left: 'LEFT',
  right: 'RIGHT',
  bidsSeries: 'BIDS_SERIES',
  asksSeries: 'ASKS_SERIES',
  bidsAnnotation: 'BIDS_ANNOTATION',
  asksAnnotation: 'ASKS_ANNOTATION',
  lineAnnotation: 'LINE_ANNOTATION',
  midPrice: 'MID_PRICE',
};
