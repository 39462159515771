export default {
  announcements: [],
  ratio: {},
  orderBook: {},
  assetPrice: {},
  volumeWAP: {},
  dominance: {},
  volumeByPrice: {},
  volumeByTime: {},
  techAnalysis: {},
  fundingRate: {
    content: new Map(),
    pairs: new Map(),
    stableCoinIds: new Set(),
  },
  fundingRateRealized: {},
  scamScore: {},
  scamScorePoints: {},
  exchangePairs: {
    content: [],
    tokenIds: new Set(),
  },
  tokenInfo: {},
  tokenTimePeriod: {},
};
