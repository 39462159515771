<template>
  <div class="dashboard-layout">
    <AppSidebar
        ref="sidebar"
        @drag="drag"
        @dragend="dragend"
        @dragstart="dragstart"
    />
    <WidgetLayout
        id="content"
        ref="gridLayoutContainer"
        :layout-prop="layout"
        :show-copy-to-pd="false"
        :is-deletable="true"
        @layoutUpdated="layoutUpdated"
        @updateFilter="updateFiltersEvent"
    />
    <div
        v-if="!layout.length"
        class="terminal-placeholder"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import WidgetLayout from '@/layouts/WidgetLayout.vue';
import { defineAsyncComponent } from 'vue';

let DragPos = { 'x': null, 'y': null, 'w': 6, 'h': 1, 'i': null };
let mouseXY = { 'x': null, 'y': null };

function getCoordinate(e) {
  mouseXY.x = e.clientX;
  mouseXY.y = e.clientY;
}

export default {
  name: 'CustomLayout',

  components: {
    WidgetLayout,

    AppSidebar: defineAsyncComponent(() =>
        import('@/components/app-sidebar/AppSidebar.vue')
    ),
  },

  computed: {
    refGridLayout() {
      const children = this.$refs.gridLayoutContainer.$children;

      for (let i = 0; i < children.length; i++) {
        if (children[i].dragEvent) {
          return children[i]
        }
      }

      return {};
    },
  },

  data() {
    return {
      layout: [],
      colNum: 2,
      index: 0,
      isResizeble: false,
      crt: null,
      hasGridItem: false,
      isDragging: false,
      dragItem: null,
      lastScrollTop: null,
      // types: {
      //   performer: 'PERFORMER',
      //   loser: 'LOSER',
      //   spot: 'spot',
      //   perpetual: 'futures_perpetual'
      // },
    };
  },

  methods: {
    ...mapActions({
      getLayout: 'layouts/getLayout',
      setLayout: 'layouts/setLayout',
    }),

    startDrag() {
      this.isDragging = true;
    },

    dragstart(data) {
      this.startDrag();
      const e = data.event;
      const dragItem = data.element;

      if (!this.hasGridItem) {
        this.hasGridItem = true;
      }

      let el = this.refGridLayout;

      this.crt = e.target.children[1].cloneNode(true);

      this.crt.style.backgroundColor = 'rgba(2, 201, 191, 0.24)';
      this.crt.style.width = dragItem.componentW === 12 ? `${el._data.width}px` : `${(el._data.width / 2) - 15}px`;
      this.crt.style.height = '678px';
      this.crt.style.border = '1px dashed #02C9BF';
      this.crt.style.borderRadius = '12px';
      this.crt.style.display = 'flex';
      this.crt.style.alignItems = 'center';
      this.crt.style.justifyContent = 'center';
      this.crt.style.flexWrap = 'wrap';
      this.crt.style.fontSize = '25px';

      document.body.appendChild(this.crt);
      e.dataTransfer.setDragImage(this.crt, el._data.width / 4, el._data.width / 6);
    },

    createLayoutItem(x, y, w, h, i, minW = 1, minH = 1, componentName, isStatic = false, stretched = false, initialParameters = {}, filters = {}) {
      return {
        x,
        y,
        w,
        h,
        i,
        minW,
        minH,
        componentName,
        static: isStatic,
        stretched,
        initialParameters,
        update: false,
        filters,
      };
    },

    addItem(name, initialParameters, x, y, i, w, h) {
      const newItem = this.createLayoutItem(
          x,
          y,
          w || 6,
          h || 23,
          i,
          1,
          h || 23,
          name,
          false,
          false,
          initialParameters || {},
          {}
      );

      this.layout.push(newItem);
    },

    drag(data) {
      const dragItem = data.element;
      let parentRect = document.getElementById('content').getBoundingClientRect();
      let mouseInGrid = false;
      if (((mouseXY.x > parentRect.left) && (mouseXY.x < parentRect.right)) && ((mouseXY.y > parentRect.top) && (mouseXY.y < parentRect.bottom))) {
        mouseInGrid = true;
      }
      if (mouseInGrid === true && (this.layout.findIndex(item => item.i === 'drop')) === -1) {
        this.layout.push({
          x: (this.layout.length * 1) % (this.colNum || 2),
          y: this.layout.length + 1 + (this.colNum || 2), // puts it at the bottom
          w: dragItem.componentW || 6,
          h: dragItem.componentH || 23,
          i: 'drop',
        });
      }

      let index = this.layout.findIndex(item => item.i === 'drop');
      if (index !== -1) {
        try {
          this.refGridLayout.$children[this.layout.length].$refs.item.style.display = 'none';
        } catch {
          return;
        }
        let el = this.refGridLayout.$children[index];
        el.dragging = { 'top': mouseXY.y - parentRect.top, 'left': mouseXY.x - parentRect.left };
        let new_pos = el.calcXY(mouseXY.y - parentRect.top, mouseXY.x - parentRect.left);
        if (mouseInGrid === true) {
          this.$refs.sidebar.closeMenu();
          this.refGridLayout.dragEvent('dragstart', 'drop', new_pos.x, new_pos.y, dragItem.componentH || 23, dragItem.componentW || 6);
          DragPos.i = String(index);
          DragPos.x = this.layout[index].x;
          DragPos.y = this.layout[index].y;
        }
        if (mouseInGrid === false) {
          this.refGridLayout.dragEvent('dragend', 'drop', new_pos.x, new_pos.y, dragItem.componentH || 23, dragItem.componentW || 6);
          this.layout = this.layout.filter(obj => obj.i !== 'drop');
        }
      }
    },
    dragend(data) {
      this.stopDrag();
      // const e = data.event
      const el = data.element;
      let parentRect = document.getElementById('content').getBoundingClientRect();
      let mouseInGrid = false;
      if (((mouseXY.x > parentRect.left) && (mouseXY.x < parentRect.right)) && ((mouseXY.y > parentRect.top) && (mouseXY.y < parentRect.bottom))) {
        mouseInGrid = true;
      }
      if (mouseInGrid === true) {
        this.refGridLayout.dragEvent('dragend', 'drop', DragPos.x, DragPos.y, 23, 1);
        this.layout = this.layout.filter(obj => obj.i !== 'drop');

        this.addItem(el.componentName, el.initialParameters, DragPos.x, DragPos.y, this.layout.length, el.componentW, el.componentH);
        this.refGridLayout.dragEvent('dragend', DragPos.i, DragPos.x, DragPos.y, 23, 1);
        try {
          this.refGridLayout.$children[this.layout.length].$refs.item.style.display = 'block';
        } catch (e) {
          return e;
        }
      }
    },

    // dblclick(data) {
    //   // const e = data.event
    //   const el = data.element
    //   const x = (this.layout.length) % (this.colNum || 2)
    //   const y = this.layout.length + (this.colNum || 2)
    //   const i =  this.layout.length + 1;
    //
    //   this.addItem(el.componentName, el.initialParameters, x, y, i, el.componentW, el.componentH)
    // },

    handleDrag(event) {
      if (this.isDragging) {
        const container = this.$refs.gridLayoutContainer;
        const scrollThreshold = 0;
        const scrollTop = container.scrollTop;
        const scrollHeight = container.scrollHeight;
        const containerHeight = container.offsetHeight;
        const scrollBottom = scrollHeight - containerHeight;
        const mouseY = event.clientY + scrollTop;
        const mouseOverTop = mouseY < scrollTop + scrollThreshold;
        // const mouseOverBottom = mouseY > containerHeight - scrollThreshold && mouseY < containerHeight;
        const mouseOverBottom = mouseY > containerHeight - scrollThreshold;

        if (mouseOverTop && scrollTop > 0) {
          container.scrollTop -= 30;
        } else if (mouseOverBottom && scrollTop < scrollBottom) {
          container.scrollTop += 30;
        }
      }
    },

    stopDrag() {
      this.isDragging = false;
      if (this.crt) {
        this.layout = this.layout.filter(obj => obj.i !== 'drop');
        document.body.removeChild(this.crt);
        this.crt = null;
      }
    },

    layoutUpdated(newLayout) {
      if (newLayout.length) {
        this.setLayout({ layout: newLayout });
      }
    },

    updateFiltersEvent({ item, event: filters }) {
      const searchIndex = this.layout.findIndex((el) => el.i == item.i);
      this.layout[searchIndex].filters = filters;
      this.layoutUpdated(this.layout);
    },
  },

  mounted() {
    document.addEventListener('mousemove', this.handleDrag);
    document.addEventListener('mouseup', this.stopDrag);
    document.addEventListener('dragover', getCoordinate, false);

    this.getLayout();

    this.layout = this.$store.getters['layouts/getCustomLayout'];
    this.index = this.layout.length + 1;
  },

  beforeDestroy() {
    document.removeEventListener('mousemove', this.handleDrag);
    document.removeEventListener('mouseup', this.stopDrag);
    document.removeEventListener('dragover', getCoordinate, false);
  }
};
</script>

<style lang="scss" scoped>
.terminal-container {
  margin-left: auto;
  width: calc(100% - 64px);
  padding-top: 62px;
}

.terminal-placeholder {
  display: flex;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: url("@/assets/images/dashboard-bg.png") 50% 50%/contain no-repeat;

  img {
    width: auto;
    height: 90%;
    object-fit: contain;
  }
}
</style>