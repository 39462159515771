<template>
  <div class="chart-wrapper scam-score">
    <SectionHeader
        :info-text="description"
        :is-deletable="false"
        :is-filters-open="isFiltersOpen"
        :stretchable="true"
        :title="title"
        @removeItem="$emit('removeItem', null)"
        @setStatic="$emit('setStatic', $event)"
        @setStretchPosition="setStretchPosition"
        @toggleFiltersVisibility="isFiltersOpen = $event"
    />
    <div class="exchange-info">
      <div class="exchange-info-item">
        <div class="exchange-info-item-title">
          <span class="exchange-info-item-title-value">Empirically Evaluated Scam Score</span>
          <v-tooltip max-width="400" top>
            <template v-slot:activator="{ on, attrs }">
              <svg
                  class="info-icon"
                  fill="none"
                  height="16"
                  v-bind="attrs"
                  viewBox="0 0 16 16"
                  width="16"
                  xmlns="http://www.w3.org/2000/svg" v-on="on"
              >
                <path
                    clip-rule="evenodd"
                    d="M7.20898 5.19649C7.20898 4.82968 7.55646 4.51114 8.0005 4.51114C8.43486 4.51114 8.78241 4.82968 8.78241 5.19649C8.78241 5.56333 8.43488 5.89152 8.0005 5.89152C7.55649 5.89152 7.20898 5.56333 7.20898 5.19649ZM7.25716 7.23327C7.25716 6.963 7.56605 6.77955 8.00044 6.77955C8.41552 6.77955 8.75334 6.96297 8.75334 7.23322V10.911C8.75334 11.1427 8.41552 11.3743 8.00044 11.3743C7.56605 11.3743 7.25716 11.1427 7.25716 10.911V7.23327Z"
                    fill="#92939C"
                    fill-rule="evenodd"
                />
                <circle cx="8" cy="8" r="7" stroke="#828392" stroke-width="1.5" />
              </svg>
            </template>
            <span>{{ 'description' }}</span>
          </v-tooltip>
        </div>
        <!--        <div class="exchange-info-item-value">-->
        <!--          <span class="exchange-info-item-volume">21%</span>-->
        <!--          <div class="exchange-percent-wrap up">-->
        <!--            <svg fill="none" height="4" viewBox="0 0 8 4" width="8" xmlns="http://www.w3.org/2000/svg">-->
        <!--              <path-->
        <!--                  d="M6.81948 0.666748H1.18165C0.724524 0.666748 0.495963 1.17269 0.819758 1.46928L3.28631 3.72857C3.68153 4.09058 4.32436 4.09058 4.71958 3.72857L5.65763 2.86934L7.18613 1.46928C7.50516 1.17269 7.2766 0.666748 6.81948 0.666748Z"-->
        <!--                  fill="white"-->
        <!--              />-->
        <!--            </svg>-->
        <!--            <span>{{ 1.60 }}%</span>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>

      <transition name="expand-filters">
        <div v-show="isFiltersOpen" ref="sectionFilters" class="chart-filters">
          <div class="chart-filter">
            <MultiSelect
                ref="exchanges"
                :default-option-value="parametersForSelects.exchanges || defaultExchanges"
                :options="exchanges"
                title="Exchanges"
                @emptySelect="parameters.exchangeIds = defaultExchanges"
                @selected="updateParameter($event, 'exchangeIds')"
            />
          </div>

          <div class="chart-filter">
            <Select
                ref="asset"
                :default-option-value="parametersForSelects.asset || defaultAsset"
                :options="assets"
                title="Asset"
                @emptySelect="parameters.asset = defaultAsset"
                @selected="updateParameter($event, 'asset')"
            />
          </div>

          <div class="chart-filter">
            <Select
                ref="pair"
                :default-option-value="defaultPair"
                :options="pairs"
                title="Pair"
                @emptySelect="parameters.currencyPair  = defaultPair"
                @selected="updateParameter($event, 'currencyPair')"
            />
          </div>
          <div class="chart-filter">
            <Select
                ref="dimension"
                :default-option-value="defaultDimension"
                :options="dimensions"
                title="Time Period"
                @emptySelect="parameters.dimension = defaultDimension"
                @selected="updateParameter($event, 'dimension')"
            />
          </div>
          <div class="chart-filter reset">
            <button :disabled="resetDisabled || showPreloader" class="reset" @click="resetParameters">
              Reset
            </button>
          </div>
        </div>
      </transition>
      <!--        <div class="scam-statistic">-->
      <!--          <h4 class="scam-statistic-title">What is Scam Score?</h4>-->
      <!--          <p class="scam-statistic-descr">Lorem ipsum dolor sit amet consectetur. Interdum dignissim in nec congue velit fringilla nisi id. Lorem lorem ut venenatis molestie sagittis rhoncus enim aliquet consequat. Dignissim viverra elementum nunc elementum volutpat ornare venenatis suspendisse. Tincidunt quam elementum mattis condimentum vestibulum vel sit amet dictumst. </p>-->
      <!--        </div>-->
    </div>
    <div class="layout-item-content custom-scroll">
      <div v-show="showPreloader" class="main-preloader widget-preloader chart-preloader">
        <Loader
            ref="preloader"
            :animationData="require('@/assets/images/Loader.json')"
            :autoPlay="true"
            :loop="true"
            :speed="1"
        />
      </div>

      <highcharts
          ref="chart"
          :options="chartOptions"
          :style="{ width: '100%', height: chartHeight }"
          constructor-type="stockChart"
      />
    </div>
  </div>
</template>

<script>
import SectionHeader from "@/components/ui/section/SectionHeader.vue";
import column from '@/mixins/chart-settings/column';
import { mapActions, mapGetters } from "vuex";
import propsParameters from '@/mixins/propsParameters';
import commonFilters from '@/mixins/filters/commonFilters';

export default {
  name: "ScamScore",

  components: {
    SectionHeader,
  },

  mixins: [commonFilters, column, propsParameters],

  props: {

    isResizeble: {
      type: Boolean,
      required: false,
    },
  },

  data() {
    return {
      parameters: {},
      title: 'Scam Score',
      description: 'Description Scam Score',
      showPreloader: true,
      suffix: '%',
      resetDisabled: true,
      isFiltersOpen: true,
    };
  },

  computed: {
    ...mapGetters({
      exchangesInfo: 'filters/exchangesInfo',
      exchangeSlugs: 'filters/exchangeSlugs',
      naming: 'filters/naming',
      filters: 'filters/scamScore',
      scamScoreScatter: 'charts/scamScorePoints',
      coinsInfo: 'filters/coinsInfo',
    }),

    defaultParameters() {
      return {
        exchangeIds: this.defaultExchanges,
        asset: this.defaultAsset,
        currencyPair: this.defaultPair,
        dimension: this.defaultDimension,
      };
    },

    defaultExchanges() {
      return [this.exchangeSlugs[this.$route.params.exchange] + ''];
    },

    exchanges() {
      const asset = this.parameters?.asset ?? this.propsParameters?.asset ?? this.initialParameters?.asset ?? 'futures_perpetual';
      const exchangesIds = Object.keys(this.filters).filter(exchangesId => this.filters[exchangesId][asset]);

      return new Map(exchangesIds.map(exchangeId => [exchangeId, this.exchangesInfo[exchangeId].displayName]));
    },

    assets() {
      const exchanges = this.parameters.exchanges || this.defaultExchanges;
      const allAssets = [...new Set(exchanges.flatMap(exchange => Object.keys(this.filters[exchange] || {})))];

      return new Map(allAssets.map(asset => [asset, this.naming[asset] || asset]));
    },

    defaultPair() {
      return this.pairs.keys().next().value;
    },

    pairs() {
      const exchanges = this.parameters.exchanges ?? this.defaultExchanges;
      const asset = this.parameters.asset || this.defaultAsset;
      const allPairs = exchanges.flatMap(exchange => this.filters[exchange]?.[asset] || []);

      return new Map(allPairs.map(pair => [pair.toString(), `${this.coinsInfo[pair[0]]?.symbol.toUpperCase() || pair[0]}/${this.coinsInfo[pair[1]]?.symbol.toUpperCase() || pair[1]}`]));
    },

    dimensions() {
      return new Map([
        ['WEEK_1', 'Week'],
        ['MONTH_1', 'Month'],
        ['YEAR_1', 'Year'],
      ]);
    },
  },

  methods: {
    ...mapActions({
      getScamScore: 'charts/getScamScore',
    }),

    setStretchPosition() {
      this.$refs.chart.chart.fullscreen.toggle();
    },

    updateParameter(parameter, name) {
      this.resetDisabled = false;
      this.parameters = {
        ...this.parameters,
        [name]: parameter
      };

      this.getChartData();

      this.resize();
    },

    async getChartData() {
      try {
        this.showPreloader = true;
        const scamScoreData = await this.getScamScore(this.parameters);

        this.chartOptions.series = Object.entries(scamScoreData).map(([exchangeName, data]) => {
          const exchange = this.exchangesInfo[exchangeName];

          return {
            name: exchange.displayName,
            color: exchange.color,
            data,
          };
        });
      } catch (e) {
        console.log(e);
      } finally {
        this.showPreloader = false;
      }
    },

    async resetParameters() {
      this.resetDisabled = true;
      this.parameters = { ...this.defaultParameters };

      await this.getChartData();

      this.$refs.exchanges.reset();
      this.$refs.asset.reset();
      this.$refs.pair.reset();
      this.$refs.dimension.reset();
    },
  },

  created() {
    this.parameters = { ...this.defaultParameters };
    this.getChartData();
  }
};
</script>

<style scoped>
.exchange-info {
  padding: 0 20px;
}

.exchange-info-item:not(:last-child) {
  margin-bottom: 20px;
}

.exchange-info-item-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  line-height: 16px;
  color: var(--textSecondaryColor);
}

.exchange-info-item-title-value {
  display: inline-block;
  margin-right: 5px;
}

.exchange-info-item-value {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.exchange-percent-wrap {
  margin-left: 10px;
}

.exchange-info-item-volume {
  font-family: "Play";
  font-size: 32px;
  font-weight: 700;
  font-style: normal;
  line-height: 48px;
  color: var(--textMainColor);
}

.exchange-info-item-amount {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  line-height: 16px;
  color: #e9e9e9;
}

.exchange-info-item-amount span {

}

.scam-info {
  margin-bottom: 20px;
}

.scam-info-title {
  display: block;
  margin-bottom: 10px;
  font-family: "Play";
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  line-height: 30px;
  color: #fff;
  color: var(--textMainColor);
}

.scam-info-descr {
  font-family: "Inter";
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  line-height: 16px;
  color: var(--textSecondaryColor);
}

.chart-filters {
  margin-bottom: 20px;
  border-bottom: none;
  padding: 0;
}

</style>