<template>
  <div class="chart-wrapper">
    <Gauge
        :data="technicalsData"
        :background-color="backgroundColor"
        :isResizeble="isResizeble"
        :plot-bands-settings="[
          { color: '#bf453d', text: 'Strong<br />Sell' },
          { color: '#f27870', text: 'Sell' },
          { color: '#ffd166', text: 'Neutral' },
          { color: '#67ca9d', text: 'Buy' },
          { color: '#1bb8b0', text: 'Strong<br />Buy' },
      ]"
    />
  </div>
</template>

<script>
import Gauge from '@/components/charts/Gauge';

export default {
  name: 'Technicals',

  components: { Gauge },

  props: {
    backgroundColor: {
      type: String,
      required: false,
    },

    isResizeble: Boolean,

    technicalsData: {
      type: Number,
      default: 50,
      required: false,
    }
  },
};
</script>

<style scoped>

</style>