'use strict';
let __extends = (this && this.__extends) || (function () {
  let extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf ||
      ({ __proto__: [] } instanceof Array && function (d, b) {
        d.__proto__ = b;
      }) ||

      function (d, b) {
        for (let p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      };

    return extendStatics(d, b);
  };

  return function (d, b) {
    if (typeof b !== 'function' && b !== null) {
      throw new TypeError('Class extends value ' + String(b) + ' is not a constructor or null');
    }

    extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
})();
exports.__esModule = true;
exports.CursorModifier = void 0;

const { IDs } = require('@/modifiers/walls-chart/IDs');
const ChartModifierBase2D_1 = require('scichart/Charting/ChartModifiers/ChartModifierBase2D');
const AnnotationBase_1 = require('scichart/Charting/Visuals/Annotations/AnnotationBase');
const LineAnnotation_1 = require('scichart/Charting/Visuals/Annotations/LineAnnotation');
const MousePosition_1 = require('scichart/types/MousePosition');
const translate_1 = require('scichart/utils/translate');
const LabelPlacement_1 = require('scichart/types/LabelPlacement');
const RolloverMarkerSvgAnnotation_1 = require('scichart/Charting/Visuals/Annotations/RolloverMarkerSvgAnnotation');
const TextAnnotation_1 = require('scichart/Charting/Visuals/Annotations/TextAnnotation');
const VerticalLineAnnotation_1 = require('scichart/Charting/Visuals/Annotations/VerticalLineAnnotation');
const AnchorPoint_1 = require('scichart/types/AnchorPoint');

const CursorModifier = /** @class */ (function (_super) {
  __extends(CursorModifier, _super);

  function CursorModifier(options) {
    let _a, _b;

    const _this = _super.call(this, options) || this;
    _this.type = 'WallsCursor';
    _this.crosshairStrokeDashArray = [];
    _this.axisLabelStroke = '#fff';
    _this.bidColor = '#02c9bf';
    _this.askColor = '#f27870';
    _this.mousePosition = MousePosition_1.EMousePosition.OutOfCanvas;
    _this.crosshairStrokeThickness = (_a = options === null || options === void 0 ? void 0 : options.crosshairStrokeThickness) !== null && _a !== void 0 ? _a : _this.crosshairStrokeThickness;
    _this.crosshairStrokeDashArray = (_b = options === null || options === void 0 ? void 0 : options.crosshairStrokeDashArray) !== null && _b !== void 0 ? _b : _this.crosshairStrokeDashArray;
    _this.bidsSeries = options.bidsSeries;
    _this.midMarketPrice = options.midLine;
    _this.asksSeries = options.asksSeries;
    _this.intersection = options.intersection;
    _this.asksData = options.asksData;
    _this.bidsData = options.bidsData;
    _this.showVerticalLineAnnotation = options.showVerticalLineAnnotation;

    return _this;
  }

  CursorModifier.prototype.onAttach = function () {
    _super.prototype.onAttach.call(this);

    this.xBidLineAnnotation = this.createLineAnnotation(this.bidColor, '#02c9bf', this.axisLabelStroke);
    this.yBidLineAnnotation = this.createLineAnnotation(this.bidColor, '#02c9bf', this.axisLabelStroke, true);
    this.xAskLineAnnotation = this.createLineAnnotation(this.askColor, '#f27870', this.axisLabelStroke);
    this.yAskLineAnnotation = this.createLineAnnotation(this.askColor, '#f27870', this.axisLabelStroke, true);
    this.parentSurface.modifierAnnotations.add(this.xBidLineAnnotation, this.yBidLineAnnotation, this.xAskLineAnnotation, this.yAskLineAnnotation);
    this.createMarkers();
    this.parentSurface.modifierAnnotations.add(this.bidsSeries.rolloverModifierProps.marker, this.asksSeries.rolloverModifierProps.marker);
    this.bidLabel = this.createTextAnnotation(AnchorPoint_1.EHorizontalAnchorPoint.Right);
    this.askLabel = this.createTextAnnotation(AnchorPoint_1.EHorizontalAnchorPoint.Left);
    this.parentSurface.modifierAnnotations.add(this.bidLabel, this.askLabel);
    this.midLine = new VerticalLineAnnotation_1.VerticalLineAnnotation({
      stroke: '#1e1e1e',
      showLabel: false,
      axisLabelFill: 'white',
      id: IDs.midPrice,
      labelPlacement: LabelPlacement_1.ELabelPlacement.Top,
      strokeThickness: 2,
      x1: this.midMarketPrice,
    });

    this.parentSurface.modifierAnnotations.add(this.midLine);
  };

  CursorModifier.prototype.onDetach = function () {
    _super.prototype.onDetach.call(this);

    this.getAnnotations().forEach(annotation => this.removeAnnotation(annotation));
    this.xBidLineAnnotation = undefined;
    this.yBidLineAnnotation = undefined;
    this.xAskLineAnnotation = undefined;
    this.yAskLineAnnotation = undefined;

    [this.bidsSeries, this.asksSeries].forEach((rs) => {
      this.parentSurface.modifierAnnotations.remove(rs.rolloverModifierProps.marker);
      rs.rolloverModifierProps['delete']();
    });

    this.bidLabel = undefined;
    this.askLabel = undefined;
  };

  CursorModifier.prototype.modifierMouseMove = function (args) {
    _super.prototype.modifierMouseMove.call(this, args);

    if (!this.mousePoint) {
      this.mousePosition = MousePosition_1.EMousePosition.OutOfCanvas;
    } else {
      const translatedMousePoint = (translate_1.translateFromCanvasToSeriesViewRect)(this.mousePoint, this.parentSurface.seriesViewRect);

      this.mousePosition = !translatedMousePoint
        ? MousePosition_1.EMousePosition.AxisArea
        : MousePosition_1.EMousePosition.SeriesArea;
    }

    this.update();
  };

  CursorModifier.prototype.modifierMouseLeave = function (args) {
    this.parentSurface.modifierAnnotations.items.forEach(annotation => {
      if (annotation.id === IDs.lineAnnotation) {
        annotation.x1 = 0;
        annotation.x2 = 0;
        annotation.y1 = 0;
        annotation.y2 = 0;
      }
    });

    this.parentSurface.annotations.items.forEach(annotation => {
      if (annotation.id !== IDs.tickAnnotation) {
        annotation.opacity = 0;
      }
    });

    _super.prototype.modifierMouseLeave.call(this, args);
    this.mousePosition = MousePosition_1.EMousePosition.OutOfCanvas;
    this.update();
  };

  CursorModifier.prototype.modifierMouseEnter = function (args) {
    _super.prototype.modifierMouseEnter.call(this, args);
  };

  CursorModifier.prototype.onParentSurfaceRendered = function () {
    this.update();
  };

  CursorModifier.prototype.getAnnotations = function () {
    return [
      this.xBidLineAnnotation,
      this.yBidLineAnnotation,
      this.xAskLineAnnotation,
      this.yAskLineAnnotation,
      this.midLine,
      this.bidLabel,
      this.askLabel,
    ];
  };

  CursorModifier.prototype.hitTestRenderableSeries = function (rs, mousePoint) {
    return mousePoint ? rs.hitTestProvider.hitTestXSlice(mousePoint.x, mousePoint.y) : undefined;
  };

  CursorModifier.prototype.getMousePosition = function () {
    return this.mousePosition;
  };

  CursorModifier.prototype.toJSON = function () {
    let json = _super.prototype.toJSON.call(this);
    let options = {
      axisLabelFill: this.axisLabelFill,
      axisLabelStroke: this.axisLabelStroke,
      crosshairStrokeDashArray: this.crosshairStrokeDashArray,
      crosshairStrokeThickness: this.crosshairStrokeThickness
    };
    Object.assign(json.options, options);

    return json;
  };
  // CursorModifier.prototype.notifyPropertyChanged = function (propertyName) {
  //   let _this = this;
  //   _super.prototype.notifyPropertyChanged.call(this, propertyName);
  //   if (propertyName === constants_1.PROPERTY.X_AXIS_ID) {
  //     this.getAnnotations().forEach(function (l) {
  //       if (l) {
  //         l.xAxisId = _this.xAxisId;
  //       }
  //     });
  //   }
  //   if (propertyName === constants_1.PROPERTY.Y_AXIS_ID) {
  //     this.getAnnotations().forEach(function (l) {
  //       if (l) {
  //         l.yAxisId = _this.yAxisId;
  //       }
  //     });
  //   }
  // };

  CursorModifier.prototype.update = function () {
    if (this.mousePosition !== MousePosition_1.EMousePosition.SeriesArea) {
      this.getAnnotations().forEach(annotation => {
        if (annotation.id !== IDs.midPrice) {
          annotation.isHidden = true;
        }
      });

      this.parentSurface.renderableSeries.get(0).paletteProvider.from = this.intersection.from;
      this.parentSurface.renderableSeries.get(1).paletteProvider.to = this.intersection.to;
      this.bidsSeries.rolloverModifierProps.marker.isHidden = true;
      this.asksSeries.rolloverModifierProps.marker.isHidden = true;

      return;
    }

    const translatedMousePoint = translate_1.translateFromCanvasToSeriesViewRect(this.mousePoint, this.parentSurface.seriesViewRect);

    if (!this.bidsSeries || !this.asksSeries || !translatedMousePoint) {
      return;
    }

    this.getAnnotations().forEach(annotation => {
      annotation.isHidden = false;
      annotation.isSvgAnnotation = false;
    });

    const midPrice = this.midMarketPrice;
    const bidHitTestInfo = this.hitTestRenderableSeries(this.bidsSeries, this.mousePoint);

    this.midCoord = translate_1.translateToNotScaled(this.parentSurface.getXAxisById(this.xAxisId).getCurrentCoordinateCalculator().getCoordinate(midPrice));
    this.xBidLineAnnotation.x1 = midPrice;
    this.xAskLineAnnotation.x1 = midPrice;

    if (this.midCoord * window.devicePixelRatio > this.mousePoint.x && bidHitTestInfo && bidHitTestInfo.isWithinDataBounds) {
      const ask = this.asksData[bidHitTestInfo.dataSeriesIndex] || this.asksData.at(-1);

      if (ask) {
        const askHitTestInfo = {
          xValue: ask[0],
          yValue: ask[1],
          yCoord: this.getYCoord(ask[1]),
        };
        const xBidValue = bidHitTestInfo.xValue || this.parentSurface.getXAxisById(this.xAxisId).visibleRange.min;
        const xAskValue = askHitTestInfo.xValue || this.parentSurface.getXAxisById(this.xAxisId).visibleRange.max;

        this.updateBid(bidHitTestInfo);
        this.updateAsk(askHitTestInfo);
        this.updatePalette(xAskValue, xBidValue);
      }
    } else {
      const askHitTestInfo = this.hitTestRenderableSeries(this.asksSeries, this.mousePoint);
      const bid = this.bidsData[askHitTestInfo.dataSeriesIndex] || this.bidsData.at(-1);

      if (bid) {
        const bidHitTestInfo_1 = {
          xValue: bid[0],
          yValue: bid[1],
          yCoord: this.getYCoord(bid[1]),
        };
        const xBidValue = bidHitTestInfo_1.xValue || this.parentSurface.getXAxisById(this.xAxisId).visibleRange.min;
        const xAskValue = askHitTestInfo.xValue || this.parentSurface.getXAxisById(this.xAxisId).visibleRange.max;

        this.updateAsk(askHitTestInfo);
        this.updateBid(bidHitTestInfo_1);
        this.updatePalette(xAskValue, xBidValue);
      }
      // }
    }
  };

  CursorModifier.prototype.updateBid = function (bidHitTestInfo) {
    const y = translate_1.translateToNotScaled(bidHitTestInfo.yCoord || this.biggestYBidCoordinate);
    const xValue = bidHitTestInfo.xValue || this.parentSurface.getXAxisById(this.xAxisId).visibleRange.min;
    const difference = Math.round(((xValue / this.midMarketPrice - 1) * 100) * 1000) / 1000;

    this.xBidLineAnnotation.x2 = bidHitTestInfo.xValue;
    this.xBidLineAnnotation.y1 = bidHitTestInfo.yValue;
    this.xBidLineAnnotation.y2 = bidHitTestInfo.yValue;
    this.yBidLineAnnotation.x1 = bidHitTestInfo.xValue;
    this.yBidLineAnnotation.x2 = bidHitTestInfo.xValue;
    this.yBidLineAnnotation.y1 = 0;
    this.yBidLineAnnotation.y2 = 1;
    this.bidLabel.x1 = (this.midCoord - 10) / window.devicePixelRatio;
    this.bidLabel.y1 = (y - 5) / window.devicePixelRatio;
    this.bidsSeries.rolloverModifierProps.marker.isHidden = false;
    this.bidsSeries.rolloverModifierProps.marker.x1 = bidHitTestInfo.xValue;
    this.bidsSeries.rolloverModifierProps.marker.y1 = bidHitTestInfo.yValue;
    this.bidLabel.text = Math.abs(difference) + '%';

    if (bidHitTestInfo.yValue) {
      const annotation = this.parentSurface.annotations.getById(IDs.bidsAnnotation);

      if (annotation) {
        annotation.opacity = 1;
        annotation.y1 = bidHitTestInfo.yValue;
      }
    }
  };

  CursorModifier.prototype.updateAsk = function (askHitTestInfo) {
    const y = translate_1.translateToNotScaled(askHitTestInfo.yCoord || this.biggestYAskCoordinate);
    const xValue = askHitTestInfo.xValue || this.parentSurface.getXAxisById(this.xAxisId).visibleRange.max;
    const difference = Math.round(((xValue / this.midMarketPrice - 1) * 100) * 1000) / 1000;

    this.xAskLineAnnotation.x2 = askHitTestInfo.xValue;
    this.xAskLineAnnotation.y1 = askHitTestInfo.yValue;
    this.xAskLineAnnotation.y2 = askHitTestInfo.yValue;
    this.yAskLineAnnotation.x1 = askHitTestInfo.xValue;
    this.yAskLineAnnotation.x2 = askHitTestInfo.xValue;
    this.yAskLineAnnotation.y1 = 0;
    this.yAskLineAnnotation.y2 = 1;
    this.asksSeries.rolloverModifierProps.marker.isHidden = false;
    this.asksSeries.rolloverModifierProps.marker.x1 = askHitTestInfo.xValue;
    this.asksSeries.rolloverModifierProps.marker.y1 = askHitTestInfo.yValue;
    this.askLabel.x1 = (this.midCoord + 10) / window.devicePixelRatio;
    this.askLabel.y1 = (y - 5) / window.devicePixelRatio;
    this.askLabel.text = Math.abs(difference) + '%';

    if (askHitTestInfo.yValue) {
      const annotation = this.parentSurface.annotations.getById(IDs.asksAnnotation);

      if (annotation) {
        annotation.opacity = 1;
        annotation.y1 = askHitTestInfo.yValue;
      }
    }
  };

  CursorModifier.prototype.updatePalette = function (xAsk, xBid) {
    this.parentSurface.renderableSeries.get(0).paletteProvider.from = xBid < xAsk ? xBid : xAsk;
    this.parentSurface.renderableSeries.get(1).paletteProvider.to = xBid > xAsk ? xBid : xAsk;
  };

  CursorModifier.prototype.getYCoord = function (y) {
    return this.parentSurface.yAxes.get(0).getCurrentCoordinateCalculator().getCoordinate(y);
  };

  CursorModifier.prototype.createMarkers = function () {
    [this.bidsSeries, this.asksSeries].forEach((rs) => {
      rs.rolloverModifierProps.marker = new RolloverMarkerSvgAnnotation_1.RolloverMarkerSvgAnnotation(rs.rolloverModifierProps);
      rs.rolloverModifierProps.marker.xAxisId = rs.xAxisId;
      rs.rolloverModifierProps.marker.yAxisId = rs.yAxisId;
      rs.rolloverModifierProps.rolloverModifier = this;
      rs.rolloverModifierProps.markerColor = rs.stroke;
    });
  };

  CursorModifier.prototype.createLineAnnotation = function (lineStroke, axisLabelFill, axisLabelStroke, isX) {
    return new LineAnnotation_1.LineAnnotation({
      xCoordinateMode: AnnotationBase_1.ECoordinateMode.DataValue,
      yCoordinateMode: isX ? AnnotationBase_1.ECoordinateMode.Relative : AnnotationBase_1.ECoordinateMode.DataValue,
      strokeThickness: this.crosshairStrokeThickness,
      strokeDashArray: this.crosshairStrokeDashArray,
      stroke: !isX || this.showVerticalLineAnnotation ? lineStroke : 'transparent',
      isHidden: true,
      id: IDs.lineAnnotation,
      showLabel: this.showVerticalLineAnnotation || isX,
      axisLabelFill: axisLabelFill,
      axisLabelStroke: axisLabelStroke,
      xAxisId: this.xAxisId,
      yAxisId: this.yAxisId,
    });
  };

  CursorModifier.prototype.createTextAnnotation = (horizontalAnchorPoint) => (
    new TextAnnotation_1.TextAnnotation({
      xCoordinateMode: AnnotationBase_1.ECoordinateMode.Pixel,
      yCoordinateMode: AnnotationBase_1.ECoordinateMode.Pixel,
      fontSize: 11,
      fontFamily: 'Inter, sans-serif',
      textColor: 'var(--lightColor)',
      verticalAnchorPoint: AnchorPoint_1.EVerticalAnchorPoint.Bottom,
      horizontalAnchorPoint: horizontalAnchorPoint,
    })
  );

  // CursorModifier.prototype.isVerticalChart = function () {
  //   let _a, _b;
  //   let xAxis = ((_a = this.parentSurface) === null || _a === void 0 ? void 0 : _a.getXAxisById(this.xAxisId)) || ((_b = this.parentSurface) === null || _b === void 0 ? void 0 : _b.xAxes.get(0));
  //
  //   return xAxis ? xAxis.isVerticalChart : false;
  // };
  // CursorModifier.prototype.removeAnnotation = function (annotation) {
  //   if (annotation) {
  //     annotation['delete']();
  //     this.parentSurface.modifierAnnotations.remove(annotation);
  //   }
  // };
  return CursorModifier;
}(ChartModifierBase2D_1.ChartModifierBase2D));
exports.CursorModifier = CursorModifier;
