export const crosshair = {
  width: 1,
  color: 'var(--mainBorderColor)',
  dashStyle: 'dash',
  interpolate: true,
  zIndex: 15,

  label: {
    underAxisLabels: false,
    useHTML: true,
    zIndex: 2,
    enabled: true,
    padding: 6,
    shape: 'unset',
  }
};

export const marker = {
  lineWidth: 1,
  lineColor: 'var(--mainBGColor)',
  symbol: 'circle',
  radius: 2,
};

export const plotLine = {
  color: '#02c9bf7f',
  width: 1,
  value: 44.4,
  dashStyle: 'shortDash',

  label: {
    text: '%',
    align: 'right',
    textAlign: 'center',
    x: 21,
    y: 1.5,
    padding: [0, 10, 0, 10],
    useHTML: true,
    zIndex: 0,

    style: {
      color: '',
      backgroundColor: '#187b76',
      padding: '0 3px'
    },
  }
};

export const states = {
  inactive: {
    enabled: false,
  },
  hover: {
    lineWidth: 1,
    halo: {
      size: 0,
    }
  }
};

export const setCurrentValue = (point, legends, findDifference) => {
  if (!point.series.name) return {};

  const legendIndex = legends.findIndex(legend => legend.name === point.series.name);
  const legend = legends[legendIndex] || {};

  legend.currentPrice = point.y;

  if (findDifference) {
    legend.difference = findDifference(point.series.name, point);
  }

  return {};
};
