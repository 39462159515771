import {deepCopyObject, getWindowSize} from '@/helpers';
export default {
  SET_LAYOUT(state) {
    const breakpoint = getWindowSize();
    // state.breakpoint = window.innerWidth < 1024 ? 'sm' : 'lg';
    state.breakpoint = breakpoint;
    state.resized = !state.resized
  },

  SET_CUSTOM_LAYOUT(state, { customLayout, breakpoint }) {
    if(customLayout) {
      state.layout = deepCopyObject(customLayout[breakpoint]);
    } else {
      state.layout = deepCopyObject(state.customLayout[breakpoint]);
    }
  },

  SET_STRETCH_POSITION(state, { stretched, index }) {
    const itemIndex = state.layout.findIndex(item => item.i === index);

    state.layout.splice(itemIndex, 1, {
      ...state.layout[index],
      stretched,
    });
  },

  ADD_CHART(state, item) {
    const lastItem = state.layout.at(-1);

    state.layout = [
      ...state.layout,
      {
        ...item,
        y: lastItem.y + lastItem.h,
        i: lastItem.i + 2,
      }
    ];
  },

  REMOVE_CHART(state, item) {
    state.layout.splice(item.i, 1);
  },
};
