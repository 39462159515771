export default {
  maxCoinsMcap: ({ maxCoinsMcap }) => maxCoinsMcap,
  maxVolumeByDay: ({ maxVolumeByDay }) => maxVolumeByDay,
  exchangeAssetPrices: ({ filters }) => filters.orderBook,
  dominance: ({ filters }) => filters.dominance,
  technicalAnalysisFilter: ({ filters }) => filters.technicalAnalysis,
  naming: ({ naming }) => naming,
  exchangeSlugs: ({ exchangeSlugs }) => exchangeSlugs,
  coinSlugs: ({ coinSlugs }) => coinSlugs,
  exchangesInfo: ({ exchangesInfo }) => exchangesInfo,
  coinInfo: ({ coinInfo }) => coinInfo,
  coinsInfo: ({ coinsInfo }) => coinsInfo,
  unknownCoinsInfo: ({ unknownCoinsInfo }) => unknownCoinsInfo,
  orderbook: ({ filters }) => filters.orderBook,
  newSlippages: ({ filters }) => filters.slippages,
  scamScore: ({ filters }) => filters.scamScore,
  orderbookMap: ({ orderbookMap }) => orderbookMap,
};
