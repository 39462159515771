<template>
  <div class="chart-wrapper">
    <SectionHeader
        :is-deletable="false"
        :title="title"
        :toggle-filters-visible="false"
        @setStatic="$emit('setStatic', $event)"
    />
    <div class="layout-item-content custom-scroll">
      <div class="about-wrapper" v-html="description"></div>
      <!--        <a-->
      <!--            class="about-read-more"-->
      <!--            @click="readMore"-->
      <!--        >Read More</a>-->
    </div>
  </div>
</template>

<script>
import SectionHeader from "@/components/ui/section/SectionHeader.vue";
import { mapGetters } from "vuex";

export default {
  name: "About",

  components: {
    SectionHeader,
  },

  props: {},

  data: () => ({}),

  computed: {
    ...mapGetters({
      exchangesInfo: 'filters/exchangesInfo',
      exchangeSlugs: 'filters/exchangeSlugs',
    }),

    exchangeId() {
      return this.exchangeSlugs[this.$route.params.exchange];
    },

    exchange() {
      return this.exchangesInfo[this.exchangeId];
    },

    title() {
      return `About ${this.exchange.displayName}`;
    },

    description() {
      return this.exchange.description || '';
    },
  },

  methods: {
    readMore() {
      this.$emit('readMore', null);
    }
  }
};
</script>

<style lang="scss">
.about-wrapper div:not(:last-child) {
  margin-bottom: 20px;
}

.about-wrapper div h3 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: "Play";
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  line-height: 30px;
  color: var(--textMainColor);
}

.about-wrapper p {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  line-height: 16px;
  color: var(--textSecondaryColor);
}

.about-wrapper ul,
.about-wrapper ol {
  margin: 20px 0 0 0;
  padding: 0 0 0 15px;
  list-style-type: auto;
}

.about-wrapper li {
  margin: 0 0 10px 0;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  line-height: 16px;
  color: var(--textSecondaryColor);
}

.about-description:not(:last-child) {
  margin-bottom: 10px;
}

.about-read-more {
  display: inline-block;
  margin-top: 5px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  line-height: 21px;
  color: var(--accent);
}
</style>