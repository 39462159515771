import axios from 'axios';

axios.defaults.retry = 20;
axios.defaults.retryDelay = 5000;

// let registration = null
// const refreshSW = (e) => {
//   registration = e.detail;
// }
//
// document.addEventListener('swUpdated', refreshSW, { once: true });

const customAxios = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 25000,
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0',
    'Access-Control-Allow-Origin': '*'
  },
});

const requestHandler = request => {
  return request;
};

const responseHandler = response => {
  // console.log('response.headers[\'x-app-hash\']', response.headers['x-app-hash'])
  let fe_version = response.headers['x-app-hash'] || 'default'
  if(fe_version !== localStorage.getItem('fe-version') && response.config.method == 'get'){
    localStorage.setItem('fe-version', fe_version)
    if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage('skipWaiting');
    }
    window.location.reload()
    return response;
  }

  return response;
};

const errorHandler = error => {
  return Promise.reject(error);
};

customAxios.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
);

customAxios.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
);

// Add a response interceptor to retry failed requests
// customAxios.interceptors.response.use(null, (error) => {
//   const config = error.config;
//
//   // If the request was not initiated by axios, reject the error
//   if (!config) {
//     return Promise.reject(error);
//   }
//
//   // Increment the retry count and delay the retry
//   config.__retryCount = config.__retryCount || 0;
//   if (config.__retryCount >= axios.defaults.retry) {
//     // Reject the error if the maximum number of retries has been reached
//     return Promise.reject(error);
//   }
//   config.__retryCount += 1;
//
//   // Delay the retry using exponential backoff
//   const delay = axios.defaults.retryDelay * Math.pow(2, config.__retryCount - 1);
//   return new Promise((resolve) => setTimeout(() => resolve(customAxios(config)), delay));
// });

export default customAxios;
