<template>
  <div class="chart-wrapper tech-analysis-chart">
    <SectionHeader
        :is-filters-open="isFiltersOpen"
        :is-deletable="isDeletable"
        :show-copy-chart-to-pd="showCopyToPd"
        title="Crypto to Stock Indexes"
        @copyChartToPD="$emit('copyChartToPD', {})"
        @removeItem="removeItem"
        @setStretchPosition="setStretchPosition"
        @toggleFiltersVisibility="isFiltersOpen = $event"
    />

    <transition name="expand-filters">
      <div v-show="isFiltersOpen" class="chart-filters" ref="sectionFilters">
        <div class="chart-filter">
          <MultiSelect
              ref="exchange"
              :default-option-value="parametersForSelects.exchanges || defaultExchanges"
              :options="exchanges"
              title="exchanges"
              @selected="updateParameter($event, 'exchangeIds')"
          />
        </div>

        <div class="chart-filter">
          <MultiSelect
              ref="resources"
              :default-option-value="parametersForSelects.resources || defaultResources"
              :options="resources"
              title="Resources"
              @selected="updateParameter($event, 'resources')"
          />
        </div>

        <div class="chart-filter">
          <MultiSelect
              ref="pairs"
              :default-option-value="parametersForSelects.pairs || defaultPairs"
              :options="pairs"
              title="pairs"
              @emptySelect="parameters.pairs = defaultPairs"
              @selected="updateParameter($event, 'pairs')"
          />
        </div>

        <div class="chart-filter">
          <Select
              ref="dimension"
              :default-option-value="parametersForSelects.dimension || defaultDimension"
              :options="dimensions"
              title="time period"
              @selected="updateParameter($event, 'dimension')"
          />
        </div>

        <div class="chart-filter reset">
          <button class="reset" @click="resetParameters">
            Reset
          </button>
        </div>
      </div>
    </transition>

    <div class="chart-content">
      <highcharts
          ref="chart"
          :options="chartOptions"
          :style="{ width: '100%', height: '100%' }"
          constructor-type="stockChart"
      />

      <div v-show="showPreloader" class="main-preloader widget-preloader chart-preloader">
        <Loader
            ref="preloader"
            :animationData="require('@/assets/images/Loader.json')"
            :autoPlay="true"
            :loop="true"
            :speed="1"
        />
      </div>
    </div>

    <Legends
        :legend-parameters="seriesParameters"
        :legends="legends"
        measure="%"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import TechAnalysisFilters from '@/mixins/filters/techAnalysis';
import assetPrice from '@/mixins/chart-settings/assetPrice';

export default {
  name: 'CryptoToStockIndexes',

  components: {},

  props: {
    isResizeble: Boolean,
  },

  mixins: [TechAnalysisFilters, assetPrice],

  data: () => ({
    seriesParameters: {},
    suffix: '%',
  }),

  computed: {
    defaultParameters() {
      return {
        exchangeIds: this.defaultExchanges,
        pairs: this.defaultPairs,
        dimension: this.defaultDimension,
        resources: this.defaultResources,
      };
    },
  },

  methods: {
    ...mapActions({
      getTechnicalAnalysisPercentage: 'charts/getTechnicalAnalysisPercentage',
    }),


    async resetParameters() {
      this.parameters = { ...this.defaultParameters };

      await this.getChartData();

      this.$refs.exchange.reset();
      this.$refs.dimension.reset();
      this.$refs.pairs.reset();
      this.$refs.resources.reset();
    },

    findDifference(name, point) {
      return point.price;
    },

    setDifferentValue() {
      this.chartOptions.series.forEach(series => {
        const legend = this.legends.find(legend => legend.name === series.name);
        const lastItem = series.data.at(-1);

        legend.currentPrice = lastItem.y;
        legend.difference = lastItem.price;
      });
    },

    getChartData() {
      this.debounce(async () => {
        try {
          this.showPreloader = true;
          const response = await this.getTechnicalAnalysisPercentage(this.parameters);
          this.chartOptions.series = [];

          for (const key in response) {
            this.chartOptions.series.push({
              ...this.seriesItem,
              name: key,
              data: response[key],
            });
          }

          this.$nextTick(() => {
            const chart = this.$refs.chart?.chart;

            if (!chart) return;

            this.legends = chart.series.map((series) => ({
              name: series.name,
              currentPrice: series.yData.at(-1)
            }));

            this.seriesParameters = Object.fromEntries(chart.series.map(series => [
              series.name,
              {
                displayName: series.name,
                color: series.color
              }
            ]));
          });
        } catch (e) {
          console.log(e);
        } finally {
          this.showPreloader = false;
        }
      }, 500);
    },

    removeItem() {
      this.$emit('removeItem', null);
    },
  },

  created() {
    this.getChartData();
  }
};
</script>

<style lang="scss" scoped>
</style>