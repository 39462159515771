<template>
  <div class="no-data">
    <img alt="no data" class="no-data__image" src="@/assets/images/no-data.svg">
    <p class="no-data__description" v-html="text" />
  </div>
</template>

<script>
export default {
  name: 'NoData',

  props: {
    text: {
      type: String,
      required: false,
      default: 'No Data',
    },
  },
};
</script>

<style scoped lang="scss">
.no-data {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--mainBGColor);

  &__description {
     margin-top: 15px;
     text-align: center;
   }
}

</style>