import Vue from 'vue';
import Vuex from 'vuex';
import layouts from '@/store/modules/layouts';
import filters from '@/store/modules/inventory';
import charts from '@/store/modules/charts';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    layouts,
    filters,
    charts,
  }
});

export default store;