<template>
  <div class="select">
    <button
        class="select__button"
        @click="toggleSelect"
    >
      <span class="select__title">{{ title }}: </span>
      <span :title="selectedValues" class="select__selected">
        {{ selectedValues }}
        <!--        <object-->
        <!--            :class="{active: selectOpen}"-->
        <!--            :data="require('@/assets/images/arrow-dropdown.svg')"-->
        <!--            class="object-triangle"-->
        <!--            type="image/svg+xml"-->
        <!--        />-->
        <svg
            class="object-triangle"
            fill="#92939c"
            height="5"
            viewBox="0 0 10 5"
            width="10"
            xmlns="http://www.w3.org/2000/svg"
        >
          <path
              d="M9.22727 0H0.770516C0.0848333 0 -0.258008 0.758914 0.227684 1.20379L3.92751 4.59274C4.52034 5.13575 5.48458 5.13575 6.07741 4.59274L7.48449 3.30389L9.77724 1.20379C10.2558 0.758914 9.91295 0 9.22727 0Z"
              fill="var(--textSecondaryColor)"
          />
        </svg>
      </span>
    </button>
    <div
        v-show="selectOpen"
        v-click-outside="closeSelect"
        class="select__dropdown dropdown"
        @mouseleave="closeSelect"
    >
      <Search
          :is-show-search="options.size > 10"
          @search="filteredBy = $event"
      />
      <ul class="dropdown__list custom-scroll">
        <li
            v-for="([key, value], index) in filteredOptions"
            :key="index"
            :title="value"
            class="dropdown__item"
        >
          <button
              :class="{checked: selected.has(key)}"
              class="dropdown__button"
              @click="selectItem(key, value)"
          >
            <span class="dropdown__option-name">{{ value }}</span>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import debounce from '@/mixins/debounce';
import Search from "@/components/ui/inputs/Search.vue";

export default {
  name: 'MultiSelect',
  components: { Search },

  mixins: [debounce],

  props: {
    title: {
      type: String,
      required: true,
      filteringOptions: [],
    },

    options: {
      type: Map,
      required: true,
    },

    defaultOptionValue: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      selectOpen: false,
      selected: new Map(),
      filteredBy: '',
    };
  },

  watch: {
    options(options) {
      const selectedValues = [...this.selected.keys()].map(value => options.get(value));
      if (selectedValues[0] === undefined) {
        this.$emit('emptySelect');
        this.reset();
      } else {
        this.selected = new Map([...this.selected.entries()].filter(([key]) => options.get(key)));
      }
    }
  },

  computed: {
    selectedValues() {
      return Array.from(this.selected.values()).join(', ');
    },

    filteredOptions() {
      const filteredMap = new Map();
      const filterLowered = this.filteredBy.toLowerCase();

      this.options.forEach((value, key) => {
        if (typeof value === 'string' && ~value.toLowerCase().indexOf(filterLowered)) {
          filteredMap.set(key, value);
        }
      });

      return filteredMap;
    }
  },

  methods: {
    closeSelect() {
      this.selectOpen = false;
    },

    toggleSelect() {
      setTimeout(() => {
        this.selectOpen = !this.selectOpen;
      }, 0);
    },

    emitSelected() {
      this.$emit('selected', Array.from(this.selected.keys()));
    },

    selectItem(key, value) {
      const selected = new Map(this.selected.entries());
      const alreadySelected = selected.has(key);

      alreadySelected
          ? selected.delete(key)
          : selected.set(key, value);

      this.selected = selected;

      if (!this.selected.size) {
        this.reset();
      }

      this.emitSelected();
    },

    reset() {
      this.selected = new Map(
          this.defaultOptionValue.map(key => [key, this.options.get(key)])
      );
    }
  },

  created() {
    this.reset();
  }
};
</script>

<style lang="scss" scoped src="./select.scss" />
