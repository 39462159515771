import { render, staticRenderFns } from "./ScamScore.vue?vue&type=template&id=58903172&scoped=true&"
import script from "./ScamScore.vue?vue&type=script&lang=js&"
export * from "./ScamScore.vue?vue&type=script&lang=js&"
import style0 from "./ScamScore.vue?vue&type=style&index=0&id=58903172&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58903172",
  null
  
)

export default component.exports