export default {
    watch: {
        filters: {
            deep: true,
            handler() {
                this.applyFilters();
            }
        },
    },
    methods: {
        showFiltersPopup() {
            this.$refs.drawer.openDrawer();
        },

        deepClone(obj)  {
            if (obj === null) return null;
            let clone = Object.assign({}, obj);
            Object.keys(clone).forEach(
                key =>
                    (clone[key] =
                        typeof obj[key] === 'object' ? this.deepClone(obj[key]) : obj[key])
            );
            if (Array.isArray(obj)) {
                clone.length = obj.length;
                return Array.from(clone);
            }

            if (obj instanceof Map) {
                return new Map(obj);
            }
            return clone;
        },

        updateParameters(event, filterName) {
            this.resetDisabled = false;
            this.parameters[filterName] = event;
            this.page = 0;
        },

        resetParameters() {
            this.resetDisabled = true;
            this.resetFilters();
            const parameters = this.deepClone(this.defaultFilters);
            this.parameters = { ...this.defaultParameters, ...parameters };

            this.page = 0;
            if(this.$refs.exchange) {
                this.$refs.exchange.reset();
            }
            if(this.$refs.asset) {
                this.$refs.asset.reset();
            }

            this.$refs.sectionHeader.resetFilters();
        },

        applyFilters() {
            const filters = this.deepClone(this.filters);
            this.parameters = { ...this.parameters, ...filters };
            // this.closeFiltersPopup();
            this.resetDisabled = false;
            this.page = 0;
        },

        resetFilters() {
            this.filters = this.deepClone(this.defaultFilters);
            this.page = 0;
        },

        sorting(arr, sortBy, sortType) {
            if(sortType === 'string') {
                return [...arr].sort((a, b) => a[sortBy].toLowerCase().localeCompare(b[sortBy].toLowerCase()));
            }
            if(sortType === 'number') {

                return [...arr].sort((a, b) => {
                    let first = a[sortBy];
                    let second = b[sortBy];

                    if (first === '-' && second === '-') return 0;
                    if (first === '-') return this.reversed ? -1 : 1;
                    if (second === '-') return this.reversed ? 1 : -1;

                    return first - second;
                });
            }
        },

        sortTable(sortBy, sortType) {

            this.sortType = sortType;

            this.page = 0;

            this.reversed = this.sortBy === sortBy ? !this.reversed : true;
            this.sortBy = sortBy;
        },

        handleDragStart(index) {
            this.isDragging = true;
            this.dragIndex = index;
        },

        handleDrop(index) {
            const entries = Array.from(this.parameters.visibleColumnsFilters);

            if (this.dragIndex !== null && this.dragIndex !== index) {
                const draggedColumn = entries.splice(this.dragIndex, 1)[0];
                entries.splice(index, 0, draggedColumn);
                this.parameters.visibleColumnsFilters = new Map(entries);
                this.saveState();
            }
            this.isDragging = false;
            this.dragIndex = null;
        },

        allowDrop(event) {
            event.preventDefault();
        },
    },

    created() {
        this.defaultFilters.visibleColumns = [...this.defaultFilters.visibleColumnsFilters.keys()];
        const parameters = this.deepClone(this.defaultFilters);
        this.parameters = { ...this.defaultParameters, ...parameters };
        this.filters = this.deepClone(this.defaultFilters);
    },
}