<template>
  <div class="table-wrapper">
    <div class="custom-scroll max-screen-width">
      <table>
        <thead>
          <tr>
            <th class="text-left">#</th>
            <th class="text-left">Token</th>
            <th>Price</th>
            <th
                v-for="header in tableHeaders"
                :key="header.columnID"
                :class="header.className + (header.sortField === sortedBy ? ' sorted active-column' : '')"
                class="sortable"
                @click="$emit('sortTable', header.sortField)"
                v-text="header.columnName"
            />
            <th>Market Cap</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in topData" :key="index" ref="row">
            <td class="text-left rank">
              <span class="secondary-text">
                <!--              {{ page * 4 + index + 1 }}-->
                {{ item.rank }}
              </span>
            </td>
            <td class="text-left">
              <div class="symbol-wrapper">
                <LazyImg class="logo" :id="item.id" :is-token="true" />

                <div class="symbol-info text-left">
                  <router-link
                      :to="{ path: `/token/${item.slug}` }"
                      class="symbol-full-name"
                  >
                    {{ item.displayName }}
                  </router-link>
                  <span class="symbol-abbr secondary-text">{{ item.symbol.toUpperCase() }}</span>
                </div>
              </div>
            </td>
            <!--        <td class="text-left">-->
            <!--          <span>{{ item.pair }}</span>-->
            <!--        </td>-->
            <td class="price">
              <span
                  :key="index + +new Date()"
                  :class="item.className"
              >
                {{ roundNumber(noExponents(item.lastPrice)) }}
              </span>
              <span class="secondary-text"> $</span>
            </td>
            <td :class="{'active-column': tableHeaders[0].sortField === sortedBy}" class="day-per">
              <span :class="setColorGrowth(item.pricePercentChangeDay)">
                {{ roundNumber(item.pricePercentChangeDay) }}
              </span>
              <span class="secondary-text"> %</span>
            </td>
            <td :class="{'active-column': tableHeaders[1].sortField === sortedBy}" class="week-per">
              <span :class="setColorGrowth(item.pricePercentChangeWeek)">
                {{ roundNumber(item.pricePercentChangeWeek) }}
              </span>
              <span class="secondary-text"> %</span>
            </td>
            <td :class="{'active-column': tableHeaders[2].sortField === sortedBy}" class="month-per">
              <span :class="setColorGrowth(item.pricePercentChangeMonths)">
                {{ roundNumber(item.pricePercentChangeMonths) }}
              </span>
              <span class="secondary-text"> %</span>
            </td>
            <td :class="{'active-column': tableHeaders[3].sortField === sortedBy}" class="year-per">
              <span :class="setColorGrowth(item.pricePercentChangeYear)">
                {{ roundNumber(item.pricePercentChangeYear) }}
              </span>
              <span class="secondary-text"> %</span>
            </td>
            <td class="market-cap">
              <span>{{ makeCompactNumber(item.mcap) }}</span>
              <span class="secondary-text"> $</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <CustomPagination
        v-if="totalPages > 1"
        :page-count="size"
        :page-number="page"
        :pagination-size="totalPages"
        :total-items="totalElements"
        @pageChanged="$emit('pageChanged', $event)"
    />
  </div>
</template>

<script>
import { makeCompactNumber } from '@/helpers';

export default {
  name: 'TopStats',

  components: {},

  props: {
    topData: {
      type: Array,
      required: true,
    },

    sortedBy: {
      type: String,
      required: true,
    },

    totalElements: {
      type: Number,
      required: true,
    },

    totalPages: {
      type: Number,
      required: true,
    },

    page: {
      type: Number,
      required: true,
    },

    size: {
      type: Number,
      required: true,
    },

  },

  data: () => ({
    defaultImage: require(`@/assets/images/default-token-icon.svg`),
    tableHeaders: [
      { sortField: 'pricePercentChangeDay', columnName: '24h %', columnID: 4, },
      { sortField: 'pricePercentChangeWeek', columnName: '1W %', columnID: 5, },
      { sortField: 'pricePercentChangeMonths', columnName: '1M %', columnID: 6, },
      { sortField: 'pricePercentChangeYear', columnName: '1Y %', columnID: 7, },
    ]
  }),

  methods: {
    makeCompactNumber,

    noExponents(number) {
      const data = String(number).split(/[eE]/);

      if (data.length === 1) {
        return data[0];
      }

      const sign = number < 0 ? '-' : '';

      let z = '';
      const str = data[0].replace('.', '');
      let mag = Number(data[1]) + 1;

      if (mag < 0) {
        z = `${sign}0.`;
        while (mag++) z += '0';
        return z + str.replace(/^-/, '');
      }

      mag -= str.length;

      while (mag--) {
        z += '0';
      }

      return str + z;
    },

    setColorGrowth(value) {
      switch (Math.sign(value)) {
        case 1:
          return 'up';
        case -1:
          return 'down';
        default:
          return '';
      }
    },

    roundNumber(number) {
      let maxFractionDigits = 4;

      if (+number >= 10 ** maxFractionDigits) {
        return this.makeCompactNumber(number, 1, 1);
      }

      const integerPart = Math.abs(Math.round(number)) + '';
      let numFractionDigits = maxFractionDigits - integerPart.length;
      numFractionDigits = numFractionDigits > 2 ? 2 : numFractionDigits;

      return this.makeCompactNumber(number, numFractionDigits, numFractionDigits);
    },
  },
};
</script>

<style lang="scss" scoped src="@/assets/css/table.scss" />
<style lang="scss" scoped>
table {
  th,
  td {
    padding-right: 5px;
    text-align: right;
    vertical-align: middle !important;
  }

  td {
    vertical-align: middle;
  }
}

.rank {
  width: 5%;
}

.symbol-info {
  flex-grow: 1;
  width: 100px;
}

.symbol-wrapper {
  display: flex;
  align-items: center;

  .logo {
    display: block;
    margin-right: 12px;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    object-fit: contain;
  }
}

.market-cap {
  width: 18%;
  text-align: right;
  white-space: nowrap;
}

.symbol-full-name {
  display: block;
  overflow: hidden;
  transition: all .5s;
  text-decoration: none;
  text-overflow: ellipsis;
  color: var(--textMainColor);
}

.symbol-full-name:hover {
  color: var(--accent);
}

.color-up {
  animation: color-up 2s;
}

.color-down {
  animation: color-down 2s;
}

@keyframes color-up {
  0% {
    color: var(--accent);
  }

  50% {
    color: var(--accent);
  }

  100% {
    color: var(--v-mainColor-base);
  }
}

@keyframes color-down {
  0% {
    color: #f27870;
  }

  50% {
    color: #f27870;
  }

  100% {
    color: var(--v-mainColor-base);
  }
}
</style>
