<template>
  <div class="table-wrapper">
    <table>
      <thead>
        <tr>
          <th class="rank">
            <div class="header-wrapper">#</div>
          </th>
          <th
              v-for="header in headers"
              :key="header.id"
              :class="{
                [header.className]: !!header.className,
               'active-column': sortedBy === header.sortBy
              }"
          >
            <button class="header-wrapper" @click="sortTable(header.sortBy)">
              <svg
                  class="arrows"
                  fill="none"
                  height="13"
                  viewBox="0 0 12 13"
                  width="12"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    :class="{active: !isSortAscending}"
                    d="M9 4.49998L6 1.49999L3 4.49998"
                    stroke="#92939C"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                />
                <path
                    :class="{active: isSortAscending}"
                    d="M3 8.50002L6 11.5L9 8.50002"
                    stroke="#92939C"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                />
              </svg>

              {{ header.text }}
            </button>
          </th>
        </tr>
      </thead>

      <tbody>
        <template v-for="(market, i) in sortedMarketsData">
          <tr v-if="isExchangePage ? coinsInfo[market.coinId] : exchangesInfo[market.exchangeId]" :key="i">
            <td class="rank">{{ market.coinRank }}</td>

            <td :class="{ 'active-column': sortedBy === headers[0].sortBy}" class="pair">
              <div class="pair-wrapper">
                <LazyImg
                    :id="isExchangePage ? market.coinId : market.exchangeId"
                    :is-token="isExchangePage"
                />
                <div class="pair-info">
                  <router-link
                      :to="{
                      path: isExchangePage
                      ? `/token/${coinsInfo[market.coinId]?.slug}`
                      :  `/exchange/${exchangesInfo[market.exchangeId]?.slug}`
                    }"
                      class="pair-info__link"
                  >
                    <span>
                      {{
                        isExchangePage
                            ? (coinsInfo[market.coinId]?.slug ?? market.coinId)
                            : exchangesInfo[market.exchangeId].displayName
                      }}
                    </span>
                  </router-link>
                </div>
              </div>
            </td>

            <td :class="{ 'active-column': sortedBy === headers[1].sortBy}" class="pairs">
              <a
                  :href="market.url"
                  class="pairs__link"
                  target="_blank"
              >
                {{ market.pair }}

                <svg
                    v-if="market.url"
                    fill="none"
                    height="16"
                    viewBox="0 0 17 16"
                    width="17"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="M7.38053 2V3.33333H4.0472V12.6667H13.3805V9.33333H14.7139V13.3333C14.7139 13.7015 14.4154 14 14.0472 14H3.38053C3.01235 14 2.71387 13.7015 2.71387 13.3333V2.66667C2.71387 2.29848 3.01235 2 3.38053 2H7.38053ZM12.4377 3.33333H9.38053V2H14.7139V7.33333H13.3805V4.27614L8.71387 8.9428L7.77107 8L12.4377 3.33333Z"
                      fill="#92939C"
                  />
                </svg>
              </a>
            </td>

            <td :class="{ 'active-column': sortedBy === headers[2].sortBy}" class="assets">
              {{ getAsset(market.asset) }}
            </td>

            <td :class="{ 'active-column': sortedBy === headers[3].sortBy}" class="price">
              ${{ prettifyNumber(String(aroundNumber(market.price) || 0)) }}
            </td>

            <td :class="{ 'active-column': sortedBy === headers[4].sortBy}" class="depth-positive">
              ${{ prettifyNumber(String(aroundNumber(market.depthPT) || 0)) }}
            </td>

            <td :class="{ 'active-column': sortedBy === headers[5].sortBy}" class="depth-negative">
              ${{ prettifyNumber(String(aroundNumber(market.depthNT) || 0)) }}
            </td>

            <td :class="{ 'active-column': sortedBy === headers[6].sortBy}" class="liquidity">
              {{ market.liq || 0 }}
            </td>

            <td :class="{ 'active-column': sortedBy === headers[7].sortBy}" class="volume">
              ${{ prettifyNumber(String(aroundNumber(market.vol) || 0)) }}
            </td>

            <td
                :class="{ 'active-column': sortedBy === headers[8].sortBy}"
                :title="market.volPer"
                class="volume-percent"
            >
              {{ market.volPer.toFixed(2) }}%
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <!--    <CustomPagination-->
    <!--        v-if="announcements.totalPages > 1"-->
    <!--        :page-count="announcements.size"-->
    <!--        :page-number="announcements.number"-->
    <!--        :pagination-size="announcements.totalPages"-->
    <!--        :total-items="announcements.totalElements"-->
    <!--        @pageChanged="$emit('pageChanged', $event)"-->
    <!--    />-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import prettifyNumber from "@/mixins/helpers/prettifyNumber";
import aroundNumber from "@/mixins/helpers/aroundNumber";
import sortByProperty from '@/mixins/helpers/sortByProperty';

export default {
  name: 'ExchangeMarkets',

  mixins: [prettifyNumber, aroundNumber, sortByProperty],

  props: {
    marketsData: {
      type: Array,
      // required: true,
    },
  },

  data: () => ({
    sortedBy: 'liq',
    isSortAscending: false,
  }),

  computed: {
    ...mapGetters({
      exchangesInfo: 'filters/exchangesInfo',
      coinsInfo: 'filters/coinsInfo',
    }),

    isExchangePage() {
      return this.$route.name === 'exchangePage';
    },

    headers() {
      return [
        // { text: '#', sortBy: 'displayName', className: 'rank', id: 0,},
        {
          text: this.isExchangePage ? 'Tokens' : 'Exchanges',
          sortBy: this.isExchangePage ? 'coinId' : 'exchangeId',
          className: 'pair',
          id: 1,
        },
        { text: 'Pairs', sortBy: 'pair', className: 'pairs', id: 2, },
        { text: 'Type', sortBy: 'asset', id: 3, },
        { text: 'Price', sortBy: 'price', id: 4, },
        { text: '+2% Depth', sortBy: 'depthPT', id: 5, },
        { text: '-2% Depth', sortBy: 'depthNT', id: 6, },
        { text: 'Liquidity', sortBy: 'liq', id: 7, },
        { text: 'Volume', sortBy: 'vol', id: 8, },
        { text: 'Volume %', sortBy: 'volPer', id: 9, },
      ];
    },

    sortedMarketsData() {
      return this.sortByProperty(this.pairsWithVolumePercent, this.sortedBy, this.isSortAscending);
    },

    totalVolume() {
      return this.marketsData.reduce((acc, { vol }) => acc + vol, 0);
    },

    pairsWithVolumePercent() {
      const { totalVolume } = this;

      return this.marketsData.map(pair => ({
        ...pair,
        volPer: pair.vol / totalVolume * 100
      }));
    }
  },

  methods: {
    sortTable(sortBy) {
      this.isSortAscending = this.sortedBy === sortBy ? !this.isSortAscending : false;
      this.sortedBy = sortBy;
    },

    getAsset(asset) {
      return asset === 'futures_perpetual' ? 'Perpetual' :
             asset === 'futures' ? 'Futures' : 'Spot'
    },
  },
};
</script>

<style lang="scss" scoped src="@/assets/css/secondary-table.scss" />
<style lang="scss" scoped>
.header-wrapper {
  width: 100%;
}

.pair-wrapper {
  gap: 8px;
}
</style>
