<template>
  <div class="order-book-table table-wrapper custom-scroll">
    <table>
      <thead>
        <tr>
          <th
              v-for="(header, index) in tableHeaders"
              :key="header.id"
              :class="{
              'sorted active-column': header.sortBy === sortedBy,
              last: index === tableHeaders.length - 1,
              first: index === 0,
            }"
              class="sortable"
              @click="sortTable(header.sortBy)"
          >
            <div class="header-wrapper">
              <svg
                  class="arrows"
                  fill="none"
                  height="13"
                  viewBox="0 0 12 13"
                  width="12" xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    :class="{active: !isSortAscending}"
                    d="M9 4.49998L6 1.49999L3 4.49998"
                    stroke="#92939C"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                />
                <path
                    :class="{active: isSortAscending}"
                    d="M3 8.50002L6 11.5L9 8.50002"
                    stroke="#92939C"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                />
              </svg>
              {{ header.text }}
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
            v-for="(orderBookItem, i) in sortedOrderbook"
            :key="i"
            :class="{active: activeExchangeId === orderBookItem.exchangeId}"
        >
          <td :class="{'active-column first': tableHeaders[0].sortBy === sortedBy}" class="exchange">
            <router-link :to="{ path: `/exchange/${exchangesInfo[orderBookItem.exchangeId].slug}` }" class="exchange-wrapper">
              <lazy-img
                  :id="orderBookItem.exchangeId"
                  :is-token="false"
                  class="img"
              />
              <div class="exchange-info">
                <span class="exchange-info__name">
                  {{ orderBookItem.exchange }}
                  <div
                      v-if="!exchangesInfo[orderBookItem.exchangeId].isApproved"
                      class="exchange-info__warning"
                      title="Exchange is suspicious"
                  />
                </span>
                <span>
                  {{ getPercent(roundValue(orderBookItem.exchangePercentage)) }}
                  <span class="secondary-text">%</span>
                </span>
              </div>
            </router-link>
          </td>
          <td
              :class="{'active-column': tableHeaders[1].sortBy === sortedBy}"
              class="spot-access"
          >
            <div class="info-raw">
              <span>Total Spot</span>
              <span v-if="orderBookItem.totalSpotPercentage" :title="orderBookItem.totalSpotPercentage">
                {{ getPercent(roundValue(orderBookItem.totalSpotPercentage)) }}
                <span class="secondary-text">%</span>
              </span>
              <span v-else>
                -
                <span class="secondary-text">%</span>
              </span>
            </div>
            <div
                v-for="(value, pair, i) in orderBookItem.spotPercentage"
                :key="i"
                class="info-raw"
            >
              <span class="secondary-text">
                {{ `${orderBookItem.base}/${coinsInfo[pair]?.symbol.toUpperCase() ?? pair}` }}
              </span>
              <span :title="value" class="secondary-text">
                {{ getPercent(roundValue(value)) }}
                <span class="secondary-text">%</span>
              </span>
            </div>
          </td>
          <td
              :class="{'active-column': tableHeaders[2].sortBy === sortedBy}"
              class="spot-vol"
          >
            <div class="info-raw">
              <span>Total Spot</span>
              <span
                  v-if="orderBookItem.totalSpotVolume"
                  :title="makeCompactNumber(orderBookItem.totalSpotVolume)"
              >
                {{ makeCompactNumber(orderBookItem.totalSpotVolume) }}
                <span class="secondary-text">$</span>
              </span>
              <span v-else>
                -
                <span class="secondary-text">$</span>
              </span>
            </div>
            <div
                v-for="(value, pair, i) in orderBookItem.spotVolume"
                :key="i"
                class="info-raw"
            >
              <span class="secondary-text">
                {{ `${orderBookItem.base}/${coinsInfo[pair]?.symbol.toUpperCase() ?? pair}` }}
              </span>
              <span :title="makeCompactNumber(value)">
                {{ makeCompactNumber(value) }}
                <span class="secondary-text ">$</span>
              </span>
            </div>
          </td>
          <td
              :class="{'active-column': tableHeaders[3].sortBy === sortedBy}"
              class="perps-assets"
          >
            <div class=" info-raw">
              <span>Total Perps</span>
              <span v-if="orderBookItem.totalPerpetualPercentage" :title="orderBookItem.totalPerpetualPercentage">
                {{ getPercent(roundValue(orderBookItem.totalPerpetualPercentage)) }}
                <span class="secondary-text">%</span>
              </span>
              <span v-else>
                -
                <span class="secondary-text">%</span>
              </span>
            </div>
            <div
                v-for="(value, pair, i) in orderBookItem.perpetualPercentage"
                :key="i"
                class=" info-raw"
            >
              <span class="secondary-text">
                {{ `${orderBookItem.base}/${coinsInfo[pair]?.symbol.toUpperCase() ?? pair}` }}
              </span>
              <span :title="value">
                {{ getPercent(roundValue(value)) }}
                <span class="secondary-text">%</span>
              </span>
            </div>
          </td>
          <td
              :class="{'active-column last': tableHeaders[4].sortBy === sortedBy}"
              class="perps-vol"
          >
            <div class="info-raw">
              <span>Total Perps</span>
              <span
                  v-if="orderBookItem.totalPerpetualVolume"
                  :title="makeCompactNumber(orderBookItem.totalPerpetualVolume)"
              >
                {{ makeCompactNumber(orderBookItem.totalPerpetualVolume) }}
                <span class="secondary-text">$</span>
              </span>
              <span v-else>
                -
                <span class="secondary-text">$</span>
              </span>
            </div>
            <div
                v-for="(value, pair, i) in orderBookItem.perpetualVolume"
                :key="i"
                class="info-raw"
            >
              <span class="secondary-text">
                {{ `${orderBookItem.base}/${coinsInfo[pair]?.symbol.toUpperCase() ?? pair}` }}
              </span>
              <span :title="makeCompactNumber(value)">
                {{ makeCompactNumber(value) }}
                <span class="secondary-text">$</span>
              </span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { makeCompactNumber } from '@/helpers';
import sortByProperty from '@/mixins/helpers/sortByProperty';

export default {
  name: 'VolumeByExchange',

  mixins: [sortByProperty],

  props: {
    orderBook: {
      type: Array,
      required: true
    },

    activeExchangeId: {
      type: Number,
      required: false,
      default: 0,
    }
  },

  data: () => ({
    tableHeaders: [
      { sortBy: 'exchange', text: 'Exchange', id: 2, },
      { sortBy: 'totalSpotPercentage', text: 'Spot Assets', id: 3, },
      { sortBy: 'totalSpotVolume', text: 'Spot Volume', id: 4, },
      { sortBy: 'totalPerpetualPercentage', text: 'Perpetual Assets', id: 5, },
      { sortBy: 'totalPerpetualVolume', text: 'Perpetual Volume', id: 6, },
    ],
    sortedBy: 'exchange',
    isSortAscending: true,
  }),

  computed: {
    ...mapGetters({
      exchangesInfo: 'filters/exchangesInfo',
      coinsInfo: 'filters/coinsInfo',
    }),

    sortedOrderbook() {
      return this.sortByProperty(this.orderBook, this.sortedBy, this.isSortAscending);
    }
  },

  methods: {
    makeCompactNumber,

    roundValue(value) {
      return Math.round(value * 10000) / 10000;
    },

    getPercent(num) {
      return (num * 100).toFixed(2);
    },

    sortTable(sortBy) {
      this.isSortAscending = this.sortedBy === sortBy ? !this.isSortAscending : true;
      this.sortedBy = sortBy;
    },
  }
};
</script>

<style lang="scss" scoped src="@/assets/css/table.scss" />
<style lang="scss" scoped>
.order-book-table {
  border-right: 1px solid var(--mainBorderColor);
  width: calc(100% - 420px);

  table {
    height: 100%;
  }
}

tr.active::after {
  background-color: #000 !important;
}

tbody td {
  width: 20%;
}

.exchange-info {
  display: flex;
  flex-direction: column;

  &__name {
    position: relative;
  }

  &__warning {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(150%, -50%);
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background-color: #f96;
    cursor: pointer;
  }
}

.exchange-wrapper {
  display: flex;
  align-items: center;
  transition: all .5s;
  font-family: "Inter", sans-serif;
  font-size: .875rem;
  font-weight: 500;
  font-style: normal;
  line-height: 18px;
  color: var(--textMainColor);

  &:hover {
    color: var(--accent);
  }

  .img {
    display: block;
    margin-right: 12px;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    -o-object-fit: contain;
    object-fit: cover;
  }
}

td > div {
  margin-bottom: 5px;
  max-width: 160px;

  span:not(:last-child) {
    margin-right: 10px;
  }
}

.info-raw {
  display: flex;
  justify-content: space-between;
}

</style>
