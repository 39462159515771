import { plotLine } from '@/mixins/chart-settings/_commonSettings';
import moment from 'moment/moment';
import reflowHighchart from '@/mixins/reflowHighchart';

export default {
  mixins: [reflowHighchart],

  data() {
    const _this = this;

    return {
      chartOptions: {
        chart: {
          // height: 380,
          backgroundColor: 'var(--mainBGColor)',
          zoomType: 'x',
          // animation: true,
          panKey: 'shift',
          type: 'column',
          panning: {
            enabled: true,
            type: 'x'
          },
          resetZoomButton: {
            theme: {
              fill: 'var(--accent)',
              stroke: 'var(--accent)',

              style: {
                fontFamily: 'Inter, sans-serif',
                fontWeight: 700,
                color: '#fff',
              },

              states: {
                hover: {
                  fill: '#00fff7',
                  stroke: '#00fff7',
                }
              }
            },
          },
        },

        tooltip: {
          split: false,
          shared: true,
          useHTML: true,

          formatter() {
            const total = +this.points.reduce((acc, point) => acc + point.y, 0).toFixed(2);
            const tooltipPoints = this.points.map(point => {
              const percentage = point.y / total * 100;
              const percentageTwoSymbolsAfterComma = Math.round(percentage * 100) / 100;

              return `
              <div class="tooltip__item">
                <div class="tooltip__item-color" style="background-color: ${point.color}"></div>
                <span>${point.series.name}</span> 
                <span>${percentageTwoSymbolsAfterComma}%</span>
              </div>
            `
            }).join('');

            return `
              <div class="tooltip">
                <div class="tooltip__header">
                  <span>${moment(this.x).format('YYYY-MM-DD HH:mm')}</span>
                  <span>Total: ${total}</span>
                </div>
                
                <div class="tooltip__body">${tooltipPoints}</div>
              </div>
            `;
          }
        },

        exporting: {
          enabled: false,
        },

        navigator: {
          enabled: false
        },

        legend: {
          enabled: false
        },

        yAxis: [
          {
            gridLineWidth: 1,
            gridLineColor: 'var(--gridLineColor)',
            plotLines: [{
              ...plotLine,

              label: {
                ...plotLine.label,

                formatter() {
                  return _this.formatLabel(this.options.value);
                },
              }
            }],
            showLastLabel: true,
            showFirstLabel: false,
            endOnTick: false,
            showOnTick: false,

            labels: {
              align: 'left',
              y: 4,
              x: 10,

              style: {
                fontFamily: 'Inter, sans-serif',
                color: '#fff',
              },
            },
          },
        ],

        xAxis: [
          {
            gridLineWidth: 1,
            gridLineColor: 'var(--gridLineColor)',
            tickPixelInterval: 190,
            crosshair: {
              color: 'transparent',
            },
            labels: {
              align: 'center',
              // y: 15,
              x: 0,
              rotation: 0,

              style: {
                fontFamily: 'Inter, sans-serif',
                color: '#fff',
              },

              formatter(event) {
                return moment(event.value).format('YYYY-MM-DD HH:mm');
              },
            },
          }
        ],

        rangeSelector: {
          enabled: false,
        },

        scrollbar: {
          enabled: false
        },

        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
            states: {
              hover: {
                // color: 'rgba(2, 201, 191, 1)'
              }
            }
          },

          series: {
            dataGrouping: {
              enabled: false,
            },

            marker: {
              lineColor: 'var(--mainBGColor)'
            },

            states: {
              inactive: {
                enabled: false
              },

              hover: {
                lineWidth: 2,
                halo: {
                  size: 0,
                }
              }
            }
          },
        },

        series: [],
      },
    };
  },

  watch: {
    resized() {
      this.resize();
    },

    isResizeble() {
      this.resize();
    },
  },

  methods: {
    resize() {
      setTimeout(() => {
        this.chartOptions.chart.width = '100%';
        this.chartOptions.chart.width = null;
        this.$refs.chart.chart.reflow();
      }, 500);
    },

    formatLabel(event) {
      const maxNumberLength = 5;
      const integerNum = Math.abs(Math.round(event)) + '';
      const fractionDigits = maxNumberLength - integerNum.length;

      return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
      }).format(event);
    }
  },
};