var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"chart-wrapper"},[_c('Gauge',{attrs:{"data":_vm.technicalsData,"background-color":_vm.backgroundColor,"isResizeble":_vm.isResizeble,"plot-bands-settings":[
        { color: '#bf453d', text: 'Strong<br />Sell' },
        { color: '#f27870', text: 'Sell' },
        { color: '#ffd166', text: 'Neutral' },
        { color: '#67ca9d', text: 'Buy' },
        { color: '#1bb8b0', text: 'Strong<br />Buy' },
    ]}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }