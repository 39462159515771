<template>
  <div class="chart-wrapper">
    <SectionHeader
        :is-deletable="false"
        :is-filters-open="isFiltersOpen"
        :stretchable="true"
        :title="'Token to USD Chart'"
        info-text="Token description"
        @setStatic="$emit('setStatic', $event)"
        @setStretchPosition="toggleChartFullscreen"
        @toggleFiltersVisibility="isFiltersOpen = $event"
    />

    <transition name="expand-filters">
      <div v-show="isFiltersOpen" ref="sectionFilters" class="chart-filters">
        <div class="chart-filter">
          <Select
              ref="dimension"
              :default-option-value="defaultDimension"
              :options="dimensions"
              title="Time Period"
              @selected="updateParameter($event, 'dimension')"
          />
        </div>
      </div>
    </transition>

    <div class="layout-item-content">
      <highcharts
          ref="chart"
          :isResizeble="isResizeble"
          :options="chartOptions"
          :style="{ width: '100%', height: chartHeight }"
          constructor-type="stockChart"
      />
    </div>
  </div>
</template>

<script>
import SectionHeader from "@/components/ui/section/SectionHeader.vue";
import { mapActions, mapGetters } from "vuex";
import CMCToken from '@/mixins/chart-settings/CMCToken';

export default {
  name: 'Chart',

  components: {
    SectionHeader,
  },

  mixins: [CMCToken],

  props: {

    isResizeble: Boolean,
  },

  data: () => ({
    isFiltersOpen: true,
    resetDisabled: true,
    parameters: {},
  }),

  computed: {
    ...mapGetters({
      coinSlugs: 'filters/coinSlugs',
    }),

    token() {
      return this.$route.params.token;
    },

    defaultDimension() {
      return this.dimensions.keys().next().value;
    },

    dimensions() {
      return new Map([
        ['WEEK_1', 'Week'],
        ['MONTH_1', 'Month'],
        ['YEAR_1', 'Year'],
      ]);
    },

    title() {
      return `About ${this.name}`;
    },
  },

  methods: {
    ...mapActions({
      getSymbolToUSDT: 'charts/getSymbolToUSDT'
    }),

    toggleChartFullscreen() {
      this.$refs.chart.chart.fullscreen.toggle();
    },

    updateParameter(parameter, name) {
      this.resetDisabled = false;
      this.parameters = {
        ...this.parameters,
        [name]: parameter
      };

      this.getChartData();
    },

    async getChartData() {
      try {
        const res = await this.getSymbolToUSDT(this.parameters);
        this.chartOptions.series[0].data = res.map((item) => [item.time, item.price]);
        this.chartOptions.series[1].data = res.map((item) => [item.time, item.volume24h]);
        this.chartOptions.yAxis[0].plotLines[0].value = res[0].price;
        this.chartOptions.plotOptions.areaspline.threshold = res[0].price;
        this.$refs.chart.chart.reflow();
      } catch (e) {
        console.log(e);
      }
    }
  },

  created() {
    this.parameters = {
      dimension: this.dimensions.keys().next().value,
      coinId: this.coinSlugs[this.token],
    };

    this.getChartData();
  }
};
</script>

<style scoped>

</style>