<template>
  <highcharts
      ref="chart"
      class="chart"
      :options="chartOptions"
      :style="{ width: '100%', height: '100%' }"
      :isResizeble="isResizeble"
  />
</template>

<script>

import reflowHighchart from '@/mixins/reflowHighchart';

export default {
  name: 'Gauge',

  props: {
    data: {
      required: true,
      type: [Number, String],
    },

    backgroundColor: {
      type: String,
      required: false,
      default: 'var(--mainBGColor)',
    },

    plotBandsSettings: {
      type: Array,
      required: true,
    },

    isResizeble: Boolean,
  },

  data() {
    const _this = this;

    return {
      chartOptions: {
        chart: {
          type: 'gauge',
          backgroundColor: this.backgroundColor,
          height: 140,
          width: 412,
        },

        title: {
          text: null,
        },

        pane: [
          {
            startAngle: -103,
            endAngle: 103,
            center: ['50%', '100%'],
            background: {
              borderWidth: 0,
              backgroundColor: {
                linearGradient: {
                  x1: 0,
                  y1: 0,
                  x2: 0,
                  y2: 1
                },

                stops: [
                  [0, this.backgroundColor,],
                  [1, this.backgroundColor,]
                ]
              },
            },

            size: '160%',
          },
        ],

        exporting: {
          enabled: false,
        },

        tooltip: {
          enabled: false,
        },

        yAxis: [
          {
            min: 0,
            max: 100,
            lineWidth: 0,
            minorTicks: false,
            tickWidth: 50,
            tickPositions: this.plotBandsSettings.map((color, index) => (
                Math.round(100 / this.plotBandsSettings.length * index + (100 / this.plotBandsSettings.length / 2)))
            ),
            labels: {
              distance: 25,

              style: {
                color: '#92939c'
              },

              formatter: event => this.setLabels(event.value),
            },

            plotBands: this.plotBandsSettings.map((setting, index) => ({
              from: index * 100 / this.plotBandsSettings.length + 0.5,
              to: (index + 1) * 100 / this.plotBandsSettings.length,
              color: setting.color,
              thickness: '15.5%',
            })),

            pane: 0,
          }
        ],

        plotOptions: {
          gauge: {
            labels: {
              position: 'middle',
              useHTML: true,
            },

            dataLabels: {
              enabled: true,
              useHTML: true,
            },

            dial: {
              radius: '70%',
              backgroundColor: '#92939c',
              baseWidth: 65,
              baseLength: '10%',
              rearLength: '-5%'
            },

            pivot: {
              backgroundColor: this.backgroundColor,
              borderWidth: 2,
              borderColor: '#92939c',
              radius: 55,
            }
          }
        },

        credits: {
          enabled: false
        },

        series: [
          {
            name: 'Semi Circular Gauge',
            data: [this.data],
            yAxis: 0,

            dataLabels: {
              formatter() {
                return `<span class="pivot-text">${_this.setLabels(this.y)}</span>`;
              },
              align: 'center',
              verticalAlign: 'bottom',
              borderWidth: 0,
              x: 0,
              y: 50
            },
          }
        ]
      }
    };
  },

  mixins: [reflowHighchart],

  watch: {
    data(newValue) {
      this.chartOptions.series[0].data = [newValue];
    }
  },

  computed: {
    labelsProperties() {
      return new Map(
          this.chartOptions.yAxis[0].tickPositions.map((tick, index) => [tick, this.plotBandsSettings[index].text])
      );
    }
  },

  methods: {
    setLabels(number) {
      const value = this.chartOptions.yAxis[0].tickPositions.reduce((prev, curr) => (
          Math.abs(curr - number) < Math.abs(prev - number) ? curr : prev
      ));

      return this.labelsProperties.get(value);
    }
  },

  mounted() {
    // setTimeout(() => {
    //   // .$el.clientHeight
    //   this.$refs.chart.chart.reflow();
    // }, 0);
  }
};
</script>

<style scoped>

</style>