import moment from 'moment';
import { marker, setCurrentValue, states } from '@/mixins/chart-settings/_commonSettings';
import reflowHighchart from '@/mixins/reflowHighchart';

const yaxis = {
  gridLineWidth: 1,
  gridLineColor: 'var(--gridLineColor)',
  // gridLineColor: 'transparent',
  showLastLabel: false,
  showFirstLabel: false,

  labels: {
    y: 4,
    x: 50,

    style: {
      whiteSpace: 'nowrap',
      fontFamily: 'Inter, sans-serif',
      color: '#fff',
    },
  },
}

export default {
  mixins: [reflowHighchart],

  data() {
    return {
      chartOptions: {
        chart: {
          margin: [0, 57, 25, 57],
          // height: 414,
          backgroundColor: 'var(--mainBGColor)',
          zoomType: 'x',
          panKey: 'shift',

          panning: {
            enabled: true,
            type: 'x'
          },

          resetZoomButton: {
            theme: {
              fill: 'var(--accent)',
              stroke: 'var(--accent)',

              style: {
                fontFamily: 'Inter, sans-serif',
                fontWeight: 700,
                color: '#fff',
              },

              states: {
                hover: {
                  fill: '#00fff7',
                  stroke: '#00fff7',
                }
              }
            },
          },
        },

        exporting: {
          enabled: false,
        },

        title: {
          text: null
        },

        yAxis: [
          {
            ...yaxis,
          },
          {
            ...yaxis,
            opposite: false,
            labels: {
              ...yaxis.labels,
              x: -10,
            },
            resize: {
              enabled: true,
            }
          },
        ],

        xAxis: [
          {
            gridLineColor: 'var(--gridLineColor)',
            gridLineWidth: 1,
            tickPixelInterval: 250,

            labels: {
              style: {
                fontFamily: 'Inter, sans-serif',
                color: '#fff',
              },

              formatter: (a) => moment(a.value).format('YYYY/DD/MM HH:mm'),
            },
          },
        ],

        tooltip: {
          split: true,
          padding: 5,
          backgroundColor: 'var(--mainBorderColor)',

          style: {
            fontSize: 10,
            fontFamily: 'inherit',
            stroke: 'var(--textMainColor)',
          },

          positioner: (width, height, point) => setCurrentValue(point, this.legends),

          crosshairs: {
            enabled: true,
            dashStyle: 'dash',
            interpolate: true,
            label: {
              enabled: true,
              shape: 'unset',
              padding: 7,
              formatter: (ev) => moment(ev).format('YYYY/DD/MM HH:mm:ss'),
            },
          },
        },

        plotOptions: {
          series: {
            marker: {
              enabled: false,
            },
          }
        },

        series: [
          {
            name: 'pairPrice',
            data: [],
            yAxis: 0,
            lineWidth: 1,
            type: 'spline',
            marker,
            states,
          },
          {
            name: 'stockPrice',
            data: [],
            yAxis: 1,
            lineWidth: 1,
            type: 'spline',
            marker,
            states,
          },
        ],

        navigator: {
          enabled: false,
        },

        scrollbar: {
          enabled: false,
        },

        rangeSelector: {
          enabled: false,
        },

        legend: {
          verticalAlign: 'top',
          x: -300,
          y: -100,
          enabled: true,
        }
      },
    };
  },

  created() {
    this.chartOptions.series.forEach(series => {
      series.color = this.seriesParameters[series.name].color
    })
  }
};