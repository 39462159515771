<template>
  <div v-click-outside="closeSelect" class="select">
    <button
        class="select__button"
        @click="toggleSelect"
    >
      <span class="select__title">{{ title }}: </span>
      <span :title="selected" class="select__selected">
        {{ selected }}{{ suffix }}
      </span>
      <svg
          class="object-triangle"
          fill="#92939c"
          height="5"
          viewBox="0 0 10 5"
          width="10"
          xmlns="http://www.w3.org/2000/svg"
      >
        <path
            d="M9.22727 0H0.770516C0.0848333 0 -0.258008 0.758914 0.227684 1.20379L3.92751 4.59274C4.52034 5.13575 5.48458 5.13575 6.07741 4.59274L7.48449 3.30389L9.77724 1.20379C10.2558 0.758914 9.91295 0 9.22727 0Z"
            fill="var(--textSecondaryColor)"
        />
      </svg>
    </button>
    <div v-show="selectOpen" class="select__dropdown dropdown single">
      <Search
          :is-show-search="options.size > 10"
          @search="filteredBy = $event"
      />
      <ul class="dropdown__list custom-scroll">
        <li
            v-for="([key, value], index) in filteredOptions"
            :key="index"
            :title="value"
            class="dropdown__item"
        >
          <button
              :class="{checked: selected === value}"
              :disabled="selected === value"
              class="dropdown__button"
              @click="selectItem(key, value)"
          >
            <span class="dropdown__option-name">{{ value }}{{ suffix }}</span>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import debounce from '@/mixins/debounce';
import Search from "@/components/ui/inputs/Search.vue";

export default {
  name: 'Select',

  components: {
    Search,
  },

  mixins: [debounce],

  props: {
    title: {
      type: String,
      required: true,
    },

    options: {
      type: Map,
      required: true,
    },

    defaultOptionValue: {
      type: [String, Number, Boolean, Array],
      required: false,
      default() {
        return this.options.keys().next().value;
      }
    },

    suffix: {
      type: String,
      required: false,
      default: '',
    }
  },

  data() {
    return {
      selectOpen: false,
      selected: this.options.get(this.defaultOptionValue),
      selectedKey: this.defaultOptionValue,
      filteredBy: '',
    };
  },

  watch: {
    options(options) {
      this.selected = options.get(this.selectedKey) || this.defaultOptionValue;

      if (options.get(this.selectedKey) === undefined) {
        this.reset();
        this.selectedKey = null;
        this.$emit('emptySelect');
      }
    },
  },

  computed: {
    filteredOptions() {
      const filteredMap = new Map();
      const filterLowered = this.filteredBy.toLowerCase();

      this.options.forEach((value, key) => {
        if (typeof value === 'string' && ~value.toLowerCase().indexOf(filterLowered)) {
          filteredMap.set(key, value);
        }
      });

      return filteredMap;
    }
  },

  methods: {
    closeSelect() {
      this.selectOpen = false;
    },

    toggleSelect() {
      this.selectOpen = !this.selectOpen;
    },

    emitSelected(selectedKey) {
      this.debounce(() => {
        this.$emit('selected', selectedKey);
      }, 100);
    },

    selectItem(key, value) {
      this.selected = value;
      this.selectedKey = key;
      this.emitSelected(key);
    },

    reset() {
      this.selected = this.options.get(this.defaultOptionValue);
    }
  },
};
</script>

<style lang="scss" scoped src="./select.scss" />
