import { render, staticRenderFns } from "./SpotExchanges.vue?vue&type=template&id=5911e6f4&scoped=true&"
import script from "./SpotExchanges.vue?vue&type=script&lang=js&"
export * from "./SpotExchanges.vue?vue&type=script&lang=js&"
import style0 from "@/assets/css/secondary-table.scss?vue&type=style&index=0&id=5911e6f4&prod&lang=scss&scoped=true&"
import style1 from "@/assets/css/filters.scss?vue&type=style&index=1&id=5911e6f4&prod&lang=scss&scoped=true&"
import style2 from "./SpotExchanges.vue?vue&type=style&index=2&id=5911e6f4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5911e6f4",
  null
  
)

export default component.exports