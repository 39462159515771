const aroundNumber = {
    methods: {
        aroundNumber(num) {
            if(num === undefined || num === null) {
                return 0
            }

            return num
        },
    },
};

export default aroundNumber;
