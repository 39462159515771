<template>
  <div class="chart-wrapper">
    <div class="layout-item-content">
      <div class="exchange-header">
        <object
            v-if="tokenInfo.id"
            :data="`https://s2.coinmarketcap.com/static/img/coins/64x64/${tokenInfo.id}.png`"
            type="image/png"
        >
          <img :src="defaultImage" />
        </object>
        <div class="exchange-title">
          <h3 class="exchange-name">
            {{ name }}
            <span v-if="symbol">{{ symbol }}</span>
          </h3>
          <span v-if="tokenInfo.rank" class="exchange-rank">Rank #{{ tokenInfo.rank }}</span>
        </div>
      </div>
      <div class="token-tags">
        <span class="token-tags-title">Tags:</span>
        <ul v-if="tags.length" class="token-tags-list">
          <li
              v-for="tag in tags"
              :key="tag"
              class="token-tag"
          >
            {{ tag }}
          </li>
          <li
              class="token-tag active"
              @click="showTagsPopup"
          >
            All
          </li>
        </ul>
      </div>
      <div class="exchange-info">
        <div class="exchange-info-item">
          <div class="exchange-info-item-title">
            <span class="exchange-info-item-title-value">{{ name }} Price ({{ symbol }})</span>
            <v-tooltip max-width="400" top>
              <template v-slot:activator="{ on, attrs }">
                <svg
                    class="info-icon"
                    fill="none"
                    height="16"
                    v-bind="attrs"
                    viewBox="0 0 16 16"
                    width="16"
                    xmlns="http://www.w3.org/2000/svg" v-on="on"
                >
                  <path
                      clip-rule="evenodd"
                      d="M7.20898 5.19649C7.20898 4.82968 7.55646 4.51114 8.0005 4.51114C8.43486 4.51114 8.78241 4.82968 8.78241 5.19649C8.78241 5.56333 8.43488 5.89152 8.0005 5.89152C7.55649 5.89152 7.20898 5.56333 7.20898 5.19649ZM7.25716 7.23327C7.25716 6.963 7.56605 6.77955 8.00044 6.77955C8.41552 6.77955 8.75334 6.96297 8.75334 7.23322V10.911C8.75334 11.1427 8.41552 11.3743 8.00044 11.3743C7.56605 11.3743 7.25716 11.1427 7.25716 10.911V7.23327Z"
                      fill="#92939C"
                      fill-rule="evenodd"
                  />
                  <circle cx="8" cy="8" r="7" stroke="#828392" stroke-width="1.5" />
                </svg>
              </template>
              <span>{{ 'description' }}</span>
            </v-tooltip>
          </div>
          <div class="exchange-info-item-value">
            <span class="exchange-info-item-volume">
              ${{ lastPrice }}
            </span>
            <div
                :class="{'up' : +lastPricePercent > 0, 'down' : +lastPricePercent <= 0 }"
                class="exchange-percent-wrap"
            >
              <svg fill="none" height="4" viewBox="0 0 8 4" width="8" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M6.81948 0.666748H1.18165C0.724524 0.666748 0.495963 1.17269 0.819758 1.46928L3.28631 3.72857C3.68153 4.09058 4.32436 4.09058 4.71958 3.72857L5.65763 2.86934L7.18613 1.46928C7.50516 1.17269 7.2766 0.666748 6.81948 0.666748Z"
                    fill="white"
                />
              </svg>
              <span>{{ lastPricePercent }}%</span>
            </div>
          </div>
        </div>
        <div class="price-change">
          <div class="price-change-header">
            <span class="price-change-title">Price changes</span>
            <!--            <div class="chart-filter">-->
            <!--              <Select-->
            <!--                  ref="dimensions"-->
            <!--                  :default-option-value="dimensions[0]"-->
            <!--                  :options="dimensions"-->
            <!--                  title="Time Period"-->
            <!--                  @emptySelect="parameters.asset = defaultAsset"-->
            <!--                  @selected="changePeriod($event)"-->
            <!--              />-->
            <!--            </div>-->
          </div>
          <div class="price-change-value">
            <span class="price-change-value-item price-change-low">
              Low: <span>${{ lowPrice }}</span>
            </span>
            <div class="price-change-slider">
              <span class="price-change-slider-bg">
                <span :style="{width: pricePercent + '%'}" class="price-change-slider-fill"></span>
              </span>
            </div>
            <span class="price-change-value-item price-change-high">
              High: <span>${{ highPrice }}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <Popup
      ref="tagsPopup"
      :title="'Bitcoin tags'"
    >
      <span class="tags-title">Category:</span>
      <div class="tags-list">
        <div
            class="tag"
            v-for="tag in tokenInfo.tags"
            :key="tag"
        >
        {{tag}}
        </div>
      </div>
    </Popup>
  </div>
</template>

<script>
import prettifyNumber from "@/mixins/helpers/prettifyNumber";
import aroundNumber from "@/mixins/helpers/aroundNumber";
import noExponents from "@/mixins/helpers/noExponents";
import { mapGetters } from "vuex";
import Popup from "@/components/ui/popups/Popup.vue";

export default {
  name: "Info",

  data() {
    return {
      dimension: {
        DAY_1: "24h",
        WEEK_1: "1w",
        MONTH_1: "1m",
        YEAR_1: "1y"
      },
      parameters: {},
      exchangeData: {},
      defaultImage: require(`@/assets/images/default-token-icon.svg`),
    };
  },

  components: {
    Popup,
  },

  mixins: [prettifyNumber, aroundNumber, noExponents],

  computed: {
    ...mapGetters({
      tokenInfo: 'filters/coinInfo',
      timePeriod: 'charts/tokenTimePeriod',
    }),

    defaultDimension() {
      return this.dimensions.keys().next().value;
    },

    dimensions() {
      return new Map(Object.entries(this.dimension));
    },

    name() {
      return this.tokenInfo.displayName || '';
    },
    symbol() {
      return (this.tokenInfo.symbol || '').toUpperCase();
    },
    lastPrice() {
      const number = this.noExponents(this.tokenInfo.lastPrice)
      return +this.tokenInfo.lastPrice < 1 ? this.noExponents(this.trimNumber(number)) :
              this.prettifyNumber(String(this.aroundNumber(this.noExponents(this.tokenInfo.lastPrice)) || 0), 2);
    },
    lastPricePercent() {
      const period = this.timePeriod;
      let lastPricePercent = 0;

      switch (period) {
        case "DAY_1":
          lastPricePercent = this.tokenInfo.pricePercentChangeDay;
          break;
        case "WEEK_1":
          lastPricePercent = this.tokenInfo.pricePercentChangeWeek;
          break;
        case "MONTH_1":
          lastPricePercent = this.tokenInfo.pricePercentChangeMonths;
          break;
        case "YEAR_1":
          lastPricePercent = this.tokenInfo.pricePercentChangeYear;
          break;

        default:
          lastPricePercent = this.tokenInfo.pricePercentChangeDay;
      }

      return lastPricePercent ? lastPricePercent.toFixed(2) : 0;
    },
    lowPrice() {
      const number = this.noExponents(this.tokenInfo.lowPriceDay)
      return +this.tokenInfo.lowPriceDay < 1 ? this.noExponents(this.trimNumber(number)) :
          this.prettifyNumber(String(this.aroundNumber(this.noExponents(this.tokenInfo.lowPriceDay)) || 0), 2);
    },
    highPrice() {
      const number = this.noExponents(this.tokenInfo.highPriceDay)
      return +this.tokenInfo.highPriceDay < 1 ? this.noExponents(this.trimNumber(number)) :
          this.prettifyNumber(String(this.aroundNumber(this.noExponents(this.tokenInfo.highPriceDay)) || 0), 2);
    },
    tags() {
      return this.tokenInfo.tags ? this.tokenInfo.tags.slice(0, 3) : [];
    },
    pricePercent() {
      const calculatePercentage = (number, lowerBound, upperBound) => {
        let percentage = ((number - lowerBound) / (upperBound - lowerBound)) * 100;
        return percentage.toFixed(2);
      };

      const number = this.tokenInfo.lastPrice;
      const lowerBound = this.tokenInfo.lowPriceDay;
      const upperBound = this.tokenInfo.highPriceDay;

      return calculatePercentage(number, lowerBound, upperBound);
    }
  },

  methods: {
    changePeriod(period) {
      this.$store.commit('charts/SET_TOKEN_TIME_PERIOD', period);
    },

    showTagsPopup() {
        this.$refs.tagsPopup.openPopup();
    },

    trimNumber(number) {
      const trimmed = parseFloat(number).toPrecision(3);
      return parseFloat(trimmed);
    }
  },
};
</script>

<style>
.price-change .select__title {
  font-size: 12px;
}

.price-change .select__selected {
  padding-right: 8px;
  font-size: 12px;
}
</style>

<style scoped>
.exchange-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}

.exchange-header object {
  display: inline-block;
  width: 64px;
  height: 64px;
}

.exchange-title {
  margin-left: 10px;
  width: calc(100% - 74px);
}

.exchange-name {
  display: block;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2px;
  font-family: "Play";
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  line-height: 30px;
  color: var(--textMainColor);
}

.exchange-name span {
  display: inline-block;
  margin-left: 5px;
  border-radius: 6px;
  padding: 4px 8px;
  background: #383838;
  font-family: "Inter";
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  line-height: 16px;
  text-align: center;
  color: #e9e9e9;
}

.exchange-rank {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  line-height: 21px;
  color: #92939c;
  color: var(--textSecondaryColor);
}

.token-tags {
  margin-top: 22px;
  margin-bottom: 72px;
}

.token-tags-title {
  display: block;
  margin-bottom: 5px;
  font-family: "Inter", sans-serif;
  font-family: "Inter";
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  line-height: 16px;
  color: var(--textSecondaryColor);
}

.token-tags-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  padding: 0;
  gap: 5px;
}

.token-tag {
  border-radius: 12px;
  padding: 4px 12px;
  background: #383838;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  line-height: 16px;
  color: #92939c;
  color: var(--textSecondaryColor);
}

.token-tag.active {
  background: rgba(45, 202, 194, 0.1);
  color: #2dcac2;
  cursor: pointer;
}

.exchange-info {

}

.exchange-info-item:not(:last-child) {
  margin-bottom: 22px;
}

.exchange-info-item-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  line-height: 16px;
  color: var(--textSecondaryColor);
}

.exchange-info-item-title-value {
  display: inline-block;
  margin-right: 5px;
}

.exchange-info-item-value {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.exchange-percent-wrap {
  margin-left: 10px;
}

.exchange-info-item-volume {
  font-family: "Play";
  font-size: 32px;
  font-weight: 700;
  font-style: normal;
  line-height: 48px;
  color: var(--textMainColor);
}

.exchange-info-item-amount {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  line-height: 16px;
  color: #e9e9e9;
}

.price-change {

}

.price-change-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.price-change-title {
  font-family: "Inter";
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  line-height: 16px;
  color: var(--textSecondaryColor);
  color: #92939c;
}

.price-change-header .chart-filter {

}

.price-change-value {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.price-change-value-item {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  line-height: 16px;
  color: var(--textSecondaryColor);
  color: #92939c;
}

.price-change-low {
  text-align: left;
}

.price-change-high {
  text-align: right;
}

.price-change-value-item span {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  line-height: 16px;
  color: var(--textMainColor);
}

.price-change-slider {
  margin: 0 3px;
  width: 100%;
  max-width: 95px;
}

.price-change-slider-bg {
  display: inline-block;
  display: block;
  position: relative;
  border-radius: 4px;
  width: 100%;
  height: 8px;
  background: #383838;
  line-height: 5;
}

.price-change-slider-fill {
  display: inherit;
  position: absolute;
  top: 0px;
  left: 0px;
  transition: width 3s ease-in-out 0s;
  border-top-left-radius: inherit;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: inherit;
  height: 100%;
  background-color: #92939c;
}

.tags-title {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: var(--textSecondaryColor);
  display: block;
  margin-bottom: 10px;
}

.tags-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  padding: 0;
  gap: 5px;
  max-width: 500px;
}

.tag {
  border-radius: 12px;
  padding: 4px 12px;
  background: #383838;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  line-height: 16px;
  color: var(--textSecondaryColor);
}

</style>