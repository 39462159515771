import commonFilters from '@/mixins/filters/commonFilters';

export default {
  mixins: [commonFilters],

  computed: {
    defaultEventType() {
      return Array.from(this.eventTypes.keys())
    },

    eventTypes() {
      return new Map([
        ['cryptocurrency_planned_listing', 'Listing'],
        ['cryptocurrency_planned_delisting', 'Delisting']
      ]);
    },
  },
};