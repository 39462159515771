import Router from 'vue-router';
import Liquidity from '@/pages/Liquidity';
import PageNotFound from '@/pages/PageNotFound';
import Maintenance from '@/pages/Maintenance.vue';
import CoinsData from '@/pages/CoinsData';
import ArbitrageData from '@/pages/ArbitrageData';
import DEXs from '@/pages/DEXs';
import Announcements from '@/pages/Announcements';
import CustomLayout from '@/pages/PersonalDashboard.vue';
import WIP from '@/pages/WIP';
import Exchanges from '@/pages/Exchanges.vue';
import ExchangePage from '@/pages/ExchangePage.vue';
import Tokens from '@/pages/Tokens.vue';
import TokenPage from '@/pages/TokenPage.vue';

const routes = [
  {
    path: '/personal-dashboard',
    name: 'customLayout',
    component: CustomLayout,
  },
  {
    path: '/404',
    name: 'pageNotFound',
    component: PageNotFound,
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: Maintenance,
  },
  {
    path: '/',
    name: 'liquidity',
    component: Liquidity,
  },
  {
    path: '/coins-data',
    name: 'coinsData',
    component: CoinsData,
  },
  {
    path: '/arbitrage-data',
    name: 'arbitrageData',
    component: ArbitrageData,
  },
  {
    path: '/dexs',
    name: 'dexs',
    component: DEXs,
  },
  {
    path: '/listings&delistings',
    name: 'listingsDelistings',
    component: Announcements,
  },
  {
    path: '/work-in-progress',
    name: 'wip',
    component: WIP,
  },
  {
    path: '/exchange',
    name: 'exchange',
    component: Exchanges,
  },
  {
    path: '/exchange/:exchange',
    name: 'exchangePage',
    component: ExchangePage,
  },
  {
    path: '/token',
    name: 'token',
    component: Tokens,
  },
  {
    path: '/token/:token',
    name: 'tokenPage',
    component: TokenPage,
  },
  {
    path: '*',
    redirect: '/404'
  },
];

export const router = new Router({
  mode: 'history',
  routes,
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active',
  scrollBehavior(to) {
    if (to.name !== 'Trading' && to.hash) {
      window.scrollTo({
        top: document.querySelector(to.hash).offsetTop,
        behavior: 'smooth'
      });
    } else {
      return { x: 0, y: 0 };
    }
  }
});

export default router;