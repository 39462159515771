<template>
  <WidgetLayout :layout-prop="layout" />
</template>

<script>
import { mapGetters } from 'vuex';
import WidgetLayout from '@/layouts/WidgetLayout';

export default {
  name: 'WIP',

  components: { WidgetLayout },

  computed: {
    ...mapGetters({
      layout: 'layouts/wipLayout'
    })
  },
};
</script>

<style scoped>

</style>