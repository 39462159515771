export default {
  announcements: ({ announcements }) => announcements,
  ratio: ({ ratio }) => ratio,
  orderBook: ({ orderBook }) => orderBook,
  fundingRate: ({ fundingRate }) => fundingRate,
  scamScore: ({ scamScore }) => scamScore,
  scamScorePoints: ({ scamScorePoints }) => scamScorePoints,
  exchangePairs: ({ exchangePairs }) => exchangePairs.content,
  exchangePairsTokenIds: ({ exchangePairs }) => exchangePairs.tokenIds,
  tokenInfo: ({ tokenInfo }) => tokenInfo,
  tokenTimePeriod: ({ tokenTimePeriod }) => tokenTimePeriod,
};
