export default {
  filters: {},
  orderbookMap: {},
  maxCoinsMcap: 0,
  maxVolumeByDay: 0,
  exchangeSlugs: {},
  coinSlugs: {},
  exchangesInfo: {},
  coinInfo: {},
  coinsInfo: {},
  unknownCoinsInfo: {},
  naming: {
    spot: 'Spot',
    futures: 'Futures',
    futures_perpetual: 'Perpetual',
  },
};
