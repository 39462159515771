const prettifyNumber = {
    methods: {
        prettifyNumber(num, maximumFractionDigits, minimumFractionDigits) {
            const options = {
                style: 'currency',
                currency: 'USD',
                maximumFractionDigits: maximumFractionDigits || 8,
                minimumFractionDigits: minimumFractionDigits || 2,
            }
            num = Number(num).toLocaleString("en-US", options).replace('$', '')
            return num
        },
    },
};

export default prettifyNumber;
