import { fetchData } from '@/utils/fetchData';

export default {
  getOrderbookWalls(_, params) {
    return fetchData(null, '/order-book/walls', params, null);
  },

  getRatio(_, params) {
    return fetchData(null, '/ratio', params, null);
  },

  getOrderBookVolume({ commit }, params) {
    return fetchData(commit, '/order-book/volume-by-exchange', params, 'SET_ORDER_BOOK');
  },

  getAssetPrices({ commit }, params) {
    return fetchData(commit, '/asset-prices', params, 'SET_ASSET_PRICE');
  },

  getVolumeWAP({ commit }, params) {
    return fetchData(commit, '/volume-wap', params, 'SET_VOLUME_WAP');
  },

  getDominance({ commit }, params) {
    return fetchData(commit, '/dominance', params, 'SET_DOMINANCE');
  },

  getMarketShare(_, params) {
    return fetchData(null, '/market-share', params, null);
  },

  getAnnouncements({ commit }) {
    return fetchData(commit, '/announcements', {}, 'SET_ANNOUNCEMENTS');
  },

  getVolumeByPrice({ commit }, params) {
    return fetchData(commit, '/volume-by-price', params, 'SET_VOLUME_BY_PRICE');
  },

  getVolumeByTime({ commit }, params) {
    return fetchData(commit, '/order-book/volume-by-time', params, 'SET_VOLUME_BY_TIME');
  },

  getTechnicalAnalysis(_, params) {
    return fetchData(null, '/technical-analysis', params, null);
  },

  getTechnicalAnalysisPercentage(_, params) {
    return fetchData(null, '/technical-analysis/percent', params, null);
  },

  async getFundingRate({ commit, dispatch, rootState }) {
    const fundingRate = await fetchData(commit, '/funding-rate', {}, 'SET_FUNDING_RATE');
    const { coinsInfo, unknownCoinsInfo } = rootState.filters;
    const unknownIds = new Set();

    fundingRate.forEach(rate => {
      if (!coinsInfo[rate.baseCoinId] && !unknownCoinsInfo[rate.baseCoinId]) {
        unknownIds.add(rate.baseCoinId);
      }
    });

    await dispatch('filters/getUnknownCoinsInfo', [...unknownIds], { root: true });
    return fundingRate;
  },

  getFundingRateRealized({ commit }, params) {
    return fetchData(commit, '/funding-rate/realized', params, 'SET_FUNDING_RATE_REALIZED');
  },

  getScamScorePoints({ commit }, params) {
    return fetchData(commit, '/scam-score/points', params, 'SET_SCAM_SCORE_POINTS');
  },

  getScamScore({ commit }, params) {
    return fetchData(commit, '/scam-score', params, 'SET_SCAM_SCORE');
  },

  getExchangePairs({ commit }) {
    return fetchData(commit, '/exchange/pairs', {}, 'SET_EXCHANGE_PAIRS');
  },

  getFearAndGreed() {
    return fetchData(null, '/markets/fear-and-greed', {}, null);
  },

  getSymbolToUSDT(_, params) {
    return fetchData(null, '/markets/coin-usd', params, null);
  },

  getArbitrageDataCustom(_, params) {
    return fetchData(null, '/arbitrage', params, null);
  },

  getArbitrageDataHistory(_, params) {
    return fetchData(null, '/arbitrage-history', params, null);
  },
};