<template>
  <div class="chart-wrapper">
    <SectionHeader
        :is-deletable="isDeletable"
        :is-filters-open="isFiltersOpen"
        :searchable="true"
        :show-copy-chart-to-pd="showCopyToPd"
        :stretchable="false"
        title="Funding Rate"
        @copyChartToPD="$emit('copyChartToPD', {})"
        @removeItem="$emit('removeItem', null)"
        @search="searchBy = $event.toLowerCase()"
        @toggleFiltersVisibility="isFiltersOpen = $event"
    />

    <transition name="expand-filters">
      <div v-show="isFiltersOpen" class="funding-rate__filters">
        <div class="chart-filters">
          <div class="chart-filter">
            <MultiSelect
                ref="asset"
                :default-option-value="parametersForSelects.stableCoins || defaultStableCoins"
                :options="stableCoins"
                title="Stable Coins"
                @selected="visibleStableCoins = $event"
            />
          </div>

          <div class="chart-filter">
            <MultiSelect
                ref="visibleExchangesFilter"
                :default-option-value="defaultVisibibleExchanges"
                :options="visibleExchangesFilter"
                title="exchanges"
                @selected="visibleExchanges = $event"
            />
          </div>
        </div>

        <div class="custom-filters">
          <div class="show-predicted">
            <label class="show-predicted__label">
              <input v-model="isTokenMode" class="show-predicted__checkbox" type="checkbox">
              <span class="show-predicted__fake"></span>
              <span>Only Tokens</span>
            </label>
          </div>

          <div class="show-predicted">
            <label class="show-predicted__label">
              <input v-model="showPredicted" class="show-predicted__checkbox" type="checkbox">
              <span class="show-predicted__fake"></span>
              <span>Show Predicted</span>
            </label>
          </div>

          <div class="dimensions">
            <button
                v-for="([value, text], index) in dimensions"
                :key="index"
                :class="{active: selectedDimension === value}"
                class="dimensions__button"
                @click="selectedDimension = value"
            >
              {{ text }}
            </button>
          </div>
        </div>
      </div>
    </transition>

    <div ref="chartContent" class="chart-content table-wrapper">
      <div v-show="showPreloader" class="main-preloader widget-preloader chart-preloader">
        <Loader
            ref="preloader"
            :animationData="require('@/assets/images/Loader.json')"
            :autoPlay="true"
            :loop="true"
            :speed="1"
        />
      </div>

      <NoData
          v-show="!showPreloader && !totalItems"
          text="There doesn't seem to be any data. <br /> Try other filters"
      />

      <div class="table-content custom-scroll">
        <table>
          <thead>
            <tr>
              <th
                  :class="{'active-column': !sortedBy}"
                  class="text-left secondary-cell"
              >
                <button @click="sortTable('')">
                  <svg
                      class="arrows"
                      fill="none"
                      height="13"
                      viewBox="0 0 12 13"
                      width="12" xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                        :class="{'active-arrow': !isSortAscending}"
                        d="M9 4.49998L6 1.49999L3 4.49998"
                        stroke="#92939C"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                    />
                    <path
                        :class="{'active-arrow': isSortAscending}"
                        d="M3 8.50002L6 11.5L9 8.50002"
                        stroke="#92939C"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                    />
                  </svg>
                  {{ isTokenMode ? 'Token' : 'Pair' }}
                </button>
              </th>

              <th class="text-left secondary-cell">Data</th>

              <th
                  v-for="([exchangeId, exchange]) in exchanges"
                  v-show="visibleExchanges.includes(exchangeId)"
                  :key="exchangeId"
                  :class="{'active-column': sortedBy === exchangeId}"
              >
                <button class="exchange" @click="sortTable(exchangeId)">
                  <LazyImg :id="exchangeId" :is-token="false" :size="22" class="exchange__logo" />

                  {{ exchange.displayName }}

                  <svg
                      class="arrows"
                      fill="none"
                      height="13"
                      viewBox="0 0 12 13"
                      width="12" xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                        :class="{'active-arrow': !isSortAscending}"
                        d="M9 4.49998L6 1.49999L3 4.49998"
                        stroke="#92939C"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                    />
                    <path
                        :class="{'active-arrow': isSortAscending}"
                        d="M3 8.50002L6 11.5L9 8.50002"
                        stroke="#92939C"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                    />
                  </svg>
                </button>
              </th>
            </tr>
          </thead>

          <tbody
              :class="{'token-mode': isTokenMode}"
              class="funding-rate-table-body"
          >
            <tr v-for="([baseCoinId, row], index) in visibleFundingRate" :key="index" ref="row">
              <td :class="{'active-column': sortedBy === ''}">
                <div class="pair sub-row">
                  <LazyImg
                      :id="isTokenMode ? baseCoinId : JSON.parse(baseCoinId)[0]"
                      :is-token="true"
                      :size="24"
                      class="img"
                  />

                  <template v-if="isTokenMode">
                    <router-link
                        :to="{ path: `token/${coinsInfo[baseCoinId]?.slug || unknownCoinsInfo[baseCoinId]?.slug}` }"
                        class="pair"
                    >
                      {{ coinsInfo[baseCoinId]?.symbol.toUpperCase() ?? unknownCoinsInfo[baseCoinId]?.symbol.toUpperCase() ?? baseCoinId }}
                    </router-link>
                  </template>

                  <template v-else>
                    <router-link
                        :to="{ path: getRoutePath(baseCoinId) }"
                        class="pair"
                    >
                      {{ getPairName(baseCoinId) }}
                    </router-link>
                  </template>
                </div>
              </td>

              <td class="secondary-text">
                <div class="sub-row">Current</div>
                <div v-show="showPredicted && !showPreloader" class="sub-row">Predicted</div>
              </td>

              <td
                  v-for="([exchangeId]) in exchanges"
                  v-show="visibleExchanges.includes(exchangeId)"
                  :key="exchangeId"
                  :class="{
                  'active-column': sortedBy === exchangeId,
                  'cursor-default': isTokenMode
                }"
                  class="text-сenter pointer"
                  @click="openChart(baseCoinId, +exchangeId)"
              >
                <FundingRateCell
                    :previusRate="previusRate"
                    :rate="rate"
                    :showPredicted="showPredicted && !showPreloader"
                    :token-mode="isTokenMode"
                    :values="row.get(+exchangeId)"
                />

              </td>
            </tr>
          </tbody>

        </table>
      </div>

      <CustomPagination
          v-if="totalPages > 1"
          :page-count="size"
          :page-number="page"
          :pagination-size="totalPages"
          :total-items="totalItems"
          @pageChanged="page = $event"
      />
    </div>

    <div
        :class="{active: showChart}"
        class="funding-rate__history"
        @click.self="closeChart"
    >
      <transition name="expand">
        <FundingRateHistory
            v-if="showChart"
            :currency-pair="currencyPair"
            :exchange-id="selectedExchangeId"
            class="funding-rate__realized"
            @close="showChart = false"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import debounce from '@/mixins/debounce';
import FundingRateHistory from '@/components/chart-sections/FundingRateHistory';
import FundingRateCell from '@/components/funding-rate-cell/FundingRateCell';
import propsParameters from '@/mixins/propsParameters';
import { ws } from '@/utils/socket';
import NoData from '@/components/ui/no-data/NoData';

export default {
  name: 'FundingRate',

  components: {
    NoData,
    FundingRateCell,
    FundingRateHistory,
  },

  props: {
    isResizeble: Boolean,
  },

  mixins: [debounce, propsParameters],

  data: () => ({
    showPreloader: false,
    isFiltersOpen: true,
    showPredicted: true,
    selectedDimension: 0,
    showChart: false,
    sortedBy: '',
    isSortAscending: false,
    isTokenMode: true,
    visibleStableCoins: [],
    searchBy: '',
    visibleExchanges: [],
    size: 6,
    page: 0,
    currencyPair: [],
    selectedExchangeId: 0,
    previusRate: 1,
    dimensions: new Map([
      [0, 'Current Rates'],
      [1, '1D'],
      [7, '7D'],
      [30, '30D'],
      [365, '1Y'],
    ]),
  }),

  computed: {
    ...mapGetters({
      fundingRateMap: 'charts/fundingRate',
      exchangesInfo: 'filters/exchangesInfo',
      coinsInfo: 'filters/coinsInfo',
      unknownCoinsInfo: 'filters/unknownCoinsInfo',
    }),

    fundingRate() {
      return this.fundingRateMap.content;
    },

    fundingRatePairs() {
      return this.fundingRateMap.pairs;
    },

    fundingRateStableCoinIds() {
      return this.fundingRateMap.stableCoinIds;
    },

    sortedAndFilteredFundingRate() {
      const { isTokenMode, isSortAscending, visibleStableCoins } = this;
      const sortedBy = +this.sortedBy;
      const stableCoins = [...this.stableCoins.keys()];
      const visibleCoins = visibleStableCoins.length ? visibleStableCoins : stableCoins;

      if (isTokenMode) {
        const fundingRate = this.copyNestedMap(this.fundingRate);

        fundingRate.forEach(baseCoinIds => {
          baseCoinIds.forEach(exchangeIds => {
            exchangeIds.forEach(types => {
              stableCoins.forEach(stableCoin => {
                if (!visibleCoins.includes(stableCoin)) {
                  types.delete(stableCoin);
                }
              });
            });
          });
        });

        let sortedAndFilteredFundingRate = [...fundingRate.entries()].filter(rate => (
            this.filterCoinByText(rate[0])
            && [...rate[1]].filter(([exchangeId]) => this.visibleExchanges
                .includes('' + exchangeId))
                .some(type => (type[1].get('current') || type[1].get('predicted')).size)
        ));

        if (sortedBy) {
          sortedAndFilteredFundingRate = this.sortFundingRateBySymbol(sortedAndFilteredFundingRate, true);
        } else {
          sortedAndFilteredFundingRate.sort((a, b) => {
            const first = this.coinsInfo[a[0]] || this.unknownCoinsInfo[a[0]];
            const second = this.coinsInfo[b[0]] || this.unknownCoinsInfo[b[0]];

            if (first === undefined) return isSortAscending ? -1 : 1;
            if (second === undefined) return isSortAscending ? 1 : -1;

            return first.symbol.localeCompare(second.symbol);
          });
        }

        if (isSortAscending) {
          sortedAndFilteredFundingRate.reverse();
        }

        return sortedAndFilteredFundingRate;
      }

      const filteredPairs = [...this.fundingRatePairs.entries()].filter(([pairStr]) => {
        const parsedPair = JSON.parse(pairStr);

        return this.filterCoinByText(parsedPair.at(0)) && visibleCoins.includes(parsedPair.at(1))
      });

      return this.sortFundingRatePairs(filteredPairs);
    },

    visibleFundingRate() {
      const { size, page } = this;
      const from = page * size;
      const to = (page + 1) * size;

      return this.sortedAndFilteredFundingRate.slice(from, to);
    },

    totalItems() {
      return this.sortedAndFilteredFundingRate.length;
    },

    totalPages() {
      return Math.ceil(this.totalItems / this.size);
    },

    exchanges() {
      return Object.entries(this.exchangesInfo);
    },

    defaultStableCoins() {
      return [...this.stableCoins.keys()];
    },

    stableCoins() {
      return new Map(
          [...this.fundingRateStableCoinIds].map(id => [id, this.coinsInfo[id]?.symbol.toUpperCase() || this.unknownCoinsInfo[id]?.symbol.toUpperCase() || id + ''])
      );
    },

    defaultVisibibleExchanges() {
      return [...this.visibleExchangesFilter.keys()];
    },

    visibleExchangesFilter() {
      return new Map(
          (this.exchanges || []).map(([id, parameters]) => [id, parameters.displayName])
      );
    },

    rate() {
      const rateUpdateFrequency = 3;
      const defaultUpdateFrequency = 1;

      return this.selectedDimension ? this.selectedDimension * rateUpdateFrequency : defaultUpdateFrequency;
    }
  },

  watch: {
    showPredicted(showPredicted) {
      this.size = showPredicted ? 6 : 12;

      this.page = 0;
    },

    isTokenMode() {
      this.page = 0;
    },

    sortedBy() {
      this.page = 0;
    },

    visibleStableCoins() {
      this.page = 0;
    },

    rate(newValue, oldValue) {
      this.previusRate = oldValue;
    },

    isResizeble() {
      this.setSize();
    },
  },

  methods: {
    ...mapActions({
      getFundingRate: 'charts/getFundingRate',
    }),

    setSize() {
      this.debounce(() => {
        const { row, chartContent } = this.$refs;

        if (!row?.[0]) return;

        const rowHeight = row[0].clientHeight;

        this.size = Math.floor((chartContent.clientHeight - rowHeight / 2) / rowHeight) - 1;
        this.page = 0;
      }, 100);
    },

    filterCoinByText(coinId) {
      const coin = this.coinsInfo[coinId];

      return coin?.slug.includes(this.searchBy) || coin?.displayName.toLowerCase().includes(this.searchBy) || coin?.symbol.toLowerCase().includes(this.searchBy);
    },

    copyNestedMap(originalMap) {
      const copiedMap = new Map();

      for (const [key, value] of originalMap) {
        copiedMap.set(key, value instanceof Map ? this.copyNestedMap(value) : value);
      }

      return copiedMap;
    },

    sortFundingRateBySymbol(map, accumulate) {
      const isSortAscending = this.isSortAscending;
      const sortedBy = +this.sortedBy;

      return [...map].sort((a, b) => {
        let first = a[1].get(sortedBy)?.get('current');
        let second = b[1].get(sortedBy)?.get('current');

        if (first === undefined && second === undefined) return 0;
        if (first === undefined) return isSortAscending ? -1 : 1;
        if (second === undefined) return isSortAscending ? 1 : -1;
        if (accumulate) {
          first = [...first.values()].reduce((acc, val) => acc + val, 0);
          second = [...second.values()].reduce((acc, val) => acc + val, 0);
        }

        return first - second;
      });
    },

    sortFundingRatePairs(fundingRatePairs) {
      const sortedBy = +this.sortedBy;
      let sortedPairs = [...fundingRatePairs];

      if (sortedBy) {
        sortedPairs = this.sortFundingRateBySymbol(fundingRatePairs, false);
      } else {
        sortedPairs.sort((a, b) => {
          const aParsed = JSON.parse(a[0])[0];
          const bParsed = JSON.parse(b[0])[0];

          const first = this.coinsInfo[aParsed]?.symbol || this.unknownCoinsInfo[aParsed]?.symbol;
          const second = this.coinsInfo[bParsed]?.symbol || this.unknownCoinsInfo[aParsed]?.symbol;

          if (first === undefined && second === undefined) return 0;
          if (first === undefined) return this.isSortAscending ? -1 : 1;
          if (second === undefined) return this.isSortAscending ? 1 : -1;

          return first.localeCompare(second);
        });
      }

      if (this.isSortAscending) {
        sortedPairs.reverse();
      }

      return sortedPairs;
    },

    openChart(pairId, exchange) {
      if (this.isTokenMode) {
        // const stableCoinIds = [...this.fundingRate.get(pairId).get(exchange).values()].flatMap(type => [...type.keys()]);
        // const uniqueStableCoinIds = [...new Set(stableCoinIds)].map(stableCoinId => [pairId, stableCoinId]);

        return;
      }

      this.currencyPair = JSON.parse(pairId);
      this.selectedExchangeId = exchange;
      this.showChart = true;
    },

    getRoutePath(pair) {
      const [baseCoinId] = JSON.parse(pair);
      const symbol = this.coinsInfo[baseCoinId] || this.unknownCoinsInfo[baseCoinId];

      return symbol ? `/token/${symbol.slug}` : '';
    },

    getPairName(pair) {
      const [baseCoinId, quoteCoinId] = JSON.parse(pair);
      const symbol = this.coinsInfo[baseCoinId] || this.unknownCoinsInfo[baseCoinId];

      return `${symbol ? symbol.symbol : baseCoinId}/${this.coinsInfo[quoteCoinId].symbol}`.toUpperCase();
    },

    closeChart() {
      this.showChart = false;
    },

    sortTable(sortBy) {
      this.isSortAscending = this.sortedBy === sortBy ? !this.isSortAscending : false;
      this.sortedBy = sortBy;
    },

    getChartData() {
      this.showPreloader = true;

      this.debounce(async () => {
        try {
          await this.getFundingRate();
          this.subscribeChartData();

          this.highlightTableCell();
          this.setSize();
        } catch (e) {
          console.log(e);
        } finally {
          this.showPreloader = false;
        }
      }, 500);
    },

    subscribeChartData() {
      ws.subscribe(
          'FUNDING_RATE',
          {
            method: 'SUBSCRIBE',
            params: {}
          },
          (fundingRate) => {
            this.$store.commit('charts/SET_FUNDING_RATE', fundingRate.response);
            this.highlightTableCell();
          }
      );
    },

    unsubscribeChartData() {
      ws.unsubscribe(
          'FUNDING_RATE',
          {
            method: 'UNSUBSCRIBE',
            params: {}
          },
      );
    },

    highlightTableCell() {
      const cells = document.querySelectorAll('.funding-rate-table-body .pointer');

      cells.forEach(cell => {
        cell.addEventListener('mouseover', function () {
          const rowIndex = this.parentElement.rowIndex;
          const cellIndex = this.cellIndex;

          for (let i = 0; i < rowIndex; i++) {
            const row = this.parentElement.parentElement.rows[i];
            row.cells[cellIndex].classList.add('highlight');
          }
          for (let i = 0; i < cellIndex; i++) {
            this.parentElement.cells[i].classList.add('highlight');
          }
        });

        cell.addEventListener('mouseout', function () {
          document.querySelectorAll('.highlight').forEach(highlightedCell => {
            highlightedCell.classList.remove('highlight');
          });
        });
      });
    }

  },

  // mounted() {
  //   this.highlightTableCell();
  // },

  created() {
    this.visibleStableCoins = [...this.stableCoins.keys()];
    this.visibleExchanges = [...this.defaultVisibibleExchanges];

    if (this.fundingRate.size) {
      this.subscribeChartData();
      this.setSize();

      return;
    }
    this.getChartData();
  },

  beforeDestroy() {
    this.unsubscribeChartData();
  }
};
</script>

<style lang="scss" scoped>
.funding-rate {
  z-index: 0;

  &__filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px 15px;
  }

  &__history {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    overflow-x: hidden;
    transition: all 1s;
    background-color: rgba(30, 30, 30, 0.5);
    cursor: pointer;

    &.active {
      z-index: 2;
      opacity: 1;
    }
  }

  &__realized {
    margin-left: auto;
    border: 1px solid var(--mainBorderColor);
    width: 60%;
    background-color: var(--mainBGColor);
  }
}

.chart-filters {
  border-bottom: unset;
  padding: 0;
}

.custom-filters {
  display: flex;
  align-items: center;
  gap: 15px;
}

.show-predicted {
  &__label {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 6px;
  }

  &__fake {
    display: inline-block;
    position: relative;
    border: 1px solid #2dcac2;
    border-radius: 4px;
    width: 17px;
    height: 17px;
  }

  &__checkbox {
    display: none;

    &:checked ~ .show-predicted__fake::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: url("@/assets/images/checkbox-checked.svg");
      background-position: 50% 50%;
    }
  }
}

.dimensions {
  border-radius: 8px;
  padding: 4px;
  background-color: #393939;

  &__button {
    transition: background-color 0.2s;
    border-radius: 6px;
    padding: 4px 8px;

    font-size: 0.75rem;

    &:not(:last-child) {
      margin-right: 4px;
    }

    &.active {
      background-color: #2e2d2d;
    }
  }
}

table {
  width: 100%;
}

table, th, td {
  border: 1px solid #1a1a1a;
  border-collapse: collapse;
  vertical-align: middle;
}

table {
  .secondary-cell {
    padding: 12px 16px;
    color: #92939c;
  }

  th,
  td {
    transition: all .2s;
    font-size: 0.875rem;
    font-weight: 600;
  }
}

table td a.pair {
  transition: color 0.2s;
  font-size: 0.875rem;
  color: var(--textMainColor);

  &:hover {
    color: var(--accent);
  }
}

.exchange:nth-child(even),
tbody tr:nth-child(even) {
  background-color: #333232;
}

.pair,
.exchange {
  display: flex;
  align-items: center;

  &__logo {
    overflow: hidden;
    border-radius: 50%;
    min-width: 22px;
    width: 22px;
    height: 22px;
  }

  svg {
    min-width: 13px;
    width: 13px;
    height: 13px;
  }
}

.active-column {
  background-color: rgba(30, 30, 30, 0.5);

  .active-arrow {
    stroke: var(--lightColor);
  }
}

.exchange {
  justify-content: center;
  width: 100%;
  padding: 12px 16px;
  gap: 6px;
}

.pair__logo {
  margin-right: 8px;
}

.secondary-text {
  font-size: 0.75rem;
  line-height: 16px;
  color: #92939c;
}

.text-сenter {
  text-align: center;
}

.sub-row {
  padding: 10px 16px;
}

.pair {
  display: flex;
  align-items: center;
  gap: 6px;
}

.custom-pagination {
  padding: 0 20px;
}

.cursor-default {
  cursor: default !important;
}

.expand-enter-active,
.expand-leave-active {
  transform: translateX(0);
  transition: transform 1s;
}

.expand-enter,
.expand-leave-to {
  transform: translateX(100%);
}
</style>