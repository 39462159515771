<template>
  <div class="spot-chart chart-wrapper">
    <SectionHeader
        :is-deletable="isDeletable"
        :is-filters-open="isFiltersOpen"
        :show-copy-chart-to-pd="showCopyToPd"
        :title="title"
        @copyChartToPD="$emit('copyChartToPD', parameters)"
        @removeItem="removeItem"
        @setStatic="$emit('setStatic', $event)"
        @setStretchPosition="setStretchPosition"
        @toggleFiltersVisibility="isFiltersOpen = $event"
    />

    <transition name="expand-filters">
      <div v-show="isFiltersOpen" ref="sectionFilters" class="chart-filters">
        <div class="chart-filter">
          <Select
              ref="asset"
              :default-option-value="parametersForSelects.assets || defaultAsset"
              :options="assets"
              title="asset"
              @selected="updateParameter($event, 'assets')"
          />
        </div>

        <div class="chart-filter">
          <MultiSelect
              ref="exchanges"
              :default-option-value="parametersForSelects.exchanges || defaultExchanges"
              :options="exchanges"
              title="exchanges"
              @selected="updateParameter($event, 'exchangeIds')"
          />
        </div>

        <div class="chart-filter">
          <Select
              ref="pair"
              :default-option-value="parametersForSelects.pair || defaultPair"
              :options="pairs"
              title="pair"
              @emptySelect="parameters.currencyPair = defaultPair"
              @selected="updateParameter($event, 'currencyPair')"
          />
        </div>

        <div class="chart-filter">
          <Select
              ref="dimension"
              :default-option-value="parametersForSelects.dimension || defaultDimension"
              :options="dimensions"
              title="Time Period"
              @selected="updateParameter($event, 'dimension')"
          />
        </div>

        <div class="chart-filter">
          <Select
              ref="type"
              :default-option-value="parametersForSelects.typeIndex ?? defaultType"
              :options="types"
              title="Type"
              @selected="updateType"
          />
        </div>

        <div class="chart-filter">
          <Select
              ref="volume"
              :default-option-value="parametersForSelects.volume || defaultVolume"
              :options="volumes"
              title="volume"
              @selected="updateParameter($event, 'volume')"
          />
        </div>

        <div class="chart-filter">
          <Select
              ref="view"
              :default-option-value="parametersForSelects.viewIndex ?? defaultView"
              :options="views"
              title="view"
              @selected="updateView"
          />
        </div>

        <div class="chart-filter reset">
          <button :disabled="showPreloader || resetDisabled" class="reset" @click="resetParameters">
            Reset
          </button>
        </div>
      </div>
    </transition>

    <div class="chart-content">
      <div v-show="showPreloader" class="main-preloader widget-preloader chart-preloader">
        <Loader
            ref="preloader"
            :animationData="require('@/assets/images/Loader.json')"
            :autoPlay="true"
            :loop="true"
            :speed="1"
        />
      </div>

      <highcharts
          ref="chart"
          :options="chartOptions"
          :style="{ width: '100%', height: chartHeight }"
          constructor-type="stockChart"
      />
    </div>

    <Legends
        :legend-parameters="exchangesInfo"
        :legends="legends"
        measure="%"
    />
  </div>
</template>

<script>
import { makeCompactNumber } from '@/helpers';
import { mapActions, mapGetters } from 'vuex';
import Highcharts from 'highcharts';
import debounce from '@/mixins/debounce';
import commonFilters from '@/mixins/filters/commonFilters';
import vwapSettings from '@/mixins/chart-settings/volumesWAP';
import { ws } from '@/utils/socket';
import propsParameters from '@/mixins/propsParameters';

export default {
  name: 'VWAP',

  components: {},

  mixins: [debounce, vwapSettings, commonFilters, propsParameters],

  props: {
    isResizeble: Boolean,
  },

  data: () => ({
    Highcharts,
    parameters: {},
    legends: [],
    series: new Map(),
    suffix: '%',
    showPreloader: true,
    resetDisabled: true,
    isFiltersOpen: true,
  }),

  computed: {
    ...mapGetters({
      filters: 'filters/exchangeAssetPrices',
      coinsInfo: 'filters/coinsInfo',
    }),

    title() {
      const type = this.types.get(this.parameters.typeIndex ?? this.defaultType);
      return type ? `Volume-Weighted Average Price ${type}` : '';
    },

    defaultParameters() {
      return {
        exchangeIds: this.defaultExchanges,
        assets: this.defaultAsset,
        currencyPair: this.defaultPair,
        dimension: this.defaultDimension,
        typeIndex: this.defaultType,
        volume: this.defaultVolume,
        viewIndex: this.defaultView,
      };
    },

    defaultVolume() {
      return this.volumes.keys().next().value;
    },

    defaultType() {
      return this.types.keys().next().value;
    },

    defaultView() {
      return this.views.keys().next().value;
    },

    types() {
      const types = ['Bids', 'Asks', 'Avg (Bids & Asks)'];

      return new Map(types.map((type, index) => [index, type]).reverse());
    },

    dimensions() {
      return new Map([
        ['NOW', 'Now'],
        ['MINUTE_1', 'Minute'],
        ['HOUR_1', 'Hour'],
        ['DAY_1', 'Day'],
        ['WEEK_1', 'Week'],
        ['MONTH_1', 'Month'],
        ['YEAR_1', 'Year'],
      ]);
    },

    volumes() {
      const volumes = [1e4, 1e5, 1e6, 5e6, 1e7];

      return new Map(volumes.map(volume => [volume, makeCompactNumber(volume)]));
    },

    views() {
      const views = ['Spline', 'Percent', 'Total'];

      return new Map(views.map((view, index) => [index, view]));
    },
  },

  methods: {
    ...mapActions({
      getVolumeWAPData: 'charts/getVolumeWAP',
    }),

    updateParameter(parameter, name) {
      this.resetDisabled = false;
      this.parameters = {
        ...this.parameters,
        [name]: parameter
      };
      this.getVolumeWAP();
      this.$refs.chart.chart.reflow();
    },

    updateType(typeIndex) {
      this.parameters.typeIndex = typeIndex;
      const series = [...this.series.keys()].map(exchangeName => ({
        ...this.seriesItem,
        name: exchangeName,
        color: this.exchangesInfo[exchangeName].color,
        data: [...this.series.get(exchangeName).entries()].map(([time, data]) => [time, data[typeIndex]])
      }));

      series.sort((a, b) => b.data.at(-1)[this.parameters.typeIndex] - a.data.at(-1)[this.parameters.typeIndex]);
      this.chartOptions.series = series;
      this.updateView(this.parameters.viewIndex);
    },

    updateView(view) {
      this.parameters.viewIndex = view;
      const seriesType = view ? 'area' : 'spline';

      this.chartOptions.series = this.chartOptions.series.map(ser => ({
        ...ser,
        type: seriesType
      }));

      this.chartOptions.plotOptions.area.stacking = this.views.get(view).toLowerCase();
    },

    async resetParameters() {
      this.parameters = { ...this.defaultParameters };
      this.parameters.typeIndex = 2;
      this.parameters.viewIndex = 0;
      this.resetDisabled = true;

      await this.getVolumeWAP();

      this.$refs.exchanges.reset();
      this.$refs.asset.reset();
      this.$refs.pair.reset();
      this.$refs.dimension.reset();
      this.$refs.type.reset();
      this.$refs.volume.reset();
      this.$refs.view.reset();
    },

    getVolumeWAP() {
      this.showPreloader = true;
      this.debounce(async () => {
        this.unsubscribeVolumesWapData();

        // eslint-disable-next-line no-unused-vars
        const { typeIndex, dimension, viewIndex, ...parameters } = this.parameters;
        this.wsParameters = JSON.stringify({
          ...parameters,
          assets: [parameters.assets],
          currencyPair: parameters.currencyPair.split(',')
        });

        try {
          const volumes = await this.getVolumeWAPData(this.parameters);
          this.series = new Map();
          this.setSeries(volumes);

          this.parameters.dimension === this.defaultDimension
              ? this.subscribeVolumesWapData()
              : this.wsParameters = null;
        } catch (e) {
          console.log(e);
        } finally {
          this.showPreloader = false;
        }
      }, 500);
    },

    setSeries(volumes) {
      for (const key in volumes) {
        this.series.set(key, new Map(
            volumes[key].map(([time, ...rest]) => [time, rest])
        ));
      }

      this.updateType(this.parameters.typeIndex);

      this.legends = this.chartOptions.series.map(ser => ({
        name: ser.name,
        currentPrice: ser.data.at(-1)[1]
      }));
    },

    setStretchPosition() {
      this.$refs.chart.chart.fullscreen.toggle();
    },

    subscribeVolumesWapData() {
      ws.subscribe(
          'VOLUME_WAP',
          {
            method: 'SUBSCRIBE',
            params: this.wsParameters
          },
          (res) => {
            if (res.params !== this.wsParameters) {
              return;
            }

            const volumes = res.response;

            [...this.series.keys()].forEach(exchangeName => {
              const data = volumes[exchangeName];

              if (data) {
                const [time, ...rest] = data[0];

                this.series.get(exchangeName).set(time, rest);
              } else {
                const updateTime = Object.values(volumes)[0][0][0];
                const lastValue = [...this.series.get(exchangeName)].pop()[1];

                this.series.get(exchangeName).set(updateTime, lastValue);
              }
            });

            this.chartOptions.series = this.chartOptions.series.map(series => {
              const exchange = this.series.get(series.name);
              exchange.delete(exchange.keys().next().value);

              return {
                ...series,
                data: [...exchange.entries()].map(([time, data]) => [time, data[this.parameters.typeIndex]])
              };
            });

            this.legends.forEach(exchange => {
              const data = this.series.get(exchange.name);

              if (data) {
                exchange.currentPrice = [...data][data.size - 1][1][this.parameters.typeIndex];
              }
            });
          }
      );
    },

    unsubscribeVolumesWapData() {
      ws.unsubscribe(
          'VOLUME_WAP',
          {
            method: 'UNSUBSCRIBE',
            params: this.wsParameters
          },
      );

      this.wsParameters = null;
    },

    removeItem() {
      this.$emit('removeItem', null);
    },
  },

  mounted() {
    this.getVolumeWAP();
  },

  beforeDestroy() {
    this.unsubscribeVolumesWapData();
  }
};
</script>

<style lang="scss" scoped>
</style>