<template>
  <highcharts
      ref="chart"
      :options="chartOptions"
      :style="{ width: '100%', height: chartHeight }"
  />
</template>

<script>
import debounce from '@/mixins/debounce';
import Highcharts from 'highcharts';
import HighchartsNoData from 'highcharts/modules/no-data-to-display';
import oneLineSettings from '@/mixins/chart-settings/oneLine';
import reflowHighchart from '@/mixins/reflowHighchart';

HighchartsNoData(Highcharts);

export default {
  name: 'OneLine',

  props: {
    seriesData: {
      type: Array,
      default: () => [],
    },

    isFiltersOpen: {
      type: Boolean,
      required: true,
    },

    isResizeble: Boolean,
  },

  mixins: [debounce, oneLineSettings, reflowHighchart],

  data: () => ({
    chartHeight: '100%',
  }),

  watch: {
    seriesData(seriesData) {
      if (!seriesData.length) {
        return;
      }

      this.chartOptions.series[0].data = seriesData;

      const plotLine = this.chartOptions.yAxis[1].plotLines[0];
      plotLine.value = seriesData.at(-1)[1];
    },
  },

  methods: {
    toggleChartFullscreen() {
      this.$refs.chart.chart.fullscreen.toggle();
    }
  },

  mounted() {
    setTimeout(() => {
      this.$refs.chart?.chart.reflow();
    }, 0);
  }
};
</script>

<style scoped>

</style>
