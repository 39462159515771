export default {
  walls: 'OrderbookWalls',
  ratio: 'Ratio',
  orderBook: 'VolumeByExchange',
  asset: 'AssetPrice',
  dominance: 'Dominance',
  statistics: 'SymbolStatistics',
  announcements: 'Announcements',
  dexVsCex: 'DexVsCex',
  tradingVol: 'VolumeByPrice',
  orderbookWalls: 'OrderbookWalls',
  vwap: 'VolumesWAP',
  volByTime: 'VolumeByTime',
  techAnalysis: 'TechAnalysis',
  funding: 'fundingRate',
  cryptoToStockIndexes: 'CryptoToStockIndexes',
  volDistribution: 'volumeDistribution',
  coinToUSD: 'CoinToUSD',
  exchangeAbout: 'About',
  exchangeInfo: 'Info',
  exchangeMarkets: 'Markets',
  exchangeOrderbook: 'VolumeDistribution',
  exchangeScamScore: 'ScamScore',
  tokenInfo: 'TokenInfo',
  tokenStatistics: 'TokenStatistics',
  tokenChart: 'TokenChart',
  tokenCommunity: 'TokenCommunity',
  tokenAbout: 'TokenAbout',
  tokenMarkets: 'TokenMarkets',
  fearGreed: 'FearGreed',
  technicals: 'Technicals',
  powTokens: 'PoWTokens',
  spotExchanges: 'SpotExchanges',
  arbitrageCalculator: 'ArbitrageCalculator',
};