<template>
  <div class="chart-wrapper">
    <SectionHeader
        :is-deletable="isDeletable"
        :is-filters-open="isFiltersOpen"
        :show-copy-chart-to-pd="showCopyToPd"
        title="Orderbook vol by time"
        @copyChartToPD="$emit('copyChartToPD', parameters)"
        @removeItem="removeItem"
        @setStretchPosition="setStretchPosition"
        @toggleFiltersVisibility="isFiltersOpen = $event"
    />

    <transition name="expand-filters">
      <div v-show="isFiltersOpen" ref="sectionFilters" class="chart-filters">
        <div class="chart-filter">
          <Select
              ref="asset"
              :default-option-value="parametersForSelects.assets || defaultAsset"
              :options="assets"
              title="asset"
              @selected="updateParameter($event, 'asset')"
          />
        </div>

        <div class="chart-filter">
          <MultiSelect
              ref="exchanges"
              :default-option-value="parametersForSelects.exchange || defaultExchanges"
              :options="exchanges"
              title="exchanges"
              @selected="updateParameter($event, 'exchangeIds')"
          />
        </div>

        <div class="chart-filter">
          <Select
              ref="pair"
              :default-option-value="parametersForSelects.pair || defaultPair"
              :options="pairs"
              title="trading pair"
              @emptySelect="parameters.currencyPair = defaultPair"
              @selected="updateParameter($event, 'currencyPair', true)"
          />
        </div>

        <div class="chart-filter">
          <Select
              ref="slippage"
              :default-option-value="parametersForSelects.slippageIndex || defaultSlippage"
              :options="slippages"
              suffix="%"
              title="slippage"
              @emptySelect="parameters.slippageIndex = defaultSlippage"
              @selected="updateParameter($event, 'slippageIndex')"
          />
        </div>

        <div class="chart-filter">
          <Select
              ref="dimension"
              :default-option-value="parametersForSelects.dimension || defaultDimension"
              :options="dimensions"
              title="Time Period"
              @selected="updateParameter($event, 'dimension')"
          />
        </div>

        <div class="chart-filter">
          <Select
              ref="view"
              :default-option-value="parametersForSelects.viewIndex || defaultView"
              :options="views"
              title="view"
              @selected="updateView"
          />
        </div>

        <div class="chart-filter reset">
          <button :disabled="resetDisabled || showPreloader" class="reset" @click="resetParameters">
            Reset
          </button>
        </div>
      </div>
    </transition>

    <div class="chart-content">
      <div v-show="showPreloader" class="main-preloader widget-preloader chart-preloader">
        <Loader
            ref="preloader"
            :animationData="require('@/assets/images/Loader.json')"
            :autoPlay="true"
            :loop="true"
            :speed="1"
        />
      </div>

      <highcharts
          ref="chart"
          :options="chartOptions"
          :style="{ width: '100%', height: chartHeight }"
          constructor-type="stockChart"
      />
    </div>

    <Legends
        :legend-parameters="exchangesInfo"
        :legends="legends"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import vwapSettings from '@/mixins/chart-settings/volumesWAP';
import debounce from '@/mixins/debounce';
import { ws } from '@/utils/socket';
import propsParameters from '@/mixins/propsParameters';
import commonFilters from '@/mixins/filters/commonFilters';

export default {
  name: 'OrderbookVolumeByTime',

  mixins: [commonFilters, vwapSettings, debounce, propsParameters],

  components: {},

  props: {

    isResizeble: Boolean,
  },

  data: () => ({
    legends: [],
    parameters: {},
    wsParameters: '',
    suffix: '',
    showPreloader: true,
    resetDisabled: true,
    isFiltersOpen: true,
  }),

  computed: {
    ...mapGetters({
      exchangesInfo: 'filters/exchangesInfo',
    }),

    defaultParameters() {
      return {
        exchangeIds: this.defaultExchanges,
        asset: this.defaultAsset,
        currencyPair: this.defaultPair,
        dimension: this.defaultDimension,
        slippageIndex: this.defaultSlippage,
        viewIndex: this.defaultView,
      };
    },

    defaultPair() {
      return this.pairs.keys().next().value;
    },

    defaultView() {
      return this.views.keys().next().value;
    },

    views() {
      const views = ['Spline', 'Percent', 'Total'];

      return new Map(views.map((view, index) => [index, view]));
    },

    dimensions() {
      return new Map([
        ['NOW', 'Now'],
        ['DAY_1', 'Day'],
        ['WEEK_1', 'Week'],
        ['MONTH_1', 'Month'],
        ['YEAR_1', 'Year'],
      ]);
    }
  },

  methods: {
    ...mapActions({
      getVolumeByTime: 'charts/getVolumeByTime',
    }),

    setStretchPosition() {
      this.$refs.chart.chart.fullscreen.toggle();
    },

    updateParameter(parameter, name) {
      this.resetDisabled = false;
      this.parameters = {
        ...this.parameters,
        [name]: parameter
      };

      this.getChartData();
    },

    updateView(view) {
      const seriesType = view ? 'area' : 'spline';

      this.chartOptions.series = this.chartOptions.series.map(ser => ({
        ...ser,
        type: seriesType
      }));
      this.parameters.viewIndex = view;
      this.chartOptions.plotOptions.area.stacking = this.views.get(view).toLowerCase();
    },

    async resetParameters() {
      this.parameters = { ...this.defaultParameters };
      this.resetDisabled = true;

      await this.getChartData();

      this.$refs.exchanges.reset();
      this.$refs.pair.reset();
      this.$refs.slippage.reset();
      this.$refs.dimension.reset();
      this.$refs.view.reset();
    },

    getExchangeColor(exchange) {
      return this.exchangesInfo[exchange]?.color;
    },

    getChartData() {
      this.showPreloader = true;
      this.debounce(async () => {
        this.unsubscribeVolByTimeData();

        const { asset, currencyPair, slippageIndex } = this.parameters;
        this.wsParameters = JSON.stringify({
          assets: [asset],
          currencyPair: currencyPair.split(','),
          slippageIndex: slippageIndex
        })

        try {
          // eslint-disable-next-line no-unused-vars
          const { viewIndex, ...parameters } = this.parameters
          const res = await this.getVolumeByTime(parameters);
          const series = Object.entries(res).map(([exchangeName, exchangeData]) => ({
            ...this.seriesItem,
            name: exchangeName,
            color: this.getExchangeColor(exchangeName),
            // pointInterval: 24 * 3600 * 1000,
            data: exchangeData,
          }));

          series.sort((a, b) => b.data.at(-1)[1] - a.data.at(-1)[1]);
          this.legends = series.map(ser => ({
            name: ser.name,
            currentPrice: ser.data.at(-1)[1]
          }));

          this.chartOptions.series = series;
          this.updateView(this.parameters.viewIndex);
          this.parameters.dimension === this.defaultDimension
              ? this.subscribeVolByTimeData()
              : this.wsParameters = null;
        } catch (e) {
          console.log(e);
        } finally {
          this.showPreloader = false;
        }

      }, 500);
    },

    subscribeVolByTimeData() {
      ws.subscribe(
          'ORDER_BOOK_VOL_BY_TIME',
          {
            method: 'SUBSCRIBE',
            params: this.wsParameters
          },
          (res) => {
            if (res.params !== this.wsParameters) {
              return;
            }

            const volumes = res.response;
            this.chartOptions.series.forEach(ser => {
              let volume = volumes[ser.name];

              if (!volume) {
                volume = ser.data.at(-1);
                volume.splice(0, 1, res.eventTime);
              }

              ser.data.push([volume[0], volume[1]]);
              ser.data.shift();
            });

            this.legends.forEach(exchange => {
              const data = this.chartOptions.series.find(ser => ser.name === exchange.name).data;
              const lastEl = data.at(-1);

              if (data) {
                exchange.currentPrice = lastEl[1];
              }
            });

            this.legends = [...this.legends];
          }
      );
    },

    unsubscribeVolByTimeData() {
      ws.unsubscribe(
          'ORDER_BOOK_VOL_BY_TIME',
          {
            method: 'UNSUBSCRIBE',
            params: this.wsParameters
          },
      );

      this.wsParameters = null;
    },

    removeItem() {
      this.$emit('removeItem', null);
    },
  },

  created() {
    this.getChartData();
  },

  beforeDestroy() {
    this.unsubscribeVolByTimeData();
  }
};
</script>

<style lang="scss" scoped>
</style>